import { Injectable } from '@angular/core';
import { ProceduresAndSpecialty } from '@app/core/models/front/public';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CatalogueSearchService {
  private proceduresSource = new BehaviorSubject<ProceduresAndSpecialty[] | null>(null);
  currentProcedures: Observable<ProceduresAndSpecialty[] | null> = this.proceduresSource.asObservable();
 


  updateProcedures(procedures: ProceduresAndSpecialty[]) {
    this.proceduresSource.next(procedures);
  }
}
