import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from '@app/shared/shared.module';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { PatientsModule } from '../patients/patients.module';
import { SharedComponentModule } from '../shared/components/shared-component.module';
import { ButtonsConsentComponent } from './components/buttons-consent/buttons-consent.component';
import { CiTemplateComponent } from './components/ci-template/ci-template.component';
import { DataConsentComponent } from './components/data-consent/data-consent.component';
import { DetailConsentComponent } from './components/detail-consent/detail-consent.component';
import { DraftConsentComponent } from './components/draft-consent/draft-consent.component';
import { ExpiredConsentComponent } from './components/expired-consent/expired-consent.component';
import { GenConsentComponent } from './components/gen-consent/gen-consent.component';
import { LegalRepresentativeSignsComponent } from './components/legal-representative-signs/legal-representative-signs.component';
import { PendingBioConsentComponent } from './components/pending-bio-consent/pending-bio-consent.component';
import { PendingSignRl2ConsentComponent } from './components/pending-sign-rl2-consent/pending-sign-rl2-consent.component';
import { PrintedConsentComponent } from './components/printed-consent/printed-consent.component';
import { SignedConsentComponent } from './components/signed-consent/signed-consent.component';
import { EditConsentComponent } from './pages/edit-consent/edit-consent.component';
import { ListConsentsComponent } from './pages/list-consents/list-consents.component';
import { NewConsentComponent } from './pages/new-consent/new-consent.component';

@NgModule({
  declarations: [
    ListConsentsComponent,
    NewConsentComponent,
    EditConsentComponent,
    ButtonsConsentComponent,
    DataConsentComponent,
    GenConsentComponent,
    DetailConsentComponent,
    SignedConsentComponent,
    PrintedConsentComponent,
    DraftConsentComponent,
    PendingBioConsentComponent,
    PendingSignRl2ConsentComponent,
    ExpiredConsentComponent,
    LegalRepresentativeSignsComponent,
    CiTemplateComponent,
  ],

  imports: [
    CommonModule,
    FontAwesomeModule,
    SharedModule,
    ReactiveFormsModule,
    FormsModule,
    SharedComponentModule,
    PatientsModule,
  ],
  exports: [
    DataConsentComponent,
    NewConsentComponent,
    GenConsentComponent
  ]
})
export class ConsentsModule {}
