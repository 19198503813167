<div class="back-white-color box_title">
    <h4 class="subtitle tipo-second-color regular center">{{'SUPER-ADMIN.CLIENT.DATA-COMPANY.TITLE-DATA-COMPANY' | translate}}</h4>
</div>
<div class="box-container">
    <div class="box">
        <form [formGroup]="dataCompany">
            <div class="form">
                <div class="row">
                    <div class="input">
                        <label for="company_name">*{{'SUPER-ADMIN.CLIENT.DATA-COMPANY.LABEL-COMPANY-NAME' | translate}}</label>
                        <input type="text" id="company_name" formControlName="businessName" [ngClass]="{ wrong: shouldShowError('businessName') }">
                    </div>
                    <p
                    class="message_error wrongtext small"
                    *ngIf="this.dataCompany.get('businessName')?.errors?.['required'] && this.isSubmit">
                    {{'SUPER-ADMIN.CLIENT.DATA-COMPANY.MESSAGE-ERROR-COMPANY-NAME' | translate}}
                  </p>
                    <div class="input">
                        <label for="privacy_url">{{'SUPER-ADMIN.CLIENT.DATA-COMPANY.LABEL-PRIVACY-URL' | translate}}</label>
                        <input type="text" id="privacy_url" formControlName="website" >
                        <span class="small tipo-second-color">{{'SUPER-ADMIN.CLIENT.DATA-COMPANY.MESSAGE-URL' | translate}}</span>
                    </div>
                    <div class="input">
                        <label for="agent">{{'SUPER-ADMIN.CLIENT.DATA-COMPANY.LABEL-AGENT' | translate}}</label>
                        <input type="text" id="agent" formControlName="name"> 
                    </div>
                    <div class="input">
                        <label for="email">{{'SUPER-ADMIN.CLIENT.DATA-COMPANY.LABEL-EMAIL-AGENT' | translate}}</label>
                        <input type="email" id="email" formControlName="email">
                    </div>
                </div>
               <div class="row">
                <div class="input">
                    <label for="document">*{{'SUPER-ADMIN.CLIENT.DATA-COMPANY.LABEL-DOCUMENT' | translate}}</label>
                    <input type="text" id="document" formControlName="cif" [ngClass]="{ wrong: shouldShowError('cif') }">
                    <span class="small tipo-second-color">{{'SUPER-ADMIN.CLIENT.DATA-COMPANY.MESSAGE-DOCUMENT' | translate}}</span>
                </div>
                <p
                class="message_error wrongtext small"
                *ngIf="this.dataCompany.get('cif')?.errors?.['pattern'] && this.isSubmit">
                {{'SUPER-ADMIN.CLIENT.DATA-COMPANY.MESSAGE-ERROR-DOCUMENT' | translate}}
              </p>
                <div class="space">
                </div>
                <div class="input">
                    <label for="document_agent">{{'SUPER-ADMIN.CLIENT.DATA-COMPANY.LABEL-DOCUMENT-AGENT' | translate}}</label>
                    <input type="text" id="document_agent" formControlName="dniCif" [ngClass]="{ wrong: shouldShowError('dniCif') }">
                </div>
                <p
                class="message_error wrongtext small"
                *ngIf="this.dataCompany.get('dniCif')?.errors?.['pattern'] && this.isSubmit">
                {{'SUPER-ADMIN.CLIENT.DATA-COMPANY.MESSAGE-ERROR-DOCUMENT' | translate}}
              </p>

                <div class="input">
                    <label for="mobile">{{'SUPER-ADMIN.CLIENT.DATA-COMPANY.LABEL-PHONE-AGENT' | translate}}</label>
                    <input type="tel" id="mobile" formControlName="mobile" [ngClass]="{ wrong: shouldShowError('mobile') }">
                </div>
                <p
                class="message_error wrongtext small"
                *ngIf="this.dataCompany.get('mobile')?.errors?.['pattern'] && this.isSubmit">
                {{'SUPER-ADMIN.CLIENT.DATA-COMPANY.MESSAGE-ERROR-PHONE-AGENT' | translate}}
              </p>

               </div>
            </div>          
          
           <div class="buttons">
            <button class="button tipo-white-color back-blue-color" (click)="togglePagesOneTwo()">
                <fa-icon [icon]="faCircleArrowLeft" class="icono"></fa-icon>
               {{'SUPER-ADMIN.CLIENT.DATA-COMPANY.BUTTON-BACK' | translate}}
            </button>
            <button class="button tipo-white-color back-blue-color" (click)="onSubmit()">                
               {{'SUPER-ADMIN.CLIENT.DATA-HIRING.BUTTON-CONTINUE' | translate}}
               <fa-icon [icon]="faCircleArrowRight" class="icono"></fa-icon>
            </button>
           </div>
        </form>
    </div> 
 </div>