<div
  class="back-light-blue-color"
  [ngClass]="{ wrapper: isApp, wrapper_quick: isQuickapi }">
  <div class="maxwidth">
      <div class="inline">
        <div class="box box2">
          <div class="input">
            <label for="language tipo-main-color regular">{{ "PRIVATE.PATIENT.GEN-DOCS.SELECT-LANGUAGE" | translate }}</label>
            <select name="language" id="language" [(ngModel)]="selectedTemplateLanguage" (ngModelChange)="changeTemplateLanguage($event)">
              <option [ngValue]="null" disabled="disabled">{{ "PRIVATE.PATIENT.GEN-DOCS.SELECT-LANGUAGE-FIRST-SELECT-VALUE" | translate }}</option>
              <option *ngFor= "let language of languages; let i=index" ngValue="{{language.locale}}">{{language.name}}</option>
            </select>
            <label for="docType tipo-main-color regular">{{ "PRIVATE.PATIENT.GEN-DOCS.SELECT-TEMPLATE" | translate }}</label>
            <select name="docType" id="doc_type"  [(ngModel)]="selectedTemplateUuid" (ngModelChange)="changeSelectedTemplate($event)">
              <option [ngValue]="null" disabled="disabled">{{ "PRIVATE.PATIENT.GEN-DOCS.SELECT-TEMPLATE-FIRST-SELECT-VALUE" | translate }}</option>
              <option *ngFor= "let template of templates; let i=index" ngValue="{{template.uuid}}">{{template.type}}</option>
            </select>
          </div>
        </div>
        <div class="sign">
          <app-generate-doc
          (formGroupEvent)="handleChildFormGroup($event)"
          [isPatientCreated]="true" [patient]="patient" ></app-generate-doc>
        </div>

      </div>



    <div class="docpdf rgpd">
      <app-rgpd-template [patient]="patient"></app-rgpd-template>
    </div>
  </div>
</div>
