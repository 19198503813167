import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { SharedModule } from '@app/shared/shared.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ListPatientsAdminComponent } from './pages/list-patients-admin/list-patients-admin.component';
import { FilePatientAdminComponent } from './pages/file-patient-admin/file-patient-admin.component';
import { PatientsModule } from '@app/private/patients/patients.module';


@NgModule({
    declarations: [
     ListPatientsAdminComponent,   
     FilePatientAdminComponent
     
    ],
    imports: [
      CommonModule,
      FontAwesomeModule,
      SharedModule,
      ReactiveFormsModule,
      FormsModule,
      PatientsModule
    ],   
  
  })
  export class PatientsAdminModule { }