import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { COMPANY_ROLES, DOCTOR_ROLES, GENERATE_CIS, SUPERVISOR } from '@app/core/constants/Permissions';
import {
  ANESTESIA_ADDITIONAL_CLAUSE,
  OPHTHALMOLOGY_ADDITIONAL_CLAUSE,
} from '@app/core/constants/additional-clauses.constants';
import StatusEnum from '@app/core/enums/status.enum';
import { Consent } from '@app/core/models/input/consent/consent.model';
import { User } from '@app/core/models/input/user.model';
import { ClausesService } from '@app/core/services/api/consents/clauses/clauses.service';
import { LoginService } from '@app/core/services/login/login.service';
import { ProcedureService } from '@app/core/services/procedure/procedure.service';
import { RoleService } from '@app/core/services/role/role.service';
import { faBackward, faMaximize, faMinimize } from '@fortawesome/free-solid-svg-icons';
import { ConsentService } from '../../../../core/services/consent/consent.service';
import { ConsentsShareService } from '../../services/consents-share.service';
import { Subscription } from 'rxjs';
import { DEFAULT_ELCI_LANGUAGE } from '@app/core/constants/Constants';

@Component({
  selector: 'app-edit-consent',
  templateUrl: './edit-consent.component.html',
  styleUrls: ['./edit-consent.component.scss'],
})
export class EditConsentComponent implements OnInit, OnDestroy {
  faMaximize = faMaximize;
  faBackward = faBackward;
  faMinimize = faMinimize;

  isMax = false;
  consent?: Consent;
  user = new User();
  canGenerateCis = false;
  isDoctor = false;
  StatusEnum = StatusEnum;
  patientName?: string;
  patientFirstName?: string;
  patientSecondName?: string;

  isApp = true;
  isQuickapi = false;

  GENERATE_CIS = GENERATE_CIS;
  COMPANY_ROLES = COMPANY_ROLES;
  DOCTOR_ROLES = DOCTOR_ROLES;
  SUPERVISOR = SUPERVISOR;

  private selectedTemplateLanguageSubscription?: Subscription;
  selectedTemplateLanguage = DEFAULT_ELCI_LANGUAGE;

  constructor(
    private route: ActivatedRoute,
    private consentService: ConsentService,
    private consentsShareService: ConsentsShareService,
    private procedureService: ProcedureService,
    private clauseService: ClausesService,
    public roleService: RoleService,
    private loginService: LoginService
  ) {}

  ngOnInit() {
    this.handleSelectedTemplateLanguageChange();

    if (this.roleService.isQuickapi()) {
      this.isApp = false;
      this.isQuickapi = true;
    }

    this.consentService.getConsent(this.route.snapshot.paramMap.get('consentUuid') as string).subscribe({
      next: consent => {
        this.consent = consent;        
        this.consentService.setConsent(consent);
        this.user = this.loginService.userValue ?? new User();

        this.canGenerateCis =
          this.roleService.hasRole(SUPERVISOR) ||
          this.roleService.hasRole(COMPANY_ROLES);

        this.isDoctor = this.roleService.hasRole(DOCTOR_ROLES) && this.consent?.doctor?.uuid === this.user.uuid;
        this.patientName = this.consent?.patient?.name;
        this.patientFirstName = this.consent?.patient?.firstLastName;
        this.patientSecondName = this.consent?.patient?.secondLastName;      
        this.getProcedureAndAdditionalClause(consent.idProcedure ?? 0, consent.consentAdditionalData.additionalClause);
      },
    });
  }

  getProcedureAndAdditionalClause(idProcedure: number, additionalClauseCode: string | undefined) {
    this.procedureService
      .getProcedureWithImagesByCode(idProcedure.toString(), this.selectedTemplateLanguage.split('_')[0])
      .subscribe({
        next: procedure => {
          if (procedure) {
            this.consentsShareService.setSelectedProcedure(procedure);
            if (additionalClauseCode) {
              this.getClausesByProcedure(
                this.getAdditionalClauseProcedureIdPerSpecialty(procedure.specialityId?.toString() ?? '0'),
                additionalClauseCode
              );
            }
          }
        },
      });
  }

  getAdditionalClauseProcedureIdPerSpecialty(specialtyId: string): string {
    switch (specialtyId) {
      case '5':
        return OPHTHALMOLOGY_ADDITIONAL_CLAUSE;
      case '31':
        return ANESTESIA_ADDITIONAL_CLAUSE;
      default:
        return '0';
    }
  }

  private getClausesByProcedure(idProcedure: string, additionalClauseCode: string) {
    this.clauseService.getClausesByProcedure(idProcedure).subscribe({
      next: additionalClauses => {
        const additionalClause = additionalClauses?.find(clause => clause.code === additionalClauseCode);
        if (additionalClause) {
          this.consentsShareService.setSelectedAdditionalClause(additionalClause);
        }
      },
    });
  }

  handleSelectedTemplateLanguageChange(): void {
    this.selectedTemplateLanguageSubscription = this.consentsShareService.templateLanguage$.subscribe(locale => {
      if (locale && this.selectedTemplateLanguage !== locale && locale != '') {
        this.selectedTemplateLanguage = locale;
      }
    });
  }

  maximizeTemplate() {
    this.isMax = !this.isMax;
  }

  ngOnDestroy(): void {
    if (this.selectedTemplateLanguageSubscription) {
      this.selectedTemplateLanguageSubscription.unsubscribe();
    }
  }
}
