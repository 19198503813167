import { SubscriptionTypes } from "./company/company-parameters.model";
import { UserDevice } from "./device.model";
import { Language } from "./language.model";
import { Role } from "./role.model";
import { Specialty } from "./specialty.model";
import { UserStatus } from "./user-status.model";


export class User {
    id?:string;
    uuid?: string;
    companyUuid?: string;
    name?: string;
    lastname?: string;
    company?: string;
    dniCif?: string;
    userDevice?: UserDevice;
    logo?: string;
    roles?: Role[];
    specialty?: Specialty [];
    service?: string;
    userName? :string;
    userStatus? : UserStatus;
    language?: Language;
    subscription?: SubscriptionTypes[];
    defaultPhonePrefix?: string;
    creationDate?: Date;
    cancelDate?: Date;
    email?:string;

}

export class UserSignature {
    userUuid?: string;
    format?: string;
    content?: string;
}

