import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-file-company',
  templateUrl: './file-company.component.html'
})
export class FileCompanyComponent {

  @Input() his?: string;
}
