<!-- When statusId = 2 or the consent was generated by the doctor -->
<td
  class="action doctor"
  *ngIf="
    isDoctor &&
      (status?.id === Status.SIGNED ||
        status?.id === Status.REVOKED ||
        status?.id === Status.PENDING_SIGN_RL2 ||
        status?.id === Status.REVOKED_RL2 ||
        status?.id === Status.PRINTED ||
        status?.id === Status.PENDING_BIO_SIGN ||
        (status?.id === Status.EXPIRED && objectType === ObjectTypeEnum.CONSENT));
    else noDoctor
  ">
    <!-- Download file button-->
    <!-- Icon when if is not downloading the file -->
    <div class="tooltip">
      <fa-icon
    [icon]="faFileCircleCheck"
    class="icono tipo-second-color"
    (click)="downloadFile(downloadUuid ?? '')"
    *ngIf="!downloadingFile && (doctorUuid === user?.uuid) && (status?.id !== Status.PENDING_BIO_SIGN)"></fa-icon>
    <p class="text_tip small">
      {{"PRIVATE.CONSENTS.LIST-CONSENTS.TOOLTIP-DOWNLOAD-DOCUMENT" | translate}}
    </p>
     <!-- Icon when if is downloading the file -->
     <fa-icon
     [icon]="faSpinner"
     animation="spin-pulse"
     style="padding-left: 7px"
     class="icono tipo-second-color"
     *ngIf="downloadingFile && (doctorUuid === user?.uuid)"></fa-icon>

    </div>


    <!-- Download report button-->
    <!-- Icon when if is not downloading the report -->
    <div class="tooltip">
        <fa-icon
          [icon]="faReceipt"
          style="padding-left: 7px"
          class="icono tipo-second-color"
          (click)="downloadRemoteFile(downloadUuid ?? '')"
          *ngIf="!downloadingRemote && (doctorUuid === user?.uuid && OutputType.DIGITAL_REMOTE === outputType?.id && this.objectType === ObjectTypeEnum.CONSENT)"></fa-icon>
          <p class="text_tip small">
            {{"PRIVATE.CONSENTS.LIST-CONSENTS.TOOLTIP-DOWNLOAD-REMOTE" | translate }}
          </p>
          <!-- Icon when if is downloading the report -->
          <fa-icon
          [icon]="faSpinner"
          animation="spin-pulse"
          style="padding-left: 7px"
          class="icono tipo-second-color"
          *ngIf="downloadingRemote && (doctorUuid === user?.uuid && OutputType.DIGITAL_REMOTE === outputType?.id && this.objectType === ObjectTypeEnum.CONSENT)"></fa-icon>
    </div>
     <!-- Download remote button-->
    <!-- Icon when if is not downloading the remote -->
    <div class="tooltip">
      <fa-icon
        [icon]="faFingerprint"
        style="padding-left: 7px"
        class="icono tipo-second-color"
        (click)="downloadReportFile(downloadUuid ?? '')"
        *ngIf="!downloadingReport && (doctorUuid === user?.uuid && OutputType.DIGITAL_REMOTE === outputType?.id )"></fa-icon>
        <p class="text_tip small">
          {{"PRIVATE.CONSENTS.LIST-CONSENTS.TOOLTIP-DOWNLOAD-EVIDENCE" | translate }}
        </p>
        <!-- Icon when if is downloading the report -->
        <fa-icon
        [icon]="faSpinner"
        animation="spin-pulse"
        style="padding-left: 7px"
        class="icono tipo-second-color"
        *ngIf="downloadingReport && (doctorUuid === user?.uuid && OutputType.DIGITAL_REMOTE === outputType?.id )"></fa-icon>
  </div>

</td>
<ng-template #noDoctor>
  <td
    class="action nodoctor"
    *ngIf="
      (status?.id === Status.SIGNED ||
        status?.id === Status.REVOKED ||
        status?.id === Status.PENDING_SIGN_RL2 ||
        status?.id === Status.REVOKED_RL2 ||
        status?.id === Status.PRINTED ||
        status?.id === Status.PENDING_BIO_SIGN ||
        (status?.id === Status.EXPIRED && objectType === ObjectTypeEnum.CONSENT)) &&
      canDownloadFile === true
    ">
    <!-- Download file button-->
    <!-- Icon when if is not downloading the file -->
    <div class="tooltip">
      <fa-icon
      [icon]="faFileCircleCheck"
      class="icono tipo-second-color"
      (click)="downloadFile(downloadUuid ?? '')"
      *ngIf="!downloadingFile && (status?.id !== Status.PENDING_BIO_SIGN) "></fa-icon>
      <p class="text_tip small">
        {{"PRIVATE.CONSENTS.LIST-CONSENTS.TOOLTIP-DOWNLOAD-DOCUMENT" | translate}}
      </p>
        <!-- Icon when if is downloading the file -->
        <fa-icon
        [icon]="faSpinner"
        animation="spin-pulse"
        style="padding-left: 7px"
        class="icono tipo-second-color"
        *ngIf="downloadingFile"></fa-icon>
    </div>
    <!-- Download report button-->
    <!-- Icon when if is not downloading the report -->
    <div class="tooltip">
      <fa-icon
      [icon]="faReceipt"
      style="padding-left: 7px"
      class="icono tipo-second-color"
      (click)="downloadRemoteFile(downloadUuid ?? '')"
      *ngIf="!downloadingRemote && (OutputType.DIGITAL_REMOTE === outputType?.id && this.objectType === ObjectTypeEnum.CONSENT )"></fa-icon>
      <p class="text_tip small">
        {{"PRIVATE.CONSENTS.LIST-CONSENTS.TOOLTIP-DOWNLOAD-REMOTE" | translate }}
      </p>
      <!-- Icon when if is downloading the report -->
      <fa-icon
      [icon]="faSpinner"
      animation="spin-pulse"
      style="padding-left: 7px"
      class="icono tipo-second-color"
      *ngIf="downloadingRemote && (OutputType.DIGITAL_REMOTE === outputType?.id && this.objectType === ObjectTypeEnum.CONSENT )"></fa-icon>
    </div>
     <!-- Download report button-->
    <!-- Icon when if is not downloading the report -->
    <div class="tooltip">
      <fa-icon
      [icon]="faFingerprint"
      style="padding-left: 7px"
      class="icono tipo-second-color"
      (click)="downloadReportFile(downloadUuid ?? '')"
      *ngIf="!downloadingReport && (OutputType.DIGITAL_REMOTE === outputType?.id)"></fa-icon>
      <p class="text_tip small">
        {{"PRIVATE.CONSENTS.LIST-CONSENTS.TOOLTIP-DOWNLOAD-EVIDENCE" | translate }}
      </p>
      <!-- Icon when if is downloading the report -->
      <fa-icon
      [icon]="faSpinner"
      animation="spin-pulse"
      style="padding-left: 7px"
      class="icono tipo-second-color"
      *ngIf="downloadingReport && (OutputType.DIGITAL_REMOTE === outputType?.id )"></fa-icon>
    </div>
  </td>
</ng-template>


<!-- When statusId = 11 or 12 -->
<td *ngIf="status?.id === Status.ERROR" class="action">
  <fa-icon [icon]="faCircleExclamation" class="icono red"></fa-icon>
  <!-- TODO (click)="openWindow()" -->
</td>
<td
  *ngIf="
    status?.id === Status.GENERATING ||
    status?.id === Status.PENDING ||
    status?.id === Status.REJECTED ||
    status?.id === Status.DRAFT ||
    status?.id === Status.REJECTED_RL2
  "></td>
