<div class="box_container">
    <div class="box">
      <div class="row">
        <div class="column">
          <p class="bold tipo-second-color">
            {{'SUPER-ADMIN.LIST-COMPANY.TITLE-COMPANY-NAME' | translate}}:
            <span class="regular">{{companyData?.businessName}}</span>
          </p>
         
         
        </div>
        <div class="column">
          <p class="bold tipo-second-color">
            {{'SUPER-ADMIN.HEADER-USER.USER-TYPE' | translate}}:
            <span class="regular">{{roleUser}}</span>
            
          </p>
                
         
        </div>
     
        <div class="column">
          <p class="bold tipo-second-color">
            {{'SUPER-ADMIN.LIST-COMPANY.SUBSCRIPTION' | translate}}:
            <span class="regular" *ngIf="isDigital">{{"SUPER-ADMIN.DATA-COMPANY-ADMIN.DATA-DIGITAL-ACCESS" | translate}}</span>
            <span class="regular" *ngIf="!isDigital">{{"SUPER-ADMIN.DATA-COMPANY-ADMIN.DATA-PAPER" | translate}}</span>
          </p>
        
        </div>
      
        </div>    
    </div>
</div>    
