enum StatusEnum {
    PENDING = 1,
    SIGNED = 2,
    REJECTED = 3,
    REVOKED = 4,
    PRINTED = 5,
    DRAFT = 6,
    PENDING_SIGN_RL2 = 7,
    REVOKED_RL2 = 8,
    REJECTED_RL2 = 9,
    PENDING_BIO_SIGN = 10,
    GENERATING = 11,
    ERROR = 12,
    EXPIRED = 13,

  }

  export default StatusEnum;