import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Filter } from '@app/core/models/front/filters';
import { Pagination } from '@app/core/models/front/pagination';
import { Sorters } from '@app/core/models/front/sorters';
import { Page } from '@app/core/models/input/page/pages.model';
import { EnvironmentService } from '@app/environment.service';
import { Observable, BehaviorSubject } from 'rxjs';
import { UtilsService } from '../utils/utils.service';
import { DigitalDocumentOutputDTO } from '@app/core/models/output/digitalDocuments/digital-document.model';
import { DigitalDocumentTypeInputDTO } from '@app/core/models/input/digital-documents/digital-document-type.model';

@Injectable({
  providedIn: 'root'
})
export class DigitalDocumentsService {

  constructor(
    private http: HttpClient,
    private env: EnvironmentService,
    private uSrv: UtilsService
  ) { }

  private digitalDocumentSubject: BehaviorSubject<DigitalDocumentTypeInputDTO | null> = new BehaviorSubject<DigitalDocumentTypeInputDTO | null>(null);
  digitalDocument$: Observable<DigitalDocumentTypeInputDTO | null> = this.digitalDocumentSubject.asObservable();

  getDigitalDocument(): DigitalDocumentTypeInputDTO | null {
    return this.digitalDocumentSubject.getValue();
  }

  setDigitalDocument(digitalDocument: DigitalDocumentTypeInputDTO) {
    this.digitalDocumentSubject.next(digitalDocument);
  }

  getDigitalDocuments(pagination: Pagination, filters: Filter[], sorters: Sorters): Observable<Page> {
    return this.http.get<Page>(
      `${this.env.apiUrl}/dds?` +
        this.uSrv.genPaginationInUri(pagination)+
        this.uSrv.getFiltersInUri(filters) +
        this.uSrv.genSortersInUri(sorters)
    );
  }

  postDigitalDocument(digitalDocumentOutput: DigitalDocumentOutputDTO) {
    const headers = new HttpHeaders({
      'Content-Type':  'application/json'
    })
    return this.http.post<DigitalDocumentOutputDTO>(`${this.env.apiUrl}/dds`, JSON.stringify(digitalDocumentOutput), {headers: headers})
  }


}
