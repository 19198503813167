import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthenticationGuard } from '@app/core/guards/elci/authentication.guard';
import { ApiHandlerComponent } from './api-handler/pages/api-handler/api-handler.component';

const routes: Routes = [
  {
    path: '',
    component: ApiHandlerComponent,

    canActivate: [AuthenticationGuard],
    children: [
      {
        path: 'v1/customer',
        loadChildren: () =>
          import('./quick-api.module').then(m => m.QuickApiModule),
        component: ApiHandlerComponent,
        canActivate: [AuthenticationGuard],
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class QuickApiRoutingModule {}