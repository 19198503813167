<div class="back-white-color box_title">
    <h4 class="subtitle tipo-second-color regular center">
      {{'SUPER-ADMIN.CLIENT.DATA-CONFIRN.TITLE-DATA-CONFIRM' | translate}}
    </h4>
  </div>
  <div class="box-container">
    <div class="box">

        <div class="form">
          <div class="row">
            <p class="bold tipo-second-color">
             <fa-icon [icon]="faUserDoctor" class="icono"></fa-icon>
               {{'SUPER-ADMIN.CLIENT.DATA-HIRING.LABEL-PROFESSIONALS' | translate}}:
              <span class="regular">{{doctorsAmountsName?.name}}</span>
            </p>
            <p class="bold tipo-second-color">
                <fa-icon [icon]="faCreditCard" class="icono"></fa-icon>
               {{'SUPER-ADMIN.CLIENT.DATA-HIRING.LABEL-AMOUNT' | translate}}:
              <span class="regular">{{dataClientService.dataClient?.get('dataHiring.price')?.value}}</span>
            </p>
            <p class="bold tipo-second-color">
                <fa-icon [icon]="faEye" class="icono"></fa-icon>
               {{'SUPER-ADMIN.CLIENT.DATA-HIRING.LABEL-SUSCRIPTION' | translate}}:
              <span class="regular" *ngFor="let name of subscriptionTypesNames; let last = last"> {{name}}{{ !last ? ', ' : '' }} </span>
            </p>
            <p class="bold tipo-second-color">
              <fa-icon [icon]="faCreditCard" class="icono"></fa-icon>
             {{'SUPER-ADMIN.CLIENT.DATA-HIRING.LABEL-MODE' | translate}}:
            <span class="regular">{{paymentMethodTypesName?.name}}</span>
          </p>
          <p class="bold tipo-second-color" *ngIf="isSepa">
            <fa-icon [icon]="faCreditCard" class="icono"></fa-icon>
            {{ 'SUPER-ADMIN.CLIENT.DATA-HIRING.LABEL-SEPA' | translate }}:
          <span class="regular">{{dataClientService.dataClient?.get('dataHiring.bankAccount')?.value}}</span>
        </p>
          </div>
          <div class="row">
            <p class="bold tipo-second-color">
                <fa-icon [icon]="faCreditCard" class="icono"></fa-icon>
               {{'SUPER-ADMIN.CLIENT.DATA-HIRING.LABEL-MODE' | translate}}:
              <span class="regular">{{billingTypesName?.name}}</span>
            </p>
            <p class="bold tipo-second-color" >
                <fa-icon [icon]="faCreditCard" class="icono"></fa-icon>
                {{ 'SUPER-ADMIN.CLIENT.DATA-HIRING.LABEL-TYPE' | translate }}:
              <span class="regular">{{registryTypesName?.name}}</span>
            </p>
            <p class="bold tipo-second-color" *ngIf="!isBillingSigns">
              <fa-icon [icon]="faCreditCard" class="icono"></fa-icon>
             {{'SUPER-ADMIN.CLIENT.DATA-HIRING.LABEL-SIGNS' | translate}}:
            <span class="regular">{{signaturesAmountsName?.name}}</span>
          </p>
          <p class="bold tipo-second-color" *ngIf="isBillingSigns">
            <fa-icon [icon]="faEye" class="icono"></fa-icon>
            {{'SUPER-ADMIN.CLIENT.DATA-HIRING.LABEL-HIS' | translate}}
          <span class="regular">{{dataClientService.dataClient?.get('dataHiring.his')?.value}}</span>
        </p>
        <p class="bold tipo-second-color" *ngIf="isBillingSigns">
          <fa-icon [icon]="faUserDoctor" class="icono"></fa-icon>
          {{ 'PRIVATE.DOCTOR.DATA-DOCTOR.SPECIALTY' | translate }}:
          <span class="regular">{{ dataClientService.dataClient?.get('dataHiring.specialtiesName')?.value}}</span>
      </p>
          </div>
      </div>
          <hr />
          <p class="bold tipo-second-color">{{'SUPER-ADMIN.CLIENT.DATA-CONFIRN.TITLE-DATA-ACCESS' | translate}}</p>

          <div class="form">
              <div class="row">
                <p class="bold tipo-second-color">
                  <fa-icon [icon]="faUser" class="icono"></fa-icon>
                  {{'SUPER-ADMIN.CLIENT.DATA-ACCESS.LABEL-USER' | translate}}:
                    <span class="regular">{{dataClientService.dataClient?.get('dataAccess.username')?.value}}</span>
                </p>
              </div>
              <div class="row">
                <p class="bold tipo-second-color">
                  <fa-icon [icon]="faEarthEurope" class="icono"></fa-icon>
                    {{'SUPER-ADMIN.CLIENT.DATA-CONFIRN.LABEL-LANGUAGE' | translate}}:
                    <span class="regular">{{languageName?.name}}</span>
                </p>
              </div>
          </div>
          <hr />
          <p class="bold tipo-second-color">{{'SUPER-ADMIN.CLIENT.DATA-CONFIRN.TITLE-DATA-CONTACT' | translate}}</p>

      <div class="form">
          <div class="row">
            <p class="bold tipo-second-color">
                <fa-icon [icon]="faClipboard" class="icono"></fa-icon>
               {{'SUPER-ADMIN.CLIENT.DATA-COMPANY.LABEL-COMPANY-NAME' | translate}}:
              <span class="regular">{{dataClientService.dataClient?.get('dataCompany.businessName')?.value}}</span>
            </p>
            <p class="bold tipo-second-color">
                <fa-icon [icon]="faEnvelope" class="icono"></fa-icon>
               {{'SUPER-ADMIN.CLIENT.DATA-CONTACT.LABEL-EMAIL' | translate}}:
              <span class="regular">{{dataClientService.dataClient?.get('dataContact.email')?.value}}</span>
            </p>
            <p class="bold tipo-second-color">
                <fa-icon [icon]="faPhone" class="icono"></fa-icon>
               {{'SUPER-ADMIN.CLIENT.DATA-CONFIRN.LABEL-PHONE' | translate}}:
              <span class="regular">{{dataClientService.dataClient?.get('dataContact.phone')?.value}}</span>
            </p>
            <p class="bold tipo-second-color">
                <fa-icon [icon]="faMap" class="icono"></fa-icon>
               {{'SUPER-ADMIN.CLIENT.DATA-CONTACT.LABEL-CITY' | translate}}:
              <span class="regular">{{dataClientService.dataClient?.get('dataContact.locality')?.value}}</span>
            </p>
            <p class="bold tipo-second-color">
                <fa-icon [icon]=" faMapPin" class="icono"></fa-icon>
               {{'SUPER-ADMIN.CLIENT.DATA-CONTACT.LABEL-PROVINCE' | translate}}:
              <span class="regular">{{stateName?.name}}</span>
            </p>
          </div>
          <div class="row">
            <p class="bold tipo-second-color">
                <fa-icon [icon]="faClipboard" class="icono"></fa-icon>
              {{'SUPER-ADMIN.CLIENT.DATA-COMPANY.LABEL-DOCUMENT' | translate}}:
              <span class="regular">{{dataClientService.dataClient?.get('dataCompany.cif')?.value}}</span>
            </p>
            <p class="bold tipo-second-color">
                <fa-icon [icon]="faMobile" class="icono"></fa-icon>
               {{'SUPER-ADMIN.CLIENT.DATA-CONTACT.LABEL-MOBILE' | translate}}:
              <span class="regular">{{dataClientService.dataClient?.get('dataContact.mobile')?.value}}</span>
            </p>
            <p class="bold tipo-second-color">
                <fa-icon [icon]=" faMapPin" class="icono"></fa-icon>
               {{'SUPER-ADMIN.CLIENT.DATA-CONTACT.LABEL-ADDRESS' | translate}}:
              <span class="regular">{{dataClientService.dataClient?.get('dataContact.address')?.value}}</span>
            </p>
            <p class="bold tipo-second-color">
                <fa-icon [icon]="faLocationDot " class="icono"></fa-icon>
               {{'SUPER-ADMIN.CLIENT.DATA-CONTACT.LABEL-POSTAL-CODE' | translate}}:
              <span class="regular">{{dataClientService.dataClient?.get('dataContact.postCode')?.value}}</span>
            </p>
            <p class="bold tipo-second-color">
              <fa-icon [icon]="faUserSecret" class="icono"></fa-icon>
             {{'SUPER-ADMIN.CLIENT.DATA-COMPANY.LABEL-PRIVACY-URL' | translate}}:
            <span class="regular">{{dataClientService.dataClient?.get('dataCompany.website')?.value}}</span>
          </p>
          </div>
        </div>
        <hr />
        <p class="bold tipo-second-color">{{'SUPER-ADMIN.CLIENT.DATA-CONFIRN.TITLE-DATA-RGPD' | translate}}</p>

    <div class="form">
        <div class="row">
          <p class="bold tipo-second-color">
            <fa-icon [icon]="faClipboard" class="icono"></fa-icon>
            {{'SUPER-ADMIN.CLIENT.DATA-COMPANY.LABEL-COMPANY-NAME' | translate}}:
            <span class="regular">{{dataClientService.dataClient?.get('dataRgpd.businessNameFis')?.value}}</span>
          </p>
          <p class="bold tipo-second-color">
            <fa-icon [icon]=" faMapPin" class="icono"></fa-icon>
            {{'SUPER-ADMIN.CLIENT.DATA-CONTACT.LABEL-ADDRESS' | translate}}:
            <span class="regular">{{dataClientService.dataClient?.get('dataRgpd.addressFis')?.value}}</span>
          </p>
          <p class="bold tipo-second-color">
            <fa-icon [icon]="faLocationDot " class="icono"></fa-icon>
            {{'SUPER-ADMIN.CLIENT.DATA-CONTACT.LABEL-POSTAL-CODE' | translate}}:
            <span class="regular">{{dataClientService.dataClient?.get('dataRgpd.postCodeFis')?.value}}</span>
          </p>
          <p class="bold tipo-second-color">
            <fa-icon [icon]="faEnvelope" class="icono"></fa-icon>
            {{'SUPER-ADMIN.CLIENT.DATA-CONTACT.LABEL-EMAIL' | translate}}:
            <span class="regular">{{dataClientService.dataClient?.get('dataRgpd.emailLOPD')?.value}}</span>
          </p>
        </div>
        <div class="row">
          <p class="bold tipo-second-color">
            <fa-icon [icon]="faClipboard" class="icono"></fa-icon>
            {{'SUPER-ADMIN.CLIENT.DATA-COMPANY.LABEL-DOCUMENT' | translate}}:
            <span class="regular">{{dataClientService.dataClient?.get('dataRgpd.cifFis')?.value}}</span>
          </p>
          <p class="bold tipo-second-color">
            <fa-icon [icon]="faMap" class="icono"></fa-icon>
            {{'SUPER-ADMIN.CLIENT.DATA-CONTACT.LABEL-CITY' | translate}}:
            <span class="regular">{{dataClientService.dataClient?.get('dataRgpd.localityFis')?.value}}</span>
          </p>
          <p class="bold tipo-second-color">
            <fa-icon [icon]=" faMapPin" class="icono"></fa-icon>
            {{'SUPER-ADMIN.CLIENT.DATA-CONTACT.LABEL-PROVINCE' | translate}}:
            <span class="regular">{{stateFisName?.name}}</span>
          </p>

        </div>
      </div>
      <hr />
      <div class="row">
        <input type="checkbox" name="policy" id="policy" [ngClass]="{'isAccepted':isAccepted}" (click)="checkedAccepted()">
        <label for="policy" class="small"> {{'SUPER-ADMIN.CLIENT.DATA-CONFIRN.MESSAGE-ACCEPT' |translate}} <a href="https://elconsentimiento.com/politicaprivacidad" target="_blank" rel="noopener noreferrer">{{'SUPER-ADMIN.CLIENT.DATA-CONFIRN.MESSAGE-PRIVACY-POLICY' | translate}}</a> {{'SUPER-ADMIN.CLIENT.DATA-CONFIRN.MESSAGE-DEVICES' | translate}}</label>
       </div>

        <div class="buttons">
          <button class="button tipo-white-color back-blue-color" (click)="togglePagesFiveSix()">
            <fa-icon [icon]="faCircleArrowLeft" class="icono"></fa-icon>
            {{'SUPER-ADMIN.CLIENT.DATA-COMPANY.BUTTON-BACK' | translate}}
          </button>
          <button class="button tipo-white-color back-blue-color" (click)="onSubmit()">
            {{'SUPER-ADMIN.CLIENT.DATA-CONFIRN.BUTTON-CREATE' | translate}}
            <fa-icon [icon]="faCircleArrowRight" class="icono"></fa-icon>
          </button>
        </div>

    </div>
  </div>

