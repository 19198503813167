import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EnvironmentService } from '@app/environment.service';
import { UtilsService } from '../utils/utils.service';
import { Statistics } from '@app/core/models/input/statistics/statistics.model';
import { Page } from '@app/core/models/input/page/pages.model';
import { Pagination } from '@app/core/models/front/pagination';
import { Filter } from '@app/core/models/front/filters';
import { Sorters } from '@app/core/models/front/sorters';
import { ConsentsBySpecialty, DoctorsBySpecialty } from '@app/core/models/input/statistics/elements-by-specialty.model';
import { ConsentsMostUsed } from '@app/core/models/input/statistics/top-consents.model';
import { ApiDocumentsByCompany, DdsByCategoryCompany, RgpdsByCompany, SignaturesByCompany, SignedDocumentsByCompany } from '@app/core/models/input/statistics/documents-generated.model';
import { Specialty } from '@app/core/models/input/specialty.model';

@Injectable({
  providedIn: 'root'
})
export class StatisticsService {

  constructor(private http: HttpClient,
    private env: EnvironmentService,
    private uSrv: UtilsService) { }


    getStatistics(startDate:string | null, endDate:string | null) {
      return this.http.get<Statistics>(`${this.env.apiUrl}/statistics?startDate=${startDate}&endDate=${endDate}`);
    }
    getTotalConsentsByDoctor(
      pagination: Pagination,
      filters: Filter[],
      sorters: Sorters
    ){
      return this.http.get<Page>(`${this.env.apiUrl}/statistics/sudo/doctor-consents?` +
      this.uSrv.genPaginationInUri(pagination) +
      this.uSrv.getFiltersInUri(filters) +
      this.uSrv.genSortersInUri(sorters));
    }

    getDoctorsBySpecialty(){
      return this.http.get<DoctorsBySpecialty[]>(`${this.env.apiUrl}/statistics/sudo/doctors-by-specialty`);
    }
    getConsentsBySpecialty(filters: Filter[]){
      return this.http.get<ConsentsBySpecialty[]>(`${this.env.apiUrl}/statistics/sudo/consents-by-specialty?`+ this.uSrv.getFiltersInUri(filters));
    }
    getConsentsMostUsed(filters: Filter[]) {
      return this.http.get<ConsentsMostUsed[]>(`${this.env.apiUrl}/statistics/sudo/generated-consents?`+ this.uSrv.getFiltersInUri(filters));
    }

    getRGPDsByCompany(filters: Filter[]){
      return this.http.get<RgpdsByCompany[]>(`${this.env.apiUrl}/statistics/sudo/generated-rgpds-by-company?`+ this.uSrv.getFiltersInUri(filters));
    }
    getDDsByCategory(filters: Filter[]){
      return this.http.get<DdsByCategoryCompany[]>(`${this.env.apiUrl}/statistics/sudo/generated-dds-by-category-company?`+ this.uSrv.getFiltersInUri(filters));
    }
    getSignaturesByCompany(filters: Filter[]){
      return this.http.get<SignedDocumentsByCompany>(`${this.env.apiUrl}/statistics/sudo/signature-totals-by-company?`+ this.uSrv.getFiltersInUri(filters));
    }
    getApiDocumentsByCompany(filters: Filter[]){
      return this.http.get<ApiDocumentsByCompany[]>(`${this.env.apiUrl}/statistics/sudo/api-documents-totals-by-company?`+ this.uSrv.getFiltersInUri(filters));
    }
   

    getSpecialties(){
      return this.http.get<Specialty[]>(`${this.env.apiUrl}/consents/specialties`);
    }

    // TODO debemos pensar como hacer la consulta dinámica por idioma
    getProcedures(pagination: Pagination, specialitiesId: number | null, procedureName:string){        
      if(specialitiesId === null || specialitiesId == 0){  
        return this.http.get<Page>(`${this.env.apiUrl}/consents/procedures/ES/ES?`+ 
        this.uSrv.genPaginationInUri(pagination) +
        `&procedureName=` + procedureName);
        
      } else{     
        return this.http.get<Page>(`${this.env.apiUrl}/consents/procedures/ES/ES?`+ 
        this.uSrv.genPaginationInUri(pagination) +
        `&specialitiesId=` + specialitiesId +       
        `&procedureName=` + procedureName);
      }
    }
}