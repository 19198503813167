<div class="wrapper">
    <header class="header inlineflex">
        <div class="header_logo">
            <a [routerLink]="['/']" class="tipo-white-color">
            <img src="../../../assets/img/header/logo-ELCI.png" alt="Elci app logo">
            </a>
        </div>
      <div class="header_logods">
        <p class="bold tipo-second-color">Una solución Legal<span class="tipo-orange-color">Tech</span></p>
      </div>
      <div class="clientesd">
        <a [routerLink]="['login']" class="tipo-second-color" >
            <fa-icon [icon]="faUserDoctor" class="icono"></fa-icon>
            <span class="bold">ÁREA DE CLIENTES</span>
        </a>
      </div>
    </header>
        <button class="header_button" (click)="mostrarMenu()"[ngClass]="{'activo':logoblanco}">
            <p class="tipo-second-color small bold button_p"> MENÚ</p>
            <div class="linea top"></div>
            <div class="linea mid"></div>
            <div class="linea bot"></div>
        </button>

        <div class="menu back-blue-color tipo-white-color bold" [ngClass]="{'visible':menuvisible}" (mouseleave)="quitarMenu()">
            <ul class="menu_ul">
                <a [routerLink]="['']" class="tipo-white-color" (click)="mostrarMenu()"><li class="menu_li">INICIO</li></a>
                <a [routerLink]="['quees']" class="tipo-white-color" (click)="mostrarMenu()"><li class="menu_li">QUÉ ES</li></a>
                <a [routerLink]="['comofunciona']" class="tipo-white-color"  (click)="mostrarMenu()"><li class="menu_li">CÓMO FUNCIONA</li></a>
                <a [routerLink]="['quienessomos']" class="tipo-white-color" (click)="mostrarMenu()"><li class="menu_li">QUIÉNES SOMOS</li></a>
                <a [routerLink]="['catalogo']" class="tipo-white-color" (click)="mostrarMenu()"><li class="menu_li">CATÁLOGO</li></a>
                <a [routerLink]="['contacto']" class="tipo-white-color" (click)="mostrarMenu()"><li class="menu_li">CONTACTO</li></a>
                <a [routerLink]="['login']" class="tipo-white-color clientesm" (click)="mostrarMenu()"> <fa-icon [icon]="faUserDoctor" class="icono"></fa-icon> <li class="menu_li">ÁREA DE CLIENTES</li></a>

            </ul>
        </div>


</div>


