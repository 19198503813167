import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from '@app/shared/shared.module';
import { DataAdministratorComponent } from './components/data-administrator/data-administrator.component';
import { FileAdministratorComponent } from './pages/file-administrator/file-administrator.component';
import { ListAdministratorsComponent } from './pages/list-administrators/list-administrators.component';
import { NewAdministratorComponent } from './pages/new-administrator/new-administrator.component';
import { SharedComponentModule } from '../shared/components/shared-component.module';

@NgModule({
  declarations: [
    ListAdministratorsComponent,
    FileAdministratorComponent,
    NewAdministratorComponent,
    DataAdministratorComponent,
  ],
  imports: [CommonModule, SharedModule, ReactiveFormsModule, FormsModule, SharedComponentModule],
  exports: [
    FileAdministratorComponent
  ]
})
export class AdministratorsModule {}
