import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import {
  ADMINISTRATORS_ROLES,
  COMPANY_ROLES,
} from '@app/core/constants/Permissions';
import {
  VariableContainerTypeEnum,
  VariableTypeEnum,
} from '@app/core/enums/superdocs.enum';
import { SelectOption } from '@app/core/models/front/select-option.model';
import { Company } from '@app/core/models/input/company/company.model';
import { Patient } from '@app/core/models/input/patient/patient.model';
import { RepresentativePatientDTO } from '@app/core/models/input/patient/representative.model';
import { User, UserSignature } from '@app/core/models/input/user.model';
import { UserOutput } from '@app/core/models/output/user-output.model';
import { CompanyService } from '@app/core/services/company/company.service';
import { LoginService } from '@app/core/services/login/login.service';
import { RoleService } from '@app/core/services/role/role.service';
import { TemplateService } from '@app/core/services/template/template.service';
import { UserService } from '@app/core/services/user/user.service';
import { UtilsService } from '@app/core/services/utils/utils.service';
import { VariableService } from '@app/core/services/utils/variable/variable.service';
import StringUtils from '@app/core/utils/string.utils';
import { Observable, forkJoin, map } from 'rxjs';
import { TemplateInputDTO } from '../../../../core/models/input/template/template.model';
import { PatientService } from '../../../../core/services/patient/patient.service';
import { MAIN_LOGO } from '@app/core/constants/Constants';

@Component({
  selector: 'app-digital-document-template',
  templateUrl: './digital-document-template.component.html'
})
export class DigitalDocumentTemplateComponent implements OnInit, OnChanges {
  originalTemplate?: TemplateInputDTO;
  template?: TemplateInputDTO;

  user?: User;
  @Input() patient?: Patient;
  templateUuid = '';
  company?: Company;




  constructor(
    private activatedRoute: ActivatedRoute,
    private loginService: LoginService,
    private templateService: TemplateService,
    private variableService: VariableService,
    private patientService: PatientService,
    private companyService: CompanyService,
    private utils: UtilsService,
    private userService: UserService,
    private roleService: RoleService
  ) {
    this.activatedRoute.params.subscribe(param => {
      this.templateUuid = param['template-uuid'];
      this.getPatientByUuid(param['uuid']);
      this.user = this.loginService.userValue ?? new User();
    });
  }

  ngOnInit() {
    this.joinApiCalls();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['patient']) {
      // Execute any function you need with the new value
      this.constructVariables();
    }
  }

  joinApiCalls() {
    const sources = [
      this.getDDTemplate(this.templateUuid),
      this.getCompanyByUuid(this.user?.companyUuid ?? ''),
    ];
    forkJoin(sources)
      .pipe(
        map(([templates, company]) => ({
          templates,
          company,
        }))
      )
      .subscribe(({ templates, company }) => {
        // Assign to api objects
        this.originalTemplate = templates as TemplateInputDTO;
        this.company = company as Company;

        this.template = JSON.parse(JSON.stringify(this.originalTemplate));

        // Set the templateUuid in the shareService
        this.templateService.setTemplate(
          this.template ?? new TemplateInputDTO()
        );
        // Construct variables
        this.constructVariables();
      });
  }

  /**
   * Constructs the variables object based on available data or placeholders.
   * If the object is null, it waits and doesn't change the anchor.
   * If the object is present, it replaces values with "--".
   */
  constructVariables() {
    if (this.originalTemplate) {
      // Reset the template with the variables
      this.template = JSON.parse(JSON.stringify(this.originalTemplate));
    }

    const allDataVariables = {
      ...this.constructSigners(),
      ...this.constructCompanyVariables(),
      ...this.constructAddressee(this.patient),
      ...this.constructDateData(),
      ...this.shouldShowBodyBlocks(),
    };

    if (this.template) {
      this.template = this.variableService.mapVariables(
        this.template,
        allDataVariables
      );
    }

    this.constructAsyncVariables();
  }

  constructAsyncVariables() {
    this.fillDoctorsData();
    this.constructCompanyLogo();
  }

  constructCompanyLogo() {
    if (
      this.template?.variables?.some(
        variable => variable.anchor === '%company_logo%'
      )
    ) {
      this.companyService
        .getCompanyLogoApi(this.user?.companyUuid ?? '', MAIN_LOGO)
        .subscribe({
          next: (logo: UserSignature) => {
            if (logo) {
              const companyLogo = this.constructBase64Image(
                logo.format ?? '',
                logo.content ?? ''
              );
              const companyLogoVariable = {
                company_logo: companyLogo,
              };

              if (this.template) {
                this.template = this.variableService.mapVariables(
                  this.template,
                  companyLogoVariable
                );
              }
            }
          },
        });
    }
  }

  fillDoctorsData() {
    if (
      this.template?.variables?.some(
        variable => variable.anchor === '%doctor_select%'
      )
    ) {
      if (
        this.roleService.hasRole([...ADMINISTRATORS_ROLES, ...COMPANY_ROLES])
      ) {
        this.userService.getDoctors().subscribe({
          next: doctors => {
            const doctorsFullNames: SelectOption[] = doctors.map(doctor => {
              return new SelectOption(doctor.uuid ?? '', doctor.fullName ?? '');
            });

            const doctorSelectData = {
              doctor_select: JSON.stringify(doctorsFullNames),
              doctor_name: '-', // Hide the doctor name and last name
              doctor_last_name: '-',
              doctor_signature: this.constructBase64Image(
                'image/gif',
                'R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw=='
              ),
            };
            if (this.template) {
              this.template = this.variableService.mapVariables(
                this.template,
                doctorSelectData
              );
            }
          },
        });
      } else {
        this.constructDoctorData();
      }
    }
  }

  constructDoctorData(): void {
    if (
      !this.roleService.hasRole(ADMINISTRATORS_ROLES) &&
      this.template?.variables
    ) {
      const doctorSelect = this.template.variables.filter(
        variable => variable.anchor === '%doctor_select%'
      )[0];
      if (doctorSelect) {
        doctorSelect.type = VariableTypeEnum.STRING;
        doctorSelect.container = VariableContainerTypeEnum.FORM;
        if (this.user?.uuid) {
          this.getDoctorSignature(this.user?.uuid)
            .then((userSignature: UserSignature) => {
              const doctorSignature = this.constructBase64Image(
                userSignature.format ?? '',
                userSignature.content ?? ''
              );
              if (this.template) {
                const doctorSelectData = {
                  doctor_select: '-',
                  doctor_name: this.user?.name,
                  doctor_last_name: this.user?.lastname,
                  doctor_signature: doctorSignature,
                };
                this.template = this.variableService.mapVariables(
                  this.template,
                  doctorSelectData
                );
              }
            })
            .catch(error => {
              // Handle any errors here
              console.error(error);
            });
        }
      }
    }
  }

  /**
   * Constructs the body content for signers based on the consent's patient and representatives,
   * if present. It determines the presence and count of representatives and constructs the body
   * content accordingly, updating the template body for each signer.
   */
  constructSigners(): Record<string, string | undefined> {
    let firstRepresentativeData: Record<string, string | undefined> = {};
    let secondRepresentativeData: Record<string, string | undefined> = {};
    // Check if the consent has a patient associated
    if (this.patient) {
      // Get the representatives from the patient
      const representatives = this.patient?.representativePatients;
      const firstRepresentative = representatives?.[0];
      const secondRepresentative = representatives?.[1];
      const isFirstRepresentativeActive = representatives?.[0]?.active;
      const isSecondRepresentativeActive = representatives?.[1]?.active;

      // Determine the presence and count of representatives
      const hasRepresentative =
        representatives && representatives?.length !== 0;

      // Construct and update body content for the first signer or patient
      if (
        hasRepresentative &&
        firstRepresentative &&
        isFirstRepresentativeActive
      ) {
        firstRepresentativeData =
          this.constructBodyFirstSignerRepresentative(firstRepresentative);
      } else {
        firstRepresentativeData = this.constructBodySignerPatient(this.patient);
      }

      secondRepresentativeData =
        this.constructBodySecondSignerRepresentative(secondRepresentative ?? new RepresentativePatientDTO(), isSecondRepresentativeActive ?? false);
    } else {
      // Clean the variables in case of patient or consent undefined
      return {
        signer_name_1: '',
        signer_last_name_1: '',
        signer_document_1: '',
        signer_document_type_1: '',
        signer_document_type_2: '',
        signer_last_name_2: '',
        signer_name_2: '',
        signer_document_2: '',
      };
    }
    return {
      ...firstRepresentativeData,
      ...secondRepresentativeData,
    };
  }

  /**
   * Constructs the body content for a signer who is the consent's patient.
   *
   * @param patient The patient for whom to construct the body content.
   * @returns A record containing the body content for the patient signer.
   */
  constructBodySignerPatient(patient: Patient): Record<string, string> {
    return {
      signer_name_1: patient.name ?? '',
      signer_last_name_1: StringUtils.getPatientFullLastNames(patient),
      signer_document_1: patient.documentNumber ?? '',
      signer_role_1: '',
      signer_role_2: '',
      signer_document_type_1: 'NIF',
      signer_document_type_2: 'NIF',
      signer_last_name_2: '',
      signer_name_2: '',
      signer_document_2: '',
    };
  }

  /**
   * Constructs the body content for the first signer who is a representative.
   *
   * @param representative The first representative for whom to construct the body content.
   * @returns A record containing the body content for the first representative signer.
   */
  constructBodyFirstSignerRepresentative(
    representative: RepresentativePatientDTO
  ): Record<string, string> {
    return {
      signer_name_1: representative.name ?? '',
      signer_last_name_1:
        StringUtils.getRepPatientFullLastNames(representative),
      signer_document_1: representative.documentNumber ?? '',
      signer_role_1: representative.relationship ?? '',
      signer_document_type_1: 'NIF',
    };
  }

  /**
   * Constructs the body content for the second signer who is a representative.
   *
   * @param representative The second representative for whom to construct the body content.
   * @returns A record containing the body content for the second representative signer.
   */
  constructBodySecondSignerRepresentative(
    representative: RepresentativePatientDTO,
    secondRepresentativeActive: boolean
  ): Record<string, string> {
    return {
      signer_name_2: secondRepresentativeActive ? representative.name ?? '' : '',
      signer_last_name_2: secondRepresentativeActive ? StringUtils.getRepPatientFullLastNames(
        representative ?? new RepresentativePatientDTO()
      ) : '',
      signer_document_2: secondRepresentativeActive ? representative.documentNumber ?? '' : '',
      signer_role_2: representative.relationship ?? '',
      signer_document_type_2: 'NIF',
    };
  }

  /**
   * Constructs the body content for the first signer who is a representative.
   *
   * @param patient The patient for whom to construct the body content.
   * @returns A record containing the body content for the first representative signer.
   */
  constructAddressee(
    patient: Patient | undefined
  ): Record<string, string | undefined> {
    // Check if the consent has a patient associated
    if (patient) {
      // Define body variables related to signers data
      return {
        addressee_document_type: 'NIF',
        addressee_document: patient.documentNumber ?? '',
        addressee_name: patient.name ?? '',
        addressee_last_name: StringUtils.getPatientFullLastNames(patient),
        addressee_mail: patient.email ?? '',
        addressee_phone: patient.mobile ?? '',
        addressee_num_his_cli: patient.healthHistoryExt ?? '',
        addressee_age: this.utils.getAge(patient.birthdate).toString(),
      };
    }
    return {};
  }

  constructDateData(): Record<string, string | undefined> {
    return {
      day: this.utils.getActualDay(),
      month: this.utils.getActualMonth(),
      month_name: this.utils.getActualMonthName(),
      year: this.utils.getActualYear(),
    };
  }

  constructCompanyVariables(): Record<string, string | undefined> {
    // Check if the consent has a patient associated
    if (this.company) {
      // Define body variables related to signers data
      return {
        business_name_rgpd: this.company?.businessNameFis,
        business_name: this.company?.businessName,
        business_phone: this.company?.user?.phone ?? this.company?.user?.mobile,
        business_mobile:
          this.company?.user?.mobile ?? this.company?.user?.phone,
        cif: this.company?.cif,
        cif_rgpd: this.company?.cifFis,
        business_address_rgpd: StringUtils.getCompanyFullAddress(this.company),
        direction: StringUtils.getUserFullAddress(
          this.company?.user ?? new UserOutput()
        ),
        email: this.company?.user?.email,
        business_email_rgpd: this.company?.emailLOPD,
        url: this.company?.website,
        url_rgpd: this.company?.website,
        document_date: StringUtils.formatDateDDMMYYYY(new Date()),
      };
    }
    return {};
  }

  /**
   * Determines which body blocks should be shown based on certain conditions.
   * @returns A record containing information about which blocks should be shown.
   */
  shouldShowBodyBlocks(): Record<string, string | undefined> {
    // Initialize an empty object to store block information
    let blocks: Record<string, string | undefined> = {};

    // Retrieve block objects from the template variables
    const blockObjects = this.template?.variables?.filter(obj =>
      obj?.name?.startsWith('block')
    );

    // Iterate through each block object
    blockObjects?.forEach(block => {
      if (!block) return;

      // Determine the state of representative patients
      const representatives = this.patient?.representativePatients;
      const isFirstRepresentativeActive =
        this.patient?.representativePatients?.[0]?.active;
      const isSecondRepresentativeActive =
        this.patient?.representativePatients?.[1]?.active;

      // Initialize a variable to determine whether the block should be shown
      let showBlock: boolean | undefined;

      // Determine the visibility of each block based on its name
      switch (block.name) {
        case 'block1':
          showBlock =
            (representatives && representatives?.length === 0) ||
            !isFirstRepresentativeActive;
          break;
        case 'block2':
          showBlock =
            representatives &&
            isFirstRepresentativeActive &&
            representatives?.length === 1;
          break;
        case 'block3':
          showBlock =
            representatives &&
            isSecondRepresentativeActive &&
            representatives?.length === 2;
          break;
        default:
          showBlock = false;
          break;
      }

      // Create a new block object with its visibility status
      const newBlock = {
        [block.name as string]: showBlock ? 'true' : undefined, // En SUPERDOCS [contentService] hay un varModel.getValue() != null. Si hay cualquier valor muestra el bloque
      };

      // Update the blocks object with the new block information
      blocks = {
        ...blocks,
        ...newBlock,
      };

      // Modify the template content to show or hide the block
      const modifiedReplacementBlock = showBlock
        ? `id='%${block.name}%' style='`
        : `id='%${block.name}%' style='display:none;`;

      if (this.template?.content) {
        this.template.content = this.template.content.replace(
          new RegExp(`id='%${block.name}%' style='display:none;`, 'g'),
          modifiedReplacementBlock
        );
      }
    });

    // Return the record containing information about which blocks should be shown
    return blocks;
  }

  private getDoctorSignature(uuid: string): Promise<UserSignature> {
    return new Promise((resolve, reject) => {
      this.userService.getUserSignatureApi(uuid).subscribe({
        next: (data: UserSignature) => {
          this.userService.setUserSignature(data);
          resolve(data);
        },
        error: err => {
          reject(new Error(err)); // Make sure to handle errors
        },
      });
    });
  }

  private constructBase64Image(format: string, content: string): string {
    return `data:image/${format};base64,${content}`;
  }

  getDDTemplate(templateUuid: string): Observable<TemplateInputDTO> {
    return this.templateService.getTemplateByUuid(templateUuid);
  }

  getPatientByUuid(patientUuid: string): Observable<Patient> {
    return this.patientService.getPatientByUuid(patientUuid);
  }

  getCompanyByUuid(companyUuid: string): Observable<Company> {
    return this.companyService.getCompany(companyUuid);
  }
}
