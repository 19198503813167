<p class="subtitle tipo-second-color">{{ "PRIVATE.PERSONALIZATION.EDIT-LOGO.TITLE-LOGO" | translate }}</p>
<p class="regular small">{{ "PRIVATE.PERSONALIZATION.EDIT-LOGO.SUBTITLE-LOGO" | translate }}</p>

    <div class="box_logo">
        <input type="file" name="logoMain" id="logoMain" (change)="uploadFile($event, 'MAIN')">
        <div class="box_img" *ngIf="logoMain">
        <img [src]="'data:image/'+logoMain.format +';base64,'+ logoMain.content " alt="{{ 'PRIVATE.PERSONALIZATION.EDIT-LOGO.TITLE-LOGO' | translate }}" class="logo">     
            <button class="button tipo-second-color back-white-color" (click)="deleteLogo('MAIN')">
                <fa-icon [icon]="faTrashCan" class="icono"></fa-icon>
            </button>
        </div>        
    </div>
    <div class="logo_rgpd" *appHasRole="COMPANY_ROLES">
        <p class="subtitle tipo-second-color">{{ "PRIVATE.PERSONALIZATION.EDIT-LOGO.TITLE-LOGO-GDPR" | translate }}</p>
        <p class="regular small">{{ "PRIVATE.PERSONALIZATION.EDIT-LOGO.SUBTITLE-LOGO-GDPR" | translate }}</p>
        <div class="box_logo">
            <input type="file" name="logoRGPD" id="logoRgpd" (change)="uploadFile($event, 'RGPD')">
            <div class="box_img" *ngIf="logoRGPD">
            <img [src]="'data:image/'+logoRGPD.format +';base64,'+ logoRGPD.content " alt="{{ 'PRIVATE.PERSONALIZATION.EDIT-LOGO.TITLE-LOGO-GDPR' | translate }}" class="logo">     
                <button class="button tipo-second-color back-white-color" (click)="deleteLogo('RGPD')">
                    <fa-icon [icon]="faTrashCan" class="icono"></fa-icon>
                </button>
            </div>
        </div>
    </div>
    
    <p class="small regular tipo-orange-color">*{{'PRIVATE.PERSONALIZATION.EDIT-LOGO.MESSAGE-CHANGE-LOGO' | translate}}</p>
   