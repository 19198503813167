<div class="back-light-blue-color" [ngClass]="{'wrapper': isApp, 'wrapper_quick': isQuickapi }">

    <div class="edit_consent_box ">
        <!-- Formulario de consentimiento -->
        <div class="edit_consent">
            <div class="box box2">
                <h4 class="title tipo-second-color bold">{{ "PRIVATE.CONSENTS.FILE-CONSENT.TITLE" | translate }}</h4>

                <!-- Mostrar si el consentimiento está en borrador  -->
                <app-draft-consent *ngIf="StatusEnum.DRAFT === consent?.status?.id && (canGenerateCis || isDoctor)"></app-draft-consent>

                <!-- Always visible -->
                <app-detail-consent [consent]="consent"></app-detail-consent>

                <!-- Mostrar si el consentimiento está firmado o revocado por el doctor que está logueado-->
                <app-signed-consent [consent]="consent" *ngIf="(StatusEnum.SIGNED === consent?.status?.id || StatusEnum.REVOKED === consent?.status?.id) && (canGenerateCis || isDoctor)"></app-signed-consent>

                <!-- Mostrar si el consentimiento está impreso -->
                <app-printed-consent *ngIf="StatusEnum.PRINTED === consent?.status?.id && (canGenerateCis || isDoctor)"></app-printed-consent>

                <!-- Mostrar si el consentimiento está pendiente de firma bio -->
                <app-pending-bio-consent [patient]="consent?.patient" *ngIf="StatusEnum.PENDING_BIO_SIGN === consent?.status?.id && (canGenerateCis || isDoctor)"></app-pending-bio-consent>

                <!-- Mostrar si el consentimiento está pendiente de firma del segundo representante (solo en firmas separadas) -->
                <app-pending-sign-rl2-consent *ngIf="(StatusEnum.PENDING_SIGN_RL2 === consent?.status?.id || StatusEnum.REVOKED_RL2 === consent?.status?.id) && (canGenerateCis || isDoctor)" [onSign]="StatusEnum.PENDING_SIGN_RL2 === consent?.status?.id"></app-pending-sign-rl2-consent>

                <!-- Mostrar si el consentimiento está expirado -->
                <app-expired-consent [consent]="consent" *ngIf="StatusEnum.EXPIRED === consent?.status?.id && (canGenerateCis || isDoctor)"></app-expired-consent>


            </div>
        </div>
        <!-- Vista consentimiento -->
        <div class="document back-white-color" [ngClass]="{'maximize':isMax}">
                <div class="buttons back-grey-color">
                    <button class="button tipo-second-color button_max" (click)="maximizeTemplate()" *ngIf="isMax === false">
                        <fa-icon [icon]="faMaximize" class="icono fa-1xl"></fa-icon>
                        {{ "PRIVATE.CONSENTS.NEW-CONSENT.BUTTON-FULLSCREEN" | translate }}
                    </button>
                    <button class="button tipo-second-color button_max" (click)="maximizeTemplate()" *ngIf="isMax">
                        <fa-icon [icon]="faMinimize" class="icono fa-1xl"></fa-icon>
                       {{"PRIVATE.CONSENTS.NEW-CONSENT.BUTTON-MINIMIZE" | translate }}
                    </button>
                </div>
                <div class="consent">
                    <app-ci-template [consent]="consent"></app-ci-template>
                </div>
        </div>
    </div>

</div>