import { DEFAULT_PAGE, DEFAULT_PAGE_SIZE } from "@app/core/constants/Constants";

export class Pagination {
    size: number;
    page: number;

    constructor(size: number = DEFAULT_PAGE_SIZE, page: number = DEFAULT_PAGE) {
        this.size = size;
        this.page = page;
    }
}