<div class="back-white-color box_title">
    <h4 class="subtitle tipo-second-color regular center">{{'SUPER-ADMIN.CLIENT.DATA-RGPD.TITLE-DATA-RGPD' | translate}}</h4>
</div>
<div class="box-container">
    <div class="box">
        <form [formGroup]="dataRgpd">
            <div class="form">
                <div class="row">
                    <input type="checkbox" formControlName="copied" id="copied" [ngClass]="{'isCheckedCopied':isCheckedCopied}" (click)="checkedCopied()" />
                    <label for="copied" class="small"> {{'SUPER-ADMIN.CLIENT.DATA-RGPD.LABEL-COPIED' | translate}}</label>
               
                    <div class="input">
                        <label for="company_name_fis">*{{'SUPER-ADMIN.CLIENT.DATA-RGPD.LABEL-COMPANY-NAME-FIS' | translate}}</label>
                        <input type="text" id="company_name_fis" formControlName="businessNameFis" [ngClass]="{ wrong: shouldShowError('businessNameFis') }">
                    </div>
                    <p
                    class="message_error wrongtext small"
                    *ngIf="this.dataRgpd.get('businessNameFis')?.errors?.['required'] && this.isSubmit">
                    {{'SUPER-ADMIN.CLIENT.DATA-COMPANY.MESSAGE-ERROR-COMPANY-NAME' | translate}}
                  </p>

                    <div class="input">
                        <label for="address_fis">*{{'SUPER-ADMIN.CLIENT.DATA-RGPD.LABEL-ADDRESS-FIS' | translate}}</label>
                        <input type="text" id="address_fis" formControlName="addressFis" [ngClass]="{ wrong: shouldShowError('addressFis') }">
                    </div>
                    <p
                    class="message_error wrongtext small"
                    *ngIf="this.dataRgpd.get('addressFis')?.errors?.['required'] && this.isSubmit">
                    {{'SUPER-ADMIN.CLIENT.DATA-CONTACT.MESSAGE-ERROR-ADDRESS' | translate}}
                  </p>

                    <div class="input">
                        <label for="postal_code_fis">*{{'SUPER-ADMIN.CLIENT.DATA-RGPD.LABEL-POSTAL-CODE-FIS' | translate}}</label>
                        <input type="text" id="postal_code_fis" formControlName="postCodeFis" [ngClass]="{ wrong: shouldShowError('postCodeFis') }">
                    </div>
                    <p
                    class="message_error wrongtext small"
                    *ngIf="this.dataRgpd.get('postCodeFis')?.errors?.['pattern'] && this.isSubmit">
                    {{'SUPER-ADMIN.CLIENT.DATA-CONTACT.MESSAGE-ERROR-POSTAL-CODE' | translate}}
                  </p>

                    <div class="input">
                        <label for="email_company_fis">*{{'SUPER-ADMIN.CLIENT.DATA-RGPD.LABEL-EMAIL-RGPD' | translate}}</label>
                        <input type="email" id="email_company_fis" formControlName="emailLOPD" [ngClass]="{ wrong: shouldShowError('emailLOPD') }"> 
                    </div>
                    <p
                    class="message_error wrongtext small"
                    *ngIf="this.dataRgpd.get('emailLOPD')?.errors?.['pattern'] && this.isSubmit">
                    {{'SUPER-ADMIN.CLIENT.DATA-CONTACT.MESSAGE-ERROR-MOBILE' | translate}}
                  </p>
                </div>
               <div class="row"> 
                <div class="input">
                    <label for="document_fis">*{{'SUPER-ADMIN.CLIENT.DATA-RGPD.LABEL-DOCUMENT-FIS' | translate}}</label>
                    <input type="text" id="document_fis" formControlName="cifFis" [ngClass]="{ wrong: shouldShowError('cifFis') }">
                    <span class="small tipo-second-color">{{'SUPER-ADMIN.CLIENT.DATA-COMPANY.MESSAGE-DOCUMENT' | translate}}</span>
                </div>
                <p
                    class="message_error wrongtext small"
                    *ngIf="this.dataRgpd.get('cifFis')?.errors?.['pattern'] && this.isSubmit">
                    {{'SUPER-ADMIN.CLIENT.DATA-COMPANY.MESSAGE-ERROR-DOCUMENT' | translate}}
                  </p>

                <div class="input">
                    <label for="city_fis">*{{'SUPER-ADMIN.CLIENT.DATA-RGPD.LABEL-CITY-FIS' | translate}}</label>
                    <input type="text" id="city_fis" formControlName="localityFis" [ngClass]="{ wrong: shouldShowError('localityFis') }">                    
                </div> 
                <p
                    class="message_error wrongtext small"
                    *ngIf="this.dataRgpd.get('localityFis')?.errors?.['required'] && this.isSubmit">
                    {{'SUPER-ADMIN.CLIENT.DATA-CONTACT.MESSAGE-ERROR-CITY' | translate}}     
                  </p>

                <div class="input">
                    <label for="stateIdFis">*{{'SUPER-ADMIN.CLIENT.DATA-RGPD.LABEL-PROVINCE-FIS' | translate}}</label>
                    <select formControlName="stateIdFis" id="stateIdFis" [(ngModel)]="selectValue" [ngClass]="{ wrong: shouldShowError('stateIdFis') }">
                        <option [ngValue]="null"></option>
                        <option value="{{state.id}}" *ngFor= "let state of states; let i=index">{{state.name}}</option>                                             
                    </select>
                </div>
                <p
                    class="message_error wrongtext small"
                    *ngIf="this.dataRgpd.get('stateIdFis')?.errors?.['required'] && this.isSubmit">
                    {{'SUPER-ADMIN.CLIENT.DATA-CONTACT.MESSAGE-ERROR-PROVINCE' | translate}}
                  </p>                
          
           <div class="buttons">
            <button class="button tipo-white-color back-blue-color" (click)="togglePagesFourFive()">
                <fa-icon [icon]="faCircleArrowLeft" class="icono"></fa-icon>
               {{'SUPER-ADMIN.CLIENT.DATA-COMPANY.BUTTON-BACK' | translate}}
            </button>
            <button class="button tipo-white-color back-blue-color" (click)="onSubmit()" >                
               {{'SUPER-ADMIN.CLIENT.DATA-HIRING.BUTTON-CONTINUE' | translate}}
               <fa-icon [icon]="faCircleArrowRight" class="icono"></fa-icon>
            </button>
           </div>
        </div>
        </div>
        </form>
    </div> 
 </div>


