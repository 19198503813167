import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from '@app/shared/shared.module';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { DevicesComponent } from './components/devices/devices.component';
import { EditDeviceComponent } from './components/edit-device/edit-device.component';
import { EditLogoComponent } from './components/edit-logo/edit-logo.component';
import { EditSignComponent } from './components/edit-sign/edit-sign.component';
import { EditUrlComponent } from './components/edit-url/edit-url.component';
import { ConfigurationComponent } from './pages/configuration/configuration.component';
import { DeviceDocumentsComponent } from './pages/device-documents/device-documents.component';

@NgModule({
  declarations: [
    ConfigurationComponent,
    EditUrlComponent,
    EditLogoComponent,
    EditDeviceComponent,
    EditSignComponent,
    DevicesComponent,
    DeviceDocumentsComponent,
  ],
  imports: [
    CommonModule,
    FontAwesomeModule,
    SharedModule,
    ReactiveFormsModule,
    FormsModule,
  ],
  exports: [
    EditSignComponent
  ]
})
export class PersonalizationModule {}
