<div class="pagination back-white-color tipo-second-color">
  <div class="elements" *ngIf="totalElements > 0">
    <p class="show_records">    
      {{'PRIVATE.PATIENT.LIST-PATIENTS.SHOW-ELEMENTS' | translate}}
      <strong class="parcial">{{ numbersOfElements }}</strong>
      /
      <strong class="total">{{ totalElements }}</strong>
    </p>
  </div>
  <div *ngIf="totalPages > 0">
    <p class="show_pages">
      {{ 'PRIVATE.PATIENT.LIST-PATIENTS.SHOW-PAGES' | translate }}
      <strong class="parcial">{{ pageNumber + 1 }}</strong>
      /
      <strong class="total">{{ totalPages }}</strong>
    </p>
  </div>
  
  <div class="pages" *ngIf="totalPages > 0">
    <!-- Botón para la primera página -->
    <a (click)="goToFirstPage()" id="first" *ngIf="pageNumber > 0">
      <fa-icon [icon]="faBackwardStep" class="icono"></fa-icon>
    </a>

    <a (click)="paginar(-1)" id="menos" *ngIf="pageNumber !== 0">
      <fa-icon [icon]="faCaretLeft" class="icono fa-xl"></fa-icon>
    </a>
    
    <!-- Muestra las páginas calculadas -->
    <span *ngFor="let page of pages" class="page-item" [class.active]="page === pageNumber">
      <a (click)="goToPage(page + 1)" [class.active]="page === pageNumber">
        {{ page + 1 }}
      </a>
    </span>

    <a (click)="paginar(1)" id="mas" *ngIf="lastPage !== true">
      <fa-icon [icon]="faCaretRight" class="icono fa-xl"></fa-icon>
    </a>

    <!-- Botón para la última página -->
    <a (click)="goToLastPage()" id="last" *ngIf="pageNumber < totalPages - 1">
      <fa-icon [icon]="faForwardStep" class="icono"></fa-icon>
    </a>
  </div>
</div>
