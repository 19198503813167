import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, ValidationErrors, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import QuickApiDocumentTypeEnum from '@app/core/enums/quick-api-document-type.enum';
import StatusEnum from '@app/core/enums/status.enum';
import { AlertOptions } from '@app/core/models/alert';
import { Consent } from '@app/core/models/input/consent/consent.model';
import { UserOrPatientFullName } from '@app/core/models/input/patient/patient-search.model';
import { Patient, PatientInputDTO } from '@app/core/models/input/patient/patient.model';
import { RepresentativePatientDTO } from '@app/core/models/input/patient/representative.model';
import { QuickApiInputDTO, QuickApiOutputDTO } from '@app/core/models/output/quick-api.model';
import { AlertService } from '@app/core/services/alert/alert.service';
import { ConsentService } from '@app/core/services/consent/consent.service';
import { LoginService } from '@app/core/services/login/login.service';
import { PatientService } from '@app/core/services/patient/patient.service';
import StringUtils from '@app/core/utils/string.utils';
import { ElciValidators, validNumbersValidator } from '@app/core/utils/validators';
import { TranslateService } from '@ngx-translate/core';
import ApiActionEnum from '../../enum/api-action.enum';
import { QuickApiService } from '../../services/quick-api/quick-api.service';
import { DataQuickApiInputDTO } from '@app/quick-api/models/quick-api-data.model';
import { QuickApiError } from '@app/quick-api/models/quick-api-error.model';

@Component({
  selector: 'app-api-handler',
  templateUrl: './api-handler.component.html',
  styleUrls: ['./api-handler.component.scss'],
})
export class ApiHandlerComponent implements OnInit {
  dataQuickForm!: FormGroup;

  errors: QuickApiError[] = [];

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private patientSrv: PatientService,
    private quickApiSrv: QuickApiService,
    private consentSrv: ConsentService,
    private alertService: AlertService,
    private translate: TranslateService,
    private loginSrv: LoginService
  ) {}

  /**
   * Initializes the component and handles incoming data from query parameters.
   * If valid data is found, it performs actions based on the specified action type.
   */
  ngOnInit(): void {
    // Retrieve base64 encoded data from query parameters or encode an empty object if no data is present
    const base64Data = this.route.snapshot.queryParamMap.get('data') ?? btoa('{}');
    // Decode the base64 data to obtain the original JSON string
    const data = StringUtils.b64DecodeUnicode(base64Data);

    // Parse the JSON string into an object
    const quickDataInputDTO = JSON.parse(data);

    // If no valid data is found, return without performing any action
    if (!quickDataInputDTO) {
      return;
    }

    // Switch based on the action type provided in the quickDataInputDTO object
    switch (quickDataInputDTO.action) {
      // If the action is to create CI (Consent Information)
      case ApiActionEnum.CREATE_CI:
        // Call the createOrRedirect method with appropriate parameters for generating consent
        this.createOrRedirect(quickDataInputDTO, 'generate-consent', QuickApiDocumentTypeEnum.CONSENT);
        break;
      // If the action is to create RGPD (General Data Protection Regulation)
      case ApiActionEnum.CREATE_RGPD:
        // Call the createOrRedirect method with appropriate parameters for generating GDPR document
        this.createOrRedirect(quickDataInputDTO, 'generate-gdpr', QuickApiDocumentTypeEnum.RGPD);
        break;
      // If the action is to create DD (Data Disclosure)
      case ApiActionEnum.CREATE_DD:
        // Call the createOrRedirect method with appropriate parameters for generating DD document
        this.createOrRedirect(quickDataInputDTO, 'dds', QuickApiDocumentTypeEnum.DD);
        break;
      // If the action is to ratify CI (Consent Information)
      case ApiActionEnum.RATIFY_CI:
        // Call the handleRatifyCi method
        this.handleRatifyCi(quickDataInputDTO);
        break;
      // If the action is to resend CI (Consent Information)
      case ApiActionEnum.RESEND_CI:
        // Call the handleResendCi method with quickDataInputDTO as parameter
        this.handleResendCi(quickDataInputDTO);
        break;
      // If none of the above actions match, push an error to the errors array
      default:
        // Push an error object to errors array indicating the action is null
        this.errors.push(new QuickApiError('action', 'null'));
        break;
    }
  }

  /**
   * Handles the ratification process for a consent instance received from DataQuickApi.
   * @param dataQuickApi The input data containing information related to the consent.
   */
  handleRatifyCi(dataQuickApi: DataQuickApiInputDTO) {
    // Check if elciId is provided
    if (!dataQuickApi.elcid) {
      // Push error if elciId is null
      this.errors.push(new QuickApiError('elciId', 'null'));
      return; // Exit the function
    }

    // Retrieve consent by its ID asynchronously
    this.getConsentById(dataQuickApi.elcid).then((consent: Consent) => {
      // Get the currently logged in user
      const user = this.loginSrv.userValue;

      // Configure alert options for display
      const alertOptions = new AlertOptions();
      alertOptions.keepAfterRouteChange = true; // Alert should persist after route change
      alertOptions.autoClose = false; // Alert should not automatically close

      // Check if consent status is not PENDING_BIO_SIGN
      if (StatusEnum.PENDING_BIO_SIGN !== consent.status?.id) {
        // Warn user about consent status
        this.alertService.warn(this.translate.instant('CONSENT-NOT-PENDING-BIO-SIGN-EXPIRED'), alertOptions);
      } else if (consent.doctorUuid !== user?.uuid) {
        // Warn user if consent does not belong to them
        this.alertService.warn(this.translate.instant('API-HANDLER.NOT-HIS-CONSENT'), alertOptions);
      }

      // Navigate to ratify-consent route with consent UUID
      this.router.navigate([`/portal/quick-api/patients/${consent.uuid}/ratify-consent`], { relativeTo: this.route });
    });
  }

  /**
   * Handles the resend consent action based on the provided dataQuickApi.
   * If the provided dataQuickApi does not contain a valid elcid, an error is pushed to the errors array.
   * Otherwise, it retrieves consent information by ID and performs necessary checks before proceeding to resend consent.
   * @param dataQuickApi The input data containing necessary information for the resend consent action.
   */
  handleResendCi(dataQuickApi: DataQuickApiInputDTO) {
    // Check if elcid is provided, if not, push an error and return
    if (!dataQuickApi.elcid) {
      this.errors.push(new QuickApiError('elciId', 'null'));
      return;
    }

    // Retrieve consent information by ID asynchronously
    this.getConsentById(dataQuickApi.elcid).then((consent: Consent) => {
      // Retrieve the current user
      const user = this.loginSrv.userValue;

      // Alert options configuration
      const alertOptions = new AlertOptions();
      alertOptions.keepAfterRouteChange = true;
      alertOptions.autoClose = false;

      // Check if consent is not expired
      if (StatusEnum.EXPIRED !== consent.status?.id) {
        // If consent is not expired, warn the user that consent cannot be resent
        this.alertService.warn(this.translate.instant('API-HANDLER.CONSENT-NOT-EXPIRED'), alertOptions);
      } else if (consent.doctorUuid !== user?.uuid) {
        // If the current user is not authorized to modify the consent, warn the user
        this.alertService.warn(this.translate.instant('API-HANDLER.NOT-HIS-CONSENT'), alertOptions);
      }

      // Navigate to the resend consent page for the specified consent UUID
      this.router.navigate([`/portal/quick-api/patients/${consent.uuid}/resend-consent`], { relativeTo: this.route });
    });
  }

  /**
   * Creates or redirects based on the provided dataQuickApi, redirectionPoint, and documentTypeId.
   * If dataQuickApi fails validation, no action is taken.
   * Otherwise, it retrieves patient information by name or document ID and performs necessary operations based on the search result.
   * @param dataQuickApi The input data containing necessary information for creating or redirecting.
   * @param redirectionPoint The endpoint to redirect to after creating or processing the data.
   * @param documentTypeId The type of document to be processed.
   */
  private createOrRedirect(dataQuickApi: DataQuickApiInputDTO, redirectionPoint: string, documentTypeId: number) {
    // Validate dataQuickApi, if validation fails, return without further action
    if (!this.validateDataQuickApi(dataQuickApi)) {
      return;
    }

    // Retrieve patient information by name or document ID asynchronously

    if (dataQuickApi.id !== null && dataQuickApi.id !== '') {
      this.getPatientByNameOrDocument(dataQuickApi.id ?? '').then((patientSearch: UserOrPatientFullName[]) => {
        this.handlePatientAndRedirect(dataQuickApi, patientSearch, redirectionPoint, documentTypeId);
      });
    }else{
      const patientName = dataQuickApi.name + " " + dataQuickApi.lastName1 + " " + dataQuickApi.lastName2;
      this.getPatientByNameAndRepresentativeDocument(patientName ?? '', dataQuickApi.representative1Id ?? '').then((patientSearch: UserOrPatientFullName[]) => {
        this.handlePatientAndRedirect(dataQuickApi, patientSearch, redirectionPoint, documentTypeId);
      });
    }
  }

  /**
   * Saves Quick API data asynchronously.
   * @param dataQuickApi The input data containing necessary information for saving Quick API data.
   * @param patient The patient information to be saved.
   * @param documentTypeId The type of document associated with the Quick API data.
   * @returns A promise resolving to the saved Quick API data.
   */
  private saveQuickApiData(
    dataQuickApi: DataQuickApiInputDTO,
    patient: PatientInputDTO,
    documentTypeId: number
  ): Promise<QuickApiInputDTO> {
    // Return a promise for asynchronous handling of the save operation
    return new Promise((resolve, reject) => {
      // Subscribe to the saveQuickApiData service method
      this.quickApiSrv.saveQuickApiData(new QuickApiOutputDTO(dataQuickApi, patient, documentTypeId)).subscribe({
        // On successful save, resolve the promise with the returned data
        next: quickApiData => {
          if (quickApiData) {
            resolve(quickApiData);
          }
        },
        // On error, reject the promise with the error
        error: err => {
          reject(err);
        },
      });
    });
  }

  /**
   * Creates a new patient asynchronously.
   * @param dataQuickApi The input data containing necessary information for creating the patient.
   * @returns A promise resolving to the created patient.
   */
  private createPatient(dataQuickApi: DataQuickApiInputDTO): Promise<Patient> {
    // Return a promise for asynchronous handling of the patient creation operation
    return new Promise((resolve, reject) => {
      // Subscribe to the newPatient service method
      this.patientSrv.newPatient(dataQuickApi).subscribe({
        // On successful creation, resolve the promise with the created patient
        next: patient => {
          if (patient) {
            resolve(patient);
          }
        },
        // On error, reject the promise with the error
        error: err => {
          reject(err);
        },
      });
    });
  }

  /**
   * Maps the data from DataQuickApiInputDTO to create a PatientInputDTO object.
   * @param dataQuickApi The input data containing necessary information for creating the patient.
   * @returns The mapped PatientInputDTO object.
   */
  mapDataPatient(dataQuickApi: DataQuickApiInputDTO): PatientInputDTO {
    // Map data from DataQuickApiInputDTO to create a PatientInputDTO object
    return {
      name: dataQuickApi.name,
      firstLastName: dataQuickApi.lastName1,
      secondLastName: dataQuickApi.lastName2,
      documentNumber: dataQuickApi.id,
      genderId: dataQuickApi.gender,
      birthdate: dataQuickApi.birthday,
      email: dataQuickApi.email,
      mobile: dataQuickApi.mobile,
      healthHistoryExt: dataQuickApi.numHisCliExt,
      isActive: true,
      representativePatients: this.mapRepresentativePatients(dataQuickApi),
      generateRGPD: false,
      isQuickApi: true,
    };
  }

  /**
   * Maps representative patient data from DataQuickApiInputDTO to create an array of RepresentativePatientDTO objects.
   * @param dataQuickApi The input data containing necessary information for mapping representative patients.
   * @returns An array of mapped RepresentativePatientDTO objects.
   */
  mapRepresentativePatients(dataQuickApi: DataQuickApiInputDTO): RepresentativePatientDTO[] {
    // Initialize an array to store representative patients
    const representatives = new Array<RepresentativePatientDTO>();

    // Check if patient has representative 1 and add representative if true
    if (dataQuickApi.patientHasRepresentative1) {
      representatives.push({
        name: dataQuickApi.representative1Name,
        firstLastName: dataQuickApi.representative1lastName1,
        secondLastName: dataQuickApi.representative1lastName2,
        documentNumber: dataQuickApi.representative1Id,
        email: dataQuickApi.representative1Email,
        mobile: dataQuickApi.representative1Mobile,
        isMainRepresentativePatient: true,
      });
    }

    // Check if patient has representative 2 and add representative if true
    if (dataQuickApi.patientHasRepresentative2) {
      representatives.push({
        name: dataQuickApi.representative2Name,
        firstLastName: dataQuickApi.representative2lastName1,
        secondLastName: dataQuickApi.representative2lastName2,
        documentNumber: dataQuickApi.representative2Id,
        email: dataQuickApi.representative2Email,
        mobile: dataQuickApi.representative2Mobile,
        isMainRepresentativePatient: false,
      });
    }

    // Return the array of representative patients
    return representatives;
  }

  /**
   * Retrieves patient information by name or document asynchronously.
   * @param documentOrName The document number or name of the patient to search for.
   * @returns A promise resolving to an array of UserOrPatientFullName objects representing patients found.
   */
  getPatientByNameOrDocument(documentOrName: string): Promise<UserOrPatientFullName[]> {
    // Return a promise for asynchronous handling of the patient retrieval operation
    return new Promise((resolve, reject) => {
      // Subscribe to the getPatientsByNameOrDocument service method
      this.patientSrv.getPatientsByNameOrDocument(documentOrName).subscribe({
        // On successful retrieval, resolve the promise with the retrieved patient data
        next: patient => {
          if (patient) {
            resolve(patient);
          }
        },
        // On error, reject the promise with the error
        error: err => {
          reject(err);
        },
      });
    });
  }

  /**
   * Retrieves patient information by name or document asynchronously.
   * @param documentOrName The name of the patient to search for.
   * @param representativeDocument The name of the patient to search for.
   * @returns A promise resolving to an array of UserOrPatientFullName objects representing patients found.
   */
  getPatientByNameAndRepresentativeDocument(documentOrName: string, representativeDocument: string): Promise<UserOrPatientFullName[]> {
    // Return a promise for asynchronous handling of the patient retrieval operation
    return new Promise((resolve, reject) => {
      // Subscribe to the getPatientsByNameOrDocument service method
      this.patientSrv.getPatientsByNameAndRepresentativeDocument(documentOrName, representativeDocument).subscribe({
        // On successful retrieval, resolve the promise with the retrieved patient data
        next: patient => {
          if (patient) {
            resolve(patient);
          }
        },
        // On error, reject the promise with the error
        error: err => {
          reject(err);
        },
      });
    });
  }

  /**
   * Retrieves consent information by ID asynchronously.
   * @param consentId The ID of the consent to retrieve.
   * @returns A promise resolving to the consent information.
   */
  getConsentById(consentId: string): Promise<Consent> {
    // Return a promise for asynchronous handling of the consent retrieval operation
    return new Promise((resolve, reject) => {
      // Subscribe to the getConsentUuidById service method
      this.consentSrv.getConsentUuidById(consentId).subscribe({
        // On successful retrieval, resolve the promise with the retrieved consent data
        next: consent => {
          if (consent) {
            resolve(consent);
          }
        },
        // On error, reject the promise with the error
        error: err => {
          reject(err);
        },
      });
    });
  }

  /**
   * Validates the provided DataQuickApiInputDTO object.
   * @param dataQuickApi The input data to be validated.
   * @returns A boolean indicating whether the data is valid (true) or not (false).
   */
  validateDataQuickApi(dataQuickApi: DataQuickApiInputDTO): boolean {
    // Initialize the form using the provided dataQuickApi
    this.dataQuickForm = this.initForm(dataQuickApi);

    // Apply dynamic validations for representatives
    this.representativesDynamicValidations(this.dataQuickForm);

    // Check if the form is invalid
    if (this.dataQuickForm.invalid) {
      // If the form is invalid, log validation errors
      this.getFormValidationErrors();
      // Return false indicating validation failure
      return false;
    }

    // If the form is valid, return true indicating validation success
    return true;
  }

  /**
   * Applies dynamic validations to representative form controls based on the value of 'patientHasRepresentative1' and 'patientHasRepresentative2'.
   * @param dataQuickForm The form group containing representative form controls.
   */
  representativesDynamicValidations(dataQuickForm: FormGroup) {
    // Check if patient has representative 1
    const hasRep1Value = dataQuickForm.get('patientHasRepresentative1')?.value;
    if (hasRep1Value === 1) {
      // If patient has representative 1, set validators for representative 1 form controls
      dataQuickForm.get('representative1Name')?.setValidators([Validators.required]);
      dataQuickForm.get('representative1lastName1')?.setValidators([Validators.required]);
      dataQuickForm.get('representative1Id')?.setValidators([Validators.required]);
      dataQuickForm.get('id')?.clearValidators();
    } else {
      // If patient does not have representative 1, clear validators for representative 1 form controls
      dataQuickForm.get('representative1Name')?.clearValidators();
      dataQuickForm.get('representative1lastName1')?.clearValidators();
      dataQuickForm.get('representative1Id')?.clearValidators();
      dataQuickForm.get('id')?.setValidators([Validators.required]);
    }

    // Update the validity of the form controls after changing the validators for representative 1
    dataQuickForm.get('representative1Name')?.updateValueAndValidity();
    dataQuickForm.get('representative1lastName1')?.updateValueAndValidity();
    dataQuickForm.get('representative1Id')?.updateValueAndValidity();

    // Check if patient has representative 2
    const hasRep2Value = dataQuickForm.get('patientHasRepresentative2')?.value;
    if (hasRep2Value === 1) {
      // If patient has representative 2, set validators for representative 2 form controls
      dataQuickForm.get('representative2Name')?.setValidators([Validators.required]);
      dataQuickForm.get('representative2lastName1')?.setValidators([Validators.required]);
      dataQuickForm.get('representative2Id')?.setValidators([Validators.required]);
    } else {
      // If patient does not have representative 2, clear validators for representative 2 form controls
      dataQuickForm.get('representative2Name')?.clearValidators();
      dataQuickForm.get('representative2lastName1')?.clearValidators();
      dataQuickForm.get('representative2Id')?.clearValidators();
    }

    // Update the validity of the form controls after changing the validators for representative 2
    dataQuickForm.get('representative2Name')?.updateValueAndValidity();
    dataQuickForm.get('representative2lastName1')?.updateValueAndValidity();
    dataQuickForm.get('representative2Id')?.updateValueAndValidity();
    dataQuickForm.get('id')?.updateValueAndValidity();
  }

  private initForm(dataQuickApi: DataQuickApiInputDTO): FormGroup {
    return new FormGroup({
      action: new FormControl({ value: dataQuickApi.action, disabled: false }, [Validators.required]),
      name: new FormControl({ value: dataQuickApi.name, disabled: false }, [Validators.required]),
      lastName1: new FormControl({ value: dataQuickApi.lastName1, disabled: false }, [Validators.required]),
      lastName2: new FormControl({
        value: dataQuickApi.lastName2,
        disabled: false,
      }),
      id: new FormControl({ value: dataQuickApi.id, disabled: false }, [Validators.required]),
      birthday: new FormControl({ value: dataQuickApi.birthday, disabled: false }, [Validators.required]),
      email: new FormControl({ value: dataQuickApi.email, disabled: false }, [ElciValidators.emailValidator]),
      mobile: new FormControl({ value: dataQuickApi.mobile, disabled: false }),
      patientHasRepresentative1: new FormControl({ value: dataQuickApi.patientHasRepresentative1, disabled: false }, [
        ElciValidators.trueOrFalseInteger,
      ]),
      representative1value: new FormControl({
        value: dataQuickApi.representative1Name,
        disabled: false,
      }),
      representative1lastName1: new FormControl({
        value: dataQuickApi.representative1lastName1,
        disabled: false,
      }),
      representative1lastName2: new FormControl({
        value: dataQuickApi.representative1lastName2,
        disabled: false,
      }),
      representative1Id: new FormControl({
        value: dataQuickApi.representative1Id,
        disabled: false,
      }),
      representative1Email: new FormControl({
        value: dataQuickApi.representative1Email,
        disabled: false,
      }),
      representative1Mobile: new FormControl({
        value: dataQuickApi.representative1Mobile,
        disabled: false,
      }),
      patientHasRepresentative2: new FormControl({ value: dataQuickApi.patientHasRepresentative2, disabled: false }, [
        ElciValidators.trueOrFalseInteger,
      ]),
      representative2value: new FormControl({
        value: dataQuickApi.representative2Name,
        disabled: false,
      }),
      representative2lastName1: new FormControl({
        value: dataQuickApi.representative2lastName1,
        disabled: false,
      }),
      representative2lastName2: new FormControl({
        value: dataQuickApi.representative2lastName2,
        disabled: false,
      }),
      representative2Id: new FormControl({
        value: dataQuickApi.representative2Id,
        disabled: false,
      }),
      representative2Email: new FormControl({
        value: dataQuickApi.representative2Email,
        disabled: false,
      }),
      representative2Mobile: new FormControl({
        value: dataQuickApi.representative2Mobile,
        disabled: false,
      }),
      gender: new FormControl({ value: dataQuickApi.gender, disabled: false }, [validNumbersValidator([1, 2])]),
      numHisCliExt: new FormControl({
        value: dataQuickApi.numHisCliExt,
        disabled: false,
      }),
      expedienteCliExt: new FormControl({
        value: dataQuickApi.expedienteCliExt,
        disabled: false,
      }),
      historiaCliExt: new FormControl({
        value: dataQuickApi.historiaCliExt,
        disabled: false,
      }),
      tratamientoCliExt: new FormControl({
        value: dataQuickApi.tratamientoCliExt,
        disabled: false,
      }),
      callback: new FormControl({
        value: dataQuickApi.callback,
        disabled: false,
      }),
      customer: new FormControl({ value: dataQuickApi.customer, disabled: false }, [Validators.required]),
    });
  }

  private handlePatientAndRedirect(dataQuickApi: DataQuickApiInputDTO, patientSearch: UserOrPatientFullName[], redirectionPoint: string, documentTypeId: number){
    // Map patient data into dataQuickApi, will modify the patient if needed (in the backend)
    const patient = this.mapDataPatient(dataQuickApi);

    // Check if patientSearch has exactly one result
    if (patientSearch.length !== 1) {
      // If patient is not found, create a new patient
      this.createPatient(patient).then((foundedPatient: Patient) => {
        // If a patient is successfully created, navigate to the redirection point
        if (foundedPatient.uuid) {
          this.router.navigate([`/portal/quick-api/patients/${foundedPatient.uuid}/${redirectionPoint}`], {
            relativeTo: this.route,
          });
        }
      });
    }

    // Set the UUID of the patient found in patientSearch
    patient.uuid = patientSearch[0].uuid;

    // Save quick API data and patient information asynchronously
    this.saveQuickApiData(dataQuickApi, patient, documentTypeId).then((quickApiSavedData: QuickApiInputDTO) => {
      // Save the UUID into the consent service
      this.quickApiSrv.setQuickApiUuid(quickApiSavedData.uuid ?? null);
    });

    // Navigate to the redirection point for the patient found in patientSearch
    this.router.navigate([`/portal/quick-api/patients/${patientSearch[0].uuid}/${redirectionPoint}`], {
      relativeTo: this.route,
    });
  }

  /**
   * Retrieves form validation errors and adds them to the errors array.
   */
  getFormValidationErrors() {
    // Iterate through each form control
    Object.keys(this.dataQuickForm.controls).forEach(key => {
      // Get validation errors for the current form control
      const controlErrors: ValidationErrors | null | undefined = this.dataQuickForm.get(key)?.errors;
      // If controlErrors is not null or undefined, iterate through each error
      if (controlErrors != null) {
        Object.keys(controlErrors).forEach(keyError => {
          // Push a new QuickApiError object to the errors array for each validation error found
          this.errors?.push(new QuickApiError(key, keyError));
        });
      }
    });
  }
}
