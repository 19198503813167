<div class="box_container">
    <div class="box box2 box_data">
        <div class="buttons button_edit" *ngIf="inEdit">
            <p class="small">{{"PRIVATE.ADMINISTRATOR.DATA-ADMINISTRATOR.TITLE-EDIT" | translate }}</p>
            <button class="button tipo-second-color back-white-color" (click)="changeEdit()">
                <fa-icon [icon]="faPenToSquare" class="icono fa-xl"></fa-icon>
            </button>
        </div>
        <form [formGroup]="dataAdministrator" class="form_dataadministrator"  >

            <div class="input">
                <label for="name" class="name">{{"PRIVATE.ADMINISTRATOR.DATA-ADMINISTRATOR.NAME" | translate}}</label>
                <input type="text" formControlName="name" id="name" [class.back_blue]="this.inEdition" [ngClass]="{'wrong': shouldShowError('name')}">
            </div>
            <p class="message_error wrongtext" *ngIf="this.dataAdministrator.get('name')?.errors?.['minlength'] && this.isSubmit">{{"PRIVATE.PATIENT.DATA-PATIENT.MESSAGE-FIELD-NAME" | translate }}</p>

             <div class="input">
                <label for="surname" class="surname">{{"PRIVATE.PATIENT.DATA-PATIENT.SURNAME" | translate}}</label>
                <input type="text" formControlName="surname" id="surname" [class.back_blue]="this.inEdition" [ngClass]="{'wrong': shouldShowError('surname')}">
            </div>
            <p class="message_error wrongtext" *ngIf="this.dataAdministrator.get('surname')?.errors?.['minlength'] && this.isSubmit">{{"PRIVATE.PATIENT.DATA-PATIENT.MESSAGE-FIELD-NAME" | translate }}</p>

            <div class="input">
                <label for="surnametwo" class="surnametwo">{{"PRIVATE.PATIENT.DATA-PATIENT.SURNAMETWO" | translate}}</label>
                <input type="text" formControlName="surnametwo" id="surnametwo" [class.back_blue]="this.inEdition">
            </div>

            <div class="input">
                <label for="phone" class="phone">{{"PRIVATE.ADMINISTRATOR.DATA-ADMINISTRATOR.PHONE" | translate}}</label>
                <input type="tel" formControlName="phone" id="phone" [class.back_blue]="this.inEdition" [ngClass]="{'wrong': shouldShowError('phone')}" >
            </div>
            <p class="message_error wrongtext" *ngIf="this.dataAdministrator.get('phone')?.errors?.['pattern'] && this.isSubmit">{{"PRIVATE.PATIENT.DATA-PATIENT.MESSAGE-FIELD-PHONE" | translate }}</p>

            <div class="input">
                <label for="email" class="email">{{"PRIVATE.ADMINISTRATOR.DATA-ADMINISTRATOR.EMAIL" | translate}}</label>
                <input type="email" formControlName="email" id="email" [class.back_blue]="this.inEdition" [ngClass]="{'wrong': shouldShowError('email')}" >
            </div>
            <p class="message_error wrongtext" *ngIf="this.dataAdministrator.get('email')?.errors?.['email'] && this.isSubmit">{{"PRIVATE.PATIENT.DATA-PATIENT.MESSAGE-FIELD-EMAIL" | translate }}</p>

            <div class="input">
                <label for="user" class="user">{{"PRIVATE.ADMINISTRATOR.DATA-ADMINISTRATOR.USER" | translate}}</label>
                <input type="text" formControlName="user" id="user" [class.back_blue]="this.inEdition && this.inUser" [ngClass]="{'wrong': shouldShowError('user')}"   >
            </div>
            <p class="message_error wrongtext" *ngIf="this.dataAdministrator.get('user')?.errors?.['minlength'] && this.isSubmit">{{"PRIVATE.PATIENT.DATA-PATIENT.MESSAGE-FIELD-NAME" | translate }}</p>

            <div class="input">
                <label for="pass" class="pass">{{ "PRIVATE.COMPANY.DATA-COMPANY.PASS" | translate }}</label>
                <input type="password" formControlName="pass" id="pass" [class.back_blue]="this.inEdition && this.inUser"  [ngClass]="{'wrong': shouldShowError('pass')}">
                            
            </div>
            <div class="buttons" *ngIf="!isNewPass">
                <button class="button tipo-main-color back-grey-color" (click)="openWindow()">
                    {{"PRIVATE.COMPANY.DATA-COMPANY.CHANGE-PASS" | translate }}
                </button>
            </div>
            <p class="message_error wrongtext" *ngIf="this.dataAdministrator.get('pass')?.errors?.['minlength'] && this.isSubmit">{{"PRIVATE.PATIENT.DATA-PATIENT.MESSAGE-FIELD-NAME" | translate }}</p>
            <div class="input input_checkbox">
                <div class="box_checkbox ">
                    <label for="viewcis" class="viewcis">{{ "PRIVATE.ADMINISTRATOR.DATA-ADMINISTRATOR.VIEW-CIS" | translate }}</label>
                    <div class="tooltip">
                        <fa-icon [icon]="faCircleQuestion" class="icono tipo-second-color ico_tool"></fa-icon>
                        <p class="text_tip small">{{ "PRIVATE.ADMINISTRATOR.DATA-ADMINISTRATOR.TOOLTIP-ONE" | translate }}</p>
                    </div>
                </div>
                <label class="viewcis_label" [ngClass]="{'isCheckedViewcis':isCheckedViewcis}" (click)="checkedViewcis()"></label>
                <input type="checkbox" formControlName="viewcis" id="viewcis" >
            </div>


            <div class="input input_checkbox">
                <div class="box_checkbox ">
                    <label for="supervisor" class="supervisor">{{ "PRIVATE.ADMINISTRATOR.DATA-ADMINISTRATOR.SUPERVISOR" | translate}}</label>
                    <div class="tooltip">
                        <fa-icon [icon]="faCircleQuestion" class="icono tipo-second-color ico_tool"></fa-icon>
                        <p class="text_tip small">{{ "PRIVATE.ADMINISTRATOR.DATA-ADMINISTRATOR.TOOLTIP-TWO" | translate}}</p>
                    </div>
                </div>
                <label class="supervisor_label" [ngClass]="{'isCheckedSupervisor':isCheckedSupervisor}" (click)="checkedSupervisor()"></label>
                <input type="checkbox" formControlName="supervisor" id="supervisor" >
            </div>

            <div class="input input_radio input_status" >
                <label for="status" class="status">{{"PRIVATE.DOCTOR.DATA-DOCTOR.STATE" | translate}}</label>
                <span>{{ "PRIVATE.DOCTOR.DATA-DOCTOR.INACTIVE" | translate }}</span>
                <label for="status" class="switch-button-label" [ngClass]="{'isCheckedStatus':isCheckedStatus}" (click)="checkedStatus()"></label>                
                <input type="checkbox" formControlName="status" class="switch-button-checkbox" >
                <span>{{ "PRIVATE.DOCTOR.DATA-DOCTOR.ACTIVE" | translate }}</span>
            </div>


            <div class="input input_date" *ngIf="isDisabled">
                <label for="dateregister" class="dateregister">{{"PRIVATE.DOCTOR.DATA-DOCTOR.REGISTER-DATE" | translate}}</label>
                <input type="text" formControlName="dateregister" id="dateregister" [value]="registerDate | UTCToLocal" >
            </div>

            <div class="input input_date" *ngIf="isDisabled">
                <label for="datelastaccess" class="datelastaccess">{{"PRIVATE.DOCTOR.DATA-DOCTOR.LAST-ACCESS-DATE" | translate }}</label>
                <input type="text" formControlName="datelastaccess" id="datelastaccess"  [value]="lastAccessDate | UTCToLocal" >
            </div>
            <div class="input input_date" *ngIf="isDisabled">
                <label for="canceldate" class="canceldate">{{"PRIVATE.DOCTOR.DATA-DOCTOR.CANCEL-DATE" | translate }}</label>
                <input type="text" formControlName="canceldate" id="canceldate" [value]="cancelDate | UTCToLocal" >
            </div>
            <div class="buttons buttons_save" *ngIf="!inEdit || !isDisabled">
                <button class="button tipo-white-color back-blue-color" type="submit" [ngClass]="{'back-blue-color': !confirmInactive, inactive: confirmInactive}" [disabled]="confirmInactive" (click)="onSubmit()">
                    <fa-icon [icon]="faFileExport" class="icono"></fa-icon>
                    {{ "PRIVATE.PATIENT.DATA-PATIENT.BUTTON-SAVE" | translate }}
                </button>
                <button class="button tipo-main-color back-grey-color" (click)="changeEdit()">
                    {{ "PRIVATE.PATIENT.DATA-PATIENT.BUTTON-CANCEL" | translate }}
                </button>
            </div>
        </form>

         <!--para cambiar la password -->
         <div class="window" *ngIf="isOpenWindow">
            <div class="window_reset">
                <h4 class="tipo-second-color bold">{{"PRIVATE.COMPANY.DATA-COMPANY.CHANGE-PASS" | translate }}</h4>
                <form [formGroup]="dataPass" class="reset">                    
                    <div class="inlineflex" *appHasRole="ADMINISTRATORS_ROLES">
                        <input [type]="showPasswordOld ? 'text' : 'password'" class="pass back_blue" id="pass" placeholder="Password actual" formControlName="oldPassword">
                        <fa-icon [icon]="faEyeSlash" class="icono"  *ngIf="showPasswordOld === false" (click)="showPassOld()"></fa-icon>
                        <fa-icon [icon]="faEye" class="icono"  *ngIf="showPasswordOld" (click)="showPassOld()"></fa-icon>
                    </div>                       
                    <div class="inlineflex">
                        <input [type]="showPasswordNew ? 'text' : 'password'" class="pass back_blue" id="pass" placeholder="Nueva password" formControlName="newPassword">
                        <fa-icon [icon]="faEyeSlash" class="icono"  *ngIf="showPasswordNew === false" (click)="showPassNew()"></fa-icon>
                        <fa-icon [icon]="faEye" class="icono"  *ngIf="showPasswordNew" (click)="showPassNew()"></fa-icon>
                    </div>
                    <div class="buttons">
                        <button class="button tipo-white-color back-blue-color" (click)="changePass()" >
                            <fa-icon [icon]="faFileExport" class="icono iconono"></fa-icon>
                            {{ "PRIVATE.PATIENT.DATA-PATIENT.BUTTON-SAVE" | translate }}
                        </button>
                        <button class="button tipo-main-color back-grey-color" (click)="openWindow()">
                            {{ "PRIVATE.PATIENT.DATA-PATIENT.BUTTON-CANCEL" | translate }}
                        </button>
                    </div>
                </form>
            </div>
        </div>

          <!-- popup para confirmar que se quiere desactivar un profesional -->
          <div class="confirmar" *ngIf="confirmInactive">
            <h4 class="title tipo-second-color bold">{{ 'PRIVATE.DOCTOR.DATA-DOCTOR.TITLE-MESSAGE-CONFIRM-INACTIVE-USER' | translate }}</h4>
            <p class="small">{{ 'PRIVATE.DOCTOR.DATA-DOCTOR.SUBTITLE-MESSAGE-CONFIRM-INACTIVE-USER' | translate }}</p>
            <div class="buttons">
            <button class="button tipo-white-color back-blue-color" (click)="inactiveUser()">
                <fa-icon [icon]="faCheckDouble" class="icono iconono"></fa-icon>
                {{ 'PRIVATE.PATIENT.FILE-PATIENT.BUTTON-CONFIRM' | translate }}
            </button>
            <button (click)="confirm()" class="button tipo-main-color back-grey-color">
                {{ 'PRIVATE.PATIENT.DATA-PATIENT.BUTTON-CANCEL' | translate }}
            </button>
        </div>
    </div>

</div>


