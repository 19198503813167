<div class="wrapper">
    <div class="cabecera">
        <img class="movil" src="../../../assets/img/avisolegal/cabecera-movil.jpg" alt="Aviso legal">
        <img class="desktop" src="../../../assets/img/avisolegal/cabecera.jpg" alt="Aviso legal">
        <h1 class="titulo_seccion tipo-second-color bold">Aviso Legal</h1>
    </div>
    <div class="maxwidth">
        <div class="contenido tipo-main-color regular">
            <section class="avisolegal">

                <article >

                    <h4>1. Titular del Sitio Web</h4>
                    <ul>
                        <li><strong>Denominación social:</strong> DIRECTUM SOCIETAS, S.L. (en adelante, El Consentimiento)</li>
                        <li><strong>CIF/NIF:</strong> B-84867779</li>
                        <li><strong>Domicilio social:</strong> C/ Doctor Esquerdo 66, 28007 Madrid</li>
                        <li><strong>Teléfono:</strong> <a href="tel:+34911599997">(+911) 599 997</a></li>
                        <li><strong>Dirección de correo electrónico:</strong> <a href="mailto:info@elconsentimiento.es" target="_blank" rel="noopener noreferrer">info@elconsentimiento.es</a></li>
                        <li><strong>Formulario de contacto:</strong> <a href="httlis://www.elconsentimiento.es/contac" target="_self">contacto</a></li>
                    </ul>
                </article>

                <article>
                    <h4> Registros:</h4>
                    <ul>
                        <li> <strong>Registro Mercantil de Madrid,</strong> Libro de Sociedades Tomo 23.427, Folio 186, Sección 8, Hoja M 420183.</li>
                        <li>Marca <strong>El Consentimiento</strong> (OEPM): Clasificación de Niza 35 /42 /45, Clasificación de Viena 24.17.20 / 26.02.08 / 27.05.09 / 27.05.10 / 29.01.03 / 29.02.06</li>
                        <li>Expediente M 365369</li>
                        <li>Registro General de Protección de Datos de la AEPD</li>
                    </ul>
                </article>

                <article>
                    <h4>Códigos de conducta y normativa específica:</h4>
                    <ul>
                        <li><a href="https://www.boe.es/buscar/act.php?id=BOE-A-2002-13758" target="_blank" rel="noopener noreferrer">Ley 34/2002 de 11 de julio,</a> de servicios de la sociedad de la información y de comercio electrónico.</li>
                        <li> <a href="https://www.boe.es/buscar/act.php?id=BOE-A-2002-22188" target="_blank" rel="noopener noreferrer">Ley 41/2002 de 14 de noviembre,</a> básica reguladora de la autonomía del paciente y de derechos y obligaciones en materia de información y documentación clínica.</li>
                        <li> <a href="https://www.boe.es/buscar/act.php?id=BOE-A-2003-21340" target="_blank" rel="noopener noreferrer">Ley 44/2003 de 21 de noviembre,</a> de ordenación de las profesiones sanitarias: https://www.boe.es/buscar/act.php?id=BOE-A-2003-21340</li>
                    </ul>
                </article>

                <article>
                    <h4>Páginas de El Consentimiento (En adelante, Sitio Web):</h4>
                    <p><strong>Página web principal:</strong> <a href="https://www.elconsentimiento.es">www.elconsentimiento.es</a></p>
                    <p><strong>Perfiles sociales: </strong>
                    <a href="https://www.linkedin.com/company/42937326/admin/" target="_blank" rel="noopener noreferrer">Linkedin </a>
                    </p>
                </article>

                <article>
                    <h4>2. Protección de datos personales</h4>
                    <h5>2.a. Responsable del tratamiento</h5>
                    <ul>
                        <li><strong>Datos de contacto del responsable:</strong> Directum Societas, S.L. (B-84867779) con domicilio en C/ Doctor Esquerdo 66, Local, 28007, Madrid, teléfono 91 212 13 90 y correo electrónico de contacto <a href="mailto:lopd@elconsentimiento.es">lopd@elconsentimiento.es</a></li>
                        <li><strong>Datos de contacto del Delegado de Protección de Datos (DPO):</strong> D. Francisco Javier Rivas Olivares, <a href="mailto:lopd@elconsentimiento.es">lopd@elconsentimiento.es</a></li>
                    </ul>
                    <h5>2.b. Derechos de Protección de Datos - POLIARSO</h5>
                    <p><strong>Cómo ejercitar los derechos:</strong></p>
                    <p>Los usuarios pueden dirigir una comunicación por escrito al domicilio social de El Consentimiento o a la dirección de correo electrónico indicado en el encabezamiento de este aviso legal, incluyendo en ambos casos fotocopia de su DNI u otro documento identificativo similar, para solicitar el ejercicio de los derechos siguientes:</p>
                    <ul>
                        <li>Derecho a solicitar el acceso a los datos personales: usted podrá preguntar a El Consentimiento si está tratando sus datos.</li>
                        <li>Derecho a solicitar su rectificación (en caso de que sean incorrectos) o supresión.</li>
                        <li>Derecho a solicitar la limitación de su tratamiento, en cuyo caso únicamente serán conservados por El Consentimiento para el ejercicio o la defensa de reclamaciones.</li>
                        <li>Derecho a oponerse al tratamiento: El Consentimiento dejará de tratar los datos en la forma que usted indique, salvo que por motivos legítimos imperiosos o el ejercicio o la defensa de posibles reclamaciones se tengan que seguir tratando.</li>
                        <li>Derecho a la portabilidad de los datos: en caso de que quiera que sus datos sean tratados por otra firma, El Consentimiento le facilitará la portabilidad de sus datos al nuevo responsable, siempre que nos haya prestado su consentimiento y sea técnicamente viable (en formato interoperable).</li>
                    </ul>
                    <p><strong>Modelos, formularios y más información sobre los derechos referidos: </strong><a href="https://www.agpd.es/portalwebAGPD/CanalDelCiudadano/derechos/index-ides-idphp.php" target="_blank" rel="noopener noreferrer">Derechos</a></p>
                    <br>
                    <p> <strong>Posibilidad de retirar el consentimiento:</strong></p>
                    <p>En el caso de que se haya otorgado el consentimiento para alguna finalidad específica, usted tiene derecho a retirar el consentimiento en cualquier momento, sin que ello afecte a la licitud del tratamiento basado en el consentimiento previo a su retirada. Si no desea que El Consentimiento trate sus datos personales puede hacérnoslo saber en cualquier momento notificándonoslo por escrito debidamente firmado a las siguientes direcciones de correo postal y electrónico, acreditando su identidad:</p>
                    <p>Calle Doctor Esquerdo nº66, Local C.P.28007, Madrid, España</p>
                    <p><a href="mailto:lopd@elconsentimiento.es">lopd@elconsentimiento.es</a></p><br>
                    <p><strong> Cómo reclamar ante la Autoridad de Control de datos personales:</strong></p>
                    <p>Si usted como usuario considera que hay un problema con la forma en que el El Consentimiento está manejando sus datos, puede dirigir sus reclamaciones al Delegado de Protección de Datos de El Consentimiento o a la autoridad de protección de datos que corresponda, siendo la Agencia Española de Protección de Datos la indicada en el caso de España.</p>
                    <h5>2.c. Conservación de los datos</h5>
                    <p><strong> Datos desagregados:</strong> </p>
                    <p>Los datos desagregados serán conservados sin plazo de supresión.</p>
                    <p><strong>Datos sujetos a Tratamiento: </strong></p>
                    <p>Los datos personales se conservarán durante el tiempo que se mantenga la relación contractual y, una vez finalice ésta, no se solicite la supresión pertinente por el interesado. En cualquier caso, será el mínimo necesario, pudiendo mantenerse hasta:</p>
                    <ul>
                        <li>4 años: Ley sobre Infracciones y Sanciones en el Orden Social (obligaciones en materia de afiliación, altas, bajas, cotización, pago de salarios…); Arts. 66 y sig. Ley General Tributaria (libros de contabilidad…);</li>
                        <li>5 años: Art. 1964 Código Civil (acciones personales sin plazo especial)</li>
                        <li>6 años: Art. 30 Código de Comercio (libros de contabilidad, facturas…)</li>
                        <li>10 años: Art. 25 Ley de Prevención del Blanqueo de Capitales y Financiación del Terrorismo.</li>
                    </ul>
                </article>

                <article>
                    <h4>3. Procedencia, finalidades y legitimidad</h4>
                    <h5> 3.a. E-mail, formularios de contacto y medios de comunicación tradicionales</h5>
                    <p><strong>Web y hosting:</strong></p>
                    <p>El Sitio Web de El Consentimiento cuenta con un cifrado que permite al usuario el envío seguro de sus datos personales a través de formularios de contacto de tipo estándar y alojados en los servidores que el proveedor ofrece a El Consentimiento.</p><br>

                    <p><strong>Datos recabados a través de la web:</strong></p>
                    <p>Los datos personales recogidos serán objeto de tratamiento automatizado e incorporados a los correspondientes ficheros de los queEl Consentimiento es titular y responsable. A estos efectos:
                    Nos llegará su IP, que será usada para comprobar el origen del mensaje con objeto de ofrecerle recomendaciones legales adecuadas al mismo y para detectar posibles irregularidades, así como datos relativos a su ISP.</p>
                    <p> Asimismo, podrá facilitarnos sus datos a través de teléfono, correo electrónico y otros medios de comunicación indicados en la sección de contacto.</p><br>
                    <p><strong>Correo electrónico:</strong></p>
                    <p> Nuestro prestador de servicios de correo electrónico es Mailgun Technologies, Inc. No obstante, reforzamos ciertas comunicaciones, si procede, con otros proveedores externos.</p><br>
                    <p><strong>Mensajería instantánea: El Consentimiento</strong></p>
                    <p>Sí presta servicio a través de mensajería instantánea a través de redes sociales para lo cual ha recabado previamente el consentimiento expreso de sus clientes.</p><br>

                    <p><strong>Otros servicios:</strong></p>
                    <p>Ciertos servicios prestados a través del Sitio Web pueden contener condiciones particulares con previsiones específicas en materia de protección de datos personales. Se hace indispensable su lectura y aceptación con carácter previo a la solicitud del servicio de que se trate.</p><br>
                    <p><strong>Finalidad y legitimación:</strong></p>
                    <p> En El Consentimiento tratamos la información que nos facilitan las personas interesadas con el fin de mantener la relación profesional, comercial y/o contractual que en su caso se establezca, con independencia de que se llegue a formalizar o no ésta, o posteriormente se mantenga o no en vigor, a efectos estadísticos y de remisión de propaganda y publicidad de nuevos servicios jurídicos, de carácter docente, formativos, benéficos, convocatoria a eventos, promociones y otros de carácter financiero o inversor, pudiendo utilizar para las comunicaciones canales de SMS y apps de mensajería instantánea para lo cual el interesado ha prestado previamente su consentimiento expreso.</p>
                    <p>Con el fin de poder ofrecerle productos y servicios de acuerdo con sus intereses y mejorar su experiencia como cliente, elaboraremos un ‘perfil comercial’, en base a la información facilitada, no tomándose decisiones automatizadas en base a dicho perfil, adoptando en todo momento las medidas de seguridad oportunas para salvaguardar el tratamiento de los datos de carácter personal que nos ha facilitado.</p>
                </article>

                <article>
                    <h4>4. Redes sociales</h4>
                    <p><strong>Presencia en redes:</strong></p>
                    <p>El Consentimiento cuenta con perfil en algunas de las principales redes sociales de Internet (Twitter e Instagram).</p><br>
                    <p><strong>Finalidad y legitimación:</strong></p>
                    <p>El tratamiento que El Consentimiento llevará a cabo con los datos dentro de cada una de las referidas redes será, como máximo, el que la red social permita a los perfiles corporativos. Así pues, El Consentimiento podrá informar, cuando la ley no lo prohíba, a sus seguidores por cualquier vía que la red social permita sobre sus actividades, ponencias, ofertas, así como prestar servicio personalizado de atención al cliente.</p><br>
                    <p><strong>Extracción de datos:</strong></p>
                    <p> En ningún caso El Consentimiento extraerá datos de las redes sociales, a menos que se obtuviera puntual y expresamente el consentimiento del usuario para ello.</p><br>
                    <p><strong>Derechos:</strong></p>
                    <p>Cuando, debido a la propia naturaleza de las redes sociales, el ejercicio efectivo de los derechos de protección de datos del seguidor quede supeditado a la modificación del perfil personal de éste, El Consentimiento le ayudará y aconsejará a tal fin en la medida de sus posibilidades.</p>
                </article>

                <article>
                    <h4>5. Navegación web (cookies)</h4>
                    <p><strong>Qué son las cookies:</strong></p>
                    <p>La versión de escritorio del Sitio Web utiliza cookies; su versión móvil no las usa. Las cookies son ficheros creados en el navegador del usuario para registrar su actividad en el Sitio Web y permitirle una navegación más fluida y personalizada.</p>
                    <p>Las cookies no son virus informáticos. No dañan su ordenador ni ralentizan su funcionamiento. Puede eliminarlas en cualquier momento, o rechazarlas configurando el programa de navegación que utiliza.
                    Usted puede recibir dos tipos de cookies al visitar nuestra web:</p>
                    <ul>
                        <li><strong>Cookies de terceros:</strong> en alguna página del Sitio Web se muestra contenido embebido o invocado a través del cual se pueden estar instalando cookies de terceros. Cuando incluimos estos contenidos en nuestro Sitio Web nos aseguramos de que la única finalidad de estas cookies sea técnica. Los terceros que prestan los contenidos pueden decidir cambiar la finalidad de estas cookies, por lo que revisamos estas páginas cada cierto tiempo. Si desea hacernos alguna pregunta sobre las cookies que se instalan a través de este Sitio Web, contacte con nosotros.
                        Estas cookies están instaladas en su equipo por el servicio Google Analytics. Son cookies estadísticas que nos permiten conocer datos útiles para mejorar nuestra página, como, por ejemplo, número de visitas que hemos recibido o las horas de mayor tráfico. Para obtener más información sobre las condiciones de privacidad de este servicio, visite el siguiente  <a href="https://www.google.es/intl/es/analytics/privacyoverview.html" target="_blank" rel="noopener noreferrer">enlace.</a></li>
                        <li><strong>Redes Sociales:</strong> En otros sitios en los que El Consentimiento tiene página o perfil social se instalan cookies de terceros a todos sus visitantes, aunque no sean usuarios registrados en las correspondientes plataformas: Página de Cookies de Twitter y Página de Privacidad de Instagram.</li>
                    </ul>
                    <p><strong>Cómo borrar las cookies:</strong></p>
                    <p>Para utilizar este Sitio Web no resulta necesaria la instalación de cookies. El usuario puede no aceptarlas o configurar su navegador para bloquearlas y, en su caso, eliminarlas. Aprenda a administrar sus cookies en Firefox, Chrome, IE, Opera y Safari.</p>
                    <p>En caso contrario, entendemos que contamos con su consentimiento para su instalación.</p><br>
                    <p><strong>Cookies propias, generadas por nuestro servidor:</strong></p>
                    <p>Estas cookies tienen distintas funciones:</p><br>
                    <p><strong>Cookies de autenticación:</strong> Se utilizan para reconocer a los usuarios registrados que se han “logado” en una página web. Son las cookies que permiten que Usted acceda a las secciones de acceso restringido de la web, en caso de que se le hayan otorgado claves de acceso para ello. Si rechaza su instalación o las borra, sus claves de acceso no funcionarán de forma correcta.</p><br>
                    <p> <strong>Cookies de personalización de interfaz:</strong> Las usamos para facilitar su navegación. Son las que permiten recordar el tamaño de la letra del texto para configurar nuestra web automáticamente conforme a sus preferencias durante su visita. Si rechaza su instalación o las borra, la navegación por nuestra web no será tan sencilla.</p>
                    <p> Si tiene alguna duda sobre nuestra política de cookies, póngase en contacto con nosotros en la siguiente dirección: <a href="mailto:lopd@elconsentimiento.es">lopd@elconsentimiento.es</a></p>
                </article>

                <article>
                    <h4>6. Confidencialidad y destrucción documental</h4>
                    <p><strong>Secreto profesional:</strong></p>
                    <p>Los profesionales de El Consentimiento que tuvieran algún tipo de intervención en los servicios prestados al cliente, están comprometidos a no divulgar ni hacer uso de la información a la que hayan accedido por razón de su profesión, por así disponerlo cada una de las regulaciones existentes para cada uno de ellos. La información suministrada por el cliente tendrá, en todo caso, la consideración de confidencial, sin que pueda ser utilizada para otros fines que los relacionados con los servicios contratados a El Consentimiento, obligándose a no divulgar ni revelar información sobre las pretensiones del cliente, los motivos del asesoramiento solicitado o la duración de su relación con este.</p><br>

                    <p><strong>Destrucción documental:</strong></p>
                    <p>Con el objetivo de preservar y garantizar la confidencialidad, El Consentimiento se compromete a destruir toda la información confidencial a la que por razón de una prestación de servicios haya tenido acceso una vez transcurridos 90 días desde la finalización del servicio al Cliente, salvo que exista una obligación legal para su conservación. Si el Cliente desea conservar el original o una copia de dicha información, deberá imprimirla o guardarla por sus propios medios o acudir a la sede de El Consentimiento para recogerla antes de su destrucción.</p>
                    <h4>7. Contenido del Sitio Web</h4>
                    <p>Los contenidos literarios del Sitio Web se encuentran registrados en el Registro de Propiedad Intelectual y se ofrecen bajo la licencia correspondiente. Se exceptúan de esta licencia todos los contenidos no literarios, aquellos sobre los que se indique otra licencia aplicable y los signos distintivos (marcas, nombres comerciales, etc.).</p>
                    <h4> 8. Responsabilidad por el contenido del Sitio Web</h4>
                    <p>El Sitio Web contiene textos elaborados con fines informativos. Las opiniones vertidas en los mismos no reflejan necesariamente los puntos de vista de El Consentimiento. Los contenidos de los artículos publicados en este Sitio Web no pueden ser considerados, en ningún caso, sustitutivo de asesoramiento. El usuario no debe actuar sobre la base de la información contenida en este Sitio Web sin recurrir previamente al correspondiente asesoramiento profesional.
                    Los enlaces externos que contiene este Sitio Web conducen a sitios gestionados por terceros. El Consentimiento no responde de los contenidos ni del estado de dichos sitios. El uso de enlaces externos tampoco implica que El Consentimiento recomiende o apruebe los contenidos de las páginas de destino.</p>
                    <h4>9. Normativa y resolución de conflictos</h4>
                    <p>Las presentes Condiciones de Uso del Sitio Web se rigen en todos y cada uno de sus extremos por la ley española. El idioma de redacción e interpretación de este aviso legal es el castellano. Este aviso legal no se archivará individualmente para cada usuario, sino que permanecerá accesible por medio de Internet en este mismo Sitio Web.
                    Las partes acuerdan someterse a los Juzgados y Tribunales de Madrid, por ser este el domicilio social de El Consentimiento, con renuncia expresa a cualquier otra jurisdicción que pudiera corresponderles.</p>
                    <h4>10. Propiedad intelectual</h4>
                    <p>Eres responsable de respetar los derechos de los demás, incluidos los derechos de autor. Por eso, debes saber que todos los contenidos de este sitio web, textos, imágenes, diseños y códigos fuente, son el trabajo constante de un equipo médicos, juristas, diseñadores, programadores y especialistas en general, que los realizan a fin de proteger a los profesionales sanitarios.</p>
                    <p>  Cualquier copia o reproducción de los mismos, sea del contenido íntegro o de una parte, así como la explotación de la licencia de uso a través de otros softwares o aplicaciones sin autorización expresa de DS Legal Group, además de inmoral va en contra de la normativa y hará que efectuemos las acciones judiciales correspondientes.</p>
                </article>
            </section>


        </div>
    </div>
    <div class="pie">
        <img class="movil" src="../../../assets/img/avisolegal/footer-movil.jpg" alt="Aviso legal">
        <img class="desktop" src="../../../assets/img/avisolegal/footer.jpg" alt="Aviso legal">
    </div>
</div>
