import { Component } from '@angular/core';
import { faHouseMedical, faStethoscope, faUserGroup, faGraduationCap, faFileContract, faScaleBalanced, faFileSignature, faDownload } from '@fortawesome/free-solid-svg-icons';
import { Ficha } from '../../interfaces/interfaces';

@Component({
  selector: 'app-quees',
  templateUrl: './quees.component.html',
  styleUrls: ['./quees.component.scss']
})
export class QueesComponent {
  faHouseMedical = faHouseMedical;
  faStethoscope = faStethoscope;
  faUserGroup = faUserGroup;
  faGraduationCap = faGraduationCap;
  faFileContract = faFileContract;
  faScaleBalanced = faScaleBalanced;
  faFileSignature = faFileSignature;
  faDownload = faDownload;
  ficha: Array<Ficha> = [
    {
      icono: faHouseMedical,
      number: '43',
      titulo: 'Especialidades disponibles',
      subtitulo: ''
    },
    {
      icono: faStethoscope,
      number: '+1500',
      titulo: 'Procedimientos médicos',
      subtitulo: ''
    },
    {
      icono: faUserGroup,
      number: '+1000',
      titulo: 'Profesionales usando la solución',
      subtitulo: ''
    },
    {
      icono: faGraduationCap,
      number: '10',
      titulo: 'Digitalizando documentos clínicos',
      subtitulo: ''
    },
    {
      icono: faFileContract,
      number: '',
      titulo: 'Procedimientos actualizados',
      subtitulo: 'por un equipo de peritos médicos y juristas que conjugan la técnica quirúrgica con la lex artis ad hoc'
    },
    {
      icono: faScaleBalanced,
      number: '',
      titulo: 'Contenidos ajustados a la ley',
      subtitulo: 'seguridad jurídica para el profesional y decisión informada del paciente que mejora la calidad asistencial'
    },
    {
      icono: faFileSignature,
      number: '',
      titulo: 'Firma biométrica y firma remota',
      subtitulo: 'documentos de RGPD, procedimientos y a medida con firma biométrica sobre tablet o remota'
    },
    {
      icono: faDownload,
      number: '',
      titulo: 'Archivo en la nube rápido y seguro',
      subtitulo: 'acceso a la base de datos de pacientes y procedimientos consultando el histórico almacenado en la nube'
    }
  ]

}
