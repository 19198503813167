<p class="tipo-second-color bold section">{{ "PRIVATE.CONSENTS.EXPIRED-CONSENT.TITLE-RESEND" | translate }}</p>
<div class="input_row"> 
    <div class="input">
        <select name="device" id="device" [(ngModel)]="selectedDevice">
            <option [ngValue]="null" hidden>{{ "PRIVATE.CONSENTS.EXPIRED-CONSENT.INPUT-SELECT" | translate }}</option>
            <option *ngFor= "let device of devices; let i=index" ngValue="{{device.deviceName}}">{{device.deviceDescription}}</option>
           
        </select>
    </div>
    <button class="button tipo-white-color back-blue-color"
    (click)="resendConsent(consentUuid ?? '')"
    [disabled]="isButtonChecked" 
    [ngClass]="{'back-blue-color': !isButtonChecked, 'inactive': isButtonChecked}">
        <fa-icon [icon]="faPaperPlane" class="icono"></fa-icon>
        {{ "PRIVATE.CONSENTS.EXPIRED-CONSENT.BUTTON-SEND" | translate }}
    </button>   
</div>    
