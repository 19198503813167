import { Injectable } from '@angular/core';
import * as CryptoJS from 'crypto-js';
import { CookieService } from 'ngx-cookie-service';
import { Observable } from 'rxjs';
import { EnvironmentService } from '../../../environment.service';
import StorageTypeEnum from '@app/shared/enums/storage-type.enum';

@Injectable({
  providedIn: 'root',
})
export abstract class CacheService {
  constructor(
    private env: EnvironmentService,
    private cookieService: CookieService
  ) {}

  public getItemObservableNotEncrypted(key: string): Observable<string> {
    return new Observable<string>(observer => {
      const item = this.getItemNotEncrypted(key, StorageTypeEnum.LOCAL_STORAGE);
      if (item) {
        observer.next(item);
      } else {
        observer.error();
      }
      observer.complete();
    });
  }

  public getItemObservable(key: string): Observable<string> {
    return new Observable<string>(observer => {
      const item = this.getItem(key, StorageTypeEnum.LOCAL_STORAGE);
      if (item) {
        observer.next(item);
      } else {
        observer.error();
      }
      observer.complete();
    });
  }

  protected getItem(key: string, storageType: number): string | null {
    const storageMap: { [key: string]: Storage } = {
      '1': sessionStorage,
      '2': localStorage
    };

    const storage = storageMap[storageType.toString()];
    if (storage) {
      const encryptedData = storage.getItem(key);
      return encryptedData ? this.decryptData(encryptedData) : null;
    }
    return null;
  }

  protected getItemNotEncrypted(key: string, storageType: number): string | null {
    const storageMap: { [key: string]: Storage } = {
      '1': sessionStorage,
      '2': localStorage
    };

    const storage = storageMap[storageType.toString()];
    if (storage) {
      const data = storage.getItem(key);
      if (data && data !== 'undefined') {
        try {
          return JSON.parse(data);
        } catch (e) {
          return data;
        }
      }
    }
    return null;
  }

  protected setItemNotEncrypted(key: string, data: string, storageType: number) {
    const storageMap: { [key: string]: Storage } = {
      '1': sessionStorage,
      '2': localStorage
    };

    const storage = storageMap[storageType.toString()];
    if (storage) {
      try {
        storage.setItem(key, JSON.stringify(data));
      } catch (e) {
        storage.setItem(key, data);
      }
    }
  }

  protected setItem(key: string, data: string, storageType: number) {
    const storageMap: { [key: string]: Storage } = {
      '1': sessionStorage,
      '2': localStorage
    };

    const storage = storageMap[storageType.toString()];
    if (storage) {
      storage.setItem(key, this.encryptData(data));
    }
  }


  protected setCookie(name: string, data: string, expireDays: number): void {
    const dateExpire = Date.now() + expireDays;
    this.cookieService.set(name, this.encryptData(data), {
      sameSite: 'None',
      secure: true,
      expires: dateExpire,
    });
  }

  protected getCookie(name: string): string {
    return this.decryptData(this.cookieService.get(name));
  }

  protected deleteCookie(name: string): void {
    return this.cookieService.delete(name);
  }

  protected checkCookie(name: string): boolean {
    return this.cookieService.check(name);
  }

  protected removeItem(key: string, storageType: number) {
    const storageMap: { [key: string]: Storage } = {
      '1': sessionStorage,
      '2': localStorage
    };

    const storage = storageMap[storageType.toString()];
    if (storage) {
      storage.removeItem(key);
    }
  }

  protected clear() {
    localStorage.clear();
    sessionStorage.clear();
  }

  encryptData(data: string): string {
    try {
      if (!this.env.doNotEncryptValues) {
        return CryptoJS.AES.encrypt(
          JSON.stringify(data),
          this.env.localStorageSecretKey
        ).toString();
      } else {
        return data;
      }
    } catch (e) {
      return '';
    }
  }

  decryptData(data: string | null) {
    if (!data) {
      return '';
    }
    try {
      if (!this.env.doNotEncryptValues) {
        const bytes = CryptoJS.AES.decrypt(
          data,
          this.env.localStorageSecretKey
        );
        if (bytes.toString()) {
          return JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
        }
      } else {
        return data;
      }
    } catch (e) {
      return '';
    }
  }
}
