<div
  class="back-light-blue-color"
  [ngClass]="{ wrapper: isApp, wrapper_quick: isQuickapi }">
  <div class="new_consent_box">
    <!-- Formulario de consentimiento -->
    <div class="new_consent">
      <div class="box">
        <h4 class="title tipo-second-color bold">
          {{ 'PRIVATE.CONSENTS.NEW-CONSENT.TITLE' | translate }}
        </h4>
        <p class="regular">
          {{ 'PRIVATE.CONSENTS.NEW-CONSENT.SUBTITLE' | translate }}
        </p>
        <app-data-consent
          (consentGenerated)="onConsentGenerated($event)"></app-data-consent>
        <app-gen-consent></app-gen-consent>
        <div class="mobile">
          <h5 class="tipo-second-color">
            <fa-icon
              [icon]="fa5"
              class="icono fa-1xl back-blue-color tipo-white-color number"></fa-icon>
            {{
              'PRIVATE.CONSENTS.NEW-CONSENT.TITLE-SELECT-LANGUAGE-TEMPLATE'
                | translate
            }}
          </h5>
          <div class="input">
            <label for="language tipo-main-color regular">
              {{ 'PRIVATE.CONSENTS.NEW-CONSENT.SELECT-LANGUAGE' | translate }}
            </label>
            <select
              name="language"
              id="language"
              placeholder="Selecciona idioma"
              [(ngModel)]="selectedTemplateLanguage"
              (ngModelChange)="changeTemplateLanguage($event)">
              <option
                *ngFor="let language of languages; let i = index"
                [ngValue]="language.locale">
                {{ language.name }}
              </option>
            </select>
          </div>
          <div class="input">
            <label for="docType tipo-main-color regular">
              {{ 'PRIVATE.CONSENTS.NEW-CONSENT.SELECT-TEMPLATE' | translate }}
            </label>
            <select
              name="docType"
              id="doc_type"
              placeholder="Selecciona plantilla"
              [(ngModel)]="selectedTemplate"
              (ngModelChange)="changeSelectedTemplate($event)">
              <option
                *ngFor="let template of templates; let i = index"
                [ngValue]="template.uuid">
                {{ template.type }}
              </option>
            </select>
          </div>
        </div>
        <div class="buttons">
          <button
            class="button tipo-white-color back-blue-color"
            [disabled]="isButtonChecked"
            [ngClass]="{
              'back-blue-color': !isButtonChecked,
              inactive: isButtonChecked
            }"
            (click)="submitDataConsentFormDraft()">
            <fa-icon [icon]="faHardDrive" class="icono"></fa-icon>
            {{ 'PRIVATE.CONSENTS.NEW-CONSENT.BUTTON-SAVE' | translate }}
          </button>
          <button
            class="button tipo-white-color back-blue-color"
            [disabled]="isButtonChecked"
            [ngClass]="{
              'back-blue-color': !isButtonChecked,
              inactive: isButtonChecked
            }"
            (click)="submitDataConsentForm()">
            <fa-icon [icon]="faHouseMedicalCircleCheck" class="icono"></fa-icon>
            {{ 'PRIVATE.CONSENTS.NEW-CONSENT.BUTTON-GENERATE' | translate }}
          </button>
          <button
            class="button tipo-main-color back-grey-color"
            (click)="cancel()">
            {{ 'PRIVATE.PATIENT.DATA-PATIENT.BUTTON-CANCEL' | translate }}
          </button>
        </div>
      </div>
    </div>
    <!-- Vista consentimiento -->
    <div class="document back-white-color" [ngClass]="{ maximize: isMax }">
      <div class="back-grey-color">
        <h5 class="tipo-second-color desktop">
          {{
            'PRIVATE.CONSENTS.NEW-CONSENT.TITLE-SELECT-LANGUAGE-TEMPLATE'
              | translate
          }}
        </h5>
        <div class="input_row desktop">
          <div class="input">
            <label for="language tipo-main-color regular">
              {{ 'PRIVATE.CONSENTS.NEW-CONSENT.SELECT-LANGUAGE' | translate }}
            </label>
            <select
              name="language"
              id="language"
              placeholder="Selecciona idioma"
              [(ngModel)]="selectedTemplateLanguage"
              (ngModelChange)="changeTemplateLanguage($event)">
              <option
                *ngFor="let language of languages; let i = index"
                ngValue="{{ language.locale }}">
                {{ language.name }}
              </option>
            </select>
          </div>
          <div class="input">
            <label for="docType tipo-main-color regular">
              {{ 'PRIVATE.CONSENTS.NEW-CONSENT.SELECT-TEMPLATE' | translate }}
            </label>
            <select
              name="docType"
              id="doc_type"
              placeholder="Selecciona plantilla"
              [(ngModel)]="selectedTemplate"
              (ngModelChange)="changeSelectedTemplate($event)">
              <option
                *ngFor="let template of templates; let i = index"
                ngValue="{{ template.uuid }}">
                {{ template.type }}
              </option>
            </select>
          </div>
        </div>
        <div class="buttons">
          <button
            class="button tipo-second-color button_max"
            (click)="maximizeTemplate()"
            *ngIf="!isMax">
            <fa-icon [icon]="faMaximize" class="icono fa-1xl"></fa-icon>
            {{ 'PRIVATE.CONSENTS.NEW-CONSENT.BUTTON-FULLSCREEN' | translate }}
          </button>
          <button
            class="button tipo-second-color button_max"
            (click)="maximizeTemplate()"
            *ngIf="isMax">
            <fa-icon [icon]="faMinimize" class="icono fa-1xl"></fa-icon>
            {{ 'PRIVATE.CONSENTS.NEW-CONSENT.BUTTON-MINIMIZE' | translate }}
          </button>
        </div>
      </div>
      <div class="consent back-white-color">
        <app-ci-template></app-ci-template>
      </div>
    </div>
  </div>
</div>
