import { ConsentsByStatuses } from "./consents-by-statuses.model";
import { BarChartData } from "./consents-last-six-months.model";
import { StatisticConsentsByDoctor } from "./statistic-consents-by-doctor.model";
import { StatisticDDs } from "./statistic-dds.model";
import { StatisticPatients } from "./statistic-patients.model";
import { TopConsents } from "./top-consents.model";

export class Statistics {
    topConsents?: Array<TopConsents>;
    consentsByStatuses?: Array<ConsentsByStatuses>;
    consentsLastSixMonths?: Array<BarChartData>;
    statisticConsentsByDoctor?: Array<StatisticConsentsByDoctor>;
    statisticPatients?: Array<StatisticPatients>;
    statisticDDs?:StatisticDDs;
}

