import { Injectable } from '@angular/core';
import { RgpdTemplate } from '@app/core/models/input/template/rgpd-template.model';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PatientsShareService {

  private templateSubject: BehaviorSubject<RgpdTemplate | null> = new BehaviorSubject<RgpdTemplate | null>(null);
  template$: Observable<RgpdTemplate | null> = this.templateSubject.asObservable();

  setTemplate(template: RgpdTemplate) {
    this.templateSubject.next(template);
  }

}
