import { Component } from '@angular/core';
import { faCircleXmark } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-window-error',
  templateUrl: './window-error.component.html',
  styleUrls: ['./window-error.component.scss']
})
export class WindowErrorComponent {
  faCircleXmark = faCircleXmark;

  closeWindow(): void {
    const window = document.querySelector('.window');
    window?.classList.add('nodisplay');
  }
}
