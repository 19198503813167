<h5 class="title tipo-second-color bold">{{ 'PRIVATE.DASHBOARD.MY-DOCUMENTS.TITLE' | translate }}</h5>
<div class="box_documents">
    <div class="item">
            <p class="number tipo-main-color">{{statisticDDs?.created === null ? 0 : statisticDDs?.created }}</p>
            <p class="label tipo-second-color">{{ 'PRIVATE.DASHBOARD.MY-DOCUMENTS.CREATED-DOCUMENTS' | translate }}</p>
    </div>
    <div class="item">
        <p class="number tipo-main-color">{{statisticDDs?.signed === null ? 0 : statisticDDs?.signed }}</p>
        <p class="label tipo-second-color">{{ 'PRIVATE.DASHBOARD.MY-DOCUMENTS.SIGNED-DOCUMENTS' | translate }}</p>
    </div>
</div>
