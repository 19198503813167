<div class="buttons">
  <button class="button tipo-white-color back-blue-color" (click)="toggleWindow()">
    <fa-icon [icon]="faPaperPlane" class="icono"></fa-icon>
    {{ 'PRIVATE.CONSENTS.DRAFT-CONSENT.BUTTON-GIVE' | translate }}
  </button>
  <button
    class="button tipo-second-color back-grey-color"
    [routerLink]="['./edit']">
    <fa-icon [icon]="faPenToSquare" class="icono fa-xl"></fa-icon>
  </button>
  <button class="button tipo-second-color back-grey-color" (click)="toggleTrash()">
    <fa-icon [icon]="faTrashCan" class="icono fa-xl"></fa-icon>
  </button>
</div>

<!-- abrre ventana para enviar a dispositivo -->
<div class="window_back" *ngIf="isOpenWindow">
  <div class="window">
      <div class="close">
          <fa-icon [icon]="faCircleXmark" class="icono fa-2xl tipo-second-color" (click)="toggleWindow()"></fa-icon>
      </div>
       <app-gen-consent [ownSubmit]="true"></app-gen-consent>
      
  </div>
</div>

<!-- abrre ventana para confirmar borrado -->
<div class="window_back" *ngIf="isTrashModalOpen">
  <div class="window">     
      <p class="regular">{{"PRIVATE.CONSENTS.DRAFT-CONSENT.TITLE-DELETE" | translate}}</p>
      <div class="buttons">
        <button
          class="button tipo-white-color back-blue-color"
          (click)="deleteDraft()">
          <fa-icon [icon]="faHouseMedicalCircleCheck" class="icono"></fa-icon>
          {{"PRIVATE.CONSENTS.DATA-CONSENT.BUTTON-GO-ON" | translate}}
        </button>
        <button
          class="button tipo-main-color back-grey-color"
          (click)="toggleTrash()">
          {{ 'PRIVATE.PATIENT.DATA-PATIENT.BUTTON-CANCEL' | translate }}
        </button>
      </div> 
      
  </div>
</div>

