<p class="tipo-second-color bold section">{{ "PRIVATE.CONSENTS.PRINTED-CONSENT.TITLE" | translate }}<span class="small">{{ "PRIVATE.CONSENTS.PRINTED-CONSENT.SUBTITLE" | translate }}</span></p>
<div class="input_row">
    <div class="input">    
        <input type="file" name="file" id="file" (change)="uploadConsentFile($event)">
    </div>
    <div class="input">
        <select name="state" id="state" [(ngModel)]="statusSelected">
            <option value = "0">{{ "PRIVATE.CONSENTS.PRINTED-CONSENT.INPUT-STATE" | translate }}</option>
            <option value = {{Status.SIGNED}}>{{ "PRIVATE.CONSENTS.PRINTED-CONSENT.TITLE-SIGNED" | translate }}</option>
            <option value = {{Status.REJECTED}}>{{ "PRIVATE.CONSENTS.PRINTED-CONSENT.TITLE-REJECTED" | translate }}</option>
            <option value = {{Status.REVOKED}}>{{ "PRIVATE.CONSENTS.PRINTED-CONSENT.TITLE-REVOKED" | translate }}</option>
        </select>
    </div>
</div>
<div class="buttons buttons_save">
    <button class="button tipo-white-color back-blue-color" (click)="updateConsentFile()">
        <fa-icon [icon]="faFileExport" class="icono iconono"></fa-icon>
        {{ "PRIVATE.PATIENT.DATA-PATIENT.BUTTON-SAVE" | translate }}
    </button>
    <button class="button tipo-main-color back-grey-color" (click)="refresh()">
        {{ "PRIVATE.PATIENT.DATA-PATIENT.BUTTON-CANCEL" | translate }}
    </button>
</div>