import { Component, OnInit } from '@angular/core';
import { DESC } from '@app/core/constants/Constants';
import Role from '@app/core/enums/role.enum';
import { Filter } from '@app/core/models/front/filters';
import { Pagination } from '@app/core/models/front/pagination';
import { Sorters } from '@app/core/models/front/sorters';
import { Page } from '@app/core/models/input/page/pages.model';
import { User } from '@app/core/models/input/user.model';
import { RoleService } from '@app/core/services/role/role.service';
import { UserService } from '@app/core/services/user/user.service';
import { FilterUtils } from '@app/core/utils/filter.utils';
import { faFileCirclePlus, faCaretUp, faCaretDown, faCaretLeft, faCaretRight } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-list-administrators',
  templateUrl: './list-administrators.component.html',
  styleUrls: ['./list-administrators.component.scss']
})

export class ListAdministratorsComponent implements OnInit {
  faFileCirclePlus = faFileCirclePlus;
  faCaretUp = faCaretUp;
  faCaretDown = faCaretDown;
  faCaretLeft = faCaretLeft;
  faCaretRight = faCaretRight;

  // Utils
  filterUtils: FilterUtils;

  // enums
  role = Role;

  // Properties
  users?: User[];
  page?: Page;
  pagination = new Pagination();
  filters = new Array<Filter>();
  sorters = new Sorters('creationDate', DESC);


  constructor(
    private userService: UserService,
    public roleService: RoleService
  ) {
    this.filterUtils = new FilterUtils(this.filters);
  }

  ngOnInit() {
    this.addFilter(
      'rolesId',
      Role.ADMINISTRATIVE_SUPERVISOR.toString() + ',' +
      Role.ADMINISTRATIVE_VIEW_CIS.toString() + ',' +
      Role.ADMINISTRATIVE.toString()
    );

  }

  addFilter(name: string, filter: string) {
    // If we filter the requested page will be 0
    this.pagination.page = 0;
    this.filterUtils.addFilter(name, filter);
    this.getAdministratorsByCompany();
  }

  removeFilter(name: string) {
    // If we filter the requested page will be 0
    this.pagination.page = 0;
    this.filterUtils.removeFilter(name);
    this.getAdministratorsByCompany();
  }

  paginar(amount: number) {
    this.pagination.page += amount;
    this.getAdministratorsByCompany();
  }

  getAdministratorsByCompany() {
    this.userService
      .getUsers(this.pagination, this.filters, this.sorters)
      .subscribe({
        next: usersPage => {
          this.page = usersPage;
          this.users = this.page.content;
        },
      });
  }
}

