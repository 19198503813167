import { Component, Input } from '@angular/core';
import RoleEnum from '@app/core/enums/role.enum';
import { CompanyDataAdmin } from '@app/core/models/input/company/company.model';
import { UserInput } from '@app/core/models/input/user-input.model';

@Component({
  selector: 'app-header-user',
  templateUrl: './header-user.component.html',
  styleUrls: ['./header-user.component.scss']
})
export class HeaderUserComponent {
  @Input() companyData?: CompanyDataAdmin;   
  @Input() isDigital = false;  
  @Input() userData?: UserInput;
  @Input() roleUser?: string;  


  roleEnum?: RoleEnum;


}
