import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ProcedureContent, ProceduresAndSpecialty, ProceduresBySpecialty, SpecialtiesAndProcedures, TotalProceduresBySpecialty } from '@app/core/models/front/public';
import { EnvironmentService } from '@app/environment.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PublicService {

  constructor(
    private http: HttpClient,
    private env: EnvironmentService  
    
  ) { }


  getTotalSpecialtiesAndProcedures() {
    return this.http.get<SpecialtiesAndProcedures>(`${this.env.apiHost}/consents/public/list/count/ES/ES`)
  }

  getTotalProceduresBySpecialties():Observable<TotalProceduresBySpecialty[]> {
    return this.http.get<TotalProceduresBySpecialty[]>(`${this.env.apiHost}/consents/public/specialties`)
  }

  getProceduresListBySpecialty(id: number):Observable<ProceduresBySpecialty[]> {
    return this.http.get<ProceduresBySpecialty[]>(`${this.env.apiHost}/consents/public/procedures/speciality/${id}/ES/ES`)
  }

  getProcedureContent(code: number): Observable<ProcedureContent> {
    return this.http.get<ProcedureContent>(`${this.env.apiHost}/consents/public/procedures/code/${code}/ES/ES`)
  }

  getProceduresByFilter(specialityId: number, procedureName: string) {
    if(specialityId == 0 || specialityId == null){
      if (procedureName !== '')
        return this.http.get<ProceduresAndSpecialty[]>(`${this.env.apiHost}/consents/public/procedures/ES/ES?`+`procedureName=${procedureName}`)
      else 
        return this.http.get<ProceduresAndSpecialty[]>(`${this.env.apiHost}/consents/public/procedures/ES/ES?`)      
    }        
    else if (procedureName === '')
        return this.http.get<ProceduresAndSpecialty[]>(`${this.env.apiHost}/consents/public/procedures/ES/ES?`+ `specialitiesId=${specialityId}`)
      else 
        return this.http.get<ProceduresAndSpecialty[]>(`${this.env.apiHost}/consents/public/procedures/ES/ES?`+ `procedureName=${procedureName}&specialitiesId=${specialityId}`)   
  }
}
