import { Sort } from "./sort.model";

export class Page {
  content?: any; // <- will be specialized
  totalPages?: number;
  totalElements?: number;
  last?: boolean;
  size?: number;
  number?: number;
  sort?: Array<Sort>;
  numberOfElements?: number;
  first?: boolean;
  empty?: boolean;
}
