<div class="box_container">
    <div class="box box2 box_data">
        <div class="buttons button_edit" *ngIf="inEdit">
            <p class="small">{{"PRIVATE.DOCTOR.DATA-DOCTOR.TITLE-EDIT" | translate }}</p>
            <button class="button tipo-second-color back-white-color" (click)="changeEdit()">
                <fa-icon [icon]="faPenToSquare" class="icono fa-xl"></fa-icon>
            </button>
        </div>
        <form [formGroup]="dataDoctor" class="form_datadoctor" >

            <div class="input">
                <label for="name" class="name">{{"PRIVATE.DOCTOR.DATA-DOCTOR.NAME" | translate}}</label>
                <input type="text" formControlName="name" id="name" [class.back_blue]="this.inEdition" [ngClass]="{'wrong': shouldShowError('name')}">
            </div>
            <p class="message_error wrongtext" *ngIf="this.dataDoctor.get('name')?.errors?.['minlength'] && this.isSubmit">{{"PRIVATE.PATIENT.DATA-PATIENT.MESSAGE-FIELD-NAME" | translate }}</p>

            <div class="input">
                <label for="surname" class="surname">{{"PRIVATE.PATIENT.DATA-PATIENT.SURNAME" | translate}}</label>
                <input type="text" formControlName="surname" id="surname" [class.back_blue]="this.inEdition" [ngClass]="{'wrong': shouldShowError('surname')}">
            </div>
            <p class="message_error wrongtext" *ngIf="this.dataDoctor.get('surname')?.errors?.['minlength'] && this.isSubmit">{{"PRIVATE.PATIENT.DATA-PATIENT.MESSAGE-FIELD-NAME" | translate }}</p>

            <div class="input">
                <label for="surnametwo" class="surnametwo">{{"PRIVATE.PATIENT.DATA-PATIENT.SURNAMETWO" | translate}}</label>
                <input type="text" formControlName="surnametwo" id="surnametwo" [class.back_blue]="this.inEdition">
            </div>

            <div class="input">
                <label for="nif" class="nif">{{ "PRIVATE.DOCTOR.DATA-DOCTOR.DOCUMENT" | translate }}</label>
                <input type="text" formControlName="nif" id="nif" [class.back_blue]="this.inEdition" [ngClass]="{'wrong': shouldShowError('nif')}" >
            </div>
            <p class="message_error wrongtext" *ngIf="this.dataDoctor.get('nif')?.errors?.['pattern'] && this.isSubmit">{{"PRIVATE.PATIENT.DATA-PATIENT.MESSAGE-FIELD-DOCUMENT" | translate }}</p>

            <div class="input input_date">
                <label for="datebirth" class="datebirth">{{ "PRIVATE.DOCTOR.DATA-DOCTOR.BIRTHDATE" | translate}}</label>
                <input type="date" formControlName="datebirth" id="datebirth" [class.back_blue]="this.inEdition">
            </div>

            <div class="input">
                <label for="numcol" class="numcol">{{"PRIVATE.DOCTOR.DATA-DOCTOR.NUM-COL" | translate}}</label>
                <input type="text" formControlName="numcol" id="numcol" [class.back_blue]="this.inEdition && this.inUser" [(ngModel)]="userValue"  [ngClass]="{'wrong': shouldShowError('numcol')}">
            </div>
            <p class="message_error wrongtext" *ngIf="this.dataDoctor.get('numcol')?.errors?.['pattern'] && this.isSubmit">{{"PRIVATE.PATIENT.DATA-PATIENT.MESSAGE-FIELD-PHONE" | translate }}</p>

            <div class="input">
                <label for="state" class="state">{{"PRIVATE.DOCTOR.DATA-DOCTOR.PROVINCE" | translate}}</label>
                <select formControlName="state" id="state" [(ngModel)]="selectValue" [class.back_blue]="this.inEdition" >
                    <option [ngValue]="null">{{"PRIVATE.DOCTOR.DATA-DOCTOR.PROVINCE" | translate}}</option>
                    <option value="{{state.id}}" *ngFor= "let state of states; let i=index">{{state.name}}</option>

                </select>
            </div>

            <div class="input">
                <label for="phone" class="phone">{{"PRIVATE.DOCTOR.DATA-DOCTOR.PHONE" | translate}}</label>
                <input type="tel" formControlName="phone" id="phone" [class.back_blue]="this.inEdition" [ngClass]="{'wrong': shouldShowError('phone')}" >
            </div>
            <p class="message_error wrongtext" *ngIf="this.dataDoctor.get('phone')?.errors?.['pattern'] && this.isSubmit">{{"PRIVATE.PATIENT.DATA-PATIENT.MESSAGE-FIELD-PHONE" | translate }}</p>

            <div class="input">
                <label for="email" class="email">{{"PRIVATE.DOCTOR.DATA-DOCTOR.EMAIL" | translate}}</label>
                <input type="email" formControlName="email" id="email" [class.back_blue]="this.inEdition" [ngClass]="{'wrong': shouldShowError('email')}">
            </div>
            <p class="message_error wrongtext" *ngIf="this.dataDoctor.get('email')?.errors?.['email'] && this.isSubmit">{{"PRIVATE.PATIENT.DATA-PATIENT.MESSAGE-FIELD-EMAIL" | translate }}</p>

        </form>
          <!--para cambiar la password -->
          <div class="window" *ngIf="isOpenWindow">
            <div class="window_reset">
                <h4 class="tipo-second-color bold">{{"PRIVATE.COMPANY.DATA-COMPANY.CHANGE-PASS" | translate }}</h4>
                <form [formGroup]="dataPass" class="reset">                   
                    <div class="inlineflex" *ngIf="isSameUser">
                        <input [type]="showPasswordOld ? 'text' : 'password'" class="pass back_blue" id="pass" placeholder="Password actual" formControlName="oldPassword">
                        <fa-icon [icon]="faEyeSlash" class="icono"  *ngIf="showPasswordOld === false" (click)="showPassOld()"></fa-icon>
                        <fa-icon [icon]="faEye" class="icono"  *ngIf="showPasswordOld" (click)="showPassOld()"></fa-icon>
                    </div>                       
                    <div class="inlineflex">
                        <input [type]="showPasswordNew ? 'text' : 'password'" class="pass back_blue" id="pass" placeholder="Nueva password" formControlName="newPassword">
                        <fa-icon [icon]="faEyeSlash" class="icono"  *ngIf="showPasswordNew === false" (click)="showPassNew()"></fa-icon>
                        <fa-icon [icon]="faEye" class="icono"  *ngIf="showPasswordNew" (click)="showPassNew()"></fa-icon>
                    </div>
                    <div class="buttons">
                        <button class="button tipo-white-color back-blue-color" (click)="changePass()">
                            <fa-icon [icon]="faFileExport" class="icono iconono"></fa-icon>
                            {{ "PRIVATE.PATIENT.DATA-PATIENT.BUTTON-SAVE" | translate }}
                        </button>
                        <button class="button tipo-main-color back-grey-color" (click)="openWindow()">
                            {{ "PRIVATE.PATIENT.DATA-PATIENT.BUTTON-CANCEL" | translate }}
                        </button>
                    </div>
                </form>
            </div>
        </div>
        
    </div>
    <div class="box box2 box_data2">
        <form [formGroup]="dataDoctor" class="form_datadoctor"  >
            <div class="input">
                <label for="service" class="service">{{"PRIVATE.DOCTOR.DATA-DOCTOR.SERVICE" | translate }}</label>
                <input type="text" formControlName="service" id="service" [class.back_blue]="this.inEdition && !this.isSameUser">
            </div>

            <div class="input">
                <label for="speciality" class="speciality">{{"PRIVATE.DOCTOR.DATA-DOCTOR.SPECIALTY" | translate}}</label>
                <div class="specialty_list" *ngIf="isDisabled || isSameUser">
                    <span class="item" *ngFor="let item of specialty; let last = last">{{ item.description }}{{ !last ? ', ' : '' }}</span>

                </div>
                <div class="specialties_input" *ngIf="!isDisabled && !isSameUser">
                    <div class="specialties">
                        <span class="selected" *ngFor="let item of specialty; let i=index">{{ item.description }} <fa-icon [icon]="faCircleXmark" class="icono" (click)="deleteItem(i, item)"></fa-icon></span>
                     </div>
                        <ul class="specialty_select" [class.back_blue]="this.inEdition" (click)="editField()">
                            <li value="{{specialty.id}}" *ngFor= "let specialty of specialties; let i=index" (click)="addItem(i, specialty)" >{{specialty.description}}</li>
                        </ul>
                    <p class="small messageSpecialty">{{"PRIVATE.DOCTOR.DATA-DOCTOR.MESSAGE-FIELD-SPECIALTY" | translate }}</p>

                </div>
            </div>


            <div class="input" >
                <label for="user" class="user">{{"PRIVATE.DOCTOR.DATA-DOCTOR.USER" | translate}}</label>
                <input type="text" formControlName="user" id="user"[class.back_blue]="this.inEdition && this.inUser" [(ngModel)]="userValue">
            </div>

            <div class="input">
                <label for="pass" class="pass">{{ "PRIVATE.COMPANY.DATA-COMPANY.PASS" | translate }}</label>                
                <input type="password" formControlName="pass" id="pass" [class.back_blue]="this.inEdition && this.inUser" [ngClass]="{'wrong': shouldShowError('pass')}">                                   
                
            </div> 
            <div class="buttons" *ngIf="!isNew">
                <button class="button tipo-main-color back-grey-color" (click)="openWindow()">
                    {{"PRIVATE.COMPANY.DATA-COMPANY.CHANGE-PASS" | translate }}
                </button>
            </div>            
            <p class="message_error wrongtext" *ngIf="this.dataDoctor.get('pass')?.errors?.['pass'] && this.isSubmit">{{"PRIVATE.DOCTOR.DATA-DOCTOR.MESSAGE-PASS-REQUIRED" | translate }}</p>
            <div class="input input_checkbox input_credential" *ngIf="!this.isSameUser">
                <label for="">{{"PRIVATE.DOCTOR.DATA-DOCTOR.CREDENTIAL" | translate}}</label>
                <input type="checkbox" formControlName="credential" id="credential" >
                <label
                  for="credential"
                  id="credential_label"
                  [ngClass]="{ isCheckedCredential: isCheckedCredential }"
                  (click)="checkedCredential()">                   
                </label>
            </div>
           
            <div class="input input_radio input_status" *ngIf="!this.isSameUser">
                <label for="status" class="status">{{"PRIVATE.DOCTOR.DATA-DOCTOR.STATE" | translate}}</label>
                <span>{{ "PRIVATE.DOCTOR.DATA-DOCTOR.INACTIVE" | translate }}</span>
                <label for="status" class="switch-button-label" [ngClass]="{'isCheckedStatus':isCheckedStatus}" (click)="checkedStatus()"></label>                
                <input type="checkbox" formControlName="status" class="switch-button-checkbox" >
                <span>{{ "PRIVATE.DOCTOR.DATA-DOCTOR.ACTIVE" | translate }}</span>
            </div>
          


            <div class="input input_date" *ngIf="isDisabled">
                <label for="dateregister" class="dateregister">{{"PRIVATE.DOCTOR.DATA-DOCTOR.REGISTER-DATE" | translate}}</label>
                <input type="text" formControlName="dateregister" id="dateregister" [value]="registerDate | UTCToLocal" >
            </div>

            <div class="input input_date" *ngIf="isDisabled">
                <label for="datelastaccess" class="datelastaccess">{{"PRIVATE.DOCTOR.DATA-DOCTOR.LAST-ACCESS-DATE" | translate }}</label>
                <input type="text" formControlName="datelastaccess" id="datelastaccess" [value]="lastAccessDate | UTCToLocal" >
            </div>
            <div class="input input_date" *ngIf="isDisabled">
                <label for="canceldate" class="canceldate">{{"PRIVATE.DOCTOR.DATA-DOCTOR.CANCEL-DATE" | translate }}</label>
                <input type="text" formControlName="canceldate" id="canceldate" [value]="cancelDate | UTCToLocal" >
            </div>
            <div class="input" *ngIf="isBiometric">
                <label for="device" class="device">{{ "PRIVATE.DOCTOR.DATA-DOCTOR.DEVICE" | translate }}</label>
                <select formControlName="device" id="device" [(ngModel)]="selectValueDev" [class.back_blue]="this.inEdition">
                    <option [ngValue]="null" hidden>{{ "PRIVATE.DOCTOR.DATA-DOCTOR.DEVICE" | translate }}</option>
                    <option [value]="device.deviceName" *ngFor= "let device of devices; let i=index">{{device.deviceDescription}}</option>
                </select>
            </div>
            
            <!-- popup para confirmar que se quiere desactivar un profesional -->
            <div class="confirmar" *ngIf="confirmInactive">
                <h4 class="title tipo-second-color bold">{{ 'PRIVATE.DOCTOR.DATA-DOCTOR.TITLE-MESSAGE-CONFIRM-INACTIVE-USER' | translate }}</h4>
                <p class="small">{{ 'PRIVATE.DOCTOR.DATA-DOCTOR.SUBTITLE-MESSAGE-CONFIRM-INACTIVE-USER' | translate }}</p>
                <div class="buttons">
                <button class="button tipo-white-color back-blue-color" (click)="inactiveUser()">
                    <fa-icon [icon]="faCheckDouble" class="icono iconono"></fa-icon>
                    {{ 'PRIVATE.PATIENT.FILE-PATIENT.BUTTON-CONFIRM' | translate }}
                </button>
                <button (click)="confirm()" class="button tipo-main-color back-grey-color">
                    {{ 'PRIVATE.PATIENT.DATA-PATIENT.BUTTON-CANCEL' | translate }}
                </button>
                </div>
            </div>

              <!-- popup para confirmar que se quiere revocar la credencial -->
              <div class="confirmar" *ngIf="confirmCredential">
                <h4 class="title tipo-second-color bold">{{ 'PRIVATE.DOCTOR.DATA-DOCTOR.CREDENTIAL-REVOKE' | translate }}</h4>
               
                <div class="buttons">
                <button class="button tipo-white-color back-blue-color" (click)="revokeCredential()">
                    <fa-icon [icon]="faCheckDouble" class="icono iconono"></fa-icon>
                    {{ 'PRIVATE.PATIENT.FILE-PATIENT.BUTTON-CONFIRM' | translate }}
                </button>
                <button (click)="confirmRevokeCredential()" class="button tipo-main-color back-grey-color">
                    {{ 'PRIVATE.PATIENT.DATA-PATIENT.BUTTON-CANCEL' | translate }}
                </button>
                </div>
            </div>

            <div class="buttons buttons_save" *ngIf="!inEdit || !isDisabled">
                <button class="button tipo-white-color back-blue-color" type="submit" [ngClass]="{'back-blue-color': !confirmInactive || !confirmCredential, inactive: confirmInactive || confirmCredential}" [disabled]="confirmInactive || confirmCredential" (click)="onSubmit()" >
                    <fa-icon [icon]="faFileExport" class="icono iconono"></fa-icon>
                    {{ "PRIVATE.PATIENT.DATA-PATIENT.BUTTON-SAVE" | translate }}
                </button>
                <button class="button tipo-main-color back-grey-color" [ngClass]="{'back-orange-color': !confirmInactive || !confirmCredential, inactive: confirmInactive || confirmCredential}" [disabled]="confirmInactive || confirmCredential" (click)="changeEdit()">
                    {{ "PRIVATE.PATIENT.DATA-PATIENT.BUTTON-CANCEL" | translate }}
                </button>
            </div>



        </form>
    </div>

</div>

