
    <footer class="footer">   
           
                    <div class="footer_logo">
                        <img src="../../../assets/img/footer/logo-Legaltech.png" alt="DS LegalTech">
                    </div>                       
                        <div class="footer_legales ">
                                <p class="small tipo-orange-color"> 
                                    <a [routerLink]="['avisolegal']"  class="tipo-orange-color">  {{ "PRIVATE.FOOTER.LEGAL-ADVICE" | translate }} </a> ·
                                    <a [routerLink]="['politicaprivacidad']"  class="tipo-orange-color">  {{ "PRIVATE.FOOTER.LEGAL-POLICY" | translate }} </a> ·                                      
                                    <a [routerLink]="['cookies']"  class="tipo-orange-color">  {{ "PRIVATE.FOOTER.COOKIES" | translate }} </a> 
                                </p>
                                
                        </div>
                        <div class="footer_datos center">
                            <p class="titular-medium small tipo-orange-color">{{ "PRIVATE.FOOTER.EMAIL" | translate }}&nbsp;&nbsp;|&nbsp;&nbsp;{{ "PRIVATE.FOOTER.PHONE" | translate }}<span class="barra">&nbsp;&nbsp;|&nbsp;&nbsp;</span> {{ "PRIVATE.FOOTER.DIRECTION" | translate }}</p>
                    </div>
                    <div class="firma">
                        <p class="small tipo-second-color bold dslegal"> 2023 DS LEGAL GROUP®  </p>                       
                 </div> 
              
    </footer>

