<p class="tipo-second-color bold section">{{ "PRIVATE.CONSENTS.PENDINGBIO-CONSENT.TITLE" | translate }}</p>
<div class="input_row">
    <div class="input">
        <select name="device" id="device" [(ngModel)]="selectedDevice">
          <option value ="0">
            {{ 'PRIVATE.CONSENTS.EXPIRED-CONSENT.INPUT-SELECT' | translate }}
          </option>
          <option
            *ngFor="let device of devices; let i = index"
            ngValue="{{ device.deviceName }}">
            {{ device.deviceDescription }}
          </option>
        </select>
      </div>
    <button
        (click)="ratifyConsent()"
        [disabled]="sendingToRatify"
        [ngClass]="{'back-blue-color': !sendingToRatify, 'inactive': sendingToRatify}"
        class="button tipo-white-color back-blue-color"
    >
        <fa-icon [icon]="faPaperPlane" class="icono" *ngIf="!sendingToRatify"></fa-icon>
        <fa-icon [icon]="faSpinner" class="icono" animation="spin-pulse" *ngIf="sendingToRatify"></fa-icon>
        {{ "PRIVATE.CONSENTS.EXPIRED-CONSENT.BUTTON-SEND" | translate }}
    </button>
</div>