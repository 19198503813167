
import { DataQuickApiInputDTO } from "@app/quick-api/models/quick-api-data.model";
import { PatientInputDTO } from "../input/patient/patient.model";

export class QuickApiOutputDTO {
    callbackUrl?: string;
    documentTypeId?: number;
    petition?: string;
    patient?: PatientInputDTO;

    constructor(dataQuickApi: DataQuickApiInputDTO, patient: PatientInputDTO, documentTypeId: number) {
        this.callbackUrl = dataQuickApi.callback;
        this.documentTypeId = documentTypeId;
        this.petition = btoa(JSON.stringify(dataQuickApi)); // Transform to base64 to preserve encode
        this.patient = patient;
    }
}

export class QuickApiInputDTO {
    uuid?: string;
}