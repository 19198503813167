<p class="subtitle tipo-second-color">{{ "PRIVATE.PERSONALIZATION.EDIT-URL.TITLE" | translate }}</p>
<form [formGroup]="dataUrl">
<div class="box_url">    
    <label for="url">{{ "PRIVATE.PERSONALIZATION.EDIT-URL.LABEL" | translate }}</label>
    <input type="text" formControlName="url" class="url">             
</div>
<div class="buttons">
    <button class="button tipo-white-color back-blue-color" type="submit" (click)="onSubmit()">
        <fa-icon [icon]="faFileExport" class="icono"></fa-icon>
        {{ "PRIVATE.PATIENT.DATA-PATIENT.BUTTON-SAVE" | translate }}
    </button> 
</div>
</form>
<div class="box_text_gdpr">
    <p class="regular small">{{ "PRIVATE.PERSONALIZATION.EDIT-URL.PARAGRAPH-ONE" | translate}}</p>
    <p class="regular small">{{ "PRIVATE.PERSONALIZATION.EDIT-URL.PARAGRAPH-TWO-FIRST" | translate }} <span>Nombre Empresa </span>, 
        {{ "PRIVATE.PERSONALIZATION.EDIT-URL.PARAGRAPH-TWO-SECOND" | translate }} <span>Dirección empresa,</span> {{ "PRIVATE.PERSONALIZATION.EDIT-URL.PARAGRAPH-TWO-THIRD" | translate }} <span>correo empresa</span></p>
    <p class="regular small">{{ "PRIVATE.PERSONALIZATION.EDIT-URL.PARAGRAPH-THREE" | translate}}</p>
</div>
