<div class="box_container">
    <div class="box">
      <div class="row">
        <div class="column">
          <p class="bold tipo-second-color">
            {{'SUPER-ADMIN.DATA-COMPANY-ADMIN.DATA-CLIENT-TYPE' | translate}}:
            <span class="regular">{{"SUPER-ADMIN.DATA-COMPANY-ADMIN.TITLE-COMPANY" | translate}}</span>
          </p>
          <p class="bold tipo-second-color">
            {{'SUPER-ADMIN.DATA-COMPANY-ADMIN.DATA-PRODUCT' | translate}}:
            <span class="regular" *ngIf="companyData?.hasVidChain">{{"SUPER-ADMIN.DATA-COMPANY-ADMIN.TITLE-DIGITAL-VALIDATED" | translate}}</span>
          </p>
          <p class="bold tipo-second-color">
            {{'SUPER-ADMIN.DATA-COMPANY-ADMIN.DATA-PAYMENT-FORM' | translate}}:
            <span class="regular">{{companyData?.companyAdditionalData?.paymentMethodType?.name}}</span>
          </p>
         
        </div>
        <div class="column">
          <p class="bold tipo-second-color">
            {{'SUPER-ADMIN.CLIENT.DATA-HIRING.LABEL-PROFESSIONALS' | translate}}:
            <span class="regular">{{companyData?.companyAdditionalData?.doctorsAmount?.name}}</span>
          </p>
          <p class="bold tipo-second-color">
            {{'SUPER-ADMIN.DATA-COMPANY-ADMIN.DATA-PAYMENT' | translate}}:
            <span class="regular">{{companyData?.companyAdditionalData?.billingType?.name}}</span>
          </p>
          <p class="bold tipo-second-color">
            {{'SUPER-ADMIN.DATA-COMPANY-ADMIN.DATA-TASK' | translate}}:
            <span class="regular">{{companyData?.companyAdditionalData?.price}}</span>
          </p>         
         
        </div>
     
        <div class="column">
          <p class="bold tipo-second-color">
            {{'SUPER-ADMIN.DATA-COMPANY-ADMIN.DATA-API-CLIENT' | translate}}:
            <span class="regular">{{companyData?.apiActive | yesNo}}</span>
          </p>
          <p class="bold tipo-second-color">                    
              {{'SUPER-ADMIN.DATA-COMPANY-ADMIN.DATA-ENROLLMENT-CODE' | translate}}:
             <span class="regular">{{companyData?.companyVidSignerCredential?.enrollmentCode}}</span>
          </p>
        </div>
      
        </div>        
        <div class="row" *ngIf="companyData?.companyAdditionalData?.his !== null">
          <div class="column">
              <p class="bold tipo-second-color">
                {{'SUPER-ADMIN.DATA-COMPANY-ADMIN.DATA-HIS-CLIENT' | translate}}                
              </p>
              <!-- TODO para cuando nos lo pida Javi -->
              <!-- <p class="bold tipo-second-color">
                {{'SUPER-ADMIN.DATA-COMPANY-ADMIN.DATA-CIS-USED' | translate}}:
                <span class="regular">100</span>
              </p> -->
              </div>
            <div class="column">
              <p class="bold tipo-second-color">
                {{'SUPER-ADMIN.DATA-COMPANY-ADMIN.DATA-HIS-NAME' | translate}}:
                <span class="regular" >{{companyData?.companyAdditionalData?.his}}</span>
              </p>
              <!-- TODO para cuando nos lo pida Javi -->
              <!-- <p class="bold tipo-second-color">
                {{'SUPER-ADMIN.DATA-COMPANY-ADMIN.DATA-RGPDS-USED' | translate}}:
                <span class="regular">120</span>
              </p> -->
              
            </div>
         
            <div class="column">
              <!-- TODO para cuando nos lo pida Javi -->
              <!-- <p class="bold tipo-second-color">                    
                  {{'SUPER-ADMIN.DATA-COMPANY-ADMIN.DATA-DDS-USED' | translate}}:
                 <span class="regular">5</span>
              </p> -->
        </div>
      </div>
    </div>
</div>    