import { Component, OnInit } from '@angular/core';
import { StatisticsService } from '@app/core/services/statistics/statistics.service';
import { Specialty } from '@app/core/models/input/specialty/specialty.model';

@Component({
  selector: 'app-list-specialties',
  templateUrl: './list-specialties.component.html',
  styleUrls: ['./list-specialties.component.scss']
})
export class ListSpecialtiesComponent implements OnInit{

  specialties?: Specialty[];

  constructor(private statisticsService: StatisticsService ) {}

  ngOnInit(): void {
    this.getSpecialties();  
  }

 getSpecialties(){
  this.statisticsService.getSpecialties().subscribe({
    next: respuesta => {
      this.specialties = respuesta;
    }
  })
 }
  
}
