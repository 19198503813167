import { IEnvironment } from './environment.interface';

export const environment: IEnvironment = {
  production: false,
  apiHost: 'https://azureapis-pre.elconsentimiento.es',
  apiUrlWs: 'https://pre-api.elconsentimiento.com/ws',
  apiUrl: 'https://pre-api.elconsentimiento.com',
  apiConsents: 'https://azureapis-pre.elconsentimiento.es/consents',
  enableDebugTools: false,
  logLevel: 'info',
  defaultLanguage: 'es-ES',
  localStorageSecretKey: '983TCnhV2bpzzOTl20OBYGYKNs72kqJ3',
  doNotEncryptValues: true,
  turnstileSiteKey: '0x4AAAAAAAcxsP520v6xb2YF',
  urlCredential: 'https://staging.werify.eu/#/werify_point_kiosk/flagstone+nugget+mushily+absinthe+stubbly'

};
