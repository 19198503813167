<div class="wrapper">
    <div class="maxwidth">
         <h4 class="title tipo-second-color bold">{{ "PRIVATE.DOCTOR.FILE-DOCTOR.TITLE" | translate }} </h4>
         <div class="header_doctor inlineflex back-white-color" >
          <fa-icon [icon]="faUserDoctor" class="icono tipo-second-color fa-xl"></fa-icon>
          <h4 class="title tipo-second-color">{{doctorname}} {{doctorfirstLastName}} {{doctorsecondLastName}}</h4>
        </div> 
         <div class="tabset">
            <!-- Tab 1 -->
            <input type="radio" name="tabset" id="tab1" aria-controls="file" [checked]="selectedTab === 'file'" (change)="changeTab('file')">
            <label for="tab1" class="bold tipo-main-color">{{ "PRIVATE.DOCTOR.FILE-DOCTOR.TITLE-TAB1" | translate }} </label>
            <!-- Tab 2 -->
            <input type="radio" name="tabset" id="tab2" aria-controls="consents" [checked]="selectedTab === 'consents'" (change)="changeTab('consents')">
            <label for="tab2" class="bold tipo-main-color">{{ "PRIVATE.DOCTOR.FILE-DOCTOR.TITLE-TAB2" | translate }} </label>


            <div class="tab-panels">
            <!-- datos profesional -->
              <section id="file" class="tab-panel">
                   <app-data-doctor [inEdit]="true" [isDisabled]="true" ></app-data-doctor>
              </section>
              <!-- consentimientos profesionales -->
              <section id="consents" class="tab-panel">
                   <app-documents-doctor></app-documents-doctor>
              </section>

            </div>
          </div>

    </div>
</div>

