import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Device, DocumentsDevice } from '@app/core/models/input/device.model';
import { AlertService } from '@app/core/services/alert/alert.service';
import { faBackward, faLaptopFile, faTrashCan } from '@fortawesome/free-solid-svg-icons';
import { DeviceService } from '../../../../core/services/device/device.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-device-documents',
  templateUrl: './device-documents.component.html',
  styleUrls: ['./device-documents.component.scss'],
})
export class DeviceDocumentsComponent implements OnInit {
  faTrashCan = faTrashCan;
  faBackward = faBackward;
  faLaptopFile = faLaptopFile;

  documentsDevice?: DocumentsDevice[];
  device?: Device[];
  deviceName?: string;
  documentToDelete?: string;

  constructor(
    private deviceService: DeviceService,
    private activatedRoute: ActivatedRoute,
    private alertService: AlertService,
    private translate: TranslateService
  ) {
    this.activatedRoute.params.subscribe(params => {
      this.deviceName = params['deviceName'];
    });
  }

  ngOnInit(): void {
    if (this.deviceName) {
      this.getDocumentsByDeviceName(this.deviceName);
    }
  }

  getDocumentsByDeviceName(deviceName: string) {
    this.deviceService.getDocumentsByDevice(deviceName).subscribe({
      next: documentsDevice => {
        this.documentsDevice = documentsDevice;
      },
    });
  }

  deleteDocumentInDevice(docGUI?: string) {
    this.documentToDelete = docGUI;
    this.deviceService.deleteDocumentInDevice(this.documentToDelete ?? '').subscribe({
      next: () => {
        this.alertService.success(
          this.translate.instant('PRIVATE.PERSONALIZATION.DEVICE-DOCUMENTS.MESSAGE-DELETE-DOCUMENT-OK')
        );
        window.location.reload();
      },
      error: () => {
        this.alertService.error(
          this.translate.instant('PRIVATE.PERSONALIZATION.DEVICE-DOCUMENTS.MESSAGE-DELETE-DOCUMENT-ERROR')
        );
      },
    });
  }
}
