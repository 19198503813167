import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import RoleEnum from '@app/core/enums/role.enum';
import SubscriptionTypeEnum from '@app/core/enums/subscription-type.enum';
import { CompanyDataAdmin } from '@app/core/models/input/company/company.model';
import { UserInput } from '@app/core/models/input/user-input.model';
import { CompanyService } from '@app/core/services/company/company.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-file-user',
  templateUrl: './file-user.component.html',
  styleUrls: ['./file-user.component.scss']
})
export class FileUserComponent implements OnInit {
  @Input() companyData?: CompanyDataAdmin;
   userData?: UserInput;
   roleEnum = RoleEnum;
   dataAdmin?: UserInput;

   @Input() roleUser?: string;

  companyUuid?:string;
  isDigital = false;


  constructor(private router: Router,
              private companyService:CompanyService,
              private activatedRoute: ActivatedRoute,
              private translate: TranslateService
        ) 
        {this.activatedRoute.params.subscribe(params => {      
          this.companyUuid = params['companyUuid'];      
        });
      }

      ngOnInit(): void {
        this.getCompanyDataAdmin();   
      }
  
  isProfessionalRoute(): boolean {
    // Comprueba si la ruta actual corresponde a "data-professional"
    return this.router.url.includes('file-doctor');    
  }

  getCompanyDataAdmin() {     
    if(this.companyUuid !== undefined){           
      this.companyService.getCompanyParameters(this.companyUuid).subscribe({
        next: data => {
          this.companyData = data;
          this.isDigital = this.companyData?.subscriptionTypes?.some(b => b.id === SubscriptionTypeEnum.BIOMETRIC) ?? false; 
        }
      })
    }  
 }

 receiveDoctorData(data: UserInput){
    this.userData = data;    
    this.userData?.roles?.forEach(role => {     
      switch (role.id){       
        case this.roleEnum.COMPANY_DOCTOR:
          this.roleUser = this.translate.instant ("SUPER-ADMIN.FILE-USER.ROLE-COMPANY-DOCTOR");          
          break;

        case this.roleEnum.FREEMIUM:
          this.roleUser = this.translate.instant ('SUPER-ADMIN.FILE-USER.ROLE-FREEMIUM');         
          break;

        case this.roleEnum.PARTICULAR:
          this.roleUser = this.translate.instant ("SUPER-ADMIN.FILE-USER.ROLE-PARTICULAR");          
          break;        
       }
    });
 }

 receiveAdminData(data: UserInput){
  this.dataAdmin = data
  this.dataAdmin?.roles?.forEach(role => {     
    switch (role.id){       
      case this.roleEnum.ADMINISTRATIVE:
        this.roleUser = this.translate.instant ("SUPER-ADMIN.FILE-USER.ROLE-ADMINISTRATIVE");       
        break;

      case this.roleEnum.ADMINISTRATIVE_VIEW_CIS:
        this.roleUser = this.translate.instant ("SUPER-ADMIN.FILE-USER.ROLE-ADMINISTRATIVE-VIEW-CIS");        
        break;

      case this.roleEnum.ADMINISTRATIVE_SUPERVISOR:
        this.roleUser = this.translate.instant ("SUPER-ADMIN.FILE-USER.ROLE-ADMINISTRATIVE-SUPERVISOR");        
        break; 
             }
  });
 }

}
