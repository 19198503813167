import { Component, Input } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import StatusEnum from '@app/core/enums/status.enum';
import { Consent } from '@app/core/models/input/consent/consent.model';
import { UpdateConsentFile } from '@app/core/models/output/consent/update-consent-file.model';
import { FileOutputDTO } from '@app/core/models/output/file-output.model';
import { AlertService } from '@app/core/services/alert/alert.service';
import { ConsentService } from '@app/core/services/consent/consent.service';
import { faFileExport } from '@fortawesome/free-solid-svg-icons';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-printed-consent',
  templateUrl: './printed-consent.component.html',
  styleUrls: ['./printed-consent.component.scss'],
})
export class PrintedConsentComponent {
  faFileExport = faFileExport;

  @Input() consent?: Consent;

  Status = StatusEnum;
  consentUuid?: string;
  statusSelected = 0;
  fileName = '';
  reader = new FileReader();
  isValidFileSize = false;

  constructor(
    private consentService: ConsentService,
    private activatedRoute: ActivatedRoute,
    private alertService: AlertService,
    private translate: TranslateService,
    private router: Router,
    private route: ActivatedRoute,
  ) {
    this.activatedRoute.params.subscribe(param => {
      this.consentUuid = param['consentUuid'];
    });
  }

  uploadConsentFile(event: any) {
    const file = event.target.files[0];
    this.fileName = file.name;
    this.reader.readAsDataURL(file);
    this.reader.onload = () => {
      this.isValidFileSize = this.validateFile(file);
    };
  }

  private validateFile(file: File) {
    if (file.type === 'application/pdf') {
      if (file.size < 1000000) {
        return true;
      } else {
        this.alertService.warn(
          this.translate.instant(
            'PRIVATE.CONSENTS.PRINTED-CONSENT.MESSAGE-MAXIMUM-SIZE'
          )
        );
        return false;
      }
    } else {
      this.alertService.warn(
        this.translate.instant(
          'PRIVATE.CONSENTS.PRINTED-CONSENT.MESSAGE-TYPE-FILE'
        )
      );
      return false;
    }
  }

  updateConsentFile() {
    if (this.isValidFileSize) {
      let fileObject: FileOutputDTO | null = {
        name: this.fileName,
        content: this.reader.result?.toString().replace(/^data:(.*,)?/, ''),
      };
     
      if (this.fileName === '') {
        fileObject = null;        
      }
      
      const updateFileObject: UpdateConsentFile = {
        statusId: this.statusSelected,
        file: fileObject,
      };
      
      this.consentService
        .updateConsentFile(this.consentUuid ?? '', updateFileObject)
        .subscribe({
          next: data => {           
            this.alertService.success(
              this.translate.instant(
                'PRIVATE.CONSENTS.PRINTED-CONSENT.MESSAGE-UPLOAD-FILE-OK'
              )
            );
          },
        });
    }else {
      this.alertService.error(
        this.translate.instant(
          'PRIVATE.CONSENTS.PRINTED-CONSENT.MESSAGE-FILE-NOT-VALID'
        )
      );
    }
  }

  // botón volver a la página anterior
  refresh() {
    this.router.navigate(['../'], { relativeTo: this.route });
  }
}
