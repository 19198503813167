<div class="wrapper">
    <div class="maxwidth">  
      <h4 class="title tipo-second-color bold">{{ 'PRIVATE.PATIENT.FILE-PATIENT.TITLE' | translate }}</h4>

      <div class="header_patient inlineflex back-white-color" >
        <fa-icon [icon]="faHospitalUser" class="icono tipo-second-color fa-xl"></fa-icon>
        <h4 class="title tipo-second-color">{{patientname}} {{patientfirstLastName}} {{patientsecondLastName}}</h4>
      </div>

      <app-digital-documents></app-digital-documents>

     </div>
</div>        