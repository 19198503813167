import { Component, AfterViewInit } from '@angular/core';

@Component({
  selector: 'app-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss']
})
export class LoaderComponent implements AfterViewInit {

  ngAfterViewInit() {
    const loader = document.querySelector ('.loader');
    loader?.classList.add ('nodisplay');

  }



}
