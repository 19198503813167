<h5 class="title tipo-second-color bold">{{'PRIVATE.DASHBOARD.CI-SIX-MONTHS.TITLE' | translate}}</h5>
<div class="graph_container">

    <ngx-charts-bar-vertical

        [results]="consentsLastSixMonths"
        [gradient]="gradient"
        [xAxis]="showXAxis"
        [yAxis]="showYAxis"
        >
</ngx-charts-bar-vertical>

</div>