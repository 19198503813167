import { Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import { faBackwardStep, faCaretLeft, faCaretRight, faForwardStep } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-paginator',
  templateUrl: './paginator.component.html',
  styleUrls: ['./paginator.component.scss']
})
export class PaginatorComponent implements OnChanges {

  faCaretLeft = faCaretLeft;
  faCaretRight = faCaretRight;
  faForwardStep = faForwardStep;
  faBackwardStep = faBackwardStep

  @Input() totalPages = 0;
  @Input() pageNumber = 0;
  @Input() totalElements = 0;  
  @Input() lastPage = false;
  @Input() numbersOfElements = 0;

  @Output() paginate = new EventEmitter<number>();

  pages: number[] = [];

  ngOnChanges() {
    this.updatePages();
  }

  updatePages() {
    this.pages = [];

    const maxVisiblePages = 5;
    const middlePageOffset = Math.floor(maxVisiblePages / 2);

    let startPage = Math.max(0, this.pageNumber - middlePageOffset);
    let endPage = Math.min(this.totalPages - 1, this.pageNumber + middlePageOffset);

    if (this.pageNumber <= middlePageOffset) {
      endPage = Math.min(this.totalPages - 1, maxVisiblePages - 1);
    } else if (this.pageNumber + middlePageOffset >= this.totalPages) {
      startPage = Math.max(0, this.totalPages - maxVisiblePages);
    }

    for (let i = startPage; i <= endPage; i++) {
      this.pages.push(i);
    }
  }

  paginar(amount: number) {
    this.paginate.emit(amount);   
  }

  goToPage(page: number) {
    this.paginate.emit(page - this.pageNumber);
  }

  goToFirstPage() {
    this.paginate.emit(-this.pageNumber);
  }

  goToLastPage() {
    this.paginate.emit(this.totalPages - this.pageNumber - 1);
  }
}
