import { Injectable } from '@angular/core';
import {
  VariableContainerTypeEnum,
  VariableTypeEnum,
} from '@app/core/enums/superdocs.enum';
import { SelectOption } from '@app/core/models/front/select-option.model';
import { Variable } from '@app/core/models/input/consent/variable.model';
import { TemplateInputDTO } from '@app/core/models/input/template/template.model';
import { BehaviorSubject, Observable } from 'rxjs';
import { TemplateService } from '../../template/template.service';

@Injectable({
  providedIn: 'root',
})
export class VariableService {
  constructor(private templateService: TemplateService) {}

  processedHtmlString = '';

  private varsSubject: BehaviorSubject<Variable[] | null> = new BehaviorSubject<
    Variable[] | null
  >(null);
  vars$: Observable<Variable[] | null> = this.varsSubject.asObservable();

  setVars(vars: Variable[]) {
    this.varsSubject.next(vars);
  }

  replaceVariables(
    htmlString: string,
    variables: { [key: string]: string | undefined } | undefined
  ): string {
    if (variables != undefined) {
      let processedValue = htmlString;

      for (const variable in variables) {
        if (Object.hasOwn(variables, variable)) {
          const replacementValue = variables[variable];
          const variableRegex = new RegExp(`%${variable}%`, 'g');
          processedValue = processedValue.replace(
            variableRegex,
            replacementValue ?? ''
          );
        }
      }
      return processedValue;
    }

    return '';
  }

  /**
   * Replaces placeholders in an HTML string with corresponding variable values.
   *
   * This method scans an HTML string for placeholders and replaces each one with the value
   * from a matching variable. Placeholders are identified by the 'anchor' property of each variable.
   * If a variable's value is defined, the method uses a regular expression to find and replace
   * all instances of the placeholder in the HTML string with the variable's value.
   *
   * @param {string} htmlString - The HTML string containing placeholders to be replaced.
   * @param {Variable[]} variables - An array of Variable objects used for replacement.
   * @returns {string} The processed HTML string with all placeholders replaced by their respective values.
   */
  replaceStringVariables(htmlString: string, variables: Variable[]): string {
      let processedValue = htmlString;

      variables.forEach(variable => {
        const variableRegex = new RegExp(`${variable.anchor}`, 'g');
        let htmlValue = null;

        if (variable.value && VariableTypeEnum.SRC === variable.type) {
          htmlValue = `src='${variable.value}'`;
        } else if (VariableTypeEnum.SRC !== variable.type) {
          htmlValue = variable.value ?? '';
        }

        if (htmlValue != null) {
          processedValue = processedValue.replace(
            variableRegex,
            htmlValue
          );
        }
      });
      return processedValue;
  }

  /**
   * Replaces a specific placeholder in an HTML string with an input element.
   *
   * This method targets a placeholder in the HTML string based on the 'anchor' property of the provided variable.
   * It replaces this placeholder with an HTML input element. The specifics of the input element depend on the
   * presence of a value in the variable: if the variable's value is undefined, null, or empty, the input element
   * will only contain a placeholder. If the variable has a value, the input element is populated with this value.
   *
   * @param {string} htmlString - The HTML string containing the placeholder to be replaced.
   * @param {Variable} variable - The Variable object used for replacement.
   * @returns {string} The processed HTML string with the placeholder replaced by the input element.
   */
  replaceInputVariable(htmlString: string, variable: Variable): string {
    if (variable) {
      let processedValue = htmlString;

      let replacementValue;

      const required = variable.requiered === true ? 'required' : '';
      if (
        variable.value === undefined ||
        variable.value === '' ||
        variable.value === null
      ) {
        replacementValue = `
      <input type='text' id="${variable.uuid}" placeholder="${variable.name}" ${required} style='width: 300px;background-color: #C8D6F3'>
      `;
      } else {
        replacementValue = `
      <input type='text' id="${variable.uuid}" placeholder="${variable.name}" value="${variable.value}" ${required} style='width: 300px;background-color: #C8D6F3'>
      `;
      }

      const variableRegex = new RegExp(`${variable.anchor}`, 'g');
      processedValue = processedValue.replace(variableRegex, replacementValue);

      return processedValue;
    }

    return '';
  }

  replaceSelectVariable(htmlString: string, variable: Variable): string {
    if (variable) {
      let processedValue = htmlString; // Initialize processedValue with the original HTML string

      const variableRegex = new RegExp(`${variable.anchor}`, 'g');

      const optionsSelect: SelectOption[] = JSON.parse(variable.config ?? '[]');
      let htmlOptions =
        '<option disabled selected>Selecciona una opción</option>';
      optionsSelect.forEach(optionInSelect => {
        htmlOptions =
          htmlOptions +
          "<option value='" +
          optionInSelect.value +
          "'>" +
          optionInSelect.name +
          '</option>';
      });

      const replacementValue = `
          <select id="${variable.uuid}">
           ${htmlOptions}"
           </select>
          `;

      // Replace the anchor in the HTML string with the replacement HTML
      processedValue = processedValue.replace(variableRegex, replacementValue);

      // Return the HTML string with the replacement made
      return processedValue;
    }

    // Return an empty string if no variable is provided
    return '';
  }

  /**
   * Maps variables to their corresponding values and updates a template with these variables.
   *
   * This method processes the variables in a template, applying different mapping strategies
   * based on the container type of each variable. For DOCUMENT type variables, it uses
   * mapDocumentVariables method, and for other types, it uses mapLiteralVariables method.
   * After mapping, all variables are concatenated into a single array, and the template's
   * content and variables are updated accordingly.
   *
   * @param {TemplateInputDTO} template - The template object containing content and variables.
   * @param {Record<string, string | undefined>} variablesMaps - A mapping of variable names to their values.
   * @returns {TemplateInputDTO | undefined} The updated template object with variables mapped to their values.
   */
  mapVariables(
    template: TemplateInputDTO,
    variablesMaps: Record<string, string | undefined>
  ): TemplateInputDTO | undefined {
    // Check if the template has variables
    if (template.variables) {
      // Initialize the processed HTML string with the template's content
      this.processedHtmlString = template.content ?? '';

      // Sort the template's variables by container type
      const containerMap = this.sortContainerTypes(template.variables);

      // Apply appropriate mapping function for each container type
      containerMap.forEach((variablesArray, containerType) => {
        let updatedVariables;

        if (VariableContainerTypeEnum.DOCUMENT === containerType) {
          // Use mapDocumentVariables for DOCUMENT type variables
          updatedVariables = this.mapDocumentVariables(
            variablesArray,
            variablesMaps
          );
        } else {
          // Use mapLiteralVariables for other types of variables
          updatedVariables = this.mapLiteralVariables(
            variablesArray,
            variablesMaps
          );
        }

        // Update the map with the processed variables
        containerMap.set(containerType, updatedVariables);
      });

      // Concatenate variables from all containers into a single array
      let allVariables: Variable[] = [];
      containerMap.forEach(variablesArray => {
        allVariables = allVariables.concat(variablesArray);
      });

      // Update the class property and the template with the concatenated variables
      this.setVars(allVariables);
      template.variables = allVariables;

      // Update the template content
      template.content = this.processedHtmlString;
    }

    // Return the updated template
    this.templateService.setTemplate(template);
    return template;
  }

  /**
   * Maps and updates literal variables with their corresponding values.
   *
   * Iterates over an array of variables, replacing each variable's value with a corresponding value
   * from a provided mapping, if available. It also updates an HTML string by replacing placeholders
   * (identified by each variable's 'anchor') with the variable's value.
   *
   * @param {Variable[] | undefined} variables - An array of Variable objects to be updated.
   * @param {Record<string, string | undefined>} variablesMaps - A mapping of variable anchors to their values.
   * @returns {Variable[]} The array of updated Variable objects.
   */
  private mapLiteralVariables(
    variables: Variable[],
    variablesMaps: Record<string, string | undefined>
  ): Variable[] {
    // Ensure that variables is an array, defaulting to an array with a new Variable if undefined
    const updatedVariables = variables;

    // Iterate over each variable in the array
    for (const bodyVariable of updatedVariables) {
      // Remove '%' from the anchor and use it as the matchKey
      const matchKey = bodyVariable.anchor?.replace(/%/g, '') ?? '';

      // Update the variable's value if a match is found in the mapping
      if (matchKey in variablesMaps) {
        bodyVariable.value = variablesMaps[matchKey];
      }
    }

    // Replace placeholders in the HTML string with the updated variable values
    this.processedHtmlString = this.replaceStringVariables(
      this.processedHtmlString,
      variables ?? [new Variable()]
    );

    // Return the array of updated variables
    return updatedVariables;
  }

  /**
   * Maps and updates document variables with their corresponding values and types.
   *
   * Iterates over an array of document variables, replacing each variable's value with a corresponding value
   * from a provided mapping, if available. Based on the type of the variable (e.g., STRING, DATE_INPUT),
   * different processing is applied to update the HTML string.
   *
   * @param {Variable[] | undefined} variables - An array of Variable objects to be updated.
   * @param {Record<string, string | undefined>} variablesMaps - A mapping of variable anchors to their values.
   * @returns {Variable[]} The array of updated Variable objects.
   */
  private mapDocumentVariables(
    variables: Variable[] | undefined,
    variablesMaps: Record<string, string | undefined>
  ): Variable[] {
    // Ensure that variables is an array, defaulting to an array with a new Variable if undefined
    const updatedVariables = variables ?? [new Variable()];

    // Iterate over each variable in the array
    for (const variable of updatedVariables) {
      // Remove '%' from the anchor and use it as the matchKey
      const matchKey = variable.anchor?.replace(/%/g, '') ?? '';

      // Process the variable based on its type and update the HTML string
      switch (variable.type) {
        case VariableTypeEnum.STRING:
          // Update the variable's value if a match is found in the mapping
          if (matchKey in variablesMaps) {
            variable.value = variablesMaps[matchKey];
          }
          this.processedHtmlString = this.replaceInputVariable(
            this.processedHtmlString,
            variable ?? new Variable()
          );
          break;
        case VariableTypeEnum.DATE_INPUT:
          // TODO: Implement processing for DATE_INPUT type
          break;
        case VariableTypeEnum.SELECT:
          // Update the variable's config if a match is found in the mapping
          if (matchKey in variablesMaps) {
            variable.config = variablesMaps[matchKey];
            this.processedHtmlString = this.replaceSelectVariable(
              this.processedHtmlString,
              variable ?? new Variable()
            );
          }
          break;
        default:
          break;
      }
    }

    // Return the array of updated variables
    return updatedVariables;
  }

  /**
   * Sorts an array of variables into different containers based on their type.
   *
   * This method organizes a collection of variables into specific categories as defined
   * by the VariableContainerTypeEnum. Each category corresponds to a different container type,
   * such as ADDRESSEE, SIGNER, DOCUMENT, etc. This categorization is useful for further processing
   * of variables based on their designated container.
   *
   * @param {Variable[]} variables - An array of Variable objects to be sorted.
   * @returns {Map<string, Variable[]>} A map where each key corresponds to a container type,
   *                                    and the value is an array of Variables belonging to that type.
   */
  public sortContainerTypes(variables: Variable[]): Map<string, Variable[]> {
    // Initialize a map with keys as container types and values as empty arrays
    const containerMap: Map<string, Variable[]> = new Map([
      [VariableContainerTypeEnum.ADDRESSEE, []],
      [VariableContainerTypeEnum.SIGNER, []],
      [VariableContainerTypeEnum.DOCUMENT, []],
      [VariableContainerTypeEnum.FORM, []],
      [VariableContainerTypeEnum.USER, []],
      [VariableContainerTypeEnum.SIGNER_DEFAULT, []],
      [VariableContainerTypeEnum.BLOCK, []],
      [VariableContainerTypeEnum.DATA, []],
    ]);

    // Iterate over each variable in the input array
    variables.forEach(variable => {
      // Retrieve the array corresponding to the variable's container type
      if (variable.container) {
        const container = containerMap.get(variable.container);

        // Add the variable to the appropriate container array, if the container type is recognized
        if (container) {
          container.push(variable);
        } else {
          // Log an error if the container type is not recognized (optional)
          console.error(`Unknown container type: ${variable.container}`);
        }
      }
    });

    // Return the populated map of variables sorted by container type
    return containerMap;
  }

  /**
   * Merges an external array of variables with the one held in varsSubject by UUID.
   *
   * @param {Variable[]} externalArray - The external array of Variable objects to merge.
   */
  public mergeWithSubjectVariables(externalArray: Variable[]): void {
    const subjectArray = this.varsSubject.getValue();
    this.mergeVariablesByUUID(subjectArray, externalArray);
  }

  /**
   * Merges two arrays of Variable objects, updating the value of variables from the first array
   * if a matching uuid is found in the second array.
   *
   * For each variable in the first array, if its uuid matches a variable in the second array,
   * the value of the variable from the first array is updated with the value from the second array.
   * Variables in the second array with uuids not found in the first array are added to the merged result.
   *
   * @param {Variable[]} actualArray - The first array of Variable objects.
   * @param {Variable[]} newDataArray - The second array of Variable objects.
   */
  private mergeVariablesByUUID(
    actualArray: Variable[] | null,
    newDataArray: Variable[]
  ): void {
    if (actualArray) {
      // Map to keep track of variables by uuid for quick access
      const variableMap = new Map(
        actualArray.map(variable => [variable.uuid, variable])
      );

      // Iterate over the second array to update or add variables
      newDataArray.forEach(variable => {
        if (variableMap.has(variable.uuid)) {
          // If variable exists in the map, update its value
          const existingVariable = variableMap.get(variable.uuid);
          if (existingVariable) {
            existingVariable.value = variable.value;
          }
        } else {
          // If variable does not exist in the map, add it
          variableMap.set(variable.uuid, variable);
        }
      });

      // return mergedArray;
      this.setVars(Array.from(variableMap.values()));
    }
  }
}
