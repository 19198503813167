export enum VariableTypeEnum {
  STRING = 'string',
  FORM = 'form',
  BLOCK = 'block',
  DATE = 'date',
  CHECK = 'check',
  SIGNER = 'signer',
  SELECT = 'select',
  DATE_INPUT = 'dateInput',
  SRC= 'src'
}

export enum VariableContainerTypeEnum {
  ADDRESSEE = 'addressee',
  SIGNER = 'signer',
  DOCUMENT = 'document',
  FORM = 'form',
  USER = 'user',
  TEMPLATE = 'template',
  SIGNER_DEFAULT = 'signerDefault',
  BLOCK = 'block',
  DATA = 'data'
}
