import { Component, OnInit } from '@angular/core';
import { User, UserSignature } from '@app/core/models/input/user.model';
import { SignatureOutput } from '@app/core/models/output/signature-output.model';
import { AlertService } from '@app/core/services/alert/alert.service';
import { LoginService } from '@app/core/services/login/login.service';
import { UserService } from '@app/core/services/user/user.service';
import { faTrashCan } from '@fortawesome/free-solid-svg-icons';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-edit-sign',
  templateUrl: './edit-sign.component.html',
  styleUrls: ['./edit-sign.component.scss'],
})
export class EditSignComponent implements OnInit {
  faTrashCan = faTrashCan;

  user?: User;
  uuid?: string;
  userSignature?: UserSignature;
  fileName = '';
  reader = new FileReader();
  isValidFileSize = false;

  constructor(
    private loginService: LoginService,
    private userService: UserService,
    private alertService: AlertService,
    private translate: TranslateService
  ) {}

  ngOnInit(): void {
    this.user = this.loginService.userValue ?? new User();
    this.uuid = this.user.uuid;

    this.userService.getUserSignatureApi(this.uuid ?? '').subscribe({
      next: (data) => {
        this.userService.setUserSignature(data);
        this.userSignature = data;
      },
    });
  }

  uploadSignature(event: any) {
    const file = event.target.files[0];
    this.fileName = file.name;
    this.reader.readAsDataURL(file);

    this.reader.onload = () => {
      this.isValidFileSize = this.validateFile(file);
      if (this.isValidFileSize) {
        const fileObject: SignatureOutput = {
          originalFileName: file.name,
          content: this.reader.result?.toString().replace(/^data:(.*,)?/, ''),
        };
        this.userService
          .editUserSignature(this.uuid ?? '', fileObject)
          .subscribe(() => {
            this.userService.deleteUserSignatureStorage();
            this.userService.getUserSignatureApi(this.uuid ?? '').subscribe({
              next: (data: UserSignature) => {
                this.userService.setUserSignature(data);
                this.userSignature = data;
              },
            });
          });
          this.alertService.success(
            this.translate.instant(
              'PRIVATE.PERSONALIZATION.EDIT-SIGN.MESSAGE-SIGN-OK'
            )
          );
      }else{
        this.alertService.success(
          this.translate.instant(
            'PRIVATE.PERSONALIZATION.EDIT-SIGN.MESSAGE-SIGN-ERROR'
          )
        );
      }
    };
  }

  private validateFile(file: File) {
    if (file.type === 'image/jpeg' || file.type === 'image/png' || file.type === 'image/gif') {
      if (file.size < 250000) {
        return true;
      } else {
        this.alertService.warn(
          this.translate.instant(
            'PRIVATE.PERSONALIZATION.EDIT-LOGO.MESSAGE-FILE-SIZE'
          )
        );
        return false;
      }
    } else {
      this.alertService.warn( this.translate.instant(
        'PRIVATE.PERSONALIZATION.EDIT-LOGO.MESSAGE-FILE-TYPE'
      ));
      return false;
    }
  }

  deleteSignature() {
    try {
      this.userService.deleteUserSignature(this.uuid ?? '').subscribe();
      this.userSignature = undefined;
      this.fileName = '';
    } catch (e) {
      //ignore
    }
  }
}
