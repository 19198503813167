import { Component, OnInit } from '@angular/core';
import { Filter } from '@app/core/models/front/filters';
import { Specialty } from '@app/core/models/input/specialty.model';
import { ConsentsMostUsed } from '@app/core/models/input/statistics/top-consents.model';
import { SpecialtiesService } from '@app/core/services/api/consents/specialties/specialties.service';
import { StatisticsService } from '@app/core/services/statistics/statistics.service';
import { FilterUtils } from '@app/core/utils/filter.utils';
import { faCaretDown, faCaretUp } from '@fortawesome/free-solid-svg-icons';


@Component({
  selector: 'app-consents-most-used',
  templateUrl: './consents-most-used.component.html',
  styleUrls: ['./consents-most-used.component.scss']
})
export class ConsentsMostUsedComponent implements OnInit{
  faCaretDown = faCaretDown;
  faCaretUp = faCaretUp;

  filters = new Array<Filter>();
  consents?: ConsentsMostUsed[];
  specialties?: Specialty[];
  selectedSpecialty = 0;

  // Utils
  filterUtils: FilterUtils;

  constructor(private statisticsService: StatisticsService,
              private specialtiesService: SpecialtiesService
   ) {
    this.filterUtils = new FilterUtils(this.filters);
  }
  ngOnInit(): void {   
    this.getSpecialties();
    this.getConsentsMostUsed();  
  }

  addFilter(name: string, filter: string) {  
    if (name === 'creationDate-start' || name === 'creationDate-end') {
      filter = this.convertDateFormat(filter);
    } 
    this.filterUtils.addFilter(name, filter);
    this.getConsentsMostUsed();
  }

  removeFilter(name: string) {     
    this.filterUtils.removeFilter(name);
    this.getConsentsMostUsed();
  }

  addSpecialtyFilter(specialtyId: string) {  
       // 0 means all specialtyes
    if (specialtyId != '0') {
      this.addFilter('specialtyId', specialtyId);
    } else {
      this.removeFilter('specialtyId');
    }
  }

  getConsentsMostUsed(){
    this.statisticsService.getConsentsMostUsed(this.filters).subscribe({
      next: respuesta => {
        this.consents = respuesta;
      }
    })    
  }
  getSpecialties() {
    this.specialtiesService.getSpecialties().subscribe({
      next: specialties => {
        this.specialties = specialties;
      }
    })
  }
// Convierte el formato de entrada del usuario dd/MM/YYYY a YYYY-MM-dd que es el formato del back
  convertDateFormat(dateString: string): string {
    const [day, month, year] = dateString.split('/');
    return `${year}-${month}-${day}`;
  }
 
}
