enum RoleEnum {
    PARTICULAR = 1,
    COMPANY_DOCTOR = 2,
    COMPANY = 3,
    ADMINISTRATIVE = 4,
    ADMINISTRATIVE_VIEW_CIS = 5,
    ADMINISTRATIVE_SUPERVISOR = 6,
    FREEMIUM = 7,
    LITE = 996,
    DEMO_COMPANY = 998,
    PREMIUM_DOCTOR = 999,
    ADMIN = 100,
    ADMIN_OWN_COUNTRY = 101,
    MANAGER = 110,
    MANAGER_OWN_COUNTRY = 111
  }

  export default RoleEnum;