<div class="wrapper">
  <div class="maxwidth">
    <div class="buttons">
      <button
        class="button tipo-white-color back-blue-color"
        [routerLink]="['new']">
        <fa-icon [icon]="faFileCirclePlus" class="icono"></fa-icon>
        {{ 'PRIVATE.DOCTOR.FILE-DOCTOR.BUTTON-NEW-DOCTOR' | translate }}
      </button>
    </div>
    <div class="list">
      <h4 class="title tipo-second-color bold" id="title_table">
        {{ 'PRIVATE.DOCTOR.LIST-DOCTORS.TITLE' | translate }}
      </h4>
      <table class="table" aria-describedby="title_table">
        <thead class="tipo-second-color bold">
          <th scope="col">
            <div class="column">
              {{ 'PRIVATE.DOCTOR.LIST-DOCTORS.NAME' | translate }}
              <input
                type="text"
                name="filterName"
                (debounceInput)="addFilter('fullName', filterName.value)"
                [appDebounceInput]="300"
                #filterName
                id="filterName"
                placeholder="{{
                  'PRIVATE.PATIENT.LIST-PATIENTS.FILTER' | translate
                }}" />
            </div>
          </th>
          <th scope="col" class="numcollegiate">
            <div class="column">
              {{ 'PRIVATE.DOCTOR.LIST-DOCTORS.NUMBER-COL' | translate }}
              <input
                type="text"
                name="filterUsername"
                (debounceInput)="addFilter('username', filterUsername.value)"
                [appDebounceInput]="300"
                #filterUsername
                id="filterUsername"
                placeholder="{{
                  'PRIVATE.PATIENT.LIST-PATIENTS.FILTER' | translate
                }}" />
            </div>
          </th>

          <th scope="col" class="specialty">
            {{ 'PRIVATE.DOCTOR.LIST-DOCTORS.SPECIALITY' | translate }}
          </th>
          <th scope="col" class="state">
            {{ 'PRIVATE.DOCTOR.LIST-DOCTORS.STATE' | translate }}
          </th>
        </thead>
        <tbody>
          <tr
            [routerLink]="['./' + user.uuid + '/file']"
            *ngFor="let user of users; let i = index">
            <td class="name">{{ user?.name }} {{ user?.lastname }}</td>
            <td class="numcollegiate">{{ user?.userName }}</td>

            <td class="specialty">
              <span
                class="specialty_list"
                *ngFor="let specialty of user.specialty; let last = last">
                {{ specialty.description }}{{ !last ? ', ' : '' }}
              </span>
            </td>
            <td class="state">
              <span
                class="green bold"
                *ngIf="user?.userStatus?.id === userStatusEnum.ACTIVO">
                {{ user?.userStatus?.name }}
              </span>
              <span
                class="red bold"
                *ngIf="user?.userStatus?.id === userStatusEnum.NO_ACTIVO">
                {{ user?.userStatus?.name }}
              </span>
              <span
                class="orange bold"
                *ngIf="
                  user?.userStatus?.id !== userStatusEnum.ACTIVO &&
                  user?.userStatus?.id !== userStatusEnum.NO_ACTIVO
                ">
                {{ user?.userStatus?.name }}
              </span>
            </td>
          </tr>
        </tbody>
      </table>
      <!-- Pagination -->
      <app-paginator
        [totalElements]="page?.totalElements ?? 0"  
        [numbersOfElements]="page?.numberOfElements ?? 0"
        [totalPages]="page?.totalPages ?? 0"
        [pageNumber]="pagination.page"
        [lastPage]="page?.last ?? false"
        (paginate)="paginar($event)"></app-paginator>
      <!-- End of Pagination -->
    </div>
  </div>
</div>
