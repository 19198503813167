import { Component, Input } from '@angular/core';
import { Consents } from '@app/core/models/input/statistics/consents.model';
import { StatisticConsentsByDoctor } from '@app/core/models/input/statistics/statistic-consents-by-doctor.model';

@Component({
  selector: 'app-consents-doctor',
  templateUrl: './consents-doctor.component.html',
  styleUrls: ['./consents-doctor.component.scss']
})
export class ConsentsDoctorComponent {
  @Input()  statisticConsentsByDoctor?: StatisticConsentsByDoctor[];
  @Input()  consentsByDoctor?: Consents[];
}
