import { Component, Input, OnInit } from '@angular/core';

import { ActivatedRoute, Router } from '@angular/router';
import StatusEnum from '@app/core/enums/status.enum';
import { Consent } from '@app/core/models/input/consent/consent.model';
import { SendMailToPatient } from '@app/core/models/input/consent/send-email-to-patient.model';
import { Device } from '@app/core/models/input/device.model';
import { User } from '@app/core/models/input/user.model';
import { RevokeConsentDTO } from '@app/core/models/output/consent/revoke-consent.model';
import { AlertService } from '@app/core/services/alert/alert.service';
import { ConsentService } from '@app/core/services/consent/consent.service';
import { DeviceService } from '@app/core/services/device/device.service';
import { LoginService } from '@app/core/services/login/login.service';
import { faCircleXmark, faPaperPlane, faSpinner } from '@fortawesome/free-solid-svg-icons';
import { TranslateService } from '@ngx-translate/core';
@Component({
  selector: 'app-signed-consent',
  templateUrl: './signed-consent.component.html',
  styleUrls: ['./signed-consent.component.scss'],
})
export class SignedConsentComponent implements OnInit {
  @Input() consent?: Consent;

  faPaperPlane = faPaperPlane;
  faCircleXmark = faCircleXmark;
  faSpinner = faSpinner;
  StatusEnum = StatusEnum;

  consentUuid?: string;
  
  devices?: Device[];
  selectedDevice?: string = '0';
  emailPatient?: string;
  isButtonChecked = false;
  isSendChecked = false;
  reSendingFile = false;
  revokingFile = false;
  

  constructor(
    private router: Router,
    private loginService: LoginService,
    private consentService: ConsentService,
    private activatedRoute: ActivatedRoute,
    private deviceService: DeviceService,
    private alertService: AlertService,
    private translate: TranslateService
  ) {
    this.activatedRoute.params.subscribe(param => {
      this.consentUuid = param['consentUuid'];
    });
  }
  ngOnInit(): void {
    const user = this.loginService.userValue ?? new User();

    this.deviceService.getUserDevices().subscribe({
      next: devices => {
        this.deviceService.setDevices(devices);
        this.devices = devices;
        this.selectedDevice = user.userDevice?.description;
      },
    });
    this.emailPatient = this.consent?.patient?.email;    
  }

  revokeConsent(consentUuid: string) {
    const dataResend: RevokeConsentDTO = {
      deviceName: this.selectedDevice,
      digitalCiSignatureSignTypeId: this.consent?.digitalCiSignatureSignTypeId,
    };
    dataResend.deviceName = this.selectedDevice;
    this.revokingFile = true;
    if (this.devices !== undefined) {
      this.consentService.revokeConsent(consentUuid, dataResend).subscribe({
        next: () => {
          this.alertService.success(this.translate.instant('PRIVATE.CONSENTS.SIGNED-CONSENT.MESSAGE-CONSENT-REVOKED'));
          this.revokingFile = false;
          this.router.navigate(['../'], { relativeTo: this.activatedRoute });
        },
        error: () => (this.revokingFile = false),
      });
    }
  }

  sendMail(consentUuid: string) {
    const sendMail = new SendMailToPatient();
    sendMail.email = this.emailPatient;

    this.reSendingFile = true;
    if (sendMail.email != '' && sendMail.email != null) {
      this.consentService.sendMailToPatient(consentUuid ?? '', sendMail).subscribe({
        next: () => {
          this.alertService.success(this.translate.instant('PRIVATE.CONSENTS.SIGNED-CONSENT.MESSAGE-EMAIL-SEND'));
          this.reSendingFile = false;
        },
        error: () => (this.reSendingFile = false),
      });
    } else {
      this.alertService.error(this.translate.instant('PRIVATE.CONSENTS.SIGNED-CONSENT.MESSAGE-EMAIL-SEND-NO-OK'));
      this.reSendingFile = false;
    }
  }
}
