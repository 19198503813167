<div class="box_container">
    <div class="box box2 box_data">
        <div class="buttons button_edit" >
            <button class="button tipo-second-color back-white-color" (click)="changeEdit()">
                <fa-icon [icon]="faPenToSquare" class="icono fa-xl"></fa-icon>
            </button>
        </div>
        <form [formGroup]="dataCompany" class="form_datacompany" >

            <div class="input">
                <label for="businessName" class="businessName">{{ "PRIVATE.COMPANY.DATA-COMPANY.NAME" | translate }}</label>
                <input type="text" formControlName="businessName" id="businessName" [class.back_blue]="this.inEdition" [ngClass]="{'wrong': shouldShowError('businessName')}">
            </div>  
            <p class="message_error wrongtext" *ngIf="this.dataCompany.get('businessName')?.errors?.['minlength'] && this.isSubmit">{{"PRIVATE.PATIENT.DATA-PATIENT.MESSAGE-FIELD-NAME" | translate }}</p>

            <div class="input">
                <label for="cif" class="cif">{{ "PRIVATE.COMPANY.DATA-COMPANY.CIF" | translate }}</label>
                <input type="text" formControlName="cif" id="cif" [class.back_blue]="this.inEdition" [ngClass]="{'wrong': shouldShowError('cif')}" >
            </div>
            <p class="message_error wrongtext" *ngIf="this.dataCompany.get('cif')?.errors?.['pattern'] && this.isSubmit">{{"PRIVATE.PATIENT.DATA-PATIENT.MESSAGE-FIELD-DOCUMENT" | translate }}</p>

            <div class="input">
                <label for="address" class="address">{{ "PRIVATE.COMPANY.DATA-COMPANY.DIRECTION" | translate }}</label>
                <input type="text" formControlName="address" id="address" [class.back_blue]="this.inEdition" [ngClass]="{'wrong': shouldShowError('address')}">
            </div>
            <p class="message_error wrongtext" *ngIf="this.dataCompany.get('address')?.errors?.['required'] && this.isSubmit">{{"PRIVATE.PATIENT.DATA-PATIENT.MESSAGE-FIELD-NAME" | translate }}</p>
           
            <div class="input">
                <label for="locality" class="locality">{{ "PRIVATE.COMPANY.DATA-COMPANY.PLACE" | translate }}</label>
                <input type="text" formControlName="locality" id="locality" [class.back_blue]="this.inEdition" [ngClass]="{'wrong': shouldShowError('locality')}">
            </div>
            <p class="message_error wrongtext" *ngIf="this.dataCompany.get('locality')?.errors?.['required'] && this.isSubmit">{{"PRIVATE.PATIENT.DATA-PATIENT.MESSAGE-FIELD-NAME" | translate }}</p>
          
            <div class="input">
                <label for="postCode" class="postCode">{{ "PRIVATE.COMPANY.DATA-COMPANY.POSTALCODE" | translate }}</label>
                <input type="text" formControlName="postCode" id="postCode" [class.back_blue]="this.inEdition" [ngClass]="{'wrong': shouldShowError('postCode')}">
            </div>
            <p class="message_error wrongtext" *ngIf="this.dataCompany.get('postCode')?.errors?.['pattern'] && this.isSubmit">{{"PRIVATE.COMPANY.DATA-COMPANY.MESSAGE-POSTAL-CODE" | translate }}</p>
           
            <div class="input">
                <label for="state" class="state">{{ "PRIVATE.COMPANY.DATA-COMPANY.PROVINCE" | translate }}</label>                
                <select formControlName="state" id="state" [(ngModel)]="selectValue" [class.back_blue]="this.inEdition" [ngClass]="{'wrong': shouldShowError('state')}">
                    <option [ngValue]="null" hidden>{{ "PRIVATE.COMPANY.DATA-COMPANY.PROVINCE" | translate }}</option>
                    <option value="{{state.id}}" *ngFor= "let state of states; let i=index">{{state.name}}</option>                           
                </select>
            </div> 
            <div class="input">
                <label for="phone" class="phone">{{ "PRIVATE.COMPANY.DATA-COMPANY.PHONE" | translate }}</label>
                <input type="tel" formControlName="phone" id="phone" [class.back_blue]="this.inEdition" [ngClass]="{'wrong': shouldShowError('phone')}">
            </div>
            <p class="message_error wrongtext" *ngIf="this.dataCompany.get('phone')?.errors?.['pattern'] && this.isSubmit">{{"PRIVATE.PATIENT.DATA-PATIENT.MESSAGE-FIELD-PHONE" | translate }}</p>

            <div class="input">
                <label for="mobile" class="mobile">{{ "PRIVATE.COMPANY.DATA-COMPANY.CELLULAR" | translate }}</label>
                <input type="tel" formControlName="mobile" id="mobile" [class.back_blue]="this.inEdition" [ngClass]="{'wrong': shouldShowError('mobile')}">
            </div>   
             <p class="message_error wrongtext" *ngIf="this.dataCompany.get('mobile')?.errors?.['pattern'] && this.isSubmit">{{"PRIVATE.PATIENT.DATA-PATIENT.MESSAGE-FIELD-PHONE" | translate }}</p>     
           
             <div class="input">
                <label for="email" class="email">{{ "PRIVATE.COMPANY.DATA-COMPANY.EMAIL" | translate }}</label>
                <input type="email" formControlName="email" id="email" [class.back_blue]="this.inEdition" [ngClass]="{'wrong': shouldShowError('email')}">
            </div> 
            <p class="message_error wrongtext" *ngIf="this.dataCompany.get('email')?.errors?.['email'] && this.isSubmit">{{"PRIVATE.PATIENT.DATA-PATIENT.MESSAGE-FIELD-EMAIL" | translate }}</p>        
           
            <div class="input">
                <label for="username" class="username">{{ "PRIVATE.COMPANY.DATA-COMPANY.USER" | translate }}</label>
                <input type="text" formControlName="username" id="username" [class.back_blue]="this.inEdition" [ngClass]="{'wrong': shouldShowError('username')}">
            </div>

            <div class="input">
                <label for="pass" class="pass">{{ "PRIVATE.COMPANY.DATA-COMPANY.PASS" | translate }}</label>
                <div class="password" >
                    <input type="passsword" formControlName="pass" id="pass">
                    <div class="buttons" *ngIf="!inEdit || !isDisabled">                   
                        <button class="button tipo-main-color back-grey-color" (click)="openWindow()">
                            {{"PRIVATE.COMPANY.DATA-COMPANY.CHANGE-PASS" | translate }}
                        </button>
                    </div>
                </div>
            </div>
            <div class="input" *ngIf="this.roleService.hasRole(USERS_ADMIN_ELCI)">
                <label for="status" class="status">{{ 'PRIVATE.CONSENTS.LIST-CONSENTS.LABEL-STATE' | translate }}</label>
                <select formControlName="status" id="status" [(ngModel)]="selectedStatus" [class.back_blue]="this.inEdition" [ngClass]="{'wrong': shouldShowError('status')}" >
                    <option [ngValue]="null" hidden> {{ 'PRIVATE.CONSENTS.LIST-CONSENTS.OPTION-1' | translate }}</option>
                    <option value="{{status.id}}" *ngFor="let status of statuses" >
                        {{status.name}}
                      </option>
                </select>
            </div>
            <div class="input" *ngIf="this.roleService.hasRole(USERS_ADMIN_ELCI) && his !== null">
                <label for="speciality" class="speciality">{{"PRIVATE.DOCTOR.DATA-DOCTOR.SPECIALTY" | translate}}</label>
                <div class="specialty_list" *ngIf="isDisabled">
                    <span class="item" *ngFor="let item of specialty; let last = last">{{ item.description }}{{ !last ? ', ' : '' }}</span>
                </div>
                <div class="specialties_input" *ngIf="!isDisabled">
                    <div class="specialties">
                        <span class="selected" *ngFor="let item of specialty; let i=index">{{ item.description }} <fa-icon [icon]="faCircleXmark" class="icono" (click)="deleteItem(i, item)"></fa-icon></span>
                     </div>
                        <select formControlName="specialities" id="specialty_select" [class.back_blue]="this.inEdition" [ngClass]="{'wrong': shouldShowError('specialities')}" multiple>
                            <option value="{{specialty.id}}" *ngFor= "let specialty of specialties; let i=index" (click)="addItem(i, specialty)" >{{specialty.description}}</option>
                        </select>
                </div>               
            </div>
            <p class="message_error wrongtext" *ngIf="this.dataCompany.get('specialities')?.errors?.['required'] && this.isSubmit"> {{'PRIVATE.COMPANY.DATA-COMPANY.MESSAGE-SELECT-SPECALITIES' | translate }}  </p>

            <div class="buttons buttons_save" *ngIf="!inEdit || !isDisabled">
                <button class="button tipo-white-color back-blue-color" type="submit" (click)="onSubmit()">
                    <fa-icon [icon]="faFileExport" class="icono iconono"></fa-icon>
                    {{ "PRIVATE.PATIENT.DATA-PATIENT.BUTTON-SAVE" | translate }}
                </button>
                <button class="button tipo-main-color back-grey-color" (click)="changeEdit()">
                    {{ "PRIVATE.PATIENT.DATA-PATIENT.BUTTON-CANCEL" | translate }}
                </button>
            </div>
            <!--para cambiar la password -->
            <div class="window" *ngIf="isOpenWindow">
                <div class="window_reset">
                    <h4 class="tipo-second-color bold">{{"PRIVATE.COMPANY.DATA-COMPANY.CHANGE-PASS" | translate }}</h4>                    
                    <form [formGroup]="dataPass"  class="reset"> 
                        <div class="inlineflex">
                            <input [type]="showPasswordOld ? 'text' : 'password'" class="pass back_blue" placeholder="Password actual" formControlName="oldPassword">
                            <fa-icon [icon]="faEyeSlash" class="icono"  *ngIf="showPasswordOld === false" (click)="showPassOld()"></fa-icon>
                            <fa-icon [icon]="faEye" class="icono"  *ngIf="showPasswordOld" (click)="showPassOld()"></fa-icon>
                        </div>                       
                        <div class="inlineflex">
                            <input [type]="showPasswordNew ? 'text' : 'password'" class="pass back_blue" placeholder="Nueva password" formControlName="newPassword">
                            <fa-icon [icon]="faEyeSlash" class="icono"  *ngIf="showPasswordNew === false" (click)="showPassNew()"></fa-icon>
                            <fa-icon [icon]="faEye" class="icono"  *ngIf="showPasswordNew" (click)="showPassNew()"></fa-icon>
                        </div>
                           

                        <div class="buttons">                   
                            <button type="submit" class="button tipo-white-color back-blue-color" (click)="changePass()">
                                <fa-icon [icon]="faFileExport" class="icono iconono"></fa-icon>
                                {{ "PRIVATE.PATIENT.DATA-PATIENT.BUTTON-SAVE" | translate }}
                            </button>
                            <button class="button tipo-main-color back-grey-color" (click)="openWindow()">
                                {{ "PRIVATE.PATIENT.DATA-PATIENT.BUTTON-CANCEL" | translate }}
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </form>


    </div>
    
    <div class="box box2">
        <h4 class="title tipo-second-color">{{"PRIVATE.COMPANY.DATA-COMPANY.LEGAL-DATA" | translate }}</h4>
        <form [formGroup]="dataCompany" class="form_datacompany" >
            <div class="input">
                <label for="businessNameFis" class="businessNameFis">{{ "PRIVATE.COMPANY.DATA-COMPANY.NAME" | translate }}</label>
                <input type="text" formControlName="businessNameFis" id="businessNameFis" [class.back_blue]="this.inEdition" [ngClass]="{'wrong': shouldShowError('businessNameFis')}">
            </div>  
            <p class="message_error wrongtext" *ngIf="this.dataCompany.get('businessNameFis')?.errors?.['minlength'] && this.isSubmit">{{"PRIVATE.PATIENT.DATA-PATIENT.MESSAGE-FIELD-NAME" | translate }}</p>

            <div class="input">
                <label for="cifFis" class="cifFis">{{ "PRIVATE.COMPANY.DATA-COMPANY.CIF" | translate }}</label>
                <input type="text" formControlName="cifFis" id="cifFIs" [class.back_blue]="this.inEdition" [ngClass]="{'wrong': shouldShowError('cifFis')}" >
            </div>
            <p class="message_error wrongtext" *ngIf="this.dataCompany.get('cifFis')?.errors?.['pattern'] && this.isSubmit">{{"PRIVATE.PATIENT.DATA-PATIENT.MESSAGE-FIELD-DOCUMENT" | translate }}</p>

            <div class="input">
                <label for="addressFis" class="addressFis">{{ "PRIVATE.COMPANY.DATA-COMPANY.DIRECTION" | translate }}</label>
                <input type="text" formControlName="addressFis" id="addressFis" [class.back_blue]="this.inEdition" [ngClass]="{'wrong': shouldShowError('addressFis')}">
            </div>
            <p class="message_error wrongtext" *ngIf="this.dataCompany.get('addressFis')?.errors?.['required'] && this.isSubmit">{{"PRIVATE.PATIENT.DATA-PATIENT.MESSAGE-FIELD-NAME" | translate }}</p>

            <div class="input">
                <label for="localityFis" class="localityFis">{{ "PRIVATE.COMPANY.DATA-COMPANY.PLACE" | translate }}</label>
                <input type="text" formControlName="localityFis" id="localityFis" [class.back_blue]="this.inEdition" [ngClass]="{'wrong': shouldShowError('localityFis')}">
            </div>
            <p class="message_error wrongtext" *ngIf="this.dataCompany.get('localityFis')?.errors?.['required'] && this.isSubmit">{{"PRIVATE.PATIENT.DATA-PATIENT.MESSAGE-FIELD-NAME" | translate }}</p>

            <div class="input">
                <label for="postCodeFis" class="postCodeFis">{{ "PRIVATE.COMPANY.DATA-COMPANY.POSTALCODE" | translate }}</label>
                <input type="text" formControlName="postCodeFis" id="postCodeFis" [class.back_blue]="this.inEdition" [ngClass]="{'wrong': shouldShowError('postCodeFis')}">
            </div>
            <p class="message_error wrongtext" *ngIf="this.dataCompany.get('postCodeFis')?.errors?.['pattern'] && this.isSubmit">{{"PRIVATE.PATIENT.DATA-PATIENT.MESSAGE-FIELD-PHONE" | translate }}</p>

            <div class="input">
                <label for="stateFis" class="stateFis">{{ "PRIVATE.COMPANY.DATA-COMPANY.PROVINCE" | translate }}</label>                
                <select formControlName="stateFis" id="stateFis" [(ngModel)]="selectValueFis" [class.back_blue]="this.inEdition" [ngClass]="{'wrong': shouldShowError('stateFis')}">
                    <option [ngValue]="null" hidden>{{ "PRIVATE.COMPANY.DATA-COMPANY.PROVINCE" | translate }}</option>
                    <option value="{{state.id}}" *ngFor= "let state of states; let i=index">{{state.name}}</option>                         
                </select>
            </div> 
            <div class="input" *ngIf="!this.roleService.hasRole(FREEMIUM_ROLES)">
                <label for="emailLOPD" class="emailLOPD">{{ "PRIVATE.COMPANY.DATA-COMPANY.EMAILGDPR" | translate }}</label>
                <input type="email" formControlName="emailLOPD" id="emailLOPD" [class.back_blue]="this.inEdition" [ngClass]="{'wrong': shouldShowError('emailLOPD')}">
            </div>   
            <p class="message_error wrongtext" *ngIf="this.dataCompany.get('emailLOPD')?.errors?.['email'] && this.isSubmit">{{"PRIVATE.PATIENT.DATA-PATIENT.MESSAGE-FIELD-EMAIL" | translate }}</p> 

        </form>
        <h4 class="title tipo-second-color">{{ "PRIVATE.COMPANY.DATA-COMPANY.SUSCRIPTION" | translate }}</h4>       
        <p class="tipo-main-color">{{ "PRIVATE.COMPANY.DATA-COMPANY.UPDATE" | translate }} <span class="tipo-second-color">{{creationDate | UTCToLocal}}</span></p>       
        <p class="tipo-main-color">{{ "PRIVATE.DOCTOR.DATA-DOCTOR.LAST-ACCESS-DATE" | translate }} <span class="tipo-second-color" >{{lastAccessDate | UTCToLocal}}</span></p>
        <p class="tipo-main-color">{{ "PRIVATE.COMPANY.DATA-COMPANY.STATE" | translate }} <span class="tipo-second-color" *ngIf="isActive">{{"SUPER-ADMIN.LIST-COMPANY.TYPE-ACTIVE" | translate }}</span></p>
        

    </div>
</div>