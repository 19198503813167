
<div class="list">
    <h4 class="title tipo-second-color bold" id="title_table">{{'PRIVATE.ADMINISTRATOR.LIST-ADMINISTRATORS.TITLE' | translate}}</h4>
    <table class="table" aria-describedby="title_table">
        <thead class="tipo-second-color bold">
            <th scope="col" class="id">{{'SUPER-ADMIN.LIST-COMPANY.ID' | translate}}</th>
            <th scope="col" class="company_name">
                <div class="column">
                    {{'SUPER-ADMIN.LIST-ADMINS.DATA-COMPANY' | translate}} 
                    <input type="text" name="filterBusiness" (debounceInput)="addFilter('businessName', filterBusiness.value)" [appDebounceInput]="300" #filterBusiness id="filterBusiness" placeholder="{{'PRIVATE.PATIENT.LIST-PATIENTS.FILTER' | translate }}">          
                </div>
                
            </th>
            <th scope="col" class="user_name">
                <div class="column">                    
                    <span>{{'PRIVATE.ADMINISTRATOR.LIST-ADMINISTRATORS.NAME' | translate}}</span>
                    <input type="text" name="filterName" (debounceInput)="addFilter('name', filterName.value)" [appDebounceInput]="300" #filterName id="filterName" placeholder="{{'PRIVATE.PATIENT.LIST-PATIENTS.FILTER' | translate }}">
                </div>
            </th>           
            <th scope="col" class="type"><span>{{'SUPER-ADMIN.LIST-COMPANY.TYPE' | translate}}</span> </th>
            <th scope="col" class="email"><span>{{'PRIVATE.ADMINISTRATOR.DATA-ADMINISTRATOR.EMAIL' | translate}}</span></th>                
            <th scope="col" class="login"><span>{{'SUPER-ADMIN.LIST-COMPANY.LOGIN' | translate}}</span></th>
            <th scope="col" class="date_on">
                <div class="column">                    
                    <div class="row">
                        <span>{{ "PRIVATE.PATIENT.LIST-PATIENTS.TITLE-DATE" | translate}}</span>
                        <div class="column arrows">
                            <fa-icon [icon]="faCaretUp" class="icono fa-xl up" (click)="sortAdmins('creationDate', 'asc')"></fa-icon>
                            <fa-icon [icon]="faCaretDown" class="icono fa-xl down" (click)="sortAdmins('creationDate', 'desc')"></fa-icon>
                        </div>
                    </div>
                </div>
            </th>
            <th scope="col" class="date_off"><span>{{'SUPER-ADMIN.LIST-COMPANY.DATE-OFF' | translate}}</span></th>
            <th scope="col" class="state">
                <div class="column">
                <span>{{ 'PRIVATE.CONSENTS.LIST-CONSENTS.LABEL-STATE' | translate }}</span>
                <select name="state" id="state" [(ngModel)]="selectedStatus" (ngModelChange)="addStatusFilter($event)" >
                    <option value="0"> {{ 'PRIVATE.CONSENTS.LIST-CONSENTS.OPTION-1' | translate }}</option>
                    <option *ngFor="let status of statuses" [value]="status.id">
                        {{status.name}}
                      </option>
                </select>
                </div>
            </th>
        </thead>
        <tbody>
            <tr [routerLink]="['/super-admin/users/admins/'+ admin.companyUuid + '/file-admin/'+ admin.uuid]" *ngFor="let admin of admins" >
            <td class="id">{{admin.id}}</td>
            <td class="company_name">{{admin.company}}</td>
            <td class="user_name">{{admin.name}} {{admin.lastname}}</td>  
            <td class="type">
                {{castRole(admin.roles !== undefined ? admin.roles : [])}}                                            
            </td>
            <td class="email" >{{admin.email}}</td>               
            <td class="login" >{{admin.userName}}</td>
            <td class="date_on" >{{admin.creationDate}}</td>
            <td class="date_off" >{{admin.cancelDate}}</td>
            <td class="state" ><span
                class="green bold"
                *ngIf="admin.userStatus?.id === userStatusEnum.ACTIVO">
                {{ admin.userStatus?.name }}
              </span>
              <span
                class="red bold"
                *ngIf="admin.userStatus?.id === userStatusEnum.NO_ACTIVO" >
                {{ admin.userStatus?.name }}
              </span>
             </td> 
            </tr>              
            
        </tbody>
    </table>
    <!-- Pagination -->
    <app-paginator
    [totalElements]="page?.totalElements ?? 0"  
    [numbersOfElements]="page?.numberOfElements ?? 0"
    [totalPages]="page?.totalPages ?? 0"
    [pageNumber]="pagination.page"
    [lastPage]="page?.last ?? false"
    (paginate)="paginar($event)"></app-paginator>
<!-- End of Pagination -->


