import { Component, Input, OnInit } from '@angular/core';
import { DigitalDocumentCategoryInputDTO } from '@app/core/models/input/digital-documents/digital-document-category.model';
import { DigitalDocumentsService } from '@app/core/services/digital-documents/digital-documents.service';
import {
  faCircleExclamation,
  faFileCircleCheck,
  faFileImport,
  faFileSignature,
  faFolderOpen,
  faPrint,
  faRotate,
  faTabletScreenButton,
} from '@fortawesome/free-solid-svg-icons';
import { DigitalDocumentsCategoriesService } from '../../../../core/services/digital-documents-categories/digital-documents-categories.service';
import { Page } from '@app/core/models/input/page/pages.model';
import { Pagination } from '@app/core/models/front/pagination';
import { Filter } from '@app/core/models/front/filters';
import { Sorters } from '@app/core/models/front/sorters';
import { DESC } from '@app/core/constants/Constants';
import StatusEnum from '@app/core/enums/status.enum';
import OutputTypeEnum from '@app/core/enums/output-type.enum';
import { FileService } from '@app/core/services/file/file.service';
import DownloadUtils from '@app/core/utils/download.utils';
import { ActivatedRoute, Router } from '@angular/router';
import { PatientParamsRouteEnum } from '@app/core/enums/route.enum';
import { DigitalDocumentTypeInputDTO } from '@app/core/models/input/digital-documents/digital-document-type.model';
import ObjectTypeEnum from '@app/core/enums/objectType.enum';
import { FilterUtils } from '@app/core/utils/filter.utils';


@Component({
  selector: 'app-digital-documents',
  templateUrl: './digital-documents.component.html',
  styleUrls: ['./digital-documents.component.scss'],
})
export class SpecialsDocsComponent implements OnInit {
  // If true, data loading is performed when the specific tab for this component is opened
  @Input() isOpenFromPatientFile = false;
  @Input() showDocumentsTypes = true;
  // Utils
  filterUtils: FilterUtils;

  faFileSignature = faFileSignature;
  faFileImport = faFileImport;
  faCircleExclamation = faCircleExclamation;
  faTabletScreenButton = faTabletScreenButton;
  faPrint = faPrint;
  faFolderOpen = faFolderOpen;
  faFileCircleCheck = faFileCircleCheck;
  faRotate = faRotate;

  Status = StatusEnum;
  OutputType = OutputTypeEnum;
  ObjectTypeEnum = ObjectTypeEnum;

  ddCategories?: DigitalDocumentCategoryInputDTO[];
  dds?: Page;
  page?: Page;
  pagination = new Pagination();
  filters = new Array<Filter>();
  sorters = new Sorters('creationDate', DESC);

  constructor(
    private ddsCategoriesSrv: DigitalDocumentsCategoriesService,
    private ddService: DigitalDocumentsService,
    private ddsSrv: DigitalDocumentsService,
    private fileService: FileService,
    private activatedRoute: ActivatedRoute,
    private router: Router,  
  ) {
    this.filterUtils = new FilterUtils(this.filters);
  }


  ngOnInit(): void {
    if (this.isOpenFromPatientFile) {
      this.activatedRoute.params.subscribe(param => {
        if (param['tab'] === PatientParamsRouteEnum.DIGITAL_DOCUMENTS) {
          this.loadData();
        }
      });
    } else {
      this.loadData();
    }
  }

  loadData() {
    this.addFilter(
      'patientUuid',
      this.activatedRoute.snapshot.paramMap.get('uuid') as string
    );
    this.getDDCategories();
    this.getDDs();
  }

  addStatusFilter(statusId: string) {
    // 0 means all statuses
    if (statusId != '0') {
      this.addFilter('statusId', statusId);
    } else {
      this.removeFilter('statusId');
    }
  }

  addFilter(name: string, filter: string) {
    this.filterUtils.addFilter(name, filter);
    this.getDDs();
  }

  removeFilter(name: string) {
    this.filterUtils.removeFilter(name);
    this.getDDs();
  }

  paginar(amount: number) {
    this.pagination.page += amount;
    this.getDDs();
  }

  getDDCategories() {
    this.ddsCategoriesSrv.getDigitalDocumentsCategories().subscribe({
      next: ddCategories => {
        this.ddCategories = ddCategories;
      },
    });
  }

  getDDs() {
    this.ddsSrv.getDigitalDocuments(this.pagination, this.filters, this.sorters).subscribe({
      next: dds => {
        this.dds = dds;         
      },
    });
  }

  setSelectedDigitalDocumentType(digitalDocumentType: DigitalDocumentTypeInputDTO) {
    this.ddService.setDigitalDocument(digitalDocumentType);
    this.router.navigate(['./generate-dd/' + digitalDocumentType.templateUuid], { relativeTo: this.activatedRoute });
   
    

  }

  downloadFile(uuid: string): void {
    this.fileService.getDigitalDocumentPdf(uuid).subscribe({
      next: dataDocument => {
        if (dataDocument.body?.name){
          const newName = dataDocument.body?.name.replace(/\.pdf$/, '');
          DownloadUtils.download(dataDocument.body?.content ?? '', newName, dataDocument.body?.mimeType ?? '');
         }
      }
    })
  }

  refreshData(): void {
    this.getDDs();
  }

  openWindow(): void {
    const window = document.querySelector('.window');
    window?.classList.remove('nodisplay');
  }
}
