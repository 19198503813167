import { Component, Input } from '@angular/core';
import { StatisticPatients } from '@app/core/models/input/statistics/statistic-patients.model';

@Component({
  selector: 'app-patients',
  templateUrl: './patients.component.html',
  styleUrls: ['./patients.component.scss']
})
export class PatientsComponent {

  @Input() statisticPatients?: StatisticPatients[];
}
