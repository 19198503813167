<div class="wrapper">
    <div class="cabecera">
        <img class="movil" src="../../../assets/img/comofunciona/cabecera-movil.jpg" alt="Cómo funciona el Consentimiento.es">
        <img class="desktop" src="../../../assets/img/comofunciona/cabecera.jpg" alt="Cómo funciona el Consentimiento.es">
        <h1 class="titulo_seccion tipo-second-color bold">Cómo funciona</h1>
    </div>
    <div class="maxwidth">
        <div class="contenido">
            <div class="cabecera_contenido">
                <img class="desktop" src="../../../assets/img/comofunciona/doctor-comofunciona.png" alt="El consentimiento es una aplicación para relaizar consentimientos informados personalizados">
                <div class="intro tipo-main-color regular">
                    <p class="contenido_p"><span class="bold">ElConsentimiento.es</span> Elconsentimiento.es es una aplicación en la que puedes realizar consentimientos informados personalizados en solo 3 minutos, en 4 sencillos pasos. Accede desde cualquier lugar con internet, o conecta la base de datos al HIS propio de cada centro mediante API. </p>
                    <p class="contenido_p">Todos los documentos generados  se firman sobre tablet con firma biométrica y se almacenan en la nube, siempre accesibles. Existe la posibilidad de incluir firmas remotas mediante envío de email y código pin de autentificación.</p>
                    <p class="contenido_p">Solo necesitas darte de alta en la aplicación y un dispositivo de presión táctil (tablet homologada). </p>                    
                </div>
            </div>
            <div class="fichas">
                <div class="bloque" *ngFor = 'let item of paso; let i=index'>
                    <div class="number black tipo-orange-color">{{item.number}}</div>
                    <div class="ficha center" ><span class="icono_sombra"></span>
                        <fa-icon [icon]="item.icono" class="icono"></fa-icon>                   
                        <h3 class="titulo_ficha bold tipo-second-color">{{item.titulo}}</h3>
                        <p class="texto_ficha light tipo-second-color">{{item.subtitulo}}</p>
                    </div>
                </div>    
            </div>
            <h3 class="archivo tipo-second-color bold center">Archivo siempre disponible para consultas, confirmaciones y firmas retrasadas</h3>
        </div>
    </div>
    <div class="pie">
        <img class="movil" src="../../../assets/img/comofunciona/footer-movil.jpg" alt="Cómo funciona el Consentimiento.es">
        <img class="desktop" src="../../../assets/img/comofunciona/footer.jpg" alt="Cómo funciona el Consentimiento.es">
    </div>
</div>            