import { Component, OnInit } from '@angular/core';
import { faCaretDown, faCaretUp } from '@fortawesome/free-solid-svg-icons';
import { StatisticsService } from '@app/core/services/statistics/statistics.service';
import { DoctorsBySpecialty } from '@app/core/models/input/statistics/elements-by-specialty.model';

@Component({
  selector: 'app-doctors-by-specialty',
  templateUrl: './doctors-by-specialty.component.html',
  styleUrls: ['./doctors-by-specialty.component.scss']
})
export class DoctorsBySpecialtyComponent implements OnInit{
  faCaretDown = faCaretDown;
  faCaretUp = faCaretUp;

  doctors?: DoctorsBySpecialty[];

  constructor(private statisticsService: StatisticsService, ) { }

  ngOnInit(): void {
    this.getDoctorBySpecialty();  
  }

  getDoctorBySpecialty(){
    this.statisticsService.getDoctorsBySpecialty().subscribe({
      next: respuesta => {      
       this.doctors = respuesta;
      }
    })
  }
}
