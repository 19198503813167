import { Variable } from "../consent/variable.model";

export class TemplateInputDTO {
    uuid?: string;
    name?: string;
    description?: string;
    content?: string;
    active?: boolean;
    batch?: boolean;
    orderNumber?: number;
    hasVariables?: boolean;
    variables?: Variable[];

}