import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AlertService } from '@app/core/services/alert/alert.service';
import { ConsentService } from '@app/core/services/consent/consent.service';
import {
  faPaperPlane,
  faPenToSquare,
  faTrashCan,
  faCircleXmark,
  faHouseMedicalCircleCheck
} from '@fortawesome/free-solid-svg-icons';
import { TranslateService } from '@ngx-translate/core';
import { Location } from '@angular/common';

@Component({
  selector: 'app-draft-consent',
  templateUrl: './draft-consent.component.html',
  styleUrls: ['./draft-consent.component.scss'],
})
export class DraftConsentComponent {
  faPaperPlane = faPaperPlane;
  faPenToSquare = faPenToSquare;
  faTrashCan = faTrashCan;
  faCircleXmark = faCircleXmark;
  faHouseMedicalCircleCheck = faHouseMedicalCircleCheck;

  consentUuid?: string;
  isOpenWindow = false;
  isTrashModalOpen = false;
 
  constructor(
    private activatedRoute: ActivatedRoute,
    private consentService: ConsentService,
    private alertService: AlertService,
    private translate: TranslateService,
    private location: Location
  ){
    this.activatedRoute.params.subscribe(params => {
      this.consentUuid = params['consentUuid'];     
    });
  }

  toggleWindow() {
    this.isOpenWindow = !this.isOpenWindow;
  }

  toggleTrash() {
    this.isTrashModalOpen = !this.isTrashModalOpen;
  }

  deleteDraft(){    
    if (this.consentUuid != undefined) {
      this.consentService.deleteConsentByUuid(this.consentUuid).subscribe({
        next: () => {
          this.alertService.success(
            this.translate.instant(
              'PRIVATE.CONSENTS.DRAFT-CONSENT.MESSAGE-DELETE-OK'
            )
          );
          this.location.back();
        },

        error: error => {
          this.alertService.error(error[0]);
        },
      });
      this.toggleTrash();
    }
  }
}
