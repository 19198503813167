<div class="wrapper">    
    <div class="list">
        <div class="row back-white-color">
            <div class="column">   
                <p class="tipo-second-color bold">{{'SUPER-ADMIN.CONSENT-BY-SPECIALTY.DATA-DATE-ON' | translate}}</p>
                <input type="text" name="filterDocument" (debounceInput)="addFilter('signatureDate-start', filterDocument.value)" [appDebounceInput]="300" #filterDocument id="filterDocument" placeholder="{{'PRIVATE.PATIENT.LIST-PATIENTS.FILTER' | translate }}">
            </div>
    
            <div class="column">  
                <p class="tipo-second-color bold">{{'SUPER-ADMIN.CONSENT-BY-SPECIALTY.DATA-DATE-OFF' | translate}}</p>
                <input type="text" name="filterDocument" (debounceInput)="addFilter('signatureDate-end', filterDocument.value)" [appDebounceInput]="300" #filterDocument id="filterDocument" placeholder="{{'PRIVATE.PATIENT.LIST-PATIENTS.FILTER' | translate }}">
            </div>
      </div>
        <h4 class="title tipo-second-color bold" id="title_table">{{'SUPER-ADMIN.SIGNS-BY-COMPANY.TITLE-SIGNS-BY-COMPANY' | translate}}</h4>
            
        
        <table class="table" aria-describedby="title_table">
            <thead class="tipo-second-color bold"> 
                <th scope="col" class="company_name">               
                     {{'SUPER-ADMIN.LIST-ADMINS.DATA-COMPANY' | translate}} 
                </th>                           
                <th scope="col" class="signs_cis">{{'SUPER-ADMIN.SIGNS-BY-COMPANY.SIGNS-CIS' | translate}}</th>
                <th scope="col" class="remotes">{{'SUPER-ADMIN.SIGNS-BY-COMPANY.REMOTES' | translate}}</th>
                <th scope="col" class="signs_rgpds">{{'SUPER-ADMIN.SIGNS-BY-COMPANY.SIGNS-RGPDS' | translate}}</th>
                <th scope="col" class="signs_dds">{{'SUPER-ADMIN.SIGNS-BY-COMPANY.SIGNS-DDS' | translate}}</th>
                <th scope="col" class="signs_total">{{'SUPER-ADMIN.SIGNS-BY-COMPANY.TOTAL-SIGNS' | translate}}</th>

            </thead>
            <tbody>                
                <tr *ngFor="let signature of signatures?.signedDocumentsByCompany">
                    <td class="company_name">{{signature.businessName}}</td>
                   
                    <td class="signs_cis">{{(signature.countConsentsBio!== undefined ?  signature.countConsentsBio : 0) + (signature.countConsentsRemote!== undefined ? signature.countConsentsRemote : 0) }}</td>
                    <td class="remotes" >{{signature.countConsentsRemote}}</td>
                    <td class="signs_rgpds">{{signature.countRgpds}} </td>                   
                    <td class="signs_dds">{{signature.countDds}}</td>                 
                    <td class="signs_total">{{signature.countTotal}}</td>    
                </tr>
                <tr class="total_signs">
                    <td class="company_name tipo-second-color bold">{{'SUPER-ADMIN.SIGNS-BY-COMPANY.SUPER-TOTAL-SIGNS' | translate}}</td>                   
                    <td class="signs_cis tipo-second-color bold">{{signatures?.grandCountConsentsBio}}</td>
                    <td class="remotes tipo-second-color bold">{{signatures?.grandCountConsentsRemote}}</td>
                    <td class="signs_rgpds tipo-second-color bold">{{signatures?.grandCountRgpds}} </td>                   
                    <td class="signs_dds tipo-second-color bold">{{signatures?.grandCountDds}}</td>                 
                    <td class="signs_total tipo-second-color bold" >{{signatures?.grandCountTotal}}</td>    
                </tr>
            </tbody>
        </table>       
</div>    

