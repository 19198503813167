import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ApiHandlerModule } from './api-handler/api-handler.module';
import { QuickApiRoutingModule } from './quick-api-routing.module';

@NgModule({
  declarations: [],
  imports: [CommonModule, ApiHandlerModule, QuickApiRoutingModule],
})
export class QuickApiModule {}
