import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { StatusActionsComponent } from '@app/private/shared/components/status-actions/status-actions.component';
import { SharedModule } from '@app/shared/shared.module';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { LoaderComponent } from './loader/loader.component';
import { WindowErrorComponent } from './window-error/window-error.component';
import { StatusesComponent } from './statuses/statuses.component';
import { MessageDocEndedComponent } from './message-doc-ended/message-doc-ended.component';

@NgModule({
  declarations: [
    WindowErrorComponent,
    LoaderComponent,
    StatusActionsComponent,
    StatusesComponent,   
    MessageDocEndedComponent,
  ],
  imports: [
    CommonModule,
    FontAwesomeModule,
    SharedModule,
    ReactiveFormsModule,
    FormsModule,
  ],
  exports: [
    WindowErrorComponent,
    LoaderComponent,
    StatusActionsComponent,
    StatusesComponent,
    
  ],
})
export class SharedComponentModule {}
