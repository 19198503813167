import { Company } from '../models/input/company/company.model';
import { Patient } from '../models/input/patient/patient.model';
import { RepresentativePatientDTO } from '../models/input/patient/representative.model';
import { UserOutput } from '../models/output/user-output.model';

// This utility class provides string manipulation functions related to user, company, patient, and representative data.
export default class StringUtils {
  // Given a UserOutput object, this method constructs and returns the full address as a string.
  static getUserFullAddress(user: UserOutput): string {
    if (user) {
      return `${user.address}, ${user.postCode}, ${user.locality}`;
    }
    return '';
  }

  // Given a Company object, this method constructs and returns the full address as a string.
  static getCompanyFullAddress(company: Company): string {
    if (company) {
      return `${company.address}, ${company.postCode}, ${company.locality}`;
    }
    return '';
  }

  // Given a Patient object, this method constructs and returns the full last names as a string.
  static getPatientFullLastNames(patient: Patient): string {
    return [patient.firstLastName, patient.secondLastName]
      .filter(Boolean) // Remove empty or undefined last names
      .join(' '); // Join non-empty last names with a space separator
  }

  // Given a Patient object, this method constructs and returns the full last names as a string.
  static getPatientFullPhone(patient: Patient): string {
    return [patient.phonePrefix, patient.mobile]
      .filter(Boolean) // Remove empty or undefined last names
      .join(' '); // Join non-empty last names with a space separator
  }

  // Given a RepresentativePatientDTO object, this method constructs and returns the full last names as a string.
  static getRepPatientFullLastNames(representativePatient: RepresentativePatientDTO): string {
    return [representativePatient.firstLastName, representativePatient.secondLastName]
      .filter(Boolean) // Remove empty or undefined last names
      .join(' '); // Join non-empty last names with a space separator
  }

  static formatDate(date: Date): string {
    // Get the year from the Date object
    const year = date.getFullYear();

    // Get the month from the Date object (0-based), convert to string, and pad with leading zeros
    const month = String(date.getMonth() + 1).padStart(2, '0');

    // Get the day of the month from the Date object, convert to string, and pad with leading zeros
    const day = String(date.getDate()).padStart(2, '0');

    // Concatenate the year, month, and day with hyphens to form the formatted date string
    return `${year}-${month}-${day}`;
  }

  static formatDateMinutes(date: Date): string {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    const hours = date.getHours().toString().padStart(2, '0');
    const minutes = date.getMinutes().toString().padStart(2, '0');
    const seconds = date.getSeconds().toString().padStart(2, '0');

    // Concatenate the year, month, and day with hyphens to form the formatted date string
    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
  }

  static formatDateDDMMYYYY(date: Date): string {
    // Get the year from the Date object
    const year = date.getFullYear();

    // Get the month from the Date object (0-based), convert to string, and pad with leading zeros
    const month = String(date.getMonth() + 1).padStart(2, '0');

    // Get the day of the month from the Date object, convert to string, and pad with leading zeros
    const day = String(date.getDate()).padStart(2, '0');

    // Concatenate the year, month, and day with hyphens to form the formatted date string
    return `${day}-${month}-${year}`;
  }

  // https://stackoverflow.com/questions/30106476/using-javascripts-atob-to-decode-base64-doesnt-properly-decode-utf-8-strings
  // Defines a static method named `b64DecodeUnicode` that takes a single argument `str` of type `string`.
  // The method returns a `string`.
  static b64DecodeUnicode(str: string): string {
    // Decodes the input Base64 string `str` into a binary string using `atob` and then
    // converts this binary string into a URI component by decoding percent-encoded characters.
    return decodeURIComponent(
      // Converts the decoded binary string into an array of characters,
      // and then maps each character to its percent-encoded form.
      Array.prototype.map
        .call(atob(str), function (c) {
          // Converts each character `c` to its ASCII code, then to its hexadecimal representation.
          // Pads the hexadecimal number with a leading zero if it's less than 16 (to ensure two digits).
          // Prepends '%' to the hexadecimal string to form a percent-encoded string.
          return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        })
        // Joins all the percent-encoded strings together to form a complete percent-encoded string.
        .join('')
    );
  }

  static sanitizeFilename(filename: string) {
    const invalidChars = /[<>:"/\\|?*]/g;
    return filename.replace(invalidChars, '');
  }
}
