<h5 class="title tipo-second-color bold">{{ 'PRIVATE.DASHBOARD.PATIENTS.TITLE' | translate }}</h5>
<div class="box_patients" *ngFor="let item of statisticPatients">
    <div class="item">
            <p class="number tipo-main-color">{{item.newPatients === null ? 0 : item.newPatients }}</p>
            <p class="label tipo-second-color">{{ 'PRIVATE.DASHBOARD.PATIENTS.NEW' | translate }}</p>
    </div>
    <div class="item">
        <p class="number tipo-main-color">{{item.signedRgpds === null ? 0 : item.signedRgpds  }}</p>
        <p class="label tipo-second-color">{{ 'PRIVATE.DASHBOARD.PATIENTS.GDPR' | translate }}</p>
    </div>
</div>