import { Component, OnInit } from '@angular/core';
import { Filter } from '@app/core/models/front/filters';
import { FilterUtils } from '@app/core/utils/filter.utils';
import { faCaretDown, faCaretUp } from '@fortawesome/free-solid-svg-icons';
import { StatisticsService } from '@app/core/services/statistics/statistics.service';
import { RgpdsByCompany } from '@app/core/models/input/statistics/documents-generated.model';

@Component({
  selector: 'app-rgpds-by-company',
  templateUrl: './rgpds-by-company.component.html',
  styleUrls: ['./rgpds-by-company.component.scss']
})
export class RgpdsByCompanyComponent implements OnInit{
  faCaretDown = faCaretDown;
  faCaretUp = faCaretUp;


  filters = new Array<Filter>();
  documents?: RgpdsByCompany[];

  // Utils
  filterUtils: FilterUtils;

  nameFilter = new Filter();
  nameFilterStr?: string;


  constructor(private statisticsService: StatisticsService) {
    this.filterUtils = new FilterUtils(this.filters);
  }

  ngOnInit(): void {   
   this.getRGPDsByCompany()
  }

  addFilter(name: string, filter: string) { 
    if (name === 'creationDate-start' || name === 'creationDate-end') {
      filter = this.convertDateFormat(filter);
    }
    this.filterUtils.addFilter(name, filter);  
    this.getRGPDsByCompany();  
  }

  getRGPDsByCompany(){
    this.statisticsService.getRGPDsByCompany(this.filters).subscribe({
      next: respuesta => {
        this.documents = respuesta;
      }
    })    
  }

  convertDateFormat(dateString: string): string {
    const [day, month, year] = dateString.split('/');
    return `${year}-${month}-${day}`;
  }

}
