export class Token {
    sub?: string;
    authorities?: string[];
    roles?: string[];
    username?: string;
    uuid?: string;
    main_company?: string;
    language?: string;
    iat?: number;
    exp?: number;
}