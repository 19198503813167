import { Component } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { USERS_ADMIN_ELCI } from '@app/core/constants/Permissions';
import RoleEnum from '@app/core/enums/role.enum';
import { RoleService } from '@app/core/services/role/role.service';
import { faCircleQuestion, faCircleXmark } from '@fortawesome/free-solid-svg-icons';
import { first } from 'rxjs';
import { LoginService } from '../../../core/services/login/login.service';

@Component({
  selector: 'app-login-administration',
  templateUrl: './login-administration.component.html',
  styleUrls: ['./login-administration.component.scss'],
})
export class LoginAdministrationComponent {
  faCircleXmark = faCircleXmark;
  faCircleQuestion = faCircleQuestion;

  loginAdmin!: FormGroup;
  roleEnum = RoleEnum;
  isOpenWindow = false;

  noValido = false;
  error = false;

  constructor(
    private loginService: LoginService,
    private roleService: RoleService,
    private router: Router,
    private route: ActivatedRoute
  ) {
    this.loginService.logout(null);
    this.initForms();
  }

  loginAppAdmin() {
    this.loginService
      .loginAdmin(this.loginAdmin.value.username, this.loginAdmin.value.password)
      .pipe(first())
      .subscribe({
        next: () => {
          if (this.roleService.hasRole(USERS_ADMIN_ELCI)) {
            const returnUrl = this.route.snapshot.queryParamMap.get('returnUrl') ?? '/super-admin/companies';
            // now do the navigation
            this.router.navigateByUrl(returnUrl);
          }
        },
      });
  }

  initForms(): void {
    this.loginAdmin = new FormGroup({
      username: new FormControl('', [Validators.required, Validators.minLength(3)]),
      password: new FormControl('', Validators.required),
    });
  }

  openWindow(): void {
    this.isOpenWindow = true;
  }

  closeWindow(): void {
    this.isOpenWindow = false;
  }
}
