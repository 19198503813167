<div class="wrapper">
    <!-- logo consentimiento -->
    <header class="header inlineflex">
      <div class="header_logo">        
          <img
            alt="El consentimiento"
            src="../../../assets/img/header/logo-ELCI.png" />        
      </div>
      <ul class="menu_desktop">
        <li class="menu_desktop_li" [routerLink]="['companies']">{{"SUPER-ADMIN.HEADER.MENU-COMPANIES" | translate}}</li>
        <li class="menu_desktop_li menu_maintenance">{{"SUPER-ADMIN.HEADER.MENU-MAINTENANCE" | translate}}
          <ul class="submenu_desktop back-white-color submenu1">
            <li class="submenu_desktop_li tipo-main-color" [routerLink]="['maintenance/specialties']">{{"SUPER-ADMIN.HEADER.MENU-SPECIALTIES" | translate}}</li>
            <li class="submenu_desktop_li tipo-main-color" [routerLink]="['maintenance/consents']">{{"SUPER-ADMIN.HEADER.MENU-CONSENTS" | translate}}</li>
          </ul>
        </li>
        <li class="menu_desktop_li menu_users" >{{"SUPER-ADMIN.HEADER.MENU-USERS" | translate}}
          <ul class="submenu_desktop back-white-color submenu2">
            <li class="submenu_desktop_li tipo-main-color" [routerLink]="['users/professionals']">{{"SUPER-ADMIN.HEADER.MENU-PROFESSIONALS" | translate}}</li>
            <li class="submenu_desktop_li tipo-main-color" [routerLink]="['users/admins']">{{"SUPER-ADMIN.HEADER.MENU-ADMINS" | translate}}</li>
          </ul>
        </li>
        <li class="menu_desktop_li" [routerLink]="['patients']">{{"SUPER-ADMIN.HEADER.MENU-PATIENS" | translate}}</li>
        <li class="menu_desktop_li menu_statistics">{{"SUPER-ADMIN.HEADER.MENU-STATISTICS" | translate}}
          <ul class="submenu_desktop back-white-color submenu3">
            <li class="submenu_desktop_li tipo-main-color" [routerLink]="['statistics/consents-by-doctors']">{{"SUPER-ADMIN.HEADER.MENU-CONSENTS-BY-DOCTORS" | translate}}</li>
            <li class="submenu_desktop_li tipo-main-color" [routerLink]="['statistics/doctors-by-specialty']">{{"SUPER-ADMIN.HEADER.MENU-DOCTORS-BY-SPECIALTY" | translate}}</li>
            <li class="submenu_desktop_li tipo-main-color" [routerLink]="['statistics/consents-by-specialty']">{{"SUPER-ADMIN.HEADER.MENU-CONSENTS-BY-SPECIALTY" | translate}}</li>
            <li class="submenu_desktop_li tipo-main-color" [routerLink]="['statistics/consents-most-used']">{{"SUPER-ADMIN.HEADER.MENU-CONSENTS-MOST-USED" | translate}}</li>
            <li class="submenu_desktop_li tipo-main-color" [routerLink]="['statistics/rgpds-by-company']">{{"SUPER-ADMIN.HEADER.MENU-RGPDS-BY-COMPANY" | translate}}</li>
            <li class="submenu_desktop_li tipo-main-color" [routerLink]="['statistics/dds-by-company']">{{"SUPER-ADMIN.HEADER.MENU-DDS-BY-COMPANY" | translate}}</li>
            <li class="submenu_desktop_li tipo-main-color" [routerLink]="['statistics/signs-by-company']">{{"SUPER-ADMIN.HEADER.MENU-SIGNS-BY-COMPANY" | translate}}</li>
            <li class="submenu_desktop_li tipo-main-color" [routerLink]="['statistics/api-documents-by-company']">{{"SUPER-ADMIN.HEADER.MENU-API-DOCUMENTS-BY-COMPANY" | translate}}</li>
          </ul>
        </li>
      </ul>
      <div class="header_right">  
            <!-- menú hamburguesa para móvil -->
            <button
            class="header_button"
            (click)="mostrarMenu()"
            [ngClass]="{ activo: logoblanco }">
            <p class="tipo-second-color small bold button_p">
              {{ 'PRIVATE.HEADER.BUTTON-MENU' | translate }}
            </p>
            <div class="linea top"></div>
            <div class="linea mid"></div>
            <div class="linea bot"></div>
          </button>
          <div class="menu back-blue-color tipo-white-color bold"
            [ngClass]="{ visible: menuvisible }"
            (mouseleave)="quitarMenu()">
            <ul class="menu_ul">
              <a  [routerLink]="['companies']" class="tipo-white-color" (click)="mostrarMenu()">
                  <li class="menu_li" >{{"SUPER-ADMIN.HEADER.MENU-COMPANIES" | translate}}  </li>
              </a>
              <a [routerLink]="['']" class="tipo-white-color" (click)="mostrarMenu()">
                  <li class="menu_li">{{"SUPER-ADMIN.HEADER.MENU-MAINTENANCE" | translate}}
                    <ul class="submenu ">
                      <li class="submenu_li" [routerLink]="['maintenance/specialties']">{{"SUPER-ADMIN.HEADER.MENU-SPECIALTIES" | translate}}</li>
                      <li class="submenu_li" [routerLink]="['maintenance/consents']">{{"SUPER-ADMIN.HEADER.MENU-CONSENTS" | translate}}</li>
                    </ul>
                  </li>
              </a>
              
                <li class="menu_li">{{"SUPER-ADMIN.HEADER.MENU-USERS" | translate}}
                  <ul class="submenu ">
                    <li class="submenu_li" [routerLink]="['users/professionals']" (click)="mostrarMenu()">{{"SUPER-ADMIN.HEADER.MENU-PROFESSIONALS" | translate}}</li>
                    <li class="submenu_li" [routerLink]="['users/admins']" (click)="mostrarMenu()">{{"SUPER-ADMIN.HEADER.MENU-ADMINS" | translate}}</li>
                  </ul>
                </li>
             
              <a [routerLink]="['patients']" class="tipo-white-color" (click)="mostrarMenu()">
                <li class="menu_li">{{"SUPER-ADMIN.HEADER.MENU-PATIENS" | translate}}</li>
              </a> 
              <a [routerLink]="['']" class="tipo-white-color" (click)="mostrarMenu()">
                <li class="menu_li">{{"SUPER-ADMIN.HEADER.MENU-STATISTICS" | translate}}
                  <ul class="submenu ">
                    <li class="submenu_li" [routerLink]="['statistics/consents-by-doctors']">{{"SUPER-ADMIN.HEADER.MENU-CONSENTS-BY-DOCTORS" | translate}}</li>
                    <li class="submenu_li" [routerLink]="['statistics/doctors-by-specialty']">{{"SUPER-ADMIN.HEADER.MENU-DOCTORS-BY-SPECIALTY" | translate}}</li>
                    <li class="submenu_li" [routerLink]="['statistics/consents-by-specialty']">{{"SUPER-ADMIN.HEADER.MENU-CONSENTS-BY-SPECIALTY" | translate}}</li>
                    <li class="submenu_li" [routerLink]="['statistics/consents-most-used']">{{"SUPER-ADMIN.HEADER.MENU-CONSENTS-MOST-USED" | translate}}</li>
                    <li class="submenu_li" [routerLink]="['statistics/rgpds-by-company']">{{"SUPER-ADMIN.HEADER.MENU-RGPDS-BY-COMPANY" | translate}}</li>
                    <li class="submenu_li" [routerLink]="['statistics/dds-by-company']">{{"SUPER-ADMIN.HEADER.MENU-DDS-BY-COMPANY" | translate}}</li>
                    <li class="submenu_li" [routerLink]="['statistics/signs-by-company']">{{"SUPER-ADMIN.HEADER.MENU-SIGNS-BY-COMPANY" | translate}}</li>
                    <li class="submenu_li" [routerLink]="['statistics/api-documents-by-company']">{{"SUPER-ADMIN.HEADER.MENU-API-DOCUMENTS-BY-COMPANY" | translate}}</li>
                  
                  </ul>
                </li>
              </a>         
              <li (click)="logOut()" class="submenu_li exit">
                <fa-icon [icon]="faRightFromBracket" class="icono"></fa-icon>
                {{ 'PRIVATE.HEADER.BUTTON-OUT' | translate }}
              </li>
            </ul>
          </div>
          <div class=" user">
            <p class="username tipo-second-color regular small" >
              {{user?.name}} {{user?.lastname}}
            </p>
            <p (click)="logOut()" class="desktop exit">
              <fa-icon [icon]="faRightFromBracket" class="icono"></fa-icon>
              {{ 'PRIVATE.HEADER.BUTTON-OUT' | translate }}
            </p>
            </div>
      </div>      
    </header>
</div>      