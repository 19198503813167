import { Sorters } from "../models/front/sorters";

export class SorterUtils {
    sorters : Sorters;
    
    constructor(sorters: Sorters) {
      this.sorters = sorters;
    }

    sortData(property: string, direction: string) {
        this.sorters.property = property;
        this.sorters.direction = direction;       
      }

    
}