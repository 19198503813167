import { Component, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-super-admin',
  templateUrl: './super-admin.component.html',
  styleUrls: ['./super-admin.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class SuperAdminComponent {

}
