<div class="wrapper">    
    <div class="list">
        <h4 class="title tipo-second-color bold" id="title_table">{{'SUPER-ADMIN.LIST-SPECIALTIES.TITLE-LIST-SPECIALTIES'| translate}}</h4>
        <table class="table" aria-describedby="title_table">
            <thead class="tipo-second-color bold">
                <th scope="col" class="id">{{'SUPER-ADMIN.LIST-COMPANY.ID' | translate}}</th>
                <th scope="col" class="specialty_title">
                     <span>{{'SUPER-ADMIN.LIST-CONSENTS-ADMIN.DATA-SPECIALTY' | translate}}</span>
                </th>                
               
                <th scope="col" class="state">
                    <span>{{ 'PRIVATE.CONSENTS.LIST-CONSENTS.LABEL-STATE' | translate }}</span>
                </th>
            </thead>
            <tbody>
                <tr *ngFor="let specialty of specialties" >
                    <td class="id">{{specialty.id}}</td>
                    <td class="specialty_name"> {{specialty.description}}</td>              
                    <td class="state" ><span
                        class="green bold"
                        *ngIf="specialty.activo">
                        Activo
                    </span>
                    <span
                        class="red bold"
                        *ngIf="!specialty.activo" >
                        Inactivo
                    </span></td> 
                </tr>
              
           
              
            </tbody>
        </table>
      
</div>    

