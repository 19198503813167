import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ProceduresBySpecialty, TotalProceduresBySpecialty } from '@app/core/models/front/public';
import { PublicService } from '@app/core/services/public/public.service';


@Component({
  selector: 'app-list-procedures',
  templateUrl: './list-procedures.component.html',
  styleUrls: ['./list-procedures.component.scss']
})
export class ListProceduresComponent implements OnInit{

   specialityId?: number;
   proceduresBySpecialty?: TotalProceduresBySpecialty;
   specialityName?: string;
   proceduresNumber?: number;
   proceduresNames?: ProceduresBySpecialty[];

   constructor(
    private publicService: PublicService,
    private activatedRoute: ActivatedRoute,
   
  ){
    this.activatedRoute.params.subscribe(params => {      
      this.specialityId = params['id'];     
    });
  }

  ngOnInit(): void {
    
    this.getProceduresBySpecialties(); 
    this. getProceduresListBySpecialty(); 
  
  }

  getProceduresBySpecialties() {
     this.publicService.getTotalProceduresBySpecialties().subscribe(respuesta => {      
     this.proceduresBySpecialty = respuesta.find(respuesta => respuesta.id == this.specialityId);    
      if(this.proceduresBySpecialty) {   
        this.specialityName = this.proceduresBySpecialty.description;
        this.proceduresNumber = this.proceduresBySpecialty.quantity;
      }
    })
  }

  getProceduresListBySpecialty() {
    if(this.specialityId){
      this.publicService.getProceduresListBySpecialty(this.specialityId).subscribe({
        next: datalist => {         
          this.proceduresNames = datalist;
        }
      })
    }
   
  }

  
}
