import { Component, Input } from '@angular/core';
import { StatisticDDs } from '@app/core/models/input/statistics/statistic-dds.model';

@Component({
  selector: 'app-mydocuments',
  templateUrl: './my-documents.component.html',
  styleUrls: ['./my-documents.component.scss'],
})
export class MyDocumentsComponent {
  @Input() statisticDDs?: StatisticDDs;


}
