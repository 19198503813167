import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { SharedModule } from '@app/shared/shared.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DataProfessionalComponent } from './components/data-professional/data-professional.component';
import { ListAdministrativesComponent } from './components/list-administratives/list-administratives.component';
import { ListProfessionalsComponent } from './components/list-professionals/list-professionals.component';
import { FileUserComponent } from './pages/file-user/file-user.component';
import { ListUsersComponent } from './pages/list-users/list-users.component';
import { CompanyAdminModule } from '../company/company-admin.module';
import { DoctorsModule } from '@app/private/doctors/doctors.module';
import { AdministratorsModule } from '@app/private/administrators/administrators.module';
import { PersonalizationModule } from '@app/private/personalization/personalization.module';
import { HeaderUserComponent } from './components/header-user/header-user.component';


@NgModule({
    declarations: [      
       DataProfessionalComponent,
       ListAdministrativesComponent,
       ListProfessionalsComponent,
       FileUserComponent,
       ListUsersComponent,
       HeaderUserComponent
     
    ],
    imports: [
      CommonModule,
      FontAwesomeModule,
      SharedModule,
      ReactiveFormsModule,
      FormsModule,  
      CompanyAdminModule,
      DoctorsModule,
      AdministratorsModule,
      PersonalizationModule 
    ]
   
    
  })
  export class UserModule { }