import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DigitalDocumentCategoryInputDTO } from '@app/core/models/input/digital-documents/digital-document-category.model';
import { EnvironmentService } from '@app/environment.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DigitalDocumentsCategoriesService {

  constructor(
    private http: HttpClient,
    private env: EnvironmentService,
  ) { }


  getDigitalDocumentsCategories(): Observable<DigitalDocumentCategoryInputDTO[]> {
    return this.http.get<DigitalDocumentCategoryInputDTO[]>(`${this.env.apiUrl}/dds/categories`);
  }

}
