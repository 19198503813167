<div class="wrapper">    
    <div class="list">
        <h4 class="title tipo-second-color bold" id="title_table">{{'SUPER-ADMIN.CONSENTS-BY-DOCTORS.TITLE-LIST-CONSENTS-BY-DOCTORS' | translate}}</h4>
        <table class="table" aria-describedby="title_table">
            <thead class="tipo-second-color bold">   
                <th scope="col" class="num_col">
                    <div class="column">
                        <div class="row">
                            <span>{{'SUPER-ADMIN.CONSENTS-BY-DOCTORS.DATA-NUM-COL' | translate}}</span>
                            <div class="column arrows">
                                <fa-icon [icon]="faCaretUp" class="icono fa-xl up "></fa-icon>
                                <fa-icon [icon]="faCaretDown" class="icono fa-xl down"></fa-icon>
                            </div>
                        </div>
                    
                        <input type="text" name="filterNumber" (debounceInput)="addFilter('username', filterNumber.value)" [appDebounceInput]="300" #filterNumber id="filterName" placeholder="{{'PRIVATE.PATIENT.LIST-PATIENTS.FILTER' | translate }}">
                    </div>
                </th>             
                <th scope="col" class="doctor_name">
                    <div class="column">
                        <div class="row">
                            <span>{{'SUPER-ADMIN.LIST-PATIENTS.DATA-NAME' | translate}}</span>
                            <div class="column arrows">
                                <fa-icon [icon]="faCaretUp" class="icono fa-xl up "></fa-icon>
                                <fa-icon [icon]="faCaretDown" class="icono fa-xl down"></fa-icon>
                            </div>
                        </div>
                    
                        <input type="text" name="filterName" (debounceInput)="addFilter('fullName', filterName.value)" [appDebounceInput]="300" #filterName id="filterName" placeholder="{{'PRIVATE.PATIENT.LIST-PATIENTS.FILTER' | translate }}">
                    </div>
                </th>
                <th scope="col" class="company_name">
                    <div class="column">
                        <div class="row">
                            <span>{{'SUPER-ADMIN.LIST-ADMINS.DATA-COMPANY' | translate}}</span> 
                            <div class="column arrows">
                                <fa-icon [icon]="faCaretUp" class="icono fa-xl up "></fa-icon>
                                <fa-icon [icon]="faCaretDown" class="icono fa-xl down"></fa-icon>
                            </div>
                        </div>
                        <input type="text" name="filterBusiness" (debounceInput)="addFilter('businessName', filterBusiness.value)" [appDebounceInput]="300" #filterBusiness id="filterDocument" placeholder="{{'PRIVATE.PATIENT.LIST-PATIENTS.FILTER' | translate }}">
                    </div>
                </th>
                <th scope="col" class="specialties">
                    <div class="column">                       
                        <span>{{'SUPER-ADMIN.CONSENTS-BY-DOCTORS.DATA-SPECIALTIES' | translate}}</span> 
                        <select name="specialty" id="specialty" [(ngModel)]="selectedSpecialty" (ngModelChange)="addSpecialtyFilter($event)" >
                            <option value="0"> {{ 'PRIVATE.CONSENTS.LIST-CONSENTS.OPTION-1' | translate }}</option>
                            <option *ngFor="let specialty of specialties" [value]="specialty.id">
                                {{specialty.description}}
                                </option>
                        </select>                       
                    </div>
                </th>             
                <th scope="col" class="date_on">
                    <div class="column">                    
                        <div class="row">
                            <span>{{'SUPER-ADMIN.CONSENTS-BY-DOCTORS.DATA-DATE-ON' | translate}}</span>
                            <div class="column arrows">
                                <fa-icon [icon]="faCaretUp" class="icono fa-xl up "></fa-icon>
                                <fa-icon [icon]="faCaretDown" class="icono fa-xl down"></fa-icon>
                            </div>
                        </div>
                       
                    </div>
                </th>
                <th scope="col" class="type">
                    <div class="column">
                        <span>{{'SUPER-ADMIN.LIST-CONSENTS-ADMIN.DATA-TYPE' | translate}}</span>
                        <select name="type" id="type" [(ngModel)]="selectedType" (ngModelChange)="addTypeFilter($event)">
                            <option value="0"> {{ 'PRIVATE.CONSENTS.LIST-CONSENTS.OPTION-1' | translate }}</option>
                            <option value="1">{{"SUPER-ADMIN.LIST-PROFESSIONALS.ROLE-PARTICULAR" | translate}}</option>
                            <option value="2">{{"SUPER-ADMIN.LIST-PROFESSIONALS.ROLE-COMPANY-DOCTOR" | translate}}</option>
                            <option value="7">{{"SUPER-ADMIN.LIST-PROFESSIONALS.ROLE-FREEMIUM" | translate}}</option>
                            <option value="999">{{"SUPER-ADMIN.LIST-PROFESSIONALS.ROLE-PREMIUM" | translate}}</option>
                        </select>
                        </div>
                </th>
                <th scope="col" class="state">
                    <div class="column">
                    <span>{{ 'PRIVATE.CONSENTS.LIST-CONSENTS.LABEL-STATE' | translate }}</span>
                    <select name="state" id="state" [(ngModel)]="selectedStatus" (ngModelChange)="addStatusFilter($event)" >
                        <option value="0"> {{ 'PRIVATE.CONSENTS.LIST-CONSENTS.OPTION-1' | translate }}</option>
                        <option *ngFor="let status of statuses" [value]="status.id">
                            {{status.name}}
                          </option>
                    </select>
                    </div>
                </th>
                <th scope="col" class="num_consents">{{'SUPER-ADMIN.CONSENTS-BY-DOCTORS.DATA-NUM-CIS' | translate}}</th>
            </thead>
            <tbody>                
                <tr *ngFor="let consent of consents">
                    <td class="num_col">{{consent.username}}</td>
                    <td class="doctor_name" >{{consent.fullName}}</td>
                    <td class="company_name"> {{consent.businessName}}</td>                   
                    <td class="specialties">{{consent.specialtiesNames}}</td>                 
                    <td class="date_on" >{{consent.creationDate | date : 'dd/MM/YYYY'}}</td>                   
                    <td class="type" >
                       <span *ngIf="consent.roleId === roleEnum.COMPANY_DOCTOR"> {{"SUPER-ADMIN.LIST-PROFESSIONALS.ROLE-COMPANY-DOCTOR" | translate}} </span>
                       <span *ngIf="consent.roleId === roleEnum.PARTICULAR">{{"SUPER-ADMIN.LIST-PROFESSIONALS.ROLE-PARTICULAR" | translate}}</span>
                       <span *ngIf="consent.roleId === roleEnum.FREEMIUM">{{"SUPER-ADMIN.LIST-PROFESSIONALS.ROLE-FREEMIUM" | translate}}</span>
                       <span *ngIf="consent.roleId === roleEnum.PREMIUM_DOCTOR">{{"SUPER-ADMIN.LIST-PROFESSIONALS.ROLE-PREMIUM" | translate}}</span>
                    </td>   
                    <td class="state" >                        
                        <span
                    class="green bold"
                    *ngIf="GREEN_STATUS.includes(consent.statusId ?? -1)">
                    {{ consent.statusName }}
                  </span>
                  <span
                    class="red bold"
                    *ngIf="RED_STATUS.includes(consent.statusId ?? -1)" >
                    {{ consent.statusName }}
                  </span>
                  <span
                    class="orange bold"
                    *ngIf="ORANGE_STATUS.includes(consent.statusId ?? -1)">
                    {{ consent.statusName }}
                  </span>
                    </td> 
                    <td class="num_consents" >{{consent.ciNumber}}</td> 
                </tr>
               
            </tbody>
        </table>
        <!-- Pagination -->
        <app-paginator
        [totalElements]="page?.totalElements ?? 0"  
        [numbersOfElements]="page?.numberOfElements ?? 0"
        [totalPages]="page?.totalPages ?? 0"
        [pageNumber]="pagination.page"
        [lastPage]="page?.last ?? false"
        (paginate)="paginar($event)"></app-paginator>
    <!-- End of Pagination -->
</div>    
