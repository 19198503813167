import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { COMPANY_ROLES } from '@app/core/constants/Permissions';
import { UserInput } from '@app/core/models/input/user-input.model';
import { UserService } from '@app/core/services/user/user.service';
import { faFileCirclePlus, faBackward, faUserDoctor } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-file-doctor',
  templateUrl: './file-doctor.component.html',
  styleUrls: ['./file-doctor.component.scss']
  
})

export class FileDoctorComponent  implements OnInit {
  faFileCirclePlus = faFileCirclePlus;
  faBackward = faBackward;
  faUserDoctor = faUserDoctor;

  COMPANY_ROLES = COMPANY_ROLES;

  selectedTab?: string;
  doctorUuid?:string;
  doctorname?: string;
  doctorfirstLastName?: string;
  doctorsecondLastName?: string;

  @Output() userData = new EventEmitter<UserInput>();

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private userService: UserService
  ) {
    this.activatedRoute.params.subscribe(params => {
      this.selectedTab = params['tab'];
      this.doctorUuid = params['uuid'];
    });
  }

  ngOnInit(): void {
    this.userService.getUserByUuid(this.doctorUuid ?? '').subscribe({
      next: data => {
        this.userData.emit(data);
        this.doctorname = data.name;
        this.doctorfirstLastName = data.firstLastname;
        this.doctorsecondLastName = data.secondLastname;
        
      }
    })
  }

  changeTab(tab: string) {
    // Select the tab
    this.selectedTab = tab;
    // Update the router
    this.router.navigate(['../', tab], {
      relativeTo: this.activatedRoute,
    });
  }
}
