<div class="wrapper">
    <div class="maxwidth">
        <h4 class="title tipo-second-color bold center">{{'SUPER-ADMIN.CLIENT.COMPANY-HIRING.TITLE' | translate}}</h4>
        <div>
            <app-page1-data-hiring *ngIf="showPageOne" (continue)="togglePagesOneTwo()"></app-page1-data-hiring>        
            <app-page3-data-access *ngIf="showPageTwo"  (continue)="togglePagesOneTwo()" (continue2)="togglePagesTwoThree()"></app-page3-data-access>          
            <app-page2-data-company *ngIf="showPageThree" (continue2)="togglePagesTwoThree()" (continue3)="togglePagesThreeFour()"></app-page2-data-company>
            <app-page4-data-contact *ngIf="showPageFour" (continue3)="togglePagesThreeFour()" (continue4)="togglePagesFourFive()"></app-page4-data-contact>
            <app-page5-data-rgpd *ngIf="showPageFive" (continue4)="togglePagesFourFive()" (continue5)="togglePagesFiveSix()"></app-page5-data-rgpd>
            <app-page6-data-confirm *ngIf="showPageSix" (continue5)="togglePagesFiveSix()"></app-page6-data-confirm>
        </div>
    </div>
</div>

