<div class="wrapper">
  <div class="maxwidth">
    <div class="list">
      <h4 class="title tipo-second-color bold" id="title_table">{{'SUPER-ADMIN.DOCTORS-BY-SPECIALTY.TITLE-DOCTORS-BY-SPECIALTY' | translate}}</h4>
      <table class="table" [cellSpacing]="0" aria-describedby="title_table">
        <thead class="tipo-second-color bold">
          <th scope="col" class="id"><span>{{'SUPER-ADMIN.CONSENT-BY-SPECIALTY.DATA-ID' | translate}}</span></th>
          

          <th scope="col" class="specialty">
            <div class="column">
              <div class="row">
                <span>{{'SUPER-ADMIN.LIST-CONSENTS-ADMIN.DATA-SPECIALTY' | translate}}</span>
                <div class="column arrows">
                  <fa-icon [icon]="faCaretUp" class="icono fa-xl up"></fa-icon>
                  <fa-icon [icon]="faCaretDown" class="icono fa-xl down"></fa-icon>
                </div>
              </div>            
            </div>
          </th>
          <th scope="col" class="num_doctors">
            <div class="column">
              <div class="row">
                <span>{{'SUPER-ADMIN.DOCTORS-BY-SPECIALTY.DATA-NUM-DOCTORS' | translate}}</span>
                <div class="column arrows">
                  <fa-icon [icon]="faCaretUp" class="icono fa-xl up"></fa-icon>
                  <fa-icon [icon]="faCaretDown" class="icono fa-xl down"></fa-icon>
                </div>
              </div>
            </div>
          </th>
        </thead>
        <tbody>
          <tr *ngFor="let doctor of doctors">
            <td class="id">{{doctor.idSpecialty}}</td>            
            <td class="specialty">{{doctor.specialtyName}}</td>
            <td class="num_doctors">{{doctor.count}}</td>
          </tr>
        </tbody>
      </table>
     
    </div>
  </div>
</div>
