import { Injectable } from '@angular/core';
import { CacheService } from './cache.service';
import StorageTypeEnum from '@app/shared/enums/storage-type.enum';

@Injectable({
  providedIn: 'root',
})
export class CacheImplService extends CacheService {

  public override getItem(key: string, storageType: number = StorageTypeEnum.LOCAL_STORAGE): string | null {
    return super.getItem(key, storageType);
  }

  public override setItem(key: string, data: string, storageType: number = StorageTypeEnum.LOCAL_STORAGE) {
    super.setItem(key, data, storageType);
  }

  public override getItemNotEncrypted(key: string, storageType: number = StorageTypeEnum.LOCAL_STORAGE): string | null {
    return super.getItemNotEncrypted(key, storageType);
  }

  public override setItemNotEncrypted(key: string, data: string, storageType: number = StorageTypeEnum.LOCAL_STORAGE) {
    super.setItemNotEncrypted(key, data, storageType);
  }

  public override clear() {
    super.clear();
  }

  public override removeItem(key: string, storageType: number = StorageTypeEnum.LOCAL_STORAGE) {
    super.removeItem(key, storageType);
  }

  public override getCookie(name: string): string  {
    const nameEQ = name + "=";
    const ca = document.cookie.split(';');
    for (const element of ca) {
      const c = element.trim();
      if (c.startsWith(nameEQ)) {
        const value = c.substring(nameEQ.length, c.length);       
        return value;
      }
    }   
    return '';
  }

  public override  setCookie(name: string, value: string, days: number) {
    const date = new Date();
    date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
    const expires = "expires=" + date.toUTCString();
    document.cookie = name + "=" + value + ";" + expires + ";path=/";
  }

  public override  deleteCookie(name: string) {
    document.cookie = name + '=; Max-Age=-99999999; path=/';
  }

  public override  checkCookie(name: string): boolean {
    const cookieValue = this.getCookie(name);
    const cookieExists = cookieValue !== '';     
    return cookieExists;
  }
}
