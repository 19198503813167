import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SubmitButtonService {

  private generateDocIsOnSubmitSubject: BehaviorSubject<boolean | null> = new BehaviorSubject<boolean | null>(null);
  generateDocIsOnSubmit$: Observable<boolean | null> = this.generateDocIsOnSubmitSubject.asObservable();

  setGenerateDocIsOnSubmit(submitButtonState: boolean) {
    this.generateDocIsOnSubmitSubject.next(submitButtonState);
  }

}
