import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from '@app/shared/shared.module';

import { AdministratorsModule } from './administrators/administrators.module';
import { CompanyModule } from './company/company.module';
import { ConsentsModule } from './consents/consents.module';
import { DashboardModule } from './dashboard/dashboard.module';
import { DoctorsModule } from './doctors/doctors.module';
import { PatientsModule } from './patients/patients.module';
import { PersonalizationModule } from './personalization/personalization.module';
import { PrivateRoutingModule } from './private-routing.module';
import { PrivateComponent } from './private.component';
import { FooterComponent } from './shared/components/footer/footer.component';
import { HeaderComponent } from './shared/components/header/header.component';





@NgModule({
  declarations: [
    PrivateComponent,
    HeaderComponent,
    FooterComponent,   
  ],
  imports: [
    CommonModule,
    SharedModule,
    PrivateRoutingModule,
    ReactiveFormsModule,
    DashboardModule,
    PatientsModule,
    DoctorsModule,
    AdministratorsModule,
    PersonalizationModule,
    ConsentsModule,
    CompanyModule
  ]
})
export class PrivateModule {}
