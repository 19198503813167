import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { User } from '@app/core/models/input/user.model';
import { UrlCompany } from '@app/core/models/output/logo-output.model';
import { AlertService } from '@app/core/services/alert/alert.service';
import { CompanyService } from '@app/core/services/company/company.service';
import { LoginService } from '@app/core/services/login/login.service';
import { faFileExport } from '@fortawesome/free-solid-svg-icons';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-edit-url',
  templateUrl: './edit-url.component.html',
  styleUrls: ['./edit-url.component.scss'],
})
export class EditUrlComponent implements OnInit {
  faFileExport = faFileExport;

  dataUrl!: FormGroup;
  user?: User;
  id?: string;

  enrollmentCode?: string;
  @Output() companyEvent: EventEmitter<string> = new EventEmitter();

  constructor(
    private companyService: CompanyService,
    private activedRoute: ActivatedRoute,
    private loginService: LoginService,
    private alertService: AlertService,
    private translate: TranslateService
  ) { 
    
  }

  ngOnInit(): void {   
    this.loadCompanyURL();
   
  }

  loadCompanyURL() {
    this.user = this.loginService.userValue ?? new User();
    this.id = this.user.companyUuid;
    this.dataUrl = new FormGroup({
      url: new FormControl({ value: '', disabled: false }),
    });

    if (this.id !== undefined) {
      this.companyService.getCompany(this.id).subscribe({
        next: data => {
          this.dataUrl.controls['url'].setValue(data.website);
          this.enrollmentCode = data.enrollmentCode;
          this.companyEvent.emit(this.enrollmentCode);
          
        },
      });
    }
  }
  onSubmit() {
    if (this.dataUrl.valid) {
      const urlCompany: UrlCompany = {
        website: this.dataUrl.get('url')?.value,
      };

      this.companyService
        .editUrlCompany(this.id ?? '', urlCompany)
        .subscribe(() => {
          this.alertService.success(
            this.translate.instant(
              'PRIVATE.PERSONALIZATION.EDIT-URL.MESSAGE-URL-OK'
            )
          );
        });        
    }
  }
}
