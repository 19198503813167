import { Filter } from '@app/core/models/front/filters';

export class FilterUtils {
  filters: Filter[] = [];

  constructor(filters: Filter[]) {
    this.filters = filters;
  }

  addFilter(name: string, filter: string) {
    // Check if a filter with the same name (property) already exists
    const existingFilter = this.filters.find(
      filter => filter.property === name
    );
    // If a filter with the same name exists, update its value (filter)
    if (existingFilter) {
      existingFilter.filter = filter;
    } else {
      // If no filter with the same name exists, add a new one to the array
      const newFilter = new Filter(name, filter);
      this.filters.push(newFilter);
    }
  }

  removeFilter(name: string) {
    // Find the index of the filter with the given name (property)
    const filterIndex = this.filters.findIndex(filter => filter.property === name);

    // If a filter with the given name is found, remove it from the array
    if (filterIndex !== -1) {
      this.filters.splice(filterIndex, 1);
    }
  }
}
