
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { IEnvironment } from 'src/environments/environment.interface';

@Injectable({
  providedIn: 'root'
})
export class EnvironmentService implements IEnvironment {
  get production() {
    return environment.production;
  }

  get enableDebugTools() {
    return environment.enableDebugTools;
  }

  get logLevel() {
    return environment.logLevel;
  }

  get apiHost() {
    return environment.apiHost;
  }

  get apiUrlWs() {
    return environment.apiUrlWs;
  }

  get apiUrl() {
    return environment.apiUrl;
  }

  get apiConsents() {
    return environment.apiConsents;
  }

  get defaultLanguage() {
    return environment.defaultLanguage;
  }

  get localStorageSecretKey() {
    return environment.localStorageSecretKey;
  }

  get doNotEncryptValues() {
    return environment.doNotEncryptValues;
  }

  get turnstileSiteKey() {
    return environment.turnstileSiteKey;
  }

  get urlCredential() {
    return environment.urlCredential;
  }

}
