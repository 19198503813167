import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { DoctorsAmounts } from '@app/core/models/input/company/company-parameters.model';
import { CompanyDataAdmin, CompanyVidSignerCredential, RepresentativeCompany } from '@app/core/models/input/company/company.model';
import { CompanyService } from '@app/core/services/company/company.service';

@Component({
  selector: 'app-file-company-admin',
  templateUrl: './file-company-admin.component.html',
  styleUrls: ['./file-company-admin.component.scss']
})
export class FileCompanyAdminComponent implements OnInit{

  companyUuid?: string;
  companyData?: CompanyDataAdmin;
  companyDataVidSigner?:CompanyVidSignerCredential;
  doctorsAmount?:DoctorsAmounts;
  representativeCompany?:RepresentativeCompany;
  totalUsers?: number;
  totalCompanies?: number;
  his?:string;
  
  constructor(
    private activatedRoute: ActivatedRoute,
    private companyService:CompanyService
 
  ) {
    this.activatedRoute.params.subscribe(params => {      
      this.companyUuid = params['companyUuid'];      
    });
  }

  ngOnInit(): void {
    this.getCompanyDataAdmin();   
  }

  getCompanyDataAdmin() {     
    if(this.companyUuid !== undefined){      
      this.companyService.getCompanyParameters(this.companyUuid).subscribe({
        next: data => {
          this.companyData = data;
          this.companyDataVidSigner = data.companyVidSignerCredential;
          this.representativeCompany = data.representativeCompany;
          this.totalUsers = data.users;
          this.totalCompanies = data.companies;
          this.doctorsAmount = data.companyAdditionalData?.doctorsAmount;
          this.his = data.companyAdditionalData?.his                 
        }
      })
    }  
 }
}
