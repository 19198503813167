import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { COMPANY_ROLES, VIEW_CIS } from '@app/core/constants/Permissions';
import { AuthenticationGuard } from '@app/core/guards/elci/authentication.guard';
import { FileAdministratorComponent } from './administrators/pages/file-administrator/file-administrator.component';
import { ListAdministratorsComponent } from './administrators/pages/list-administrators/list-administrators.component';
import { NewAdministratorComponent } from './administrators/pages/new-administrator/new-administrator.component';
import { FileCompanyComponent } from './company/pages/file-company/file-company.component';
import { EditConsentComponent } from './consents/pages/edit-consent/edit-consent.component';
import { ListConsentsComponent } from './consents/pages/list-consents/list-consents.component';
import { NewConsentComponent } from './consents/pages/new-consent/new-consent.component';
import { FileDoctorComponent } from './doctors/pages/file-doctor/file-doctor.component';
import { ListDoctorsComponent } from './doctors/pages/list-doctors/list-doctors.component';
import { NewDoctorComponent } from './doctors/pages/new-doctor/new-doctor.component';
import { FilePatientComponent } from './patients/pages/file-patient/file-patient.component';
import { GenDdsComponent } from './patients/pages/gen-dds/gen-dds.component';
import { GenDocsQuickComponent } from './patients/pages/gen-docs-quick/gen-docs-quick.component';
import { GenDocsComponent } from './patients/pages/gen-docs/gen-docs.component';
import { ListPatientsComponent } from './patients/pages/list-patients/list-patients.component';
import { NewPatientComponent } from './patients/pages/new-patient/new-patient.component';
import { ConfigurationComponent } from './personalization/pages/configuration/configuration.component';
import { DeviceDocumentsComponent } from './personalization/pages/device-documents/device-documents.component';
import { PrivateComponent } from './private.component';
import { MessageDocEndedComponent } from './shared/components/message-doc-ended/message-doc-ended.component';
import { OverviewComponent } from './dashboard/pages/overview/overview.component';


const routes: Routes = [
  {
    path: '',
    component: PrivateComponent,
    canActivate: [AuthenticationGuard],
    children: [
      {
        path: '',
        loadChildren: () =>
          import('./dashboard/dashboard.module').then(m => m.DashboardModule),
        component: OverviewComponent,
        canActivate: [AuthenticationGuard],
      },
      {
        path: 'patients',
        canActivate: [AuthenticationGuard],
        children: [
          {
            path: '',
            loadChildren: () =>
              import('./patients/patients.module').then(m => m.PatientsModule),
            component: ListPatientsComponent,
            canActivate: [AuthenticationGuard],
          },
          {
            path: 'new',
            loadChildren: () =>
              import('./patients/patients.module').then(m => m.PatientsModule),
            component: NewPatientComponent,
            canActivate: [AuthenticationGuard],
          },
          {
            path: ':uuid',
            canActivate: [AuthenticationGuard],
            children: [
              {
                path: ':tab',
                canActivate: [AuthenticationGuard],
                children: [
                  {
                    path: '',
                    loadChildren: () =>
                      import('./patients/patients.module').then(
                        m => m.PatientsModule
                      ),
                    component: FilePatientComponent,
                    canActivate: [AuthenticationGuard],
                  },
                  {
                    path: 'generate-gdpr',
                    loadChildren: () =>
                      import('./patients/patients.module').then(
                        m => m.PatientsModule
                      ),
                    component: GenDocsComponent,
                    canActivate: [AuthenticationGuard],
                  },
                  {
                    path: 'generate-dd',
                    canActivate: [AuthenticationGuard],
                    children: [
                      {
                        path: ':template-uuid',
                        loadChildren: () =>
                          import('./patients/patients.module').then(
                            m => m.PatientsModule
                          ),
                        canActivate: [AuthenticationGuard],
                        component: GenDdsComponent,
                      }
                    ]
                  },
                  {
                    path: 'generate-consent',
                    loadChildren: () =>
                    import('./consents/consents.module').then(m => m.ConsentsModule),
                    component: NewConsentComponent,
                    canActivate: [AuthenticationGuard],
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        path: 'consents',
        canActivate: [AuthenticationGuard],
        data: { roles: VIEW_CIS },
        children: [
          {
            path: 'new',
            loadChildren: () =>
              import('./consents/consents.module').then(m => m.ConsentsModule),
            component: NewConsentComponent,
            canActivate: [AuthenticationGuard],
          },
          {
            path: '',
            loadChildren: () =>
              import('./consents/consents.module').then(m => m.ConsentsModule),
            component: ListConsentsComponent,
            canActivate: [AuthenticationGuard],
          },
          {
            path: ':consentUuid',
            canActivate: [AuthenticationGuard],
            children: [
              {
                path: '',
                loadChildren: () =>
                  import('./consents/consents.module').then(
                    m => m.ConsentsModule
                  ),
                component: EditConsentComponent,
                canActivate: [AuthenticationGuard],
              },
              {
                path: 'edit',
                loadChildren: () =>
                  import('./consents/consents.module').then(m => m.ConsentsModule),
                component: NewConsentComponent,
                canActivate: [AuthenticationGuard],
              },
            ],
          },
        ],
      },
      {
        path: 'professionals',
        canActivate: [AuthenticationGuard],
        children: [
          {
            path: 'new',
            loadChildren: () =>
              import('./doctors/doctors.module').then(m => m.DoctorsModule),
            component: NewDoctorComponent,
            canActivate: [AuthenticationGuard],
          },
          {
            path: '',
            loadChildren: () =>
              import('./doctors/doctors.module').then(m => m.DoctorsModule),
            component: ListDoctorsComponent,
            canActivate: [AuthenticationGuard],
          },
          {
            path: ':uuid',
            canActivate: [AuthenticationGuard],
            children: [
              {
                path: ':tab',
                canActivate: [AuthenticationGuard],
                children: [
                  {
                    path: '',
                    loadChildren: () =>
                      import('./doctors/doctors.module').then(
                        m => m.DoctorsModule
                      ),
                    component: FileDoctorComponent,
                    canActivate: [AuthenticationGuard],
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        path: 'administrators',
        canActivate: [AuthenticationGuard],
        data: { roles: COMPANY_ROLES },
        children: [
          {
            path: 'new',
            loadChildren: () =>
              import('./administrators/administrators.module').then(
                m => m.AdministratorsModule
              ),
            component: NewAdministratorComponent,
            canActivate: [AuthenticationGuard],
          },
          {
            path: '',
            loadChildren: () =>
              import('./administrators/administrators.module').then(
                m => m.AdministratorsModule
              ),
            component: ListAdministratorsComponent,
            canActivate: [AuthenticationGuard],
          },
          {
            path: ':uuid',
            canActivate: [AuthenticationGuard],
            children: [
              {
                path: '',
                loadChildren: () =>
                  import('./administrators/administrators.module').then(
                    m => m.AdministratorsModule
                  ),
                component: FileAdministratorComponent,
                canActivate: [AuthenticationGuard],
              },
            ],
          },
        ],
      },
      {
        path: 'configuration',
        canActivate: [AuthenticationGuard],
        children: [
          {
            path: 'preferences',
            loadChildren: () =>
              import('./personalization/personalization.module').then(
                m => m.PersonalizationModule
              ),
            component: ConfigurationComponent,
            canActivate: [AuthenticationGuard],
          },
          {
            path: 'preferences/:tab',
            loadChildren: () =>
              import('./personalization/personalization.module').then(
                m => m.PersonalizationModule
              ),
            component: ConfigurationComponent,
            canActivate: [AuthenticationGuard],
          },
          {
            path: 'device/:deviceName/documents',
            loadChildren: () =>
              import('./personalization/personalization.module').then(
                m => m.PersonalizationModule
              ),
            component: DeviceDocumentsComponent,
            canActivate: [AuthenticationGuard],
          },
          {
            path: 'companies/:companyUuid',
            loadChildren: () =>
              import('./personalization/personalization.module').then(
                m => m.PersonalizationModule
              ),
            component: FileCompanyComponent,
            canActivate: [AuthenticationGuard],
          },
        ],
      },
      {
        path: 'quick-api',
        canActivate: [AuthenticationGuard],
        children: [
          {
            path: 'patients/:uuid/generate-consent',
            loadChildren: () =>
            import('./consents/consents.module').then(m => m.ConsentsModule),
            component: NewConsentComponent,
            canActivate: [AuthenticationGuard],
          },
          {
            path: 'patients/:consentUuid/resend-consent',
            loadChildren: () =>
            import('./consents/consents.module').then(m => m.ConsentsModule),
            component: EditConsentComponent,
            canActivate: [AuthenticationGuard],
          },
          {
            path: 'patients/:consentUuid/ratify-consent',
            loadChildren: () =>
            import('./consents/consents.module').then(m => m.ConsentsModule),
            component: EditConsentComponent,
            canActivate: [AuthenticationGuard],
          },
          {
            path: 'patients/:uuid/generate-gdpr',
            loadChildren: () =>
            import('./patients/patients.module').then(m => m.PatientsModule),
            component: GenDocsComponent,
            canActivate: [AuthenticationGuard],
          },
          {
            path: 'patients/:uuid/dds',
            component: GenDocsQuickComponent,
            canActivate: [AuthenticationGuard],
            loadChildren: () =>
            import('./patients/patients.module').then(m => m.PatientsModule),
          },
          {
            path: 'patients/:uuid/dds/generate-dd',
            canActivate: [AuthenticationGuard],
            children: [
              {
                path: ':template-uuid',
                loadChildren: () =>
                  import('./patients/patients.module').then(
                    m => m.PatientsModule
                  ),
                canActivate: [AuthenticationGuard],
                component: GenDdsComponent,
              }
            ]
          },
          {
            path: 'patients/doc-ok',
            component: MessageDocEndedComponent,
            canActivate: [AuthenticationGuard],
          },
        ],
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class PrivateRoutingModule {}
