import { Component } from '@angular/core';
import { faBackward } from '@fortawesome/free-solid-svg-icons';
@Component({
  selector: 'app-new-doctor',
  templateUrl: './new-doctor.component.html',
  styleUrls: ['./new-doctor.component.scss']
})
export class NewDoctorComponent {
  faBackward = faBackward;
}
