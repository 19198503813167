import { Patient } from '../models/input/patient/patient.model';
import { RepresentativePatientDTO } from '../models/input/patient/representative.model';

export default class PatientUtils {
  static numberOfRepresentativesActive(patient: Patient): number {
    if (patient.representativePatients) {
      return patient.representativePatients.filter(rep => rep.active).length;
    } else {
      return 0;
    }
  }

  // Returns the main representative (first)
  static firstRepresentative(patient: Patient | undefined): RepresentativePatientDTO | null {
    if (patient?.representativePatients) {
      return patient.representativePatients.filter((rep) => rep.isMainRepresentativePatient)[0];
    } else {
      return null;
    }
  }

    // Returns the second representative
    static secondRepresentative(patient: Patient | undefined): RepresentativePatientDTO | null {
        if (patient?.representativePatients) {
          return patient.representativePatients.filter((rep) => !rep.isMainRepresentativePatient)[0];
        } else {
          return null;
        }
      }
}
