<div class="alert-container">
  <div *ngFor="let alert of alerts" class="{{ cssClass(alert) }}">
    <span [innerHTML]="alert.message"></span>
    <div class="buttons">
      <button class="button tipo-white-color back-blue-color" (click)="removeAlert(alert)">
        {{ 'PRIVATE.PATIENT.FILE-PATIENT.BUTTON-UNDERSTOOD' | translate }}
      </button>
    </div>
  </div>
</div>
