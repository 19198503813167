
    <h5 class="title tipo-second-color bold">{{'PRIVATE.DASHBOARD.CONSENTS.MORE-USED.TITLE' | translate}}</h5>
    <div class="item" *ngFor="let item of topConsents; let i=index">
        <p class="number">{{item.amount}}</p>
        <div class="consents">
            <p class="name_consent tipo-second-color">{{item.procedureName}}</p>
            <p class="specialty tipo-main-color">{{item.specialtyName}}</p>
        </div>
    </div>
 


