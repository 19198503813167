import { Injectable } from '@angular/core';
import { AlertOptions, AlertType } from '@app/core/models/alert';
import { Client, IMessage, Stomp } from '@stomp/stompjs';
import { BehaviorSubject } from 'rxjs';
import * as SockJS from 'sockjs-client';
import { EnvironmentService } from '../../../environment.service';
import { ElciNotification } from '../../models/front/elci-notification.model';
import { AlertService } from '../alert/alert.service';


@Injectable({
  providedIn: 'root'
})
export class NotificationService {

  private stompClient!: Client;
  private messageSubject: BehaviorSubject<ElciNotification[]> = new BehaviorSubject<ElciNotification[]>([]);
  private defaultAlertOptionsError = new AlertOptions();

  constructor(
    private alertService: AlertService,
    private env: EnvironmentService
  ) {
    this.configureAlertOptions();
  }

  initConnectionSocket() {
    this.stompClient = Stomp.over(() => new SockJS(this.env.apiUrlWs));
    this.stompClient.configure({
      reconnectDelay: 10000,
      debug: () => {
        // Has to be empty for no logging, put a console for debug
      }
    });
  }

  joinUserQueue(userUuid: string) {
    this.initConnectionSocket();

    this.stompClient.activate();
    this.stompClient.onConnect = () => {
        this.stompClient.subscribe(`/queue/notifications-${userUuid}`, (message: IMessage) => {
            try {
                const notification: ElciNotification = JSON.parse(message.body);
                const currentMessages = this.messageSubject.getValue();
                currentMessages.push(notification);
                this.handleNotification(notification);
                this.messageSubject.next(currentMessages);
            } catch (e) {
                console.error('Error parsing notification message:', e);
            }
        });
    };

    this.stompClient.onStompError = (frame) => {
        console.error('Broker reported error: ' + frame.headers['message']);
        console.error('Additional details: ' + frame.body);
    };

    this.stompClient.onWebSocketError = (event) => {
        console.error('WebSocket error', event);
    };

    this.stompClient.onWebSocketClose = (event) => {
        console.error('WebSocket closed', event);
    };
}

  private configureAlertOptions(): void {
    this.defaultAlertOptionsError.autoClose = false;
    this.defaultAlertOptionsError.keepAfterRouteChange = true;
  }

  getMessageSubject() {
    return this.messageSubject.asObservable();
  }

  private handleNotification(notification: ElciNotification) {
    switch (notification.alertTypeId) {
        case AlertType.Success:
            this.alertService.success(notification.message ?? '', this.defaultAlertOptionsError);
            break;
        case AlertType.Error:
            this.alertService.error(notification.message ?? '', this.defaultAlertOptionsError);
            break;
        case AlertType.Info:
            this.alertService.info(notification.message ?? '', this.defaultAlertOptionsError);
            break;
        case AlertType.Warning:
            this.alertService.warn(notification.message ?? '', this.defaultAlertOptionsError);
            break;
        default:
            console.error('Unknown alert type:', notification.alertTypeId);
    }
}

  disconnect() {
    if (this.stompClient?.active) {
      this.stompClient.deactivate().then(() => {
        console.info('Disconnected from WebSocket');
      }).catch((error) => {
        console.error('Error during disconnection', error);
      });
    }
  }
}