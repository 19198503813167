<p class="regular small">{{ "PRIVATE.PERSONALIZATION.DEVICES.TITLE-ONE" | translate }} <span class="bold">{{enrollmentCode}}</span></p>
<p class="regular small">{{ "PRIVATE.PERSONALIZATION.DEVICES.TITLE-TWO" | translate }} </p>
<p class="regular small">{{ "PRIVATE.PERSONALIZATION.DEVICES.TITLE-THREE" | translate }}<span class="bold">911 599 997</span> | email:  <span class="bold">admin@elconsentimiento.es</span></p>
<div class="devices">
    <div class="device back-light-blue-color" *ngFor= "let device of devices; let i=index">
        <div class="tooltip">
             <fa-icon [icon]="faLinkSlash" class="icono tipo-second-color icono_des" (click)="openModal(device.deviceName)"></fa-icon>
             <p class="text_tip small">{{ "PRIVATE.PERSONALIZATION.DEVICES.TOOL-TIP" | translate }}</p>
        </div>
        <img src="../../../assets/img/private/device.png" alt="Dispositivo">
        <p class="name_device bold tipo-second-color">{{device.deviceDescription}}</p>
        <p class="id_device small tipo-second-color">{{device.deviceName}}</p>
        <button class="button back-white-color tipo-second-color" [routerLink]="['/portal/configuration/device/' + device.deviceName + '/documents']">
            <fa-icon [icon]="faEye" class="icono"></fa-icon>
            {{ "PRIVATE.PERSONALIZATION.DEVICES.TITLE-BUTTON" | translate }}
        </button>

    </div>
      <!-- pop up para desvincular dispositivo -->
      <div class="modal_desactive_device" *ngIf="open">
        <h4 class="title tipo-second-color bold">{{ "PRIVATE.PERSONALIZATION.DEVICES.TITLE-MODAL" | translate }}</h4>
        <p class="regular">{{ "PRIVATE.PERSONALIZATION.DEVICES.SUBTITLE-MODAL" | translate }}</p>
        <div class="buttons">
            <button class="button tipo-white-color back-blue-color" (click)="deleteDevice()">
                <fa-icon [icon]="faLinkSlash" class="icono iconono"></fa-icon>
                {{ "PRIVATE.PERSONALIZATION.DEVICES.TOOL-TIP" | translate }}
            </button>
            <button class="button tipo-main-color back-grey-color"  (click)="openModal()" >
                {{ "PRIVATE.PATIENT.DATA-PATIENT.BUTTON-CANCEL" | translate }}
            </button>
        </div>
    </div>
</div>