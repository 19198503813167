import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DoctorsBySpecialtyComponent } from './pages/doctors-by-specialty/doctors-by-specialty.component';
import { ConsentsBySpecialtyComponent } from './pages/consents-by-specialty/consents-by-specialty.component';
import { ConsentsMostUsedComponent } from './pages/consents-most-used/consents-most-used.component';
import { RgpdsByCompanyComponent } from './pages/rgpds-by-company/rgpds-by-company.component';
import { DdsByCompanyComponent } from './pages/dds-by-company/dds-by-company.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { SharedModule } from '@app/shared/shared.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TotalConsentsByDoctorsComponent } from './pages/total-consents-by-doctors/total-consents-by-doctors.component';
import { TotalSignsByCompanyComponent } from './pages/total-signs-by-company/total-signs-by-company.component';
import { TotalApiDocumentsByCompanyComponent } from './pages/total-api-documents-by-company/total-api-documents-by-company.component';



@NgModule({
  declarations: [
    DoctorsBySpecialtyComponent,
    TotalConsentsByDoctorsComponent,
    ConsentsBySpecialtyComponent,
    ConsentsMostUsedComponent,
    RgpdsByCompanyComponent,
    DdsByCompanyComponent,
    TotalSignsByCompanyComponent,
    TotalApiDocumentsByCompanyComponent
  ],
  imports: [
    CommonModule,
    FontAwesomeModule,
    SharedModule,
    ReactiveFormsModule,
    FormsModule
  ]
})
export class StatisticsModule { }
