<div class="wrapper">
    <div class="cabecera">
        <img class="movil" src="../../../assets/img/quees/cabecera-movil.jpg" alt="Qué es el Consentimiento.es">
        <img class="desktop" src="../../../assets/img/quees/cabecera.jpg" alt="Qué es el Consentimiento.es">
        <h1 class="titulo_seccion tipo-second-color bold">Qué es</h1>
    </div>
    <div class="maxwidth">
        <div class="contenido">
            <div class="cabecera_contenido">
                <img class="desktop" src="../../../assets/img/quees/doctor-quees.png" alt="El consentimiento informado es una solución digital">
                <div class="intro tipo-main-color regular">
                    <p class="contenido_p">El <span class="bold">Consentimiento informado</span> es una solución digital que garantiza a los profesionales de la salud disponer de los documentos informativos específicos de cada procedimiento, listos para presentar a sus pacientes. Cada documento contiene la información precisa y sencilla que los pacientes demandan, y se les puede añadir las circunstancias específicas que hacen de cada intervención un caso único.</p>
                    <p class="contenido_p">Y además...</p>
                </div>
            </div>
            <div class="fichas"  >
                <div class="ficha tipo-second-color back-light-blue-color center" *ngFor = 'let item of ficha; let i=index' >
                    <span class="icono_sombra"><fa-icon [icon]="item.icono" class="icono"></fa-icon></span>
                    <span class="big_number bold">{{item.number}}</span>
                    <h3 class="titulo_ficha bold">{{item.titulo}}</h3>
                    <p class="texto_ficha light">{{item.subtitulo}}</p>
                </div>
            </div>
        </div>
    </div>    
    <div class="pie">
        <img class="movil" src="../../../assets/img/quees/footer-movil.jpg" alt="Qué es el Consentimiento.es">
        <img class="desktop" src="../../../assets/img/quees/footer.jpg" alt="Qué es el Consentimiento.es">
    </div>
</div>
