import { Component } from '@angular/core';
import { faStethoscope, faBriefcase, faScaleBalanced, faLaptop  } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-quienessomos',
  templateUrl: './quienessomos.component.html',
  styleUrls: ['./quienessomos.component.scss']
})
export class QuienessomosComponent {
  faStethoscope = faStethoscope;
  faBriefcase = faBriefcase;
  faScaleBalanced = faScaleBalanced;
  faLaptop = faLaptop;

}
