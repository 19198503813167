import { Status } from "../input/common/status.model";
import { UserDevice } from "../input/device.model";
import { Specialty } from "../input/specialty.model";
import { State } from "../input/state.model";
import { UserAdditionalData } from "../input/user-additional-data.model";



export class UserOutput {
  uuid?: string;
  name?: string;
  firstLastname?: string;
  secondLastname?: string;
  dniCif?: string;
  username?: string;
  userDevice?: UserDevice;
  email?: string;
  mobile?: string;
  isActive?: boolean; 
  stateId?: number;
  userStatus?: Status;
  birthDate?: Date;
  rolesIds?: number[];
  specialtiesIds?: number[];
  userAdditionalData?: UserAdditionalData;
  creationDate?: Date;
  password?: string;
  address?: string;
  locality?: string;
  postCode?: string;
  phone?: string;
  state?: State;
  specialty?: Specialty[];
  lastAccessDate?:Date;
  cancelDate?: Date;
  generateSSICredential?: boolean;
}