
    <div class="list">
        <h4 class="title tipo-second-color bold" id="title_table">{{'SUPER-ADMIN.LIST-PROFESSIONALS.TITLE-PROFESSIONALS' | translate}}</h4>
        <table class="table" aria-describedby="title_table" >
            <thead class="tipo-second-color bold">
                <th scope="col" class="id">{{'SUPER-ADMIN.LIST-COMPANY.ID' | translate}}</th>
                <th scope="col" class="company_name">
                    <div class="column">
                        {{'SUPER-ADMIN.LIST-ADMINS.DATA-COMPANY' | translate}} 
                        <input type="text" name="filterBusiness" (debounceInput)="addFilter('businessName', filterBusiness.value)" [appDebounceInput]="300" #filterBusiness id="filterBusiness" placeholder="{{'PRIVATE.PATIENT.LIST-PATIENTS.FILTER' | translate }}">              
                    </div>
                </th>
                <th scope="col" class="user_name">
                    <div class="column">
                        <span>{{'PRIVATE.DOCTOR.DATA-DOCTOR.NAME' | translate}}</span>
                        <input type="text" name="filterName" (debounceInput)="addFilter('name', filterName.value)" [appDebounceInput]="300" #filterName id="filterName" placeholder="{{'PRIVATE.PATIENT.LIST-PATIENTS.FILTER' | translate }}">
                    </div>
                </th>
                <th scope="col" class="document">
                    <div class="column">
                        <div class="row">
                            <span>{{'PRIVATE.DOCTOR.DATA-DOCTOR.DOCUMENT' | translate}}</span> 
                            <div class="column arrows">
                                <fa-icon [icon]="faCaretUp" class="icono fa-xl up" (click)="sortProfessionals('dniCif', 'asc')"></fa-icon>
                                <fa-icon [icon]="faCaretDown" class="icono fa-xl down" (click)="sortProfessionals('dniCif', 'desc')"></fa-icon>
                            </div>
                        </div>
                        <input type="text" name="filterDocument" (debounceInput)="addFilter('dniCif', filterDocument.value)" [appDebounceInput]="300" #filterDocument id="filterDocument" placeholder="{{'PRIVATE.PATIENT.LIST-PATIENTS.FILTER' | translate }}">
                    </div>
                </th>
                <th scope="col" class="type"><span>{{'SUPER-ADMIN.LIST-COMPANY.TYPE' | translate}}</span> </th>
                <th scope="col" class="num_col"><span>{{'PRIVATE.DOCTOR.DATA-DOCTOR.NUM-COL' | translate}}</span></th>                
                <th scope="col" class="login"><span>{{'SUPER-ADMIN.LIST-COMPANY.LOGIN' | translate}}</span></th>
                <th scope="col" class="date_on">
                    <div class="column">                    
                        <div class="row">
                            <span>{{ "PRIVATE.PATIENT.LIST-PATIENTS.TITLE-DATE" | translate}}</span>
                            <div class="column arrows">
                                <fa-icon [icon]="faCaretUp" class="icono fa-xl up" (click)="sortProfessionals('creationDate', 'asc')"></fa-icon>
                                <fa-icon [icon]="faCaretDown" class="icono fa-xl down" (click)="sortProfessionals('creationDate', 'desc')"></fa-icon>
                            </div>
                        </div>
                    </div>
                </th>
                <th scope="col" class="date_off"><span>{{'SUPER-ADMIN.LIST-COMPANY.DATE-OFF' | translate}}</span></th>
                <th scope="col" class="state">
                    <div class="column">
                    <span>{{ 'PRIVATE.CONSENTS.LIST-CONSENTS.LABEL-STATE' | translate }}</span>
                    <select name="state" id="state" [(ngModel)]="selectedStatus" (ngModelChange)="addStatusFilter($event)" >
                        <option value="0"> {{ 'PRIVATE.CONSENTS.LIST-CONSENTS.OPTION-1' | translate }}</option>
                        <option *ngFor="let status of statuses" [value]="status.id">
                            {{status.name}}
                          </option>
                    </select>
                    </div>
                </th>
            </thead>
            <tbody>
                <tr [routerLink]="['/super-admin/users/professionals/'+ doctor.companyUuid + '/file-doctor/' + doctor.uuid +'/file' ]" *ngFor="let doctor of doctors">
                <td class="id">{{doctor.id}}</td>
                <td class="company_name">{{doctor.company}}</td>
                <td class="user_name"> {{doctor.name}} {{doctor.lastname}}</td>
                <td class="document" >{{doctor.dniCif}}</td>
                <!-- TODO feature_wid_1197452518  Traducciones en tablas de type en el back -->
                <td class="type" *ngFor="let rol of doctor.roles">                    
                    <span *ngIf="rol.id === roleEnum.COMPANY_DOCTOR">{{"SUPER-ADMIN.LIST-PROFESSIONALS.ROLE-COMPANY-DOCTOR" | translate}}</span> 
                    <span *ngIf="rol.id === roleEnum.PARTICULAR">{{"SUPER-ADMIN.LIST-PROFESSIONALS.ROLE-PARTICULAR" | translate}}</span>   
                    <span *ngIf="rol.id === roleEnum.FREEMIUM">{{"SUPER-ADMIN.LIST-PROFESSIONALS.ROLE-FREEMIUM" | translate}}</span>
                    <span *ngIf="rol.id === roleEnum.PREMIUM_DOCTOR">{{"SUPER-ADMIN.LIST-PROFESSIONALS.ROLE-PREMIUM" | translate}}</span>
                </td>
                <td class="num_col" >{{doctor.userName}}</td>               
                <td class="login" >{{doctor.userName}}</td>
                <td class="date_on" >{{doctor.creationDate}}</td>
                <td class="date_off" >{{doctor.cancelDate}}</td>
                <td class="state" ><span
                    class="green bold"
                    *ngIf="GREEN_STATUS.includes(doctor.userStatus?.id ?? -1)">
                    {{ doctor.userStatus?.name }}
                  </span>
                  <span
                    class="red bold"
                    *ngIf="RED_STATUS.includes(doctor.userStatus?.id ?? -1)" >
                    {{ doctor.userStatus?.name }}
                  </span>
                  <span
                    class="orange bold"
                    *ngIf="ORANGE_STATUS.includes(doctor.userStatus?.id ?? -1)">
                    {{ doctor.userStatus?.name }}
                  </span></td> 
                </tr>              
                
            </tbody>
        </table>
        <!-- Pagination -->
        <app-paginator
        [totalElements]="page?.totalElements ?? 0"  
        [numbersOfElements]="page?.numberOfElements ?? 0"
        [totalPages]="page?.totalPages ?? 0"
        [pageNumber]="pagination.page"
        [lastPage]="page?.last ?? false"
        (paginate)="paginar($event)"></app-paginator>
    <!-- End of Pagination -->
 
