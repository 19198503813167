import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { MIN_PASSWORD_LENGTH } from '@app/core/constants/Constants';
import { ChangePasswordOutputDTO } from '@app/core/models/output/change-password-output-DTO';
import { AlertService } from '@app/core/services/alert/alert.service';
import { TranslateService } from '@ngx-translate/core';
import { LoginService } from '../../../core/services/login/login.service';
import { CacheImplService } from '../../../shared/services/cache/cache-impl.service';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss'],
})
export class ResetPasswordComponent implements OnInit {
  MIN_PASSWORD_LENGTH = MIN_PASSWORD_LENGTH;

  token?: string;
  unauthorized = false;
  serverError = false;
  coincide = true;
  hide = true;
  passwordForm;

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private loginService: LoginService,
    private alertService: AlertService,
    private cacheImplService: CacheImplService,
    private translate: TranslateService
  ) {
    this.passwordForm = new FormGroup({
      password: new FormControl('', [
        Validators.required,
        Validators.minLength(MIN_PASSWORD_LENGTH),
      ]),
      rePassword: new FormControl('', [
        Validators.required,
        Validators.minLength(MIN_PASSWORD_LENGTH),
      ]),
    });
  }

  ngOnInit(): void {
    localStorage.clear();
    this.token = this.activatedRoute.snapshot.queryParamMap.get('token') ?? '';
    if (!this.token || this.token === '') {
      this.alertService.error(this.translate.instant('ERRORS.TOKEN-EXPIRED'));
    }

    this.loginService.loginGuest(this.token).subscribe(data => {
      if (data.status == 200) {
        const token = data.headers.get('authorization') ?? '';
        const onlyToken = token != '' ? token.split(/(\s+)/)[2] : ''; // Remove "Bearer" before token
        this.cacheImplService.setItem('token', onlyToken);
      } else {
        this.router.navigate(['/login']);
      }
    });
  }

  onSubmit() {
    if (this.passwordForm.valid) {
      if (
        this.passwordForm.controls.password.value ==
        this.passwordForm.controls.rePassword.value
      ) {
        this.coincide = true;

        const newPasswordModel = new ChangePasswordOutputDTO(
          this.passwordForm.controls.password.value ?? '',
          this.passwordForm.controls.rePassword.value ?? '_'
        );

        this.loginService.forceChangePassword(newPasswordModel).subscribe({
          next: response => {
            if (response.status == 200) {
              this.alertService.info(
                this.translate.instant('INFOS.PASSWORD-CHANGED')
              );
              this.router.navigate(['/login']);
            }
          },
        });
      } else {
        this.coincide = false;
      }
    }
  }
}
