import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SharedModule } from '@app/shared/shared.module';
import { CiSixMonthsComponent } from './components/ci-six-months/ci-six-months.component';
import { ConsentsDoctorComponent } from './components/consents-doctor/consents-doctor.component';
import { ConsentsMostUsedComponent } from './components/consents-most-used/consents-most-used.component';
import { ConsentsStateComponent } from './components/consents-state/consents-state.component';
import { MyDocumentsComponent } from './components/my-documents/my-documents.component';
import { NewsComponent } from './components/news/news.component';
import { PatientsComponent } from './components/patients/patients.component';
import { OverviewComponent } from './pages/overview/overview.component';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { ReactiveFormsModule } from '@angular/forms';



@NgModule({
  declarations: [
    OverviewComponent,
    ConsentsStateComponent,
    ConsentsMostUsedComponent,
    PatientsComponent,
    ConsentsDoctorComponent,
    MyDocumentsComponent,
    CiSixMonthsComponent,
    NewsComponent,
  ],
  imports: [
    CommonModule,
    SharedModule, 
    NgxChartsModule, 
    ReactiveFormsModule 
  ],
})
export class DashboardModule {}
