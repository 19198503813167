import { Component, ViewEncapsulation, OnInit } from '@angular/core';
import { RoleService } from '@app/core/services/role/role.service';

@Component({
  selector: 'app-private',
  templateUrl: './private.component.html',
  styleUrls: ['./private.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class PrivateComponent  implements OnInit{

  
  constructor (
    public roleService: RoleService
  )  {}

  ngOnInit(): void {   
   this.roleService.isQuickapi();   
  }
}
