import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from '@app/shared/shared.module';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { FileCompanyComponent } from './pages/file-company/file-company.component';
import { DataCompanyComponent } from './components/data-company/data-company.component';


@NgModule({

  declarations: [
  
    FileCompanyComponent,
       DataCompanyComponent
  ],
  imports: [
    CommonModule,
    FontAwesomeModule,
    SharedModule,
    ReactiveFormsModule,
    FormsModule,
  ],
  exports: [
    FileCompanyComponent
  ]
})
export class CompanyModule {}
