<div
  class="legalrep-signs"
  *ngIf="
    representatives &&
    isSecondRepresentativeActive &&
    representatives?.length === 2
  ">
  <p class="subtitle">
    {{ 'PRIVATE.CONSENTS.LEGALREP-SIGNS.TITLE' | translate }}
  </p>
  <div class="sign_type">
    <button
      class="button tipo-white-color back-blue-color"
      [ngClass]="{
        selected:
          digitalCiSignatureSignType ===
          DigitalCiSignatureSignTypeEnum.TOGETHER
      }"
      (click)="changeSignatureType(DigitalCiSignatureSignTypeEnum.TOGETHER)">
      {{ 'PRIVATE.CONSENTS.LEGALREP-SIGNS.INPUT-TOGETHER' | translate }}
    </button>
    <button
      class="button tipo-white-color back-blue-color"
      [ngClass]="{
        selected:
          digitalCiSignatureSignType ===
          DigitalCiSignatureSignTypeEnum.SEPARATED
      }"
      (click)="changeSignatureType(DigitalCiSignatureSignTypeEnum.SEPARATED)">
      {{ 'PRIVATE.CONSENTS.LEGALREP-SIGNS.INPUT-SEPARATE' | translate }}
    </button>
  </div>
</div>
