<div class="wrapper">
  <div class="maxwidth">
    <div class="buttons">
      <button
        class="button tipo-white-color back-blue-color"
        [routerLink]="['new']">
        <fa-icon [icon]="faFileCirclePlus" class="icono"></fa-icon>
        {{
          'PRIVATE.ADMINISTRATOR.FILE-ADMINISTRATOR.BUTTON-NEW-ADMINISTRATOR'
            | translate
        }}
      </button>
    </div>
    <div class="list">
      <h4 class="title tipo-second-color bold" id="title_table">
        {{ 'PRIVATE.ADMINISTRATOR.LIST-ADMINISTRATORS.TITLE' | translate }}
      </h4>
      <table class="table" [cellSpacing]="0" aria-describedby="title_table">
        <thead class="tipo-second-color bold">
          <th scope="col" class="name">
            <div class="column">
              {{ 'PRIVATE.ADMINISTRATOR.LIST-ADMINISTRATORS.NAME' | translate }}
              <input
                type="text"
                name="filterName"
                (debounceInput)="addFilter('fullName', filterName.value)"
                [appDebounceInput]="300"
                #filterName
                id="filterName"
                placeholder="{{
                  'PRIVATE.PATIENT.LIST-PATIENTS.FILTER' | translate
                }}" />
            </div>
          </th>
          <th scope="col" class="login">
            {{ 'PRIVATE.ADMINISTRATOR.LIST-ADMINISTRATORS.LOGIN' | translate }}
          </th>
          <th scope="col" class="view_cis">
            {{
              'PRIVATE.ADMINISTRATOR.DATA-ADMINISTRATOR.VIEW-CIS' | translate
            }}
          </th>
          <th scope="col" class="supervisor">
            {{
              'PRIVATE.ADMINISTRATOR.LIST-ADMINISTRATORS.SUPERVISOR' | translate
            }}
          </th>
          <th scope="col" class="state">
            {{ 'PRIVATE.ADMINISTRATOR.LIST-ADMINISTRATORS.STATE' | translate }}
          </th>
        </thead>
        <tbody>
          <tr
            [routerLink]="['./' + user.uuid]"
            *ngFor="let user of users; let i = index">
            <td class="name">{{ user.name }} {{ user.lastname }}</td>
            <td class="login">{{ user.userName }}</td>
            <td class="view_cis">
              <span>
                {{
                  roleService.userHasRole(user, [
                    role.ADMINISTRATIVE_VIEW_CIS
                  ]) | yesNo
                }}
              </span>
            </td>
            <td class="supervisor">
              {{
                roleService.userHasRole(user, [role.ADMINISTRATIVE_SUPERVISOR])
                  | yesNo
              }}
            </td>
            <td class="state">
              <span
                class="green bold"
                *ngIf="user?.userStatus?.name === 'Activo'">
                {{ user?.userStatus?.name }}
              </span>
              <span
                class="red bold"
                *ngIf="user?.userStatus?.name === 'No activo'">
                {{ user?.userStatus?.name }}
              </span>
              <span
                class="orange bold"
                *ngIf="
                  user?.userStatus?.name !== 'No activo' &&
                  user?.userStatus?.name !== 'Activo'
                ">
                {{ user?.userStatus?.name }}
              </span>
            </td>
          </tr>
        </tbody>
      </table>
      <!-- Pagination -->
      <app-paginator
        [totalElements]="page?.totalElements ?? 0"  
        [numbersOfElements]="page?.numberOfElements ?? 0"
        [totalPages]="page?.totalPages ?? 0"
        [pageNumber]="pagination.page"
        [lastPage]="page?.last ?? false"
        (paginate)="paginar($event)"></app-paginator>
      <!-- End of Pagination -->
    </div>
  </div>
</div>
