
    <div class="box">
       <div class="inlineflex">
        <p class="bold tipo-second-color">
           {{'SUPER-ADMIN.LIST-COMPANY.TITLE-COMPANY-NAME' | translate}}:
            <span class="regular">{{companyData?.businessName}}</span>
          </p> 
          <p class="bold tipo-second-color">
            {{'SUPER-ADMIN.LIST-COMPANY.DOCUMENT' | translate}}:
             <span class="regular">{{companyData?.cif}}</span>
           </p> 
           <p class="bold tipo-second-color" *ngIf="isListCompanies">
            {{'SUPER-ADMIN.LIST-COMPANY.TITLE-SUBCOMPANIES-NUMBER' | translate}}:
             <span class="regular">{{companyData?.companies}}</span>
           </p> 
           <p class="bold tipo-second-color">
           {{"SUPER-ADMIN.DATA-COMPANY-ADMIN.DATA-USERS" | translate}}
             <span class="regular">{{companyData?.users}}</span>
           </p> 
       </div> 
    </div>

