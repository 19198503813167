import { Directive, Input, TemplateRef, ViewContainerRef } from '@angular/core';
import { RoleService } from '@app/core/services/role/role.service';

@Directive({ selector: '[appHasRole]' })
export class HasRoleDirective {
  constructor(
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
    private roleService: RoleService
  ) {}

  @Input() set appHasRole(roles: number[]) {
    if (this.roleService.hasRole(roles)) {
      this.viewContainer.createEmbeddedView(this.templateRef);
    } else {
      this.viewContainer.clear();
    }
  }
}
