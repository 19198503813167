import { Component } from '@angular/core';
import { faUserPlus, faFileCircleCheck, faTag, faPaperPlane } from '@fortawesome/free-solid-svg-icons';
import { Paso} from '../../interfaces/interfaces';
@Component({
  selector: 'app-comofunciona',
  templateUrl: './comofunciona.component.html',
  styleUrls: ['./comofunciona.component.scss']
})
export class ComofuncionaComponent {
  faUserPlus = faUserPlus;
  faFileCircleCheck = faFileCircleCheck;
  faTag = faTag;
  faPaperPlane = faPaperPlane;
  paso: Array<Paso> = [
    {
      number:1,
      icono: faUserPlus,
      titulo: 'Seleccionar paciente',
      subtitulo: 'Dar de alta paciente nuevo o seleccionar un paciente ya creado'
    },
    {
      number:2,
      icono: faFileCircleCheck,
      titulo: 'Elegir procedimiento',
      subtitulo: 'Más de 1500 procedimientos de 43 especialidades'
    },
    {
      number:3,
      icono: faTag,
      titulo: 'Personalizar riesgos',
      subtitulo: 'Posibilidad de incluir los riesgos del paciente que afectan al procedimiento'
    },
    {
      number:4,
      icono: faPaperPlane,
      titulo: 'Enviar a leer y firmar',
      subtitulo: 'Enviar a la tablet para lectura informada y firma biométrica o envío por mail para firma remota'
    }
   ]

}
