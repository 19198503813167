import { Component } from '@angular/core';

@Component({
  selector: 'app-file-patient-admin',
  templateUrl: './file-patient-admin.component.html',
  styleUrls: ['./file-patient-admin.component.scss']
})
export class FilePatientAdminComponent {

}
