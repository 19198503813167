import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ApiHandlerComponent } from './pages/api-handler/api-handler.component';
import { SharedModule } from '@app/shared/shared.module';

@NgModule({
  declarations: [ApiHandlerComponent],
  imports: [CommonModule, SharedModule],
})
export class ApiHandlerModule {}
