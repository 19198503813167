<div class="window_error box box1">
    <fa-icon [icon]="faCircleXmark" class="icono tipo-second-color icon_close" (click)="closeWindow()"></fa-icon>
    <h4 class="title tipo-second-color bold">{{ "PRIVATE.WINDOW-ERROR.TITLE" | translate}} <span class="id_document">847823587237856746573467</span></h4>
    <p>{{ "PRIVATE.WINDOW-ERROR.UUID" | translate}} <span class="uuid_document">847823587237856746573467</span> </p>
    <p>{{ "PRIVATE.WINDOW-ERROR.DOCUMENT" | translate }} <span class="name_document">Nombre del documento</span></p>
    <p>{{ "PRIVATE.WINDOW-ERROR.STATE" | translate }} <span class="state red">Error</span></p>
    <p>{{ "PRIVATE.WINDOW-ERROR.ERROR-MENSAGE" | translate }} <span class="mesage red">Not Found</span></p>
    <p>{{ "PRIVATE.WINDOW-ERROR.DATE" | translate }} <span class="date">00/00/0000</span></p>
    <p>{{ "PRIVATE.WINDOW-ERROR.SIGNER" | translate }} <span class="signer">Pepe Pérez</span></p>
    <p>{{ "PRIVATE.WINDOW-ERROR.IDENTIFY" | translate }} <span class="id_signer">0000000</span></p>
    <p>{{ "PRIVATE.WINDOW-ERROR.EMAIL" | translate }} <span class="email_signer">email@email.com</span></p>
    <p>{{ "PRIVATE.WINDOW-ERROR.PHONE" | translate }} <span class="phone">000000000</span></p>
    <p>{{ "PRIVATE.WINDOW-ERROR.DEVICE" | translate }} <span class="name_document">dispositivo 1</span></p>         
  </div>
