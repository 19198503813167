import { Injectable } from '@angular/core';
import { Filter } from '@app/core/models/front/filters';
import { Pagination } from '@app/core/models/front/pagination';
import { Sorters } from '@app/core/models/front/sorters';
import { Patient } from '@app/core/models/input/patient/patient.model';

/**
 * `UtilsService` is an Angular Service providing utility methods that generate strings for filters, sorters, and pagination in URI format.
 * These methods are mainly used to build HTTP request parameters.
 *
 * This service is injectable and provided in the root.
 */
@Injectable({
  providedIn: 'root',
})
export class UtilsService {
  /**
   * Transforms an array of `Filter` objects into a string in URI format.
   *
   * @param filters - Array of `Filter` objects. Each `Filter` object contains `property` and `filter` attributes.
   *
   * @returns A string in the form of "filters={filterProperty:filterValue,...}".
   *          Each filter property and value is URI-encoded.
   *          The whole string is suitable for use in a URL query string.
   */
  getFiltersInUri(filters: Filter[]): string {
    if (filters.length == 0) {
      return '';
    }
    // Initialize filtersString with the starting "filters=" string
    let filtersString = '&filters=' + encodeURIComponent('{');

    // Loop through each filter object in the array
    filters.forEach((filter, index) => {
      // For each filter object, append the filter property and its value in URI-encoded format
      filtersString = filtersString + encodeURIComponent(`"${filter.property}":"${filter.filter}"`);

      // If this is not the last filter in the array, append a comma
      if (index !== filters.length - 1) {
        filtersString = filtersString + encodeURIComponent(',');
      }
    });

    // Close the encoded JSON filter string
    filtersString = filtersString + encodeURIComponent('}');

    // Return the final filter string in URI format
    return filtersString;
  }

  /**
   * Transforms a `Sorters` object into a string in URI format.
   *
   * @param sorters - `Sorters` object which contains `property` and `direction` attributes.
   *
   * @returns A string in the form of "sorters={property:direction}",
   *          where `property` is the name of the property to sort on and `direction` is the sort direction.
   *          The whole string is URI-encoded and suitable for use in a URL query string.
   */
  genSortersInUri(sorters: Sorters): string {
    // Return the sorters property and direction in URI-encoded format if exists
    if (!sorters.direction || !sorters.property) {
      return '';
    }
    return '&sorters=' + encodeURIComponent(`{"property":"${sorters.property}", "direction":"${sorters.direction}"}`);
  }

  /**
   * Transforms a `Pagination` object into a string in URI format.
   *
   * @param pagination - `Pagination` object which contains `size` and `page` attributes.
   *
   * @returns A string in the form of "&size=sizeValue&page=pageNumber", where `sizeValue` is the number of items per page and `pageNumber` is the current page number.
   *          The whole string is suitable for use in a URL query string.
   */
  genPaginationInUri(pagination: Pagination): string {
    // Return the pagination size and page number in URI format
    return `size=${pagination.size}&page=${pagination.page}`;
  }

  /**
   * Format the parameter name and parameter value to an string in URI format.
   *
   * @param parameterName - `string` Name of the parameter URI
   * @param parameterValue - `string` Value of the URI parameter.
   *
   * @returns A string in the form of "&language=es"
   */
  genParameterInUri(parameterName: string, parameterValue: string): string {
    // Return the pagination size and page number in URI format
    return `${parameterName}=${parameterValue}`;
  }

  getAge(birthDate: string | undefined): number {
    if (birthDate) {
      return this.calculateAge(birthDate);
    } else {
      return 0; // Handle cases where birthdate is not provided or invalid.
    }
  }

  private calculateAge(birthDateStr: string): number {
    const today = new Date();
    const birthDate = new Date(birthDateStr);

    let age = today.getFullYear() - birthDate.getFullYear();
    const monthDiff = today.getMonth() - birthDate.getMonth();

    // If the birth month has not occurred yet, or it's the birth month but not the birth day yet, subtract one year.
    if (monthDiff < 0 || (monthDiff === 0 && today.getDate() < birthDate.getDate())) {
      age--;
    }

    return age;
  }

  getActualDay(): string {
    const today = new Date();
    return today.getDate().toString();
  }

  getActualMonth(): string {
    const today = new Date();
    // Months are zero-indexed, so add 1 for the actual month number
    return (today.getMonth() + 1).toString();
  }

  getActualMonthName(): string {
    const date = new Date();
    return date.toLocaleString('default', { month: 'long' });
  }

  getActualYear(): string {
    const today = new Date();
    return today.getFullYear().toString();
  }

  patientHasTwoRepresentativesActive(patient: Patient): boolean {
    if (patient.representativePatients) {
      return patient.representativePatients.filter(rep => rep.active).length == 2;
    } else {
      return false;
    }
  }
}
