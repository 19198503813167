export class RepresentativePatientDTO {
  id?: number;
  name?: string;
  firstLastName?: string;
  secondLastName?: string;
  documentNumber?: string;
  birthdate?: Date;
  email?: string;
  phonePrefix?:string;
  mobile?: string;
  relationship?: string;
  isMainRepresentativePatient?: boolean;
  active?: boolean;
}
