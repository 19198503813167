import { Component } from '@angular/core';
import { CacheImplService } from '../../../shared/services/cache/cache-impl.service';

@Component({
  selector: 'app-cookie-service',
  templateUrl: './cookie-service.component.html',
  styleUrls: ['./cookie-service.component.scss'],
})
export class CookieServiceComponent {
  private cookieName = 'cookiesAccepted';
  public cookiesAccepted = false;

  constructor(private cacheImplService: CacheImplService) {
   
   // Comprueba si la cookie ya está presente al inicializar el componente
  if (this.cacheImplService.checkCookie(this.cookieName)) {
    this.cookiesAccepted = !this.cookiesAccepted  
  }
  }

  public acceptCookies() {
    this.cacheImplService.setCookie(this.cookieName, 'true', 365);
    this.cookiesAccepted = true;
  }

  public rejectCookies() {
    this.cacheImplService.deleteCookie(this.cookieName);
    this.cookiesAccepted = false;
  }
}
