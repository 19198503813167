import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { FileOutputDTO } from '@app/core/models/output/file-output.model';
import { EnvironmentService } from '@app/environment.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class FileService {
  constructor(private http: HttpClient, private env: EnvironmentService) {}

  public getConsentPdf(uuid: string): Observable<HttpResponse<FileOutputDTO>> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    return this.http.get<FileOutputDTO>(`${this.env.apiUrl}/consents/pdf/${uuid}`, {
      headers: headers,
      observe: 'response',
    });
  }

  public getRgpdPdf(uuid: string): Observable<HttpResponse<FileOutputDTO>> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    return this.http.get<FileOutputDTO>(`${this.env.apiUrl}/rgpds/pdf/${uuid}`, {
      headers: headers,
      observe: 'response',
    });
  }

  public getDigitalDocumentPdf(uuid: string): Observable<HttpResponse<FileOutputDTO>> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    return this.http.get<FileOutputDTO>(`${this.env.apiUrl}/dds/pdf/${uuid}`, {
      headers: headers,
      observe: 'response',
    });
  }

  public getConsentReportPdf(uuid: string): Observable<HttpResponse<FileOutputDTO>> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    return this.http.get<FileOutputDTO>(`${this.env.apiUrl}/consents/pdf/report/${uuid}`, {
      headers: headers,
      observe: 'response',
    });
  }

  public getRGPDReportPdf(uuid: string): Observable<HttpResponse<FileOutputDTO>> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    return this.http.get<FileOutputDTO>(`${this.env.apiUrl}/rgpds/pdf/report/${uuid}`, {
      headers: headers,
      observe: 'response',
    });
  }

  public getDDReportPdf(uuid: string): Observable<HttpResponse<FileOutputDTO>> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    return this.http.get<FileOutputDTO>(`${this.env.apiUrl}/dds/pdf/report/${uuid}`, {
      headers: headers,
      observe: 'response',
    });
  }

  public getConsentRemotePdf(uuid: string): Observable<HttpResponse<FileOutputDTO>> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    return this.http.get<FileOutputDTO>(`${this.env.apiUrl}/consents/pdf/remote/${uuid}`, {
      headers: headers,
      observe: 'response',
    });
  }
  
}
