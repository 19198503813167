<div class="box box1 box_documents">
  <div class="permisos" *ngIf="showRGPDspermission">
    <div class="buttons">
      <p class="subtitle tipo-second-color bold">
        {{ 'PRIVATE.PATIENT.GDPR-LEAVES.TITLE' | translate }}
      </p>
      <button
        class="button tipo-second-color back-white-color refresh-button"
        (click)="refreshData()">
        <fa-icon [icon]="faRotate" class="icono"></fa-icon>
      </button>
    </div>

    <!-- Mensaje que sale en caso de que no haya rgpds firmadas -->
    <div class="nosigns center" *ngIf="!rgpdForms?.forms">
      <fa-icon [icon]="faClock" class="icono fa-2xl tipo-second-color"></fa-icon>
      <p class="subtitle tipo-second-color bold">
        {{ 'PRIVATE.PATIENT.GDPR-LEAVES.MESSAGE-RGPD-PENDING' | translate }}
      </p>
    </div>

    <!-- Forms -->
    <div class="leaves inlineflex">
      <div *ngIf="rgpdForms?.forms">
        <div class="leaves inlineflex" *ngFor="let form of rgpdForms?.forms">
          <!-- Yes No responses (true | false) -->
          <span class="yesno backgreen" *ngIf="form?.value">
            {{ form?.value || false | yesNo }}
          </span>
          <span class="yesno backred" *ngIf="!form?.value">
            {{ form?.value || false | yesNo }}
          </span>
          <!-- End of yes no -->
          <p class="leave">
            {{ form?.text }}
          </p>
        </div>
      </div>
      <div *ngIf="rgpdForms?.vidForms">
        <div
          class="leaves inlineflex"
          *ngFor="let vidForm of rgpdForms?.vidForms">
          <!-- Yes No responses (true | false) -->
          <span
            class="yesno backgreen"
            *ngIf="
              vidForm?.type === 'checkbox' &&
              stringToBoolean(vidForm?.response || 'False')
            ">
            {{ stringToBoolean(vidForm?.response || 'False') | yesNo }}
          </span>
          <span
            class="yesno backred"
            *ngIf="
              vidForm?.type === 'checkbox' &&
              !stringToBoolean(vidForm?.response || 'False')
            ">
            {{ stringToBoolean(vidForm?.response || 'False') | yesNo }}
          </span>
          <!-- End of yes no -->
          <p *ngIf="vidForm?.type === 'checkbox'" class="leave">
            {{ vidForm?.title }}
          </p>
        </div>
      </div>
    </div>
    <!-- end of forms -->
</div>
  <p class="subtitle tipo-second-color bold">
    {{ 'PRIVATE.PATIENT.GDPR-LEAVES.SUBTITLE' | translate }}
  </p>
  <table class="table" [cellSpacing]="0">
    <thead class="tipo-second-color bold">
      <th scope="col" class="id">{{'PRIVATE.PATIENT.LIST-PATIENTS.TITLE-ID' | translate}}</th>
      <th scope="col" class="date">
        {{ 'PRIVATE.PATIENT.GDPR-LEAVES.DATE' | translate }}
      </th>
      <th scope="col" class="datesign">
        {{ 'PRIVATE.PATIENT.GDPR-LEAVES.DATE-SIGN' | translate }}
      </th>
      <th scope="col" class="state">
        {{ 'PRIVATE.PATIENT.DOCUMENTS-PATIENT.STATE' | translate }}
      </th>
      <th scope="col" class="action">
        {{ 'PRIVATE.PATIENT.DOCUMENTS-PATIENT.ACTIONS' | translate }}
      </th>
    </thead>
    <tbody>
      <tr *ngFor="let rgpd of rgpds; let i = index">
        <td class="id">{{rgpd?.id}}</td>
        <td class="date">{{ rgpd?.creationDate | UTCToLocal }}</td>
        <td class="datesign">{{ rgpd?.signatureDate | UTCToLocal }}</td>
          <!-- Statuses -->
          <td>
            <app-statuses
              [status]="rgpd.status"
              [outputType]="rgpd.outputType"></app-statuses>
          </td>
          <!-- End of Statuses -->
          <!-- Actions -->
          <td>
            <app-status-actions
              [status]="rgpd.status"
              [downloadUuid]="rgpd.uuid"
              [canDownloadFile]="true"
              [objectType]="ObjectTypeEnum.RGPD"
              [outputType]="rgpd.outputType"
            ></app-status-actions>
          </td>
          <!-- End of Actions -->
      </tr>
    </tbody>
  </table>
  <!-- Mensaje que sale en caso de que no haya rgpds -->
  <div class="norgpds center" *ngIf="page?.totalElements === 0">
    <fa-icon
      [icon]="faFolderOpen"
      class="icono fa-2xl tipo-second-color"></fa-icon>
    <p class="subtitle tipo-second-color bold">
      {{ 'PRIVATE.PATIENT.GDPR-LEAVES.MESSAGE-NO-RGPDS' | translate }}
    </p>
  </div>
    <!-- Pagination -->
    <app-paginator
    [totalElements]="page?.totalElements ?? 0"
    [numbersOfElements]="page?.numberOfElements ?? 0"
    [totalPages]="page?.totalPages ?? 0"
    [pageNumber]="pagination.page"
    [lastPage]="page?.last ?? false"
    (paginate)="paginar($event)"></app-paginator>
  <!-- End of Pagination -->
</div>
