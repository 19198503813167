import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from '@app/shared/shared.module';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { SharedComponentModule } from '../shared/components/shared-component.module';
import { DataDoctorComponent } from './components/data-doctor/data-doctor.component';
import { DocumentsDoctorComponent } from './components/documents-doctor/documents-doctor.component';
import { FileDoctorComponent } from './pages/file-doctor/file-doctor.component';
import { ListDoctorsComponent } from './pages/list-doctors/list-doctors.component';
import { NewDoctorComponent } from './pages/new-doctor/new-doctor.component';

@NgModule({
  declarations: [
    ListDoctorsComponent,
    FileDoctorComponent,
    NewDoctorComponent,
    DataDoctorComponent,
    DocumentsDoctorComponent,
  ],
  imports: [
    CommonModule,
    FontAwesomeModule,
    SharedModule,
    SharedComponentModule,
    ReactiveFormsModule,
    FormsModule,
    SharedComponentModule,
  ],
  exports: [
    FileDoctorComponent
  ]
})
export class DoctorsModule {}
