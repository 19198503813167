import { Component, OnInit } from '@angular/core';
import { StatisticsService } from '@app/core/services/statistics/statistics.service';
import { ProcedureStatistics } from '@app/core/models/input/statistics/procedures.model';
import { Page } from '@app/core/models/input/page/pages.model';
import { Pagination } from '@app/core/models/front/pagination';
import { SpecialtiesService } from '@app/core/services/api/consents/specialties/specialties.service';
import { Specialty } from '@app/core/models/input/specialty.model';
import { FilterUtils } from '@app/core/utils/filter.utils';
import { Filter } from '@app/core/models/front/filters';


@Component({
  selector: 'app-list-consents-admin',
  templateUrl: './list-consents-admin.component.html',
  styleUrls: ['./list-consents-admin.component.scss']
})
export class ListConsentsAdminComponent implements OnInit{

  page?: Page;
  pagination = new Pagination(); 
  procedures?: ProcedureStatistics[];
  specialties?: Specialty[];
  selectedSpecialty = 0;
  filters = new Array<Filter>();
  specialitiesId?: number;
  procedureName = '';
  selectedSpecialityId = 0;
  filteredProcedures?:ProcedureStatistics[];
  

    // Utils
    filterUtils: FilterUtils;

  constructor(private statisticsService: StatisticsService,
              private specialtiesService: SpecialtiesService ) 
  {this.filterUtils = new FilterUtils(this.filters);}

  ngOnInit(): void {
    this.getSpecialties();
    this.getProcedures();  
  }

  
  paginar(amount: number) {
    this.pagination.page += amount;
    this.getProcedures();
  }
 

  addSpecialtyFilter(specialitiesId: number ) {  
    this.selectedSpecialityId = specialitiesId;  
    this.getProcedures();      
    }        
  
  addConsentFilter(procedureName:string) {
    this.procedureName = procedureName;
    this.getProcedures();
  }


 getProcedures(){
    this.statisticsService.getProcedures(this.pagination, this.selectedSpecialityId, this.procedureName).subscribe({
    next: respuesta => {
      this.page = respuesta;
      this.procedures = this.page.content;                  
    }
  })
 }
 getSpecialties() {
  this.specialtiesService.getSpecialties().subscribe({
    next: specialties => {
      this.specialties = specialties;
    }
  })
}
 
}
