<div class="wrapper">    
    <div class="list">
        <div class="row back-white-color">
            <div class="column">   
                <p class="tipo-second-color bold">{{'SUPER-ADMIN.CONSENT-BY-SPECIALTY.DATA-DATE-ON' | translate}}</p>
                <input type="text" name="filterDateOn" (debounceInput)="addFilter('creationDate-start', filterDateOn.value)" [appDebounceInput]="300" #filterDateOn id="filterDocument" placeholder="{{'PRIVATE.PATIENT.LIST-PATIENTS.FILTER' | translate }}">
            </div>
    
            <div class="column">  
                <p class="tipo-second-color bold">{{'SUPER-ADMIN.CONSENT-BY-SPECIALTY.DATA-DATE-OFF' | translate}}</p>
                <input type="text" name="filterDateOff" (debounceInput)="addFilter('creationDate-end', filterDateOff.value)" [appDebounceInput]="300" #filterDateOff id="filterDocument" placeholder="{{'PRIVATE.PATIENT.LIST-PATIENTS.FILTER' | translate }}">
            </div>
      </div>
        <h4 class="title tipo-second-color bold" id="title_table">{{'SUPER-ADMIN.API-DOCUMENTS-BY-COMPANY.TITLE-API-DOCUMENTS' | translate}}</h4>
            
        
        <table class="table" aria-describedby="title_table">
            <thead class="tipo-second-color bold"> 
                <th scope="col" class="company_name">               
                    <div class="column">                        
                         <span>{{'SUPER-ADMIN.LIST-ADMINS.DATA-COMPANY' | translate}}</span> 
                        <input type="text" name="filterBusiness" (debounceInput)="addFilter('businessName', filterBusiness.value)" [appDebounceInput]="300" #filterBusiness id="filterDocument" placeholder="{{'PRIVATE.PATIENT.LIST-PATIENTS.FILTER' | translate }}">
                    </div>
                </th>
                <th scope="col" class="cif">
                    <div class="column">
                        <span>{{"SUPER-ADMIN.API-DOCUMENTS-BY-COMPANY.DOCUMENT" | translate}}</span> 
                        <input type="text" name="filterDocument" (debounceInput)="addFilter('businessCif', filterDocument.value)" [appDebounceInput]="300" #filterDocument id="filterDocument" placeholder="{{'PRIVATE.PATIENT.LIST-PATIENTS.FILTER' | translate }}">
                    </div>
                    
                </th>
                <th scope="col" class="his">
                  {{"SUPER-ADMIN.API-DOCUMENTS-BY-COMPANY.NAME-HIS" | translate}}
                </th>
                <th scope="col" class="state">{{"SUPER-ADMIN.API-DOCUMENTS-BY-COMPANY.API-STATE" | translate}}</th>
                <th scope="col" class="date_on">{{"SUPER-ADMIN.API-DOCUMENTS-BY-COMPANY.CREATION-DATE" | translate}}</th>                           
                <th scope="col" class="cis">{{"SUPER-ADMIN.API-DOCUMENTS-BY-COMPANY.DOCUMENTS-CIS" | translate}}</th>
                <th scope="col" class="remotes">{{"SUPER-ADMIN.API-DOCUMENTS-BY-COMPANY.DOCUMENTS-REMOTES" | translate}}</th>
                <th scope="col" class="rgpds">{{"SUPER-ADMIN.API-DOCUMENTS-BY-COMPANY.DOCUMENTS-RGPDS" | translate}}</th>
                <th scope="col" class="dds">{{"SUPER-ADMIN.API-DOCUMENTS-BY-COMPANY.DOCUMENTS-DDS" | translate}}</th>
                <th scope="col" class="revokes">{{"SUPER-ADMIN.API-DOCUMENTS-BY-COMPANY.DOCUMENTS-REVOKES" | translate}}</th>    
                <th scope="col" class="others">{{"SUPER-ADMIN.API-DOCUMENTS-BY-COMPANY.DOCUMENTS-OTHERS" | translate}}</th>            
                <th scope="col" class="total">{{"SUPER-ADMIN.API-DOCUMENTS-BY-COMPANY.DOCUMENTS-TOTAL" | translate}}</th>

            </thead>
            <tbody>                
                <tr *ngFor="let document of apiDocuments">
                    <td class="company_name">{{document.businessName}}</td>                   
                    <td class="cif">{{document.companyCif}}</td>
                    <td class="his">{{document.companyHisName}}</td>
                    <td class="state">{{document.apiActive === true ? "Activo" : "Inactivo"}}</td>
                    <td class="date_on">{{document.companyCreationDate | date : 'dd/MM/YYYY'}}</td>                           
                    <td class="cis">{{document.countConsentsBio}}</td>
                    <td class="remotes">{{document.countConsentsRemote}}</td>
                    <td class="rgpds">{{document.countRgpds}}</td>
                    <td class="dds">{{document.countDds}}</td>
                    <td class="revokes">{{document.countRevoke}}</td>    
                    <td class="others">{{document.countFile}}</td>            
                    <td class="total">{{document.countTotal}}</td>   
                </tr>
                
            </tbody>
        </table>       
</div>    


