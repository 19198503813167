import { Component, HostListener } from '@angular/core';
import {
  faCircleArrowUp,
  faCircleArrowLeft,
} from '@fortawesome/free-solid-svg-icons';
import { Location } from '@angular/common';
@Component({
  selector: 'app-to-top',
  templateUrl: './to-top.component.html',
  styleUrls: ['./to-top.component.scss'],
})
export class ToTopComponent {
  faCircleArrowUp = faCircleArrowUp;
  faCircleArrowLeft = faCircleArrowLeft;

  scrollUp? = false;
  lastScroll = document.documentElement.scrollTop;

  constructor(private location: Location) {}

  @HostListener('window:scroll', [])
  onWindowScroll(): void {
    const currentScroll = window.scrollY;
    if (currentScroll > this.lastScroll) {
      this.scrollUp = false;
    } else {
      this.scrollUp = true;
    }
    this.lastScroll = currentScroll <= 0 ? 0 : currentScroll;
  }

  scroll() {
    (function smoothscroll(): void {
      const currentScroll =
        document.documentElement.scrollTop || document.body.scrollTop;

      if (currentScroll > 0) {
        window.requestAnimationFrame(smoothscroll);
        window.scrollTo(0, currentScroll - currentScroll / 8);
      }
    })();
  }

  goBack() {
    this.location.back();
  }
}
