import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { DESC } from '@app/core/constants/Constants';
import OutputTypeEnum from '@app/core/enums/output-type.enum';
import StatusEnum from '@app/core/enums/status.enum';
import { Filter } from '@app/core/models/front/filters';
import { Pagination } from '@app/core/models/front/pagination';
import { Sorters } from '@app/core/models/front/sorters';
import { Status } from '@app/core/models/input/common/status.model';
import { Page } from '@app/core/models/input/page/pages.model';
import { DocumentForms } from '@app/core/models/input/rgpd/document-forms.model';
import { Rgpd } from '@app/core/models/input/rgpd/rgpd.model';
import { FileService } from '@app/core/services/file/file.service';
import { RgpdService } from '@app/core/services/rgpd/rgpd.service';
import DownloadUtils from '@app/core/utils/download.utils';
import { FilterUtils } from '@app/core/utils/filter.utils';
import {
  faCircleExclamation,
  faClock,
  faFileCircleCheck,
  faFileSignature,
  faFolderOpen,
  faPrint,
  faRotate,
  faTabletScreenButton
} from '@fortawesome/free-solid-svg-icons';
import { PatientParamsRouteEnum } from '../../../../core/enums/route.enum';
import ObjectTypeEnum from '@app/core/enums/objectType.enum';
@Component({
  selector: 'app-rgpd-leaves',
  templateUrl: './rgpd-leaves.component.html',
  styleUrls: ['./rgpd-leaves.component.scss'],
})
export class RgpdLeavesComponent implements OnInit {
  // If true, data loading is performed when the specific tab for this component is opened
  @Input() isOpenFromPatientFile = false;
  @Input() showRGPDspermission = true;

  // FontAwesome
  faFileSignature = faFileSignature;
  faTabletScreenButton = faTabletScreenButton;
  faPrint = faPrint;
  faCircleExclamation = faCircleExclamation;
  faFileCircleCheck = faFileCircleCheck;
  faFolderOpen = faFolderOpen;
  faClock = faClock;
  faRotate = faRotate;
  // Utils
  filterUtils: FilterUtils;

  // Enums
  OutputType = OutputTypeEnum;
  ObjectTypeEnum = ObjectTypeEnum;
  Status = StatusEnum;

  // Properties
  rgpds?: Rgpd[];
  rgpdForms?: DocumentForms;
  statuses?: Status[];
  page?: Page;
  pagination = new Pagination();
  filters = new Array<Filter>();
  sorters = new Sorters('creationDate', DESC);

  constructor(
    private activatedRoute: ActivatedRoute,
    private rgpdService: RgpdService,
    private fileService: FileService,
  ) {
    this.filterUtils = new FilterUtils(this.filters);
  }

  ngOnInit() {
    if (this.isOpenFromPatientFile) {
      this.activatedRoute.params.subscribe(param => {
        if (param['tab'] === PatientParamsRouteEnum.GDPR) {
          this.loadData();
        }
      });
    } else {
      this.loadData();
    }
  }

  loadData() {
    this.addFilter(
      'patientUuid',
      this.activatedRoute.snapshot.paramMap.get('uuid') as string
    );
    this.getRgpdForms();
  }

  addStatusFilter(statusId: string) {
    // 0 means all statuses
    if (statusId != '0') {
      this.addFilter('statusId', statusId);
    } else {
      this.removeFilter('statusId');
    }
  }

  addFilter(name: string, filter: string) {
    this.filterUtils.addFilter(name, filter);
    this.getRgpds();
  }

  removeFilter(name: string) {
    this.filterUtils.removeFilter(name);
    this.getRgpds();
  }

  paginar(amount: number) {
    this.pagination.page += amount;
    this.getRgpds();
  }

  stringToBoolean(s: string): boolean {
    return s === "True";
  }

  refreshData(): void {
    this.getRgpdForms();
    this.getRgpds();
  }

  // Gets from services
  getRgpds(): void {
    this.rgpdService
      .getRgpds(this.pagination, this.filters, this.sorters)
      .subscribe({
        next: consentsPage => {
          this.page = consentsPage;
          this.rgpds = this.page.content;

        },
      });
  }

  getRgpdForms(): void {
    this.rgpdService
      .getRgpdForm(this.activatedRoute.snapshot.paramMap.get('uuid') as string)
      .subscribe({
        next: rgpdForms => {
          this.rgpdForms = rgpdForms;
        },
      });
  }

  downloadFile(uuid: string): void {
    this.fileService.getRgpdPdf(uuid).subscribe({
      next: dataDocument => {
        if (dataDocument.body?.name){
          const newName = dataDocument.body?.name.replace(/\.pdf$/, '');
          DownloadUtils.download(dataDocument.body?.content ?? '', newName, dataDocument.body?.mimeType ?? '');
         }
      }
    })
  }

  openWindow(): void {
    const window = document.querySelector('.window');
    window?.classList.remove('nodisplay');
  }
}
