<div class="wrapper">
    
   
        <div *ngIf="showProfessionals && showAdmins" class="module">
            <h4 class="title tipo-second-color bold">{{"SUPER-ADMIN.LIST-USERS.TITLE-USERS" | translate}} {{companyData?.businessName}}</h4>
            <app-info-company [companyData]="companyData"></app-info-company>
        </div>     
        <div *ngIf="showProfessionals" class="module">
            <app-list-professionals></app-list-professionals>
        </div>
   
        <div *ngIf="showAdmins" class="module">
            <app-list-administratives></app-list-administratives>
        </div>
  

  
</div>
