import { Component, OnInit } from '@angular/core';
import { Filter } from '@app/core/models/front/filters';
import { Pagination } from '@app/core/models/front/pagination';
import { FilterUtils } from '@app/core/utils/filter.utils';
import { faCaretDown, faCaretUp } from '@fortawesome/free-solid-svg-icons';
import { Sorters } from '@app/core/models/front/sorters';
import { DESC } from '@app/core/constants/Constants';
import { Page } from '@app/core/models/input/page/pages.model';
import { StatisticsService } from '@app/core/services/statistics/statistics.service';
import { TotalConsentsByDoctor } from '@app/core/models/input/statistics/total-consents-by-doctor';
import StatusEnum from '@app/core/enums/status.enum';
import UserStatusEnum from '@app/core/enums/user-status.enum';
import RoleEnum from '@app/core/enums/role.enum';
import { StatusService } from '@app/core/services/status/status.service';
import { Status } from '@app/core/models/input/common/status.model';
import { UserService } from '@app/core/services/user/user.service';
import { SpecialtiesService } from '@app/core/services/api/consents/specialties/specialties.service';
import { Specialty } from '@app/core/models/input/specialty.model';
import { GREEN_STATUS, ORANGE_STATUS, RED_STATUS } from '@app/core/constants/StatusUsers';

@Component({
  selector: 'app-total-consents-by-doctors',
  templateUrl: './total-consents-by-doctors.component.html',
  styleUrls: ['./total-consents-by-doctors.component.scss']
})
export class TotalConsentsByDoctorsComponent implements OnInit{  
  faCaretDown = faCaretDown;
  faCaretUp = faCaretUp;

  pagination = new Pagination();
  filters = new Array<Filter>();
  sorters = new Sorters('creationDate', DESC);

  // Utils
  filterUtils: FilterUtils;

  nameFilter = new Filter();
  nameFilterStr?: string;
  page?: Page;
  isMotherCompany = false;
  consents?: TotalConsentsByDoctor[];
  statuses?: Status[];
  specialties?: Specialty[];
  selectedStatus = 0;
  selectedSpecialty = 0;
  selectedType = 0;
  // Enums
  userStatusEnum = UserStatusEnum;
  roleEnum = RoleEnum;

  // status users
  GREEN_STATUS = GREEN_STATUS;
  ORANGE_STATUS = ORANGE_STATUS;
  RED_STATUS = RED_STATUS;

  constructor(private statisticsService: StatisticsService,
              private userService: UserService,
              private specialtiesService: SpecialtiesService
   ) {
    this.filterUtils = new FilterUtils(this.filters);
  }

  ngOnInit(): void {
    this.getUserStatus();
    this.getSpecialties();
    this.getTotalConsentsByDoctor();
  
  }

  addFilter(name: string, filter: string) {
    // If we filter the requested page will be 0
    this.pagination.page = 0;
    this.filterUtils.addFilter(name, filter);
    this.getTotalConsentsByDoctor();
  }

  removeFilter(name: string) {
    // If we filter the requested page will be 0
    this.pagination.page = 0;
    this.filterUtils.removeFilter(name);
    this.getTotalConsentsByDoctor();
  }

  addStatusFilter(statusId: string) {   
    // 0 means all statuses  
    if (statusId != '0') {
      this.addFilter('statusId', statusId);
    } else {
      this.removeFilter('statusId');
    }
  }

  addSpecialtyFilter(specialtyId: string) {    
    // 0 means all specialtyes
    if (specialtyId != '0') {
      this.addFilter('specialtiesId', specialtyId);
    } else {
      this.removeFilter('specialtiesId');
    }
  }

  addTypeFilter(typeId: string) {   
    // 0 means all typees   
    if (typeId != '0') {    
      this.addFilter('roleId', typeId);
    } else {
      this.removeFilter('roleId');
    }
  }

  paginar(amount: number) {
    this.pagination.page += amount; 
    this.getTotalConsentsByDoctor();   
  }

  getTotalConsentsByDoctor() {
    this.statisticsService.getTotalConsentsByDoctor(this.pagination, this.filters, this.sorters).subscribe({
      next: respuesta => {          
          this.page = respuesta;
          this.consents = this.page.content;         
      }
    })
  }
  getUserStatus() {
    this.userService.getUserStatus().subscribe({
      next: statuses => {
        this.statuses = statuses;
      },
    });
  }

  getSpecialties() {
    this.specialtiesService.getSpecialties().subscribe({
      next: specialties => {
        this.specialties = specialties;
      }
    })
  }
}
