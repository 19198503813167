import { Component, Input } from '@angular/core';
import { TopConsents } from '@app/core/models/input/statistics/top-consents.model';

@Component({
  selector: 'app-consents-most-used',
  templateUrl: './consents-most-used.component.html',
  styleUrls: ['./consents-most-used.component.scss']
})
export class ConsentsMostUsedComponent {
  @Input()  topConsents?: TopConsents[];
}
