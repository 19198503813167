import { Component, OnInit } from '@angular/core';
import { TotalProceduresBySpecialty } from '@app/core/models/front/public';
import { PublicService } from '@app/core/services/public/public.service';

@Component({
  selector: 'app-catalogo',
  templateUrl: './catalogo.component.html',
  styleUrls: ['./catalogo.component.scss'],
})
export class CatalogoComponent implements OnInit {
  specialitiesNumber?: number;
  proceduresNumber?: number;
  specialityName?: string;
  specialities?: TotalProceduresBySpecialty[];

  // Defino los sets de IDs para asignar cada clase
  classOneIds = new Set([31, 5, 2, 1, 3, 49, 25, 4, 35]);
  classTwoIds = new Set([7, 27, 10, 29, 33, 32, 26, 44, 36, 21, 20]);
  classThreeIds = new Set([30, 8, 34, 24, 42, 37, 22]);

  constructor(private publicService: PublicService) {}

  ngOnInit(): void {
    this.getSpecialtiesAndProcedures();    
  }

  handleChildSpecialities(specialities: TotalProceduresBySpecialty[]) {
    this.specialities = specialities;          
  }

  getSpecialtiesAndProcedures() {
    this.publicService.getTotalSpecialtiesAndProcedures().subscribe({
      next: data => {
        this.specialitiesNumber = data.specialties;
        this.proceduresNumber = data.procedures;
      },
    });
  }


  putClassBySpecialityId(id: any) {
    if (this.classOneIds.has(id)) {
    return 'one';
    } else if (this.classTwoIds.has(id)) {
    return 'two';
    } else if (this.classThreeIds.has(id)) {
    return 'three';
    } else {
    return 'four';
    }
    }
}
