import { Component, Input, ViewEncapsulation } from '@angular/core';
import { BarChartData } from '@app/core/models/input/statistics/consents-last-six-months.model';




@Component({
  selector: 'app-ci-six-months',
  templateUrl: './ci-six-months.component.html',
  styleUrls: ['./ci-six-months.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class CiSixMonthsComponent  {

@Input() consentsLastSixMonths?: BarChartData[]


// options
showXAxis = true;
showYAxis = true;
gradient = false;
showLegend = false;


colorScheme = {
  domain: ['#accee4', '#accee4', '#accee4', '#accee4']

};



}
