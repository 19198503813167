enum UserStatusEnum {
    ACTIVO = 1,
    PENDIENTE = 2,
    BLOQUEADO = 3,
    NO_ACTIVO = 4,
    RECHAZADO_TPV = 5,
    RENOVADO = 6,
    RECHAZO_TPV_RENOV = 7,
    PDTE_CONFIRMACION = 8,
    PDTE_CONTRATO_SERVICIO = 9,
    PDTE_FIRMA_DATOS = 10,
    PDTE_VALIDACION = 11,
    BAJA = 12
}

export default UserStatusEnum