<div class="box_container">
    <div class="box box_data" [ngClass]="{'box2': this.newPatient, 'box_consent': !this.newPatient}">
        <div class="buttons button_edit" *ngIf="inEdit">
            <p class="small">{{"PRIVATE.PATIENT.DATA-PATIENT.TITLE-EDIT" | translate }}</p>
            <button class="button tipo-second-color back-white-color" (click)="changeEdit()">
                <fa-icon [icon]="faPenToSquare" class="icono fa-xl"></fa-icon>
            </button>
        </div>
        <form [formGroup]="dataPatient" class="form_datapatient" >
            <div class="input">
                <label for="name" class="name">{{ "PRIVATE.PATIENT.DATA-PATIENT.NAME" | translate }}</label>
                <input type="text" formControlName="name" id="name" [class.back_blue]="this.isBlue" [ngClass]="{'wrong': shouldShowError('name')}">
            </div>
            <p class="message_error wrongtext" *ngIf="(this.dataPatient.get('name')?.errors?.['required'] || this.dataPatient.get('name')?.errors?.['minlength']) && this.isSubmit">{{"PRIVATE.PATIENT.DATA-PATIENT.MESSAGE-FIELD-NAME" | translate }}</p>
            <div class="input">
                <label for="surname" class="surname">{{ "PRIVATE.PATIENT.DATA-PATIENT.SURNAME" | translate }}</label>
                <input type="text" formControlName="surname" id="surname" [class.back_blue]="this.isBlue"  [ngClass]="{'wrong': shouldShowError('surname')}">
            </div>
             <p class="message_error wrongtext" *ngIf="(this.dataPatient.get('surname')?.errors?.['required'] || this.dataPatient.get('surname')?.errors?.['minlength']) && this.isSubmit">{{"PRIVATE.PATIENT.DATA-PATIENT.MESSAGE-FIELD-NAME" | translate }}</p>
            <div class="input">
                <label for="surnametwo" class="surnametwo">{{ "PRIVATE.PATIENT.DATA-PATIENT.SURNAMETWO" | translate }}</label>
                <input type="text" formControlName="surnametwo" id="surnametwo" [class.back_blue]="this.isBlue" >
            </div>

         
            <div class="input input_radio" >
                <label for="gender" class="gender">{{ "PRIVATE.PATIENT.DATA-PATIENT.GENRE" | translate }}</label>
                     <input type="radio" formControlName="gender" value="Mujer" id="woman">
                     <label id="woman_label" [ngClass]="{'isCheckedWoman':isCheckedWoman}" (click)="checked(genderEnum.WOMAN)">{{ "PRIVATE.PATIENT.DATA-PATIENT.FEMALE" | translate }}</label>
                     
                     <input type="radio" formControlName="gender" value="Hombre" id="man" >
                     <label id="man_label" [ngClass]="{'isCheckedMan':isCheckedMan}" (click)="checked(genderEnum.MAN)" >{{ "PRIVATE.PATIENT.DATA-PATIENT.MALE" | translate }}</label>                     
            </div>
           

            <div class="input">
                <label for="nif" class="nif">{{ "PRIVATE.PATIENT.DATA-PATIENT.DOCUMENT" | translate }}</label>
                <input type="text" formControlName="nif" id="nif"  [class.back_blue]="this.isBlue" [ngClass]="{'wrong': shouldShowError('nif')}" required>
            </div>
            <p class="message_error wrongtext" *ngIf="this.dataPatient.get('nif')?.errors?.['pattern'] && this.isSubmit">{{"PRIVATE.PATIENT.DATA-PATIENT.MESSAGE-FIELD-DOCUMENT" | translate }}</p>

            <div class="input input_date">
                <label for="datebirth" class="datebirth">{{ "PRIVATE.PATIENT.DATA-PATIENT.BIRTHDATE" | translate }}</label>
                <input type="date" formControlName="datebirth" id="datebirth" [class.back_blue]="this.isBlue" [ngClass]="{'wrong': shouldShowError('date')}" >
            </div> 
            <p class="message_error wrongtext" *ngIf="this.dataPatient.get('datebirth')?.errors?.['required'] && this.isSubmit">{{"PRIVATE.PATIENT.DATA-PATIENT.MESSAGE-FIELD-BIRTHDATE" | translate }}</p>

            <div class="input">                
                <label for="phone">{{ "PRIVATE.PATIENT.DATA-PATIENT.PHONE" | translate }}</label>
                <select formControlName="phonePrefix" class="phonePrefix" [class.back_blue]="this.isBlue" >                   
                    <option *ngFor= "let prefix of phonePrefixes" value="{{prefix.countryCode}}" >{{prefix.countryCode}}</option>
                </select> 
                <input type="tel" formControlName="phone" class="phone"  [class.back_blue]="this.isBlue" [ngClass]="{'wrong': shouldShowError('phone')}">
            </div>
            <p class="message_error wrongtext" *ngIf="this.dataPatient.get('phone')?.errors?.['pattern'] && this.isSubmit">{{"PRIVATE.PATIENT.DATA-PATIENT.MESSAGE-FIELD-PHONE" | translate }}</p>

            <div class="input">
                <label for="email" class="email">{{ "PRIVATE.PATIENT.DATA-PATIENT.EMAIL" | translate }}</label>
                <input type="email" formControlName="email" id="email" [class.back_blue]="this.isBlue"  [ngClass]="{'wrong': shouldShowError('email')}" >
            </div>
            <p class="message_error wrongtext" *ngIf="this.dataPatient.get('email')?.errors?.['email'] && this.isSubmit">{{"PRIVATE.PATIENT.DATA-PATIENT.MESSAGE-FIELD-EMAIL" | translate }}</p>

            <div class="input">
                <label for="history" class="history">{{ "PRIVATE.PATIENT.DATA-PATIENT.CLINIC-HISTORY" | translate }}</label>
                <input type="text" formControlName="history" id="history" [class.back_blue]="this.isBlue"  >
            </div>
            <div class="input" *ngIf="inEdit">
                <label for="id" class="id">{{'PRIVATE.PATIENT.LIST-PATIENTS.TITLE-ID' | translate}}</label>
                <input type="text" formControlName="id" id="id">
            </div>
            <div class="input" *ngIf="dataPatient.controls['external_id'].value !== null">
                <label for="external_id" class="external_id">{{'PRIVATE.PATIENT.DATA-PATIENT.EXTERNAL-ID' | translate}}</label>
                <input type="text" formControlName="external_id" id="external_id">
            </div>     

            <div class="input input_state">
                <label for="state2" class="state">{{ "PRIVATE.PATIENT.DATA-PATIENT.STATE" | translate }}</label>
                <span>{{ "PRIVATE.PATIENT.DATA-PATIENT.INACTIVE" | translate }}</span>
                <label for="state" class="switch-button-label" [ngClass]="{'isCheckedState':isCheckedState}"></label>
                <input type="checkbox" formControlName="state" class="switch-button-checkbox"  >                
                <span>{{ "PRIVATE.PATIENT.DATA-PATIENT.ACTIVE" | translate }}</span>
            </div>
            <!-- Generar RGPD -->
            <div class="input input_gdpr" *ngIf="isNewGDPR && !this.roleService.hasRole(FREEMIUM_ROLES)"  >
                <label for="gdpr2" class="gdpr">{{"PRIVATE.PATIENT.DATA-PATIENT.GDPR" | translate}}</label>
                
                <label for="gdpr" class="switch-button-label2" [ngClass]="{'isCheckedGdpr':isCheckedGdpr}" (click)="checkedGdpr()"></label>
                <input type="checkbox" formControlName="gdpr" class="switch-button-checkbox2"  >                
                <span>{{"PRIVATE.PATIENT.DATA-PATIENT.GENERATE-GDPR" | translate}}</span>
            </div>
            <app-generate-doc 
            *ngIf="isNewGDPR && !this.roleService.hasRole(FREEMIUM_ROLES) && isCheckedGdpr" 
            (formGroupEvent)="handleChildFormGroup($event)" 
            (selectedOutputTypeChange)="onSelectedOutputTypeChange($event)"
            (selectedDevice)="onSelectedDevice($event)"
            [isPatientCreated]="false"
            [patientFromForm]="dataPatient.value" 
            [isCheckedLegal1]="isCheckedLegal1"
            [isCheckedLegal2]="isCheckedLegal2"></app-generate-doc>
           
            
            
            <div class="input legal">
                <label for="legalre1" class="legalre" id="legalrep1"
                value="oneRepresentative1"
                [ngClass]="{'isCheckedLegal1':isCheckedLegal1}"
                (click)="CheckedLegal1()">{{ "PRIVATE.PATIENT.DATA-PATIENT.LEGAL-REP-ONE" | translate }}</label>
                <input type="checkbox" formControlName="legalre1" id="legalre1"
                [(ngModel)]="legalRepresentative1">
            </div>

            <div class="buttons buttons_save" *ngIf="!inEdit || !isDisabled">
                <button class="button tipo-white-color back-blue-color" type="submit" (click)="onSubmit()" >
                    <fa-icon [icon]="faFileExport" class="icono iconono"></fa-icon>
                    {{ "PRIVATE.PATIENT.DATA-PATIENT.BUTTON-SAVE" | translate }}
                </button>
                <button (click)="refresh()" class="button tipo-main-color back-grey-color" >
                    {{ "PRIVATE.PATIENT.DATA-PATIENT.BUTTON-CANCEL" | translate }}
                </button>
            </div>
        </form>
    </div>
    <div class="box box_relegal" *ngIf="legalRepresentative1" [ngClass]="{'box2': this.newPatient, 'box_consent': !this.newPatient}">
        <form [formGroup]="dataPatient" class="form_datapatient" >
            <div class="reprelegal reprelegal1">
                <p class="subtitle tipo-second-color bold">{{ "PRIVATE.PATIENT.DATA-PATIENT.LEGAL-REP-ONE" | translate }}</p>
                <div class="input">
                    <label for="name1" class="name">{{ "PRIVATE.PATIENT.DATA-PATIENT.NAME" | translate }}</label>
                    <input type="text" formControlName="name1" id="name1" [class.back_blue]="this.isBlue"  [ngClass]="{'wrong': shouldShowError('name1')}" >
                </div>
                <p class="message_error wrongtext" *ngIf="(this.dataPatient.get('name1')?.errors?.['required'] || this.dataPatient.get('name1')?.errors?.['minlength']) &&  this.isSubmit">{{"PRIVATE.PATIENT.DATA-PATIENT.MESSAGE-FIELD-NAME" | translate }}</p>
               
                <div class="input">
                    <label for="surname1" class="surname">{{ "PRIVATE.PATIENT.DATA-PATIENT.SURNAME" | translate }}</label>
                    <input type="text" formControlName="surname1" id="surname1" [class.back_blue]="this.isBlue"  [ngClass]="{'wrong': shouldShowError('surname1')}">
                </div>
                <p class="message_error wrongtext" *ngIf="(this.dataPatient.get('surname1')?.errors?.['required'] || this.dataPatient.get('surname1')?.errors?.['minlength']) &&  this.isSubmit">{{"PRIVATE.PATIENT.DATA-PATIENT.MESSAGE-FIELD-NAME" | translate }}</p>

                <div class="input">
                    <label for="surnametwo1" class="surnametwo1">{{ "PRIVATE.PATIENT.DATA-PATIENT.SURNAMETWO" | translate }}</label>
                    <input type="text" formControlName="surnametwo1" [class.back_blue]="this.isBlue"  id="surnametwo1">
                </div>

                <div class="input">
                    <label for="parenhood1" class="parenhood">{{ "PRIVATE.PATIENT.DATA-PATIENT.PARENT-HOOD" | translate }}</label>
                    <input type="text" formControlName="parenhood1" [class.back_blue]="this.isBlue"  id="parenhood1" >
                </div>

                <div class="input">
                    <label for="nif1" class="nif">{{ "PRIVATE.PATIENT.DATA-PATIENT.DOCUMENT" | translate }}</label>
                    <input type="text" formControlName="nif1" id="nif1"  [class.back_blue]="this.isBlue" [ngClass]="{'wrong': shouldShowError('nif1')}">
                </div>
                <p class="message_error wrongtext" *ngIf="this.dataPatient.get('nif1')?.errors?.['pattern'] && this.isSubmit">{{"PRIVATE.PATIENT.DATA-PATIENT.MESSAGE-FIELD-DOCUMENT" | translate }}</p>

                <div class="input input_date">
                    <label for="datebirth1" class="datebirth">{{ "PRIVATE.PATIENT.DATA-PATIENT.BIRTHDATE" | translate }}</label>
                    <input type="date" formControlName="datebirth1" id="datebirth1"  [class.back_blue]="this.isBlue" >
                </div>

                <div class="input">
                    <label for="phone1">{{ "PRIVATE.PATIENT.DATA-PATIENT.PHONE" | translate }}</label>
                    <select formControlName="phonePrefix1" class="phonePrefix" [class.back_blue]="this.isBlue" >                   
                        <option *ngFor= "let prefix of phonePrefixes" value="{{prefix.countryCode}}" >{{prefix.countryCode}}</option>
                    </select> 
                    <input type="tel" formControlName="phone1" class="phone" [class.back_blue]="this.isBlue"  [ngClass]="{'wrong': shouldShowError('phone1')}">
                </div>
                <p class="message_error wrongtext" *ngIf="this.dataPatient.get('phone1')?.errors?.['pattern'] && this.isSubmit">{{"PRIVATE.PATIENT.DATA-PATIENT.MESSAGE-FIELD-PHONE" | translate }}</p>

                <div class="input">
                    <label for="email1" class="email">{{ "PRIVATE.PATIENT.DATA-PATIENT.EMAIL" | translate }}</label>
                    <input type="email" formControlName="email1" id="email1" [class.back_blue]="this.isBlue"  [ngClass]="{'wrong': shouldShowError('email1')}">
                </div>
                <p class="message_error wrongtext" *ngIf="this.dataPatient.get('email1')?.errors?.['email'] && this.isSubmit">{{"PRIVATE.PATIENT.DATA-PATIENT.MESSAGE-FIELD-EMAIL" | translate }}</p>

                <div class="input legal">
                    <label for="legalre2" class="legalre"  id="legalrep2"
                    value="oneRepresentative2"
                    [ngClass]="{'isCheckedLegal2':isCheckedLegal2}"
                    (click)="CheckedLegal2()">{{ "PRIVATE.PATIENT.DATA-PATIENT.LEGAL-REP-TWO" | translate }}</label>
                    <input type="checkbox" formControlName="legalre2" id="legalre2" 
                    [(ngModel)]="legalRepresentative2" >
                </div>
            </div>

            <div class="reprelegal reprelegal2" *ngIf="legalRepresentative2">
                <p class="subtitle tipo-second-color bold">{{ "PRIVATE.PATIENT.DATA-PATIENT.LEGAL-REP-TWO" | translate }}</p>
                <div class="input">
                    <label for="name2" class="name">{{ "PRIVATE.PATIENT.DATA-PATIENT.NAME" | translate }}</label>
                    <input type="text" formControlName="name2" id="name2" [class.back_blue]="this.isBlue"  [ngClass]="{'wrong': shouldShowError('name2')}">
                </div>
                <p class="message_error wrongtext" *ngIf="(this.dataPatient.get('name2')?.errors?.['required'] || this.dataPatient.get('name2')?.errors?.['minlength']) &&  this.isSubmit">{{"PRIVATE.PATIENT.DATA-PATIENT.MESSAGE-FIELD-NAME" | translate }}</p>
               
                <div class="input">
                    <label for="surname2" class="surname">{{ "PRIVATE.PATIENT.DATA-PATIENT.SURNAME" | translate }}</label>
                    <input type="text" formControlName="surname2" id="surname2" [class.back_blue]="this.isBlue"  [ngClass]="{'wrong': shouldShowError('surname2')}">
                </div>
                <p class="message_error wrongtext" *ngIf="(this.dataPatient.get('surname2')?.errors?.['required'] || this.dataPatient.get('surname2')?.errors?.['minlength']) &&  this.isSubmit">{{"PRIVATE.PATIENT.DATA-PATIENT.MESSAGE-FIELD-NAME" | translate }}</p>

                <div class="input">
                    <label for="surnametwo2" class="surnametwo2">{{ "PRIVATE.PATIENT.DATA-PATIENT.SURNAMETWO" | translate }}</label>
                    <input type="text" formControlName="surnametwo2" id="surnametwo2" [class.back_blue]="this.isBlue" >
                </div>
                <div class="input">
                    <label for="parenhood2" class="parenhood">{{ "PRIVATE.PATIENT.DATA-PATIENT.PARENT-HOOD" | translate }}</label>
                    <input type="text" formControlName="parenhood2" id="parenhood2"  [class.back_blue]="this.isBlue" >
                </div>

                <div class="input">
                    <label for="nif2" class="nif">{{ "PRIVATE.PATIENT.DATA-PATIENT.DOCUMENT" | translate }}</label>
                    <input type="text" formControlName="nif2" id="nif2" [class.back_blue]="this.isBlue"  [ngClass]="{'wrong': shouldShowError('nif2')}">
                </div>
                <p class="message_error wrongtext" *ngIf="this.dataPatient.get('nif2')?.errors?.['pattern'] && this.isSubmit">{{"PRIVATE.PATIENT.DATA-PATIENT.MESSAGE-FIELD-DOCUMENT" | translate }}</p>

                <div class="input input_date">
                    <label for="datebirth2" class="datebirth">{{ "PRIVATE.PATIENT.DATA-PATIENT.BIRTHDATE" | translate }}</label>
                    <input type="date" formControlName="datebirth2" id="datebirth2"  [class.back_blue]="this.isBlue" >
                </div>

                <div class="input">
                    <label for="phone2">{{ "PRIVATE.PATIENT.DATA-PATIENT.PHONE" | translate }}</label>
                    <select formControlName="phonePrefix2" class="phonePrefix"  [class.back_blue]="this.isBlue" >                   
                        <option *ngFor= "let prefix of phonePrefixes" value="{{prefix.countryCode}}" >{{prefix.countryCode}}</option>
                    </select> 
                    <input type="tel" formControlName="phone2" class="phone" [class.back_blue]="this.isBlue"  [ngClass]="{'wrong': shouldShowError('phone2')}">
                </div>
                <p class="message_error wrongtext" *ngIf="this.dataPatient.get('phone2')?.errors?.['pattern'] && this.isSubmit">{{"PRIVATE.PATIENT.DATA-PATIENT.MESSAGE-FIELD-PHONE" | translate }}</p>

                <div class="input">
                    <label for="email2" class="email">{{ "PRIVATE.PATIENT.DATA-PATIENT.EMAIL" | translate }}</label>
                    <input type="email" formControlName="email2" id="email2" [class.back_blue]="this.isBlue"  [class.back_blue]="this.isBlue"  [ngClass]="{'wrong': shouldShowError('email2')}">
                </div>
                <p class="message_error wrongtext" *ngIf="this.dataPatient.get('email2')?.errors?.['email'] && this.isSubmit">{{"PRIVATE.PATIENT.DATA-PATIENT.MESSAGE-FIELD-EMAIL" | translate }}</p>
            </div>
        </form>
    </div>
</div>
