<div class="wrapper">
    <div class="maxwidth">
      <div class="list">
        <h4 class="title tipo-second-color bold" id="title_table">{{'SUPER-ADMIN.DDS-BY-COMPANY.TITLE-DDS-BY-COMPANY' | translate}}</h4>
        <table class="table" aria-describedby="title_table">
          <thead class="tipo-second-color bold">          
            <th scope="col" class="document">
                <span>{{'SUPER-ADMIN.DDS-BY-COMPANY.DATA-DOCUMENT' | translate}}</span>                    
              </th>  
            <th scope="col" class="company_name">
              <span>{{'SUPER-ADMIN.LIST-ADMINS.DATA-COMPANY' | translate}}</span>                  
            </th>
            <th scope="col" class="num_docs">
              <span>{{'SUPER-ADMIN.DDS-BY-COMPANY.DATA-NUM-DDS' | translate}}</span>
        </th>
            <th scope="col" class="date_on">
              <div class="column">   
                  <span>{{'SUPER-ADMIN.CONSENT-BY-SPECIALTY.DATA-DATE-ON' | translate}}</span>
                  <input type="text" name="filterDocument" (debounceInput)="addFilter('dateOn', filterDocument.value)" [appDebounceInput]="300" #filterDocument id="filterDocument" placeholder="{{'PRIVATE.PATIENT.LIST-PATIENTS.FILTER' | translate }}">
              </div>
          </th>
          <th scope="col" class="date_off">
              <div class="column">  
                  <span>{{'SUPER-ADMIN.CONSENT-BY-SPECIALTY.DATA-DATE-OFF' | translate}}</span>
                  <input type="text" name="filterDocument" (debounceInput)="addFilter('dateOff', filterDocument.value)" [appDebounceInput]="300" #filterDocument id="filterDocument" placeholder="{{'PRIVATE.PATIENT.LIST-PATIENTS.FILTER' | translate }}">
              </div>
          </th>
          </thead>
          <tbody>
            <tr *ngFor="let document of documents">              
              <td class="document">{{document.documentTypeName}}</td>
              <td class="company_name">{{document.businessName}}</td>
              <td class="num_docs">{{document.count}}</td>
              <td class="date_on" >{{document.minDate | date : 'dd/MM/YYYY'}}</td>   
              <td class="date_off" >{{document.maxDate | date : 'dd/MM/YYYY'}}</td>  
            </tr>
          </tbody>
        </table>
       
      </div>
    </div>
  </div>
  