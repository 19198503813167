<div class="data">
  <span class="title_data">
    {{ 'PRIVATE.CONSENTS.DETAIL-CONSENT.TITLE-STATE' | translate }}
  </span>
  <span class="content state">{{ consent?.status?.name }}</span>
</div>
<p class="tipo-second-color bold section">
  {{ 'PRIVATE.CONSENTS.DETAIL-CONSENT.TITLE-PATIENT' | translate }}
</p>
<div class="data">
  <span class="title_data">
    {{ 'PRIVATE.CONSENTS.DETAIL-CONSENT.TITLE-NAME' | translate }}
  </span>
  <span class="content name">
    {{ consent?.patient?.name }} {{ consent?.patient?.firstLastName }}
    {{ consent?.patient?.secondLastName }}
  </span>
</div>
<div class="data">
  <span class="title_data">
    {{ 'PRIVATE.CONSENTS.DETAIL-CONSENT.TITLE-DOCUMENT' | translate }}
  </span>
  <span class="content name">
    {{ consent?.patient?.documentNumber }} 
  </span>
</div>
<div class="data">
  <span class="title_data">
    {{ 'PRIVATE.CONSENTS.DETAIL-CONSENT.TITLE-NUMBER' | translate }}
  </span>
  <span class="content number">{{ consent?.patient?.healthHistoryExt }}</span>
</div>
<p class="tipo-second-color bold section">
  {{ 'PRIVATE.CONSENTS.DETAIL-CONSENT.TITLE-DATA-CONSENT' | translate }}
</p>

<div class="data">
  <span class="title_data">
    {{ 'PRIVATE.CONSENTS.DETAIL-CONSENT.TITLE-SPECIALITY' | translate }}
  </span>
  <span class="content speciality">{{ consent?.specialty }}</span>
</div>
<div class="data">
  <span class="title_data">
    {{ 'PRIVATE.CONSENTS.DETAIL-CONSENT.TITLE-PROCESS' | translate }}
  </span>
  <span class="content process">{{ consent?.procedureName }}</span>
</div>
<div class="data">
  <span class="title_data">
    {{ 'PRIVATE.CONSENTS.DETAIL-CONSENT.TITLE-TYPE' | translate }}
  </span>
  <span class="content type">{{ consent?.surgeryType?.name }}</span>
</div>
<p class="tipo-second-color bold section">
  {{ 'PRIVATE.CONSENTS.DETAIL-CONSENT.TITLE-PERSONALIZATION' | translate }}
</p>
<div class="data">
  <span class="title_data">
    {{ 'PRIVATE.CONSENTS.DETAIL-CONSENT.TITLE-CONSENT' | translate }}
  </span>
  <span class="content consent">{{ consent?.consent | yesNo }}</span>
</div>
<div class="data">
  <span class="title_data">
    {{ 'PRIVATE.CONSENTS.DETAIL-CONSENT.TITLE-BE-INFORM' | translate }}
  </span>
  <span class="content be_inform">{{ consent?.beNotified | yesNo }}</span>
</div>
<div class="data">
  <span class="title_data">
    {{ 'PRIVATE.CONSENTS.DETAIL-CONSENT.TITLE-SECOND-TITLE' | translate }}
  </span>
  <span class="content second_title">
    {{ consent?.consentAdditionalData?.customerTitle }}
  </span>
</div>
<div class="data">
  <span class="title_data">
    {{ 'PRIVATE.CONSENTS.DETAIL-CONSENT.TITLE-RISKS' | translate }}
  </span>
  <span class="content risks">
    {{ consent?.consentAdditionalData?.customerRisks }}
  </span>
</div>
<div class="data">
  <span class="title_data">
    {{ 'PRIVATE.CONSENTS.DETAIL-CONSENT.TITLE-OBSERVATIONS' | translate }}
  </span>
  <span class="content observations">
    {{ consent?.consentAdditionalData?.otherObservations }}
  </span>
</div>
<div class="data">
  <span class="title_data">
    {{ 'PRIVATE.CONSENTS.DETAIL-CONSENT.TITLE-CIE-ONE' | translate }}
  </span>
  <span class="content cie_one">{{ consent?.consentAdditionalData?.diagnosticCie10 }}</span>
</div>
<div class="data">
  <span class="title_data">
    {{ 'PRIVATE.CONSENTS.DETAIL-CONSENT.TITLE-CIE-TWO' | translate }}
  </span>
  <span class="content cie_two">{{ consent?.consentAdditionalData?.procedureCie10 }}</span>
</div>
<div class="data">
  <span class="title_data">
    {{ 'PRIVATE.CONSENTS.DETAIL-CONSENT.TITLE-GEN-DATA' | translate }}
  </span>
  <span class="content gen_data">{{ consent?.creationDate | date: 'dd/MM/YYYY'}}</span>
</div>
<div class="data" *ngIf="false">
  <!-- TODO Pensar lo de la fecha de entrega -->
  <span class="title_data">
    {{ 'PRIVATE.CONSENTS.DETAIL-CONSENT.TITLE-GIVEN-DATA' | translate }}
  </span>
  <span class="content given_data">XXXXXXXXXXX</span>
</div>
<p class="tipo-second-color bold section">
  {{ 'PRIVATE.CONSENTS.DETAIL-CONSENT.TITLE-SIGN' | translate }}
</p>
<div class="data">
  <span class="title_data">
    {{'PRIVATE.PATIENT.LIST-PATIENTS.TITLE-ID' | translate}}
  </span>
  <span class="content id">{{ consent?.id }}</span>
</div>
<div class="data" *ngIf="consent?.externalId !== null">
  <span class="title_data">
    {{'PRIVATE.CONSENTS.DETAIL-CONSENT.EXTERNAL-ID' | translate}}
  </span>
  <span class="content external_id">{{ consent?.externalId }}</span>
</div>
<div class="data">
  <span class="title_data">
    {{ 'PRIVATE.CONSENTS.DETAIL-CONSENT.TITLE-SIGN-TYPE' | translate }}
  </span>
  <span class="content sign_type">{{ consent?.outputType?.name }}</span>
</div>
<div class="data">
  <span class="title_data">
    {{ 'PRIVATE.CONSENTS.DETAIL-CONSENT.TITLE-GUID' | translate }}
  </span>
  <span class="content id">{{ consent?.uuid }}</span>
</div>
<div class="data">
  <span class="title_data">
    {{ 'PRIVATE.CONSENTS.DETAIL-CONSENT.TITLE-DEVICE' | translate }}
  </span>
  <span class="content device">
    {{consent?.digitalCiSignatures?.[0]?.device}}
  </span>
</div>
<div class="data">
  <span class="title_data">
    {{ 'PRIVATE.CONSENTS.DETAIL-CONSENT.TITLE-SIGN-DATE' | translate }}
  </span>
  <span class="content sign_date">{{ consent?.signatureDate  | date: 'dd/MM/YYYY hh:mm:ss' }}</span>
</div>
<div
  class="buttons"
  *ngIf="(user.uuid === consent?.doctor?.uuid) &&
     (consent?.status?.id === StatusEnum.SIGNED ||
    consent?.status?.id === StatusEnum.REVOKED ||
    consent?.status?.id === StatusEnum.REVOKED_RL2 ||
    consent?.status?.id === StatusEnum.PENDING ||
    consent?.status?.id === StatusEnum.PENDING_SIGN_RL2 ||
    consent?.status?.id === StatusEnum.PENDING_BIO_SIGN ||
    consent?.status?.id === StatusEnum.PRINTED||
    consent?.status?.id === StatusEnum.EXPIRED);
    else noDoctor
  ">
  <button 
    class="button tipo-white-color back-blue-color"
    (click)="downloadCiFile(consent?.uuid)"
    [disabled]="downloadingFile">
    <fa-icon [icon]="faEye" class="icono" *ngIf="!downloadingFile"></fa-icon>
    <fa-icon [icon]="faSpinner" class="icono" animation="spin-pulse" *ngIf="downloadingFile"></fa-icon>
    {{ 'PRIVATE.CONSENTS.DETAIL-CONSENT.BUTTON-OPEN-CI' | translate }}
  </button>
  <!-- Mostrar cuando es firma remota pendiente de bio o no -->
  <button
    *ngIf="OutputType.DIGITAL_REMOTE === consent?.outputType?.id && (consent?.status?.id === StatusEnum.PENDING_BIO_SIGN || consent?.status?.id === StatusEnum.SIGNED)"
    class="button tipo-white-color back-blue-color"
    [disabled]="downloadingReport"
    (click)="downloadCiEvidencesFile(consent?.uuid)">
    <fa-icon [icon]="faReceipt" class="icono" *ngIf="!downloadingReport"></fa-icon>
    <fa-icon [icon]="faSpinner" class="icono" animation="spin-pulse" *ngIf="downloadingReport"></fa-icon>
    {{ 'PRIVATE.CONSENTS.DETAIL-CONSENT.BUTTON-OPEN-EVIDENCES' | translate }}
  </button>
</div>
<ng-template #noDoctor>
<div
  class="buttons"
  *ngIf="
    canDownloadFile && (consent?.status?.id === StatusEnum.SIGNED ||
    consent?.status?.id === StatusEnum.REVOKED ||
    consent?.status?.id === StatusEnum.REVOKED_RL2 ||
    consent?.status?.id === StatusEnum.PENDING ||
    consent?.status?.id === StatusEnum.PENDING_SIGN_RL2 ||
    consent?.status?.id === StatusEnum.PENDING_BIO_SIGN ||
    consent?.status?.id === StatusEnum.PRINTED||
    consent?.status?.id === StatusEnum.EXPIRED)
  ">
  <button
    class="button tipo-white-color back-blue-color"
    (click)="downloadCiFile(consent?.uuid)"
    [disabled]="downloadingFile">
    <fa-icon [icon]="faEye" class="icono" *ngIf="!downloadingFile"></fa-icon>
    <fa-icon [icon]="faSpinner" class="icono" animation="spin-pulse" *ngIf="downloadingFile"></fa-icon>
    {{ 'PRIVATE.CONSENTS.DETAIL-CONSENT.BUTTON-OPEN-CI' | translate }}
  </button>
  <!-- Mostrar cuando es firma remota pendiente de bio o no-->
  <button
    *ngIf="OutputType.DIGITAL_REMOTE === consent?.outputType?.id && (consent?.status?.id === StatusEnum.PENDING_BIO_SIGN || consent?.status?.id === StatusEnum.SIGNED)"
    [disabled]="downloadingReport"
    class="button tipo-white-color back-blue-color"
    (click)="downloadCiEvidencesFile(consent?.uuid)">
    <fa-icon [icon]="faReceipt" class="icono" *ngIf="!downloadingReport"></fa-icon>
    <fa-icon [icon]="faSpinner" class="icono" animation="spin-pulse" *ngIf="downloadingReport"></fa-icon>
    {{ 'PRIVATE.CONSENTS.DETAIL-CONSENT.BUTTON-OPEN-EVIDENCES' | translate }}
  </button>
</div>
</ng-template>