<div class="back-white-color box_title">
    <h4 class="subtitle tipo-second-color regular center">{{'SUPER-ADMIN.CLIENT.DATA-COMPANY.TITLE-DATA-COMPANY' | translate}}</h4>
</div>
<div class="box-container">
    <div class="box">
        <form [formGroup]="dataAccess">
            <div class="form">
                <div class="row">
                    <div class="input">
                        <label for="username">*{{'SUPER-ADMIN.CLIENT.DATA-ACCESS.LABEL-USER' | translate}}</label>
                        <input type="text" id="username" formControlName="username" [ngClass]="{ wrong: shouldShowError('username')}">
                    </div>
                    <p
                    class="message_error wrongtext small"
                    *ngIf="this.dataAccess.get('username')?.errors?.['required'] && this.isSubmit">
                    {{'SUPER-ADMIN.CLIENT.DATA-ACCESS.MESSAGE-ERROR-USER' | translate}}
                  </p>

                    <div class="input">
                        <label for="password">*{{'SUPER-ADMIN.CLIENT.DATA-ACCESS.LABEL-PASSWORD' | translate}}</label>
                        <input type="password" id="password" formControlName="password" [ngClass]="{ wrong: shouldShowError('password')}">
                    </div>
                    <p
                    class="message_error wrongtext small"
                    *ngIf="this.dataAccess.get('password')?.errors?.['required'] && this.isSubmit">
                    {{'SUPER-ADMIN.CLIENT.DATA-ACCESS.MESSAGE-ERROR-PASS' | translate}}
                  </p>
                </div>
               <div class="row">
                <div class="input">
                    <label for="languageId tipo-main-color regular">{{ "PRIVATE.CONSENTS.NEW-CONSENT.SELECT-LANGUAGE" | translate }}</label>
                        <select formControlName="languageId" id="language" placeholder="Selecciona idioma"  [ngClass]="{ wrong: shouldShowError('languageId')}" [(ngModel)]="selectedLanguage" (change)="selectLanguage()" >                          
                            <option *ngFor= "let language of languages; let i=index" [ngValue]="language" >{{language.name}}</option>
                        </select>
                  </div>
                  <p
                  class="message_error wrongtext small"
                  *ngIf="this.dataAccess.get('languageId')?.errors?.['required'] && this.isSubmit">
                  {{'SUPER-ADMIN.CLIENT.DATA-ACCESS.MESSAGE-ERROR-PASS' | translate}}
                </p>
                <div class="input">
                    <label for="repeat_pass">*{{'SUPER-ADMIN.CLIENT.DATA-ACCESS.LABEL-REPEAT-PASSWORD' | translate}}</label>
                    <input type="password" id="repeat_pass" formControlName="repeatPass" [ngClass]="{ wrong: shouldShowError('repeatPass')}" >
                </div>
                <p
                class="message_error wrongtext small"
                *ngIf="this.dataAccess.get('repeatPass')?.errors?.['required'] && this.isSubmit">
                {{'SUPER-ADMIN.CLIENT.DATA-ACCESS.MESSAGE-ERROR-PASS-DOUBLE'| translate}}
              </p>
               </div>
            </div>          
          
           <div class="buttons">
            <button class="button tipo-white-color back-blue-color" (click)="togglePagesTwoThree()" >
                <fa-icon [icon]="faCircleArrowLeft" class="icono"></fa-icon>
               {{'SUPER-ADMIN.CLIENT.DATA-COMPANY.BUTTON-BACK' | translate}}
            </button>
            <button class="button tipo-white-color back-blue-color" (click)="onSubmit()">                
               {{'SUPER-ADMIN.CLIENT.DATA-HIRING.BUTTON-CONTINUE' | translate}}
               <fa-icon [icon]="faCircleArrowRight" class="icono"></fa-icon>
            </button>
           </div>
        </form>
    </div> 
 </div>


