<div class="iconos">
    <fa-icon [icon]="faCircleArrowLeft" 
    class="icono iconoback tipo-second-color" 
    (click)="goBack()"></fa-icon>
    <fa-icon [icon]="faCircleArrowUp" 
    class="icono iconoup tipo-second-color"  
    *ngIf="scrollUp" 
    (click)="scroll()"></fa-icon>
</div>

