import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Device, DocumentsDevice, UserDevice } from '@app/core/models/input/device.model';
import { EnvironmentService } from '@app/environment.service';
import { of } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DeviceService {

  constructor(private http: HttpClient, private env: EnvironmentService) { }

  private userDevices?: Device[];

  setDevices(devices: Device[]) {
    this.userDevices = devices;
  }

  getUserDevices() {
    // Cache the devices in the service
    if (!this.userDevices) {
      return this.http.get<Device[]>(`${this.env.apiUrl}/device`)
    } else {
      return of(this.userDevices)
    }
  }

  deleteDevice (deviceName:string) {
    return this.http.delete<string>(`${this.env.apiUrl}/device/${deviceName}`);
  }

  getDocumentsByDevice (deviceName:string) {
    return this.http.get<DocumentsDevice[]>(`${this.env.apiUrl}/device/${deviceName}/documents`);
  }

  deleteDocumentInDevice (docGUI:string) {
    return this.http.delete<string>(`${this.env.apiUrl}/device/document/${docGUI}`);
  }

  changeUserDevice (userDevice: UserDevice) {
    const headers = new HttpHeaders({
      'Content-Type':  'application/json'
    })
  ;
    return this.http.put<UserDevice>(`${this.env.apiUrl}/users/device`, JSON.stringify(userDevice), {headers: headers})
  }

}
