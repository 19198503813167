<p class="subtitle tipo-second-color">{{ "PRIVATE.PERSONALIZATION.EDIT-SIGN.TITLE" | translate }}</p>
<p class="regular small">{{ "PRIVATE.PERSONALIZATION.EDIT-SIGN.SUBTITLE-ONE" | translate }}</p>
<p class="regular small">{{ "PRIVATE.PERSONALIZATION.EDIT-SIGN.SUBTITLE-TWO" | translate }}</p>
<div class="box_sign">
    <input type="file" name="sign" id="sign" (change)="uploadSignature($event)">
    <div class="box_img"  *ngIf="userSignature">
    <img [src]="'data:image/'+userSignature.format +';base64,'+ userSignature.content " alt="{{'PRIVATE.PERSONALIZATION.EDIT-SIGN.TITLE' | translate}}" class="sign">     
        <button class="button tipo-second-color back-white-color" (click)="deleteSignature()">
            <fa-icon [icon]="faTrashCan" class="icono"></fa-icon>
        </button>
    </div>
</div>
