import { Component, Input } from '@angular/core';
import ObjectTypeEnum from '@app/core/enums/objectType.enum';
import OutputTypeEnum from '@app/core/enums/output-type.enum';
import StatusEnum from '@app/core/enums/status.enum';
import { OutputType } from '@app/core/models/input/common/output-type.model';
import { Status } from '@app/core/models/input/common/status.model';
import { User } from '@app/core/models/input/user.model';
import { FileService } from '@app/core/services/file/file.service';
import DownloadUtils from '@app/core/utils/download.utils';
import {
  faCircleExclamation,
  faFileCircleCheck,
  faFileImport,
  faFileSignature,
  faFingerprint,
  faFolderOpen,
  faPrint,
  faReceipt,
  faSpinner,
  faTabletScreenButton
} from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-status-actions',
  templateUrl: './status-actions.component.html',
  styleUrls: ['./status-actions.component.scss'],
})
export class StatusActionsComponent {
  constructor(private fileService: FileService) {}

  // Set inputs
  @Input() status?: Status;
  @Input() outputType?: OutputType;
  @Input() downloadUuid?: string;
  @Input() isDoctor = false;
  @Input() doctorUuid?: string;
  @Input() canDownloadFile = false;
  @Input() user?: User;
  @Input() objectType?: string;

  // Import enums for html
  Status = StatusEnum;
  OutputType = OutputTypeEnum;
  ObjectTypeEnum = ObjectTypeEnum;

  // Import icons for html
  faFileSignature = faFileSignature;
  faFileImport = faFileImport;
  faCircleExclamation = faCircleExclamation;
  faTabletScreenButton = faTabletScreenButton;
  faPrint = faPrint;
  faFolderOpen = faFolderOpen;
  faFileCircleCheck = faFileCircleCheck;
  faReceipt = faReceipt;
  faSpinner = faSpinner;
  faFingerprint = faFingerprint;

  downloadingFile = false;
  downloadingReport = false;
  downloadingRemote = false;

  downloadFile(uuid: string): void {
    this.downloadingFile = true;

    if (this.objectType) {
      switch (this.objectType) {
        case ObjectTypeEnum.CONSENT:
          this.downloadCiFile(uuid);
          break;
        case ObjectTypeEnum.RGPD:
          this.downloadRgpdFile(uuid);
          break;
        case ObjectTypeEnum.DIGITAL_DOCUMENT:
          this.downloadDDFile(uuid);
          break;
        default:
          break;
      }
    }
  }

  downloadReportFile(uuid: string): void {
    this.downloadingReport = true;

    if (this.objectType) {
      switch (this.objectType) {
        case ObjectTypeEnum.CONSENT:
          this.downloadConsentReportFile(uuid);
          break;
        case ObjectTypeEnum.RGPD:
          this.downloadRGPDReportFile(uuid);
          break;
        case ObjectTypeEnum.DIGITAL_DOCUMENT:
          this.downloadDDReportFile(uuid);
          break;
        default:
          break;
      }
    }
  }


  downloadRemoteFile(uuid: string): void {
    this.downloadingRemote = true;
    if (this.objectType === ObjectTypeEnum.CONSENT ) {
          this.downloadConsentRemoteFile(uuid);
      }
    }


  downloadCiFile(uuid: string) {
    this.fileService.getConsentPdf(uuid).subscribe({
      next: dataDocument => {
        DownloadUtils.downloadFO(dataDocument);
        this.downloadingFile = false;
      },
    });
  }

  downloadRgpdFile(uuid: string) {
    this.fileService.getRgpdPdf(uuid).subscribe({
      next: dataDocument => {
        DownloadUtils.downloadFO(dataDocument);
        this.downloadingFile = false;
      },
    });
  }

  downloadDDFile(uuid: string) {
    this.fileService.getDigitalDocumentPdf(uuid).subscribe({
      next: dataDocument => {
        DownloadUtils.downloadFO(dataDocument);
        this.downloadingFile = false;
      },
    });
  }

  downloadConsentReportFile(uuid: string) {
    this.fileService.getConsentReportPdf(uuid).subscribe({
      next: dataDocument => {
        DownloadUtils.downloadFO(dataDocument);
        this.downloadingReport = false;
      },
    });
  }

  downloadRGPDReportFile(uuid: string) {
    this.fileService.getRGPDReportPdf(uuid).subscribe({
      next: dataDocument => {
        DownloadUtils.downloadFO(dataDocument);
        this.downloadingReport = false;
      },
    });
  }

  downloadDDReportFile(uuid: string) {
    this.fileService.getDDReportPdf(uuid).subscribe({
      next: dataDocument => {
        DownloadUtils.downloadFO(dataDocument);
        this.downloadingReport = false;
      },
    });
  }

  downloadConsentRemoteFile(uuid: string) {
    this.fileService.getConsentRemotePdf(uuid).subscribe({
      next: dataDocument => {
        DownloadUtils.downloadFO(dataDocument);
        this.downloadingRemote = false;
      }
    })
  }

}
