<div class="wrapper">
  <div class="box box1 box_documents_file" *ngIf="showDocumentsTypes">
    <p class="subtitle tipo-second-color bold">
      {{ 'PRIVATE.PATIENT.SPECIALS-DOCS.TITLE' | translate }}
    </p>
    <div class="file_docs contracts" *ngFor="let category of ddCategories">
      <p class="tipo-main-color title_docs">{{ category?.name }}</p>
      <div
        (click)="setSelectedDigitalDocumentType(documentType)"
        class="file_doc back-light-blue-color"
        *ngFor="let documentType of category?.digitalDocumentTypes">
        <fa-icon
          [icon]="faFileImport"
          class="icono tipo-second-color"></fa-icon>
        <p class="tipo-second-color">{{ documentType?.name }}</p>
      </div>
    </div>
  </div>

  <div class="box box1">
    <div class="buttons">
      <p class="subtitle tipo-second-color bold">
        {{ 'PRIVATE.PATIENT.SPECIALS-DOCS.SUBTITLE' | translate }}
      </p>
      <button
      class="button tipo-second-color back-white-color refresh-button"
      (click)="refreshData()">
      <fa-icon [icon]="faRotate" class="icono"></fa-icon>
    </button>

    </div>
    <table class="table">
      <thead class="tipo-second-color bold">
        <th scope="col" class="id">{{'PRIVATE.PATIENT.LIST-PATIENTS.TITLE-ID' | translate}}</th>
        <th scope="col" class="type">{{'PRIVATE.PATIENT.DIGITAL-DOCUMENTS.TYPE' | translate}}</th>
        <th scope="col" class="date">{{'PRIVATE.PATIENT.DIGITAL-DOCUMENTS.CREATION-DATE' | translate}}</th>
        <th scope="col" class="state">{{'PRIVATE.PATIENT.DOCUMENTS-PATIENT.STATE' | translate}}</th>
        <th scope="col" class="action">{{'PRIVATE.PATIENT.DOCUMENTS-PATIENT.ACTIONS' | translate }}</th>
      </thead>
      <tbody>
        <tr *ngFor="let dd of dds?.content">
          <td class="id">{{dd?.id}}</td>
          <td class="type">{{ dd?.name }}</td>
          <td class="date">{{ dd?.creationDate }}</td>
          <!-- Statuses -->
          <td class="state">
            <app-statuses
              [status]="dd.status"
              [outputType]="dd.outputType"></app-statuses>
          </td>
          <!-- End of Statuses -->
          <!-- Actions -->
          <td class="action">
            <app-status-actions
              [status]="dd.status"
              [downloadUuid]="dd.uuid"
              [canDownloadFile]="true"
              [objectType]="ObjectTypeEnum.DIGITAL_DOCUMENT"
              [outputType]="dd.outputType"
            ></app-status-actions>
          </td>
          <!-- End of Actions -->
        </tr>
      </tbody>
    </table>
    <!-- Mensaje que sale en caso de que no haya documentos -->
   <div class="noconsents center" *ngIf="dds?.totalElements === 0">
    <fa-icon
      [icon]="faFolderOpen"
      class="icono fa-2xl tipo-second-color"></fa-icon>
    <p class="subtitle tipo-second-color bold">
      {{ 'PRIVATE.PATIENT.GENERATE-DD.MESSAGE-NO-DDS' | translate }}
    </p>
  </div>
      <!-- Pagination -->
      <app-paginator
      [totalElements]="dds?.totalElements ?? 0"
      [numbersOfElements]="dds?.numberOfElements ?? 0"
      [totalPages]="dds?.totalPages ?? 0"
      [pageNumber]="pagination.page"
      [lastPage]="dds?.last ?? false"
      (paginate)="paginar($event)"></app-paginator>
    <!-- End of Pagination -->
  </div>
   <!-- TODO para mostrar cuando un documento da error -->
  <!-- <div class="window nodisplay" >
        <app-window-error></app-window-error>
      </div> -->
</div>
