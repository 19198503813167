<div class="wrapper">
  <div class="maxwidth">
    <div class="block_doctor" *appHasRole="GENERATE_CIS">
      <app-buttons-consent
        (buttonClick)="refreshConsents()"
        [refreshingConsents]="refreshingConsents"></app-buttons-consent>
    </div>

    <div class="list">
      <h4 class="title tipo-second-color bold" id="title_table">
        {{ 'PRIVATE.CONSENTS.LIST-CONSENTS.TITLE' | translate }}
      </h4>

      <table class="table" aria-describedby="title_table">
        <thead class="tipo-second-color bold">
          <th scope="col" class="id">{{ 'PRIVATE.PATIENT.LIST-PATIENTS.TITLE-ID' | translate }}</th>
          <th scope="col" class="process_title">
            <div class="column">
              {{ 'PRIVATE.CONSENTS.LIST-CONSENTS.LABEL-PROCESS' | translate }}
              <input
                type="text"
                name="filterProcedure"
                id="filterProcedure"
                (debounceInput)="addFilter('procedureName', filterProcedure.value)"
                [appDebounceInput]="300"
                #filterProcedure
                placeholder="{{ 'PRIVATE.PATIENT.LIST-PATIENTS.FILTER' | translate }}" />
            </div>
          </th>
          <th scope="col" class="specialty">
            <div class="column">
              {{ 'PRIVATE.CONSENTS.LIST-CONSENTS.LABEL-SPECIALITY' | translate }}
              <input
                type="text"
                name="filterSpecialty"
                id="filterSpecialty"
                (debounceInput)="addFilter('specialty', filterSpecialty.value)"
                [appDebounceInput]="300"
                #filterSpecialty
                placeholder="{{ 'PRIVATE.PATIENT.LIST-PATIENTS.FILTER' | translate }}" />
            </div>
          </th>
          <th scope="col" class="date_edit">
            <div class="column">
              {{ 'PRIVATE.CONSENTS.LIST-CONSENTS.LABEL-DATE-EDIT' | translate }}
            </div>
          </th>
          <th scope="col" class="date_sign">
            <div class="column">
              {{ 'PRIVATE.CONSENTS.LIST-CONSENTS.LABEL-DATE-SIGN' | translate }}
            </div>
          </th>
          <th scope="col" class="state">
            <div class="column">
              {{ 'PRIVATE.CONSENTS.LIST-CONSENTS.LABEL-STATE' | translate }}
              <select name="type" id="type" [(ngModel)]="selectedStatus" (ngModelChange)="addStatusFilter($event)">
                <option value="0">
                  {{ 'PRIVATE.CONSENTS.LIST-CONSENTS.OPTION-1' | translate }}
                </option>
                <option *ngFor="let status of statuses; let i = index" [value]="status.id">
                  {{ status.name }}
                </option>
              </select>
            </div>
          </th>
          <th scope="col" class="action">
            {{ 'PRIVATE.CONSENTS.LIST-CONSENTS.LABEL-ACTION' | translate }}
          </th>
        </thead>
        <tbody>
          <tr *ngFor="let consent of consents; let i = index">
            <td class="id">{{ consent.id }}</td>
            <td class="process" (click)="goToConsentDetail(consent)">
              {{ consent.procedureName }}
            </td>
            <td class="specialty" (click)="goToConsentDetail(consent)">
              {{ consent.specialty }}
            </td>
            <td class="date_edit" (click)="goToConsentDetail(consent)">
              <div class="tooltip">
                {{ consent.creationDate | date : 'dd/MM/YYYY' }}
                <p class="text_tip small">
                  {{ consent.creationDate | UTCToLocal }}
                </p>
              </div>
            </td>
            <td class="date_sign" (click)="goToConsentDetail(consent)">
              <div class="tooltip">
                {{ consent.signatureDate | date : 'dd/MM/YYYY' }}
                <p class="text_tip small">
                  {{ consent.signatureDate | UTCToLocal }}
                </p>
              </div>
            </td>
            <!-- Statuses -->
            <td class="state">
              <app-statuses
                [status]="consent.status"
                [outputType]="consent.outputType"
                [consent]="consent"></app-statuses>
            </td>
            <!-- End of Statuses -->
            <!-- Actions -->
            <td class="action">
              <app-status-actions
                [status]="consent.status"
                [downloadUuid]="consent.uuid"
                [isDoctor]="isDoctor"
                [user]="user"
                [doctorUuid]="consent.doctorUuid"
                [canDownloadFile]="canDownloadFile"
                [objectType]="ObjectTypeEnum.CONSENT"
                [outputType]="consent.outputType"></app-status-actions>
            </td>
            <!-- End of Actions -->
          </tr>
        </tbody>
      </table>
      <!-- Pagination -->
      <app-paginator
        [totalElements]="page?.totalElements ?? 0"
        [numbersOfElements]="page?.numberOfElements ?? 0"
        [totalPages]="page?.totalPages ?? 0"
        [pageNumber]="pagination.page"
        [lastPage]="page?.last ?? false"
        (paginate)="paginar($event)"></app-paginator>
      <!-- End of Pagination -->

      <div class="window nodisplay">
        <app-window-error></app-window-error>
      </div>
    </div>
  </div>
</div>
