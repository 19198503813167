<h5 class="title tipo-second-color bold">
  {{ 'PRIVATE.DASHBOARD.CONSENTS.STATE.TITLE' | translate }}
</h5>

<div
  class="consents_state"
  *ngFor="let item of consentsByStatuses; let i = index">
  <div class="item item_all" *ngIf="item.status === 'total'"
  [routerLink]="['consents']">
    <p class="number">{{ item.amount }}</p>
    <p class="label">
      {{ 'PRIVATE.DASHBOARD.CONSENTS.STATE.TOTAL-CI' | translate }}
    </p>
  </div>
  <div class="item item_signed green" *ngIf="item.status === 'Firmado'" 
  (click)="goConsent(status.SIGNED)">
    <p class="number">{{ item.amount }}</p>   
    <p class="label">
      {{ 'PRIVATE.DASHBOARD.CONSENTS.STATE.SIGNED' | translate }}
    </p>
  
  </div>
 <div class="item item_signed" *ngIf="item.status === 'Impreso'"
  (click)="goConsent(status.PRINTED)">
    <p class="number">{{ item.amount }}</p>
    <p class="label">
      {{ 'PRIVATE.DASHBOARD.CONSENTS.STATE.PRINTED' | translate }}
    </p>
  </div>
   <div class="item item_pending orange" *ngIf="item.status === 'Pendiente'"
  (click)="goConsent(status.PENDING)">
    <p class="number">{{ item.amount }}</p>
    <p class="label">
      {{ 'PRIVATE.DASHBOARD.CONSENTS.STATE.PENDING' | translate }}
    </p>
  </div>
  <div
    class="item item_pending orange"
    *ngIf="item.status === 'Pendiente Firma RL2'"
    (click)="goConsent(status.PENDING_SIGN_RL2)">
    <p class="number">{{ item.amount }}</p>
    <p class="label">
      {{ 'PRIVATE.DASHBOARD.CONSENTS.STATE.PENDING-RL2' | translate }}
    </p>
  </div>
  <div
    class="item item_pending orange"
    *ngIf="item.status === 'Pendiente Firma BIO'"
    (click)="goConsent(status.PENDING_BIO_SIGN)">
    <p class="number">{{ item.amount }}</p>
    <p class="label">
      {{ 'PRIVATE.DASHBOARD.CONSENTS.STATE.PENDING-BIO' | translate }}
    </p>
  </div>
  <div class="item item_signed" *ngIf="item.status === 'Borrador'"
  (click)="goConsent(status.DRAFT)">
    <p class="number">{{ item.amount }}</p>
    <p class="label">
      {{ 'PRIVATE.DASHBOARD.CONSENTS.STATE.DRAFT' | translate }}
    </p>
  </div>  
  <div class="item item_signed red" *ngIf="item.status === 'Rechazado'"
  (click)="goConsent(status.REJECTED)">
    <p class="number">{{ item.amount }}</p>
    <p class="label">
      {{ 'PRIVATE.DASHBOARD.CONSENTS.STATE.REJECTED' | translate }}
    </p>
  </div> 
  <div
    class="item item_signed red"
    *ngIf="item.status === 'Rechazado Firma RL2'"
    (click)="goConsent(status.REJECTED_RL2)">
    <p class="number">{{ item.amount }}</p>
    <p class="label">
      {{ 'PRIVATE.DASHBOARD.CONSENTS.STATE.REJECTED-RL2' | translate }}
    </p>
  </div>
  <div class="item item_signed red" *ngIf="item.status === 'Revocado'"
  (click)="goConsent(status.REVOKED)">
    <p class="number">{{ item.amount }}</p>
    <p class="label">
      {{ 'PRIVATE.DASHBOARD.CONSENTS.STATE.REVOKED' | translate }}
    </p>
  </div>
  <div class="item item_signed red" *ngIf="item.status === 'Revocación RL2'"
  (click)="goConsent(status.REVOKED_RL2)">
    <p class="number">{{ item.amount }}</p>
    <p class="label">
      {{ 'PRIVATE.DASHBOARD.CONSENTS.STATE.REVOKED-RL2' | translate }}
    </p>
  </div>
  <div class="item item_signed red" *ngIf="item.status === 'Expirado'"
  (click)="goConsent(status.EXPIRED)">
    <p class="number">{{ item.amount }}</p>
    <p class="label">
      {{ 'PRIVATE.DASHBOARD.CONSENTS.STATE.EXPIRED' | translate }}
    </p>
  </div>
  <div class="item item_signed red" *ngIf="item.status === 'Generando'"
  (click)="goConsent(status.GENERATING)">
    <p class="number">{{ item.amount }}</p>
    <p class="label">
      {{ 'PRIVATE.DASHBOARD.CONSENTS.STATE.GENERATING' | translate }}
    </p>
  </div>
  <div class="item item_signed red" *ngIf="item.status === 'Error'"
  (click)="goConsent(status.ERROR)">
    <p class="number">{{ item.amount }}</p>
    <p class="label">
      {{ 'PRIVATE.DASHBOARD.CONSENTS.STATE.ERROR' | translate }}
    </p>
  </div>
</div>
