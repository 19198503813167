
<div class="admin">
    <img class="movil" src="../../../assets/img/admin/admin-movil.jpg"
        alt="Accede a la aplicación de elConsentimiento.es">
    <img class="desktop" src="../../../assets/img/admin/admin.jpg"
        alt="Accede a la aplicación de elConsentimiento.es">
    <div class="intro_admin">
        <h1 class="bienvenida tipo-white-color">¡Hola!</h1>
        <h4 class="tipo-white-color regular">Accede a la gestión de elConsentimiento.es </h4>
    </div>
    <form [formGroup]="loginAdmin" class="login" (ngSubmit)="loginAppAdmin()">

        <input type="text" class="usuario" placeholder="Usuario" formControlName="username">
        <input type="password" class="password" placeholder="Contraseña" formControlName="password">
        <p class="olvido" (click)="openWindow()">¿Olvidaste tu contraseña?</p>

        <input type="submit" name="submit" id="submitButton" class="back-blue-color tipo-white-color"
            value="Entrar">
        <div class="error">
            <p class="regular tipo-main-color errorinvalid" *ngIf="noValido">Usuario o contraseña no válidos</p>
            <p class="regular tipo-main-color error403" *ngIf="error">Ha ocurrido un error en el servidor, si el
                problema persiste comuníquese con el administrador del sistema</p>
        </div>
    </form>
    <!-- TODO pendiente poder cambiar la password -->
    <div class="window" *ngIf="isOpenWindow">
        <div class="window_reset">
            <div class="close">
                <fa-icon [icon]="faCircleXmark" class="icono fa-2xl tipo-second-color" (click)="closeWindow()"></fa-icon>
            </div>
            <h4 class="recupera tipo-main-color bold center">Recupera tu contraseña</h4>
            <p class="regular tipo-main-color">Para restablecer tu contraseña te enviaremos un email a la cuenta de
                correo con la que te registraste</p>
            <!-- <form [formGroup]="reset" (ngSubmit)="resetPassword()" class="reset">
                <input type="text" class="usuario" placeholder="Usuario" formControlName="usuarioReset">
                <input type="email" class="email" placeholder="Email" formControlName="emailReset">
                <input type="submit" name="submit" id="submit" class="back-blue-color tipo-white-color"
                    value="Restablecer contraseña ">
            </form> -->
        </div>
    </div>

    
</div>
