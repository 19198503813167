<td
  class="state green"
  (click)="goToConsentDetail(consent)"
  *ngIf="status?.id === Status.PRINTED || status?.id === Status.SIGNED">
  <fa-icon
    *ngIf="outputType?.id === OutputType.PAPER"
    [icon]="faPrint"
    class="icono"></fa-icon>
  <fa-icon
    *ngIf="outputType?.id === OutputType.DIGITAL_VID"
    [icon]="faTabletScreenButton"
    class="icono"></fa-icon>
  <fa-icon
    *ngIf="outputType?.id === OutputType.DIGITAL_REMOTE"
    [icon]="faEnvelope"
    class="icono"></fa-icon>
  <fa-icon
    *ngIf="outputType?.id === OutputType.DRAFT"
    [icon]="faFolderOpen"
    class="icono"></fa-icon>
  {{ status?.name }}
</td>
<td
  class="state orange"
  (click)="goToConsentDetail(consent)"
  *ngIf="
    status?.id === Status.PENDING ||
    status?.id === Status.PENDING_SIGN_RL2 ||
    status?.id === Status.PENDING_BIO_SIGN
  ">
  <fa-icon
    *ngIf="outputType?.id === OutputType.PAPER"
    [icon]="faPrint"
    class="icono"></fa-icon>
  <fa-icon
    *ngIf="outputType?.id === OutputType.DIGITAL_VID"
    [icon]="faTabletScreenButton"
    class="icono"></fa-icon>
  <fa-icon
    *ngIf="outputType?.id === OutputType.DIGITAL_REMOTE"
    [icon]="faEnvelope"
    class="icono"></fa-icon>
  <fa-icon
    *ngIf="outputType?.id === OutputType.DRAFT"
    [icon]="faFolderOpen"
    class="icono"></fa-icon>
  {{ status?.name }}
</td>
<td
  class="state red"
  (click)="goToConsentDetail(consent)"
  *ngIf="
    status?.id === Status.ERROR ||
    status?.id === Status.REJECTED ||
    status?.id === Status.REVOKED ||
    status?.id === Status.REVOKED_RL2 ||
    status?.id === Status.REJECTED_RL2
  ">
  <fa-icon
    *ngIf="outputType?.id === OutputType.PAPER"
    [icon]="faPrint"
    class="icono"></fa-icon>
  <fa-icon
    *ngIf="outputType?.id === OutputType.DIGITAL_VID"
    [icon]="faTabletScreenButton"
    class="icono"></fa-icon>
  <fa-icon
    *ngIf="outputType?.id === OutputType.DIGITAL_REMOTE"
    [icon]="faEnvelope"
    class="icono"></fa-icon>
  <fa-icon
    *ngIf="outputType?.id === OutputType.DRAFT"
    [icon]="faFolderOpen"
    class="icono"></fa-icon>
  {{ status?.name }}
</td>
<td
  class="state tipo-main-color"
  (click)="goToConsentDetail(consent)"
  *ngIf="
    status?.id === Status.EXPIRED ||
    status?.id === Status.GENERATING ||
    status?.id === Status.DRAFT
  ">
  <fa-icon
    *ngIf="outputType?.id === OutputType.PAPER"
    [icon]="faPrint"
    class="icono"></fa-icon>
  <fa-icon
    *ngIf="outputType?.id === OutputType.DIGITAL_VID"
    [icon]="faTabletScreenButton"
    class="icono"></fa-icon>
  <fa-icon
    *ngIf="outputType?.id === OutputType.DIGITAL_REMOTE"
    [icon]="faEnvelope"
    class="icono"></fa-icon>
  <fa-icon
    *ngIf="outputType?.id === OutputType.DRAFT"
    [icon]="faFolderOpen"
    class="icono"></fa-icon>
  {{ status?.name }}
</td>
