<div class="wrapper_admin">
    <h4 class="title tipo-second-color bold">Ficha de usuario</h4>
        <div class="maxwidth">
            <app-header-user [companyData]="companyData" [isDigital]="isDigital" [userData]="userData" [roleUser]="roleUser"></app-header-user>
            <ng-container *ngIf="isProfessionalRoute(); then professionalComponent else adminComponent"></ng-container>

            <ng-template #professionalComponent> 
                         
                    <app-file-doctor (userData)="receiveDoctorData($event)"></app-file-doctor>
                    <div class="box-container" *ngIf="false">    
                        <div class="box box2">
                            <!--TODO mostrar la firma del médico  -->
                            <app-edit-sign></app-edit-sign>
                        </div>  
                                <!--TODO mostrar las credenciales de firma  -->
                            <app-data-professional></app-data-professional>
                       
                    </div>  
            </ng-template>

            <ng-template #adminComponent>
                <app-file-administrator (dataAdmin)="receiveAdminData($event)"></app-file-administrator>           
            </ng-template>
        </div>
</div>

