import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import {
  FormControl,
  FormGroup,
  Validators
} from '@angular/forms';
import { State } from '@app/core/models/input/state.model';
import { AlertService } from '@app/core/services/alert/alert.service';
import { DataClientService } from '@app/core/services/data-client/data-client.service';
import { StateService } from '@app/core/services/state/state.service';
import { ElciValidators } from '@app/core/utils/validators';
import { faCircleArrowLeft, faCircleArrowRight } from '@fortawesome/free-solid-svg-icons';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-page4-data-contact',
  templateUrl: './page4-data-contact.component.html',
  styleUrls: ['./page4-data-contact.component.scss']
})
export class Page4DataContactComponent implements OnInit {
  faCircleArrowRight = faCircleArrowRight;
  faCircleArrowLeft = faCircleArrowLeft;


  @Output() continue3 = new EventEmitter();
  @Output() continue4 = new EventEmitter();
  dataContact!: FormGroup;
  classInput = '';
  isSubmit = false;
  selectValue = null;
  states?: State[];

  constructor(
    private dataClientService: DataClientService,
    private alertService: AlertService,
    private translate: TranslateService,
    private stateService: StateService
  ) {}

  togglePagesThreeFour(){
    this.continue3.emit();
  }

  togglePagesFourFive(){
    this.continue4.emit();
  }

    // función que valida cada dato del formulario
    shouldShowError(inputControlName: string): boolean {
      const control = this.dataContact.get(inputControlName);
      return !!(control && control.touched && control.invalid);
    }

   ngOnInit(): void {
      this.dataContact = new FormGroup({
        address: new FormControl({ value: '', disabled: false }, [
          Validators.required,
        ]),
        postCode: new FormControl({ value: '', disabled: false },
          [Validators.pattern(/^\d{5,6}$/),]),

        email: new FormControl({ value: '', disabled: false }, [
          ElciValidators.emailValidator,
        ]),
        phone: new FormControl({ value: '', disabled: false },
        [Validators.pattern(/^[0-9+]{9,15}$/),]),
        locality: new FormControl({ value: '', disabled: false }, [
          Validators.required,
        ]),
        stateId: new FormControl({ value: '', disabled: false }, [
          Validators.required,
        ]),
        mobile: new FormControl({ value: '', disabled: false }, [
          Validators.pattern(/^[0-9+]{9,15}$/),
        ]),
      });



       // consultamos las provincias asignadas al país
       this.stateService
       .getStateByCountryId(this.dataClientService.dataClient?.get('dataAccess.languageId')?.value.id)
       .subscribe({
         next: states => {
           this.states = states;
            },
       });
    }

    onSubmit() {

      this.isSubmit = true;

      Object.keys(this.dataContact.controls).forEach(controlName => {
        this.dataContact.get(controlName)?.markAsTouched();
      });

      if (this.dataContact.valid) {
        this.dataClientService.addFormGroup('dataContact', this.dataContact);
        this.togglePagesFourFive();

      } else {
        this.classInput = 'wrong';
        this.alertService.error(
          this.translate.instant(
            'PRIVATE.PATIENT.DATA-PATIENT.MESSAGE-PATIENT-NEW-ERROR'
          )
        );
      }
    }
  }

