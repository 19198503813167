import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CiTemplate } from '@app/core/models/input/template/ci-template.model';
import { TemplateLiteInputDTO } from '@app/core/models/input/template/template-lite.model';
import { TemplateInputDTO } from '@app/core/models/input/template/template.model';
import { EnvironmentService } from '@app/environment.service';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class TemplateService {
  constructor(
    private http: HttpClient,
    private env: EnvironmentService,
  ) {}

  private templateSubject: BehaviorSubject<TemplateInputDTO | null> =
    new BehaviorSubject<TemplateInputDTO | null>(null);
  template$: Observable<TemplateInputDTO | null> =
    this.templateSubject.asObservable();

  private selectedTemplateUuidSubject: BehaviorSubject<string | null> =
    new BehaviorSubject<string | null>(null);

  selectedTemplateUuidSubject$: Observable<string | null> =
    this.selectedTemplateUuidSubject.asObservable();

  setSelectedTemplateUuid(selectedTemplateUuid: string | null) {
    this.selectedTemplateUuidSubject.next(selectedTemplateUuid);
  }

  getTemplate(): TemplateInputDTO | null {
    return this.templateSubject.getValue();
  }

  setTemplate(template: TemplateInputDTO) {
    this.templateSubject.next(template);
  }

  getTemplateByUuid(templateUuid: string): Observable<TemplateInputDTO> {
    return this.http.get<TemplateInputDTO>(
      `${this.env.apiUrl}/templates/${templateUuid}`
    );
  }

  getCiTemplates(locale: string): Observable<TemplateLiteInputDTO[]> {
    return this.http.get<TemplateLiteInputDTO[]>(
      `${this.env.apiUrl}/templates/ci?language=${locale}`
    );
  }

  getCiTemplateByUuid(templateUuid: string): Observable<CiTemplate> {
    return this.http.get<CiTemplate>(
      `${this.env.apiUrl}/templates/ci/${templateUuid}`
    );
  }

  getRgpdTemplates(locale: string): Observable<TemplateLiteInputDTO[]> {
    return this.http.get<TemplateLiteInputDTO[]>(
      `${this.env.apiUrl}/templates/rgpd?language=${locale}`
    );
  }

  getRgpdTemplateByUuid(templateUuid: string): Observable<TemplateInputDTO> {
    return this.http.get<TemplateInputDTO>(
      `${this.env.apiUrl}/templates/rgpd/${templateUuid}`
    );
  }
}
