import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import OutputTypeEnum from '@app/core/enums/output-type.enum';
import { ConsentSendToPatientInputDTO } from '@app/core/models/input/consent/Consent-send-to-patient-input.model';
import { Consent } from '@app/core/models/input/consent/consent.model';
import { Variable } from '@app/core/models/input/consent/variable.model';
import { Device } from '@app/core/models/input/device.model';
import { User } from '@app/core/models/input/user.model';
import { VarOutputDTO } from '@app/core/models/output/consent/consent-var-output-model';
import { AlertService } from '@app/core/services/alert/alert.service';
import { ConsentService } from '@app/core/services/consent/consent.service';
import { LoginService } from '@app/core/services/login/login.service';
import { VariableService } from '@app/core/services/utils/variable/variable.service';
import { ElciValidators } from '@app/core/utils/validators';
import { faEnvelopeCircleCheck, faPaperPlane, faPrint, faTabletScreenButton } from '@fortawesome/free-solid-svg-icons';
import { TranslateService } from '@ngx-translate/core';
import { Subscription, catchError, of } from 'rxjs';
import { DeviceService } from '../../../../core/services/device/device.service';
import { ConsentsShareService } from '../../services/consents-share.service';
import { SubscriptionTypes } from '@app/core/models/input/company/company-parameters.model';
import PatientUtils from '@app/core/utils/patient.utils';
import { Patient } from '@app/core/models/input/patient/patient.model';
import { PhonePrefixService } from '@app/core/services/phone-prefix/phone-prefix.service';
import { PhonePrefix } from '@app/core/models/input/phone-prefix/phone-prefix.model';
import { DigitalCiSignature } from '@app/core/models/input/consent/digital-ci-signature.model';


@Component({
  selector: 'app-gen-consent',
  templateUrl: './gen-consent.component.html',
  styleUrls: ['./gen-consent.component.scss'],
})
export class GenConsentComponent implements OnInit, OnDestroy {
  // Font awesome
  faPaperPlane = faPaperPlane;
  faPrint = faPrint;
  faTabletScreenButton = faTabletScreenButton;
  faEnvelopeCircleCheck = faEnvelopeCircleCheck;

  // Inputs
  @Input() ownSubmit = false;

  // Html used variables
  OutputTypeEnum = OutputTypeEnum;

  // Selects
  selectValueDisp = '0';
  isSendEmailChecked = true;

  // General variables
  dataGenConsent!: FormGroup;
  isOnSubmit = false;
  selectedOutputType = OutputTypeEnum.DIGITAL_VID;
  devices?: Device[];
  user?: User;
  vars?: Variable[];
  consentUuid?: string;
  consent?: Consent;
  subscriptions?: SubscriptionTypes[] = [];
  phonePrefixes?: Array<PhonePrefix> = [];
  digitalCiSignatures?: DigitalCiSignature[] = [];
  isBiometric = false;
  isPaper = false;
  isRemote = false;

  // Subscriptions
  private varSubscription?: Subscription;
  private consentSubscription?: Subscription;

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private translate: TranslateService,
    private deviceService: DeviceService,
    private loginService: LoginService,
    private consentsShareService: ConsentsShareService,
    private variableService: VariableService,
    private consentService: ConsentService,
    private alertService: AlertService,
    private phonePrefixService: PhonePrefixService
  ) {
    this.dataGenConsent = this.initForm();
    this.dataGenConsent.valueChanges.subscribe(() => {
      consentsShareService.setGenConsentForm(this.dataGenConsent);
    });
    this.activatedRoute.params.subscribe(param => {
      this.consentUuid = param['consentUuid'];
    });
  }

  getPhonePrefix() {
    return this.phonePrefixService.getPhonePrefixApi().pipe(
      catchError(error => {
        console.error(`Error fetching prefix`);
        return of(new PhonePrefix());
      })
    );
  }

  ngOnInit(): void {
    // Save the session user into a class variable.
    this.user = this.loginService.userValue ?? new User();
    // Comprobar el tipo de suscripción y si es solo papel, no mostrar botón Biométrica
    const subscriptionType: SubscriptionTypes[]  = this.user.subscription ?? [];  
    subscriptionType.some(subscription => 
      {
        switch (subscription.id){
          case 1:
            this.isPaper = true;  
            this.changeOutputType(OutputTypeEnum.PAPER, this.isPaper); 
            break;
          case 2:
            this.isBiometric = true;   
            this.changeOutputType(OutputTypeEnum.DIGITAL_VID, this.isBiometric);                
            break;
          case 3:
            this.isRemote = true;            
            break;
        }
      })   

    //Consulta de los prefijos de país
    this.getPhonePrefix().subscribe({
      next: data => {
        if (Array.isArray(data)) {
          this.phonePrefixes = data;
        }
      },
    });

    this.getDevices();
    // Init subscriptions
    this.handleConsentChange();
    this.handleVarsChange();
    this.handleOutputTypeChange();
    
  }

  protected changeOutputType(newValue: number, execute: boolean) {
    if(execute){
      this.selectedOutputType = newValue;
      this.dataGenConsent.controls['outputTypeId'].setValue(this.selectedOutputType);
      this.consentsShareService.setSelectedOutputType(newValue);   
      this.validarDigitalVID();
      this.validarRemote();
    }
  }

  validarDigitalVID() {
    if (OutputTypeEnum.DIGITAL_VID === this.selectedOutputType) {
      this.dataGenConsent.controls['device'].enable();      
    } else {
      // Disable the device required if is not digital sign
      this.dataGenConsent.controls['device'].disable();
    }
  }

  validarRemote() {
    if (OutputTypeEnum.DIGITAL_REMOTE === this.selectedOutputType) {
      if (this.numberOfRepresentatives() === 0) {
        this.dataGenConsent.controls['patientEmail'].enable();
        this.dataGenConsent.controls['phonePrefix'].enable();
        this.dataGenConsent.controls['patientPhone'].enable();
      }

      if (this.numberOfRepresentatives() === 1 || this.numberOfRepresentatives() === 2) {
        this.dataGenConsent.controls['firstRepresentativeEmail'].enable();
        this.dataGenConsent.controls['phonePrefix1'].enable();
        this.dataGenConsent.controls['firstRepresentativePhone'].enable();
      }

      if (this.numberOfRepresentatives() === 2) {
        this.dataGenConsent.controls['secondRepresentativeEmail'].enable();
        this.dataGenConsent.controls['phonePrefix2'].enable();
        this.dataGenConsent.controls['secondRepresentativePhone'].enable();
      }
    } else {
      // Disable the device required if is not digital sign
      this.dataGenConsent.controls['patientEmail'].disable();
      this.dataGenConsent.controls['phonePrefix'].disable();
      this.dataGenConsent.controls['patientPhone'].disable();
      this.dataGenConsent.controls['firstRepresentativeEmail'].disable();
      this.dataGenConsent.controls['phonePrefix1'].disable();
      this.dataGenConsent.controls['firstRepresentativePhone'].disable();
      this.dataGenConsent.controls['secondRepresentativeEmail'].disable();
      this.dataGenConsent.controls['phonePrefix2'].disable();
      this.dataGenConsent.controls['secondRepresentativePhone'].disable();
    }
  }

  protected changeSendClientValue() {
    this.isSendEmailChecked = !this.isSendEmailChecked;
    this.dataGenConsent.controls['isSendEmailChecked'].setValue(this.isSendEmailChecked);
  }

  private handleVarsChange() {
    this.varSubscription = this.variableService.vars$.subscribe(vars => {
      if (vars) {
        this.vars = vars;
      }
    });
  }

  // This are the entry point when we are using the consentSubscription
  private handleConsentChange(): void {
    this.consentSubscription = this.consentService.consent$.subscribe(consent => {
      if (consent) {
        this.consent = consent;       
        this.isSendEmailChecked = this.consent?.patient?.email != null;
        if (consent.patient) {             
          this.fillDataPatientRemote(consent.patient);
         
        }
        if(consent.digitalCiSignatures){
            this.dataGenConsent.controls['device'].setValue(consent.digitalCiSignatures[0].device)
        }
      }
    });
  }

  // función que valida cada dato del formulario
  shouldShowError(inputControlName: string): boolean {
    const control = this.dataGenConsent.get(inputControlName);
    return !!(this.isOnSubmit && control?.invalid);
  }

  fillDataPatientRemote(patient: Patient) {   
   
    this.dataGenConsent.controls['patientEmail'].setValue(patient?.email);
    this.dataGenConsent.controls['phonePrefix'].setValue(
      patient.phonePrefix === null
        ? this.user?.defaultPhonePrefix
        : patient.phonePrefix
    );
    this.dataGenConsent.controls['patientPhone'].setValue(patient?.mobile);

    const firstRepresentative = PatientUtils.firstRepresentative(patient);
    if (firstRepresentative) {
      this.dataGenConsent.controls['firstRepresentativeEmail'].setValue(firstRepresentative?.email);
      this.dataGenConsent.controls['phonePrefix1'].setValue(
        firstRepresentative.phonePrefix === null
          ? this.user?.defaultPhonePrefix
          : firstRepresentative.phonePrefix
      );
      this.dataGenConsent.controls['firstRepresentativePhone'].setValue(firstRepresentative?.mobile);
      this.dataGenConsent.controls['firstRepresentativeDocumentNumber'].setValue(firstRepresentative?.documentNumber);
    }

    const secondRepresentative = PatientUtils.secondRepresentative(this.consent?.patient);
    if (secondRepresentative) {
      this.dataGenConsent.controls['secondRepresentativeEmail'].setValue(secondRepresentative?.email);
      this.dataGenConsent.controls['phonePrefix2'].setValue(
        secondRepresentative.phonePrefix === null
          ? this.user?.defaultPhonePrefix
          : secondRepresentative.phonePrefix
      );
      this.dataGenConsent.controls['secondRepresentativePhone'].setValue(secondRepresentative?.mobile);
      this.dataGenConsent.controls['secondRepresentativeDocumentNumber'].setValue(secondRepresentative?.documentNumber);
    }
  }

  private handleOutputTypeChange(): void {
    this.consentSubscription = this.consentsShareService.selectedOutputType$.subscribe(outputType => {
      if (outputType && this.selectedOutputType === outputType) {
        this.selectedOutputType = outputType;
        this.dataGenConsent.controls['outputTypeId'].setValue(outputType);
      }
    });
  }

  protected numberOfRepresentatives(): number {
    if (this.consent?.patient) {
      return PatientUtils.numberOfRepresentativesActive(this.consent?.patient);
    }
   
    return 0;
  }

  private getDevices() {
    this.deviceService.getUserDevices().subscribe({
      next: devices => {
        this.deviceService.setDevices(devices);
        this.devices = devices;
        this.dataGenConsent.get('device')?.setValue(this.user?.userDevice?.description ?? '0');
      },
    });
  }

  private constructVariables(variables: Variable[]): VarOutputDTO[] {
    return variables.map(variable => {
      const { uuid, value } = variable;
      return { uuid, value };
    });
  }

  private mapSentToPatient(): ConsentSendToPatientInputDTO {
    return {
      outputTypeId: this.dataGenConsent.get('outputTypeId')?.value,
      device: this.dataGenConsent.get('device')?.value,
      variables: this.constructVariables(this.vars ?? []),
    };
  }

  submitSendToPatient(): void {
    if (this.consentUuid) {
      this.consentService.sendConsentToPatient(this.consentUuid, this.mapSentToPatient()).subscribe({
        next: () => {
          this.alertService.success(this.translate.instant('PRIVATE.CONSENTS.DATA-CONSENT.CREATE-CONSENT-SUCCESS'));
          this.router.navigate(['../'], { relativeTo: this.activatedRoute });
        },
        error: () => {
          this.alertService.error(this.translate.instant('PRIVATE.CONSENTS.DATA-CONSENT.UPDATE-CONSENT-ERROR'));
        },
      });
    }
  }

  // Submit
  onSubmit() {
    this.isOnSubmit = true;
  }

  private initForm(): FormGroup {
    // Validamos e inicializamos formulario
    return new FormGroup({
      device: new FormControl({ value: '0', disabled: false }, [Validators.required, ElciValidators.notEqualToZero]),
      isSendEmailChecked: new FormControl({ value: this.isSendEmailChecked, disabled: false }, [Validators.required]),
      outputTypeId: new FormControl({ value: this.selectedOutputType, disabled: false }, [Validators.required]),
      patientEmail: new FormControl({ value: this.consent?.patient?.email, disabled: false }, [
        Validators.required,
        ElciValidators.emailValidator,
      ]),
      phonePrefix: new FormControl({ value:this.user?.defaultPhonePrefix, disabled:false}),
      patientPhone: new FormControl({ value: this.consent?.patient?.mobile, disabled: false }, [Validators.required]),
      firstRepresentativeEmail: new FormControl({ value: '', disabled: false }, [
        ElciValidators.emailValidator,
        Validators.required,
      ]),
      firstRepresentativeDocumentNumber: new FormControl({ value: '', disabled: false }),
      phonePrefix1: new FormControl({ value:this.user?.defaultPhonePrefix, disabled:false}),
      firstRepresentativePhone: new FormControl({ value: '', disabled: false }, [Validators.required]),
      secondRepresentativeEmail: new FormControl({ value: '', disabled: false }, [
        ElciValidators.emailValidator,
        Validators.required,
      ]),
      phonePrefix2: new FormControl({ value:this.user?.defaultPhonePrefix, disabled:false}),
      secondRepresentativePhone: new FormControl({ value: '', disabled: false }, [Validators.required]),
      secondRepresentativeDocumentNumber: new FormControl({ value: '', disabled: false }),
    });
  }

  ngOnDestroy(): void {
    if (this.consentSubscription) {
      this.consentSubscription.unsubscribe();
    }
    if (this.varSubscription) {
      this.varSubscription.unsubscribe();
    }
  }
}
