import { Component, OnInit } from '@angular/core';
import { COMPANY_ROLES, FREEMIUM_COMPANY_ROLES } from '@app/core/constants/Permissions';
import { Logo } from '@app/core/models/input/logos/logo.model';
import { User } from '@app/core/models/input/user.model';
import { LogoOutput } from '@app/core/models/output/logo-output.model';
import { AlertService } from '@app/core/services/alert/alert.service';
import { CompanyService } from '@app/core/services/company/company.service';
import { LoginService } from '@app/core/services/login/login.service';
import { CacheImplService } from '@app/shared/services/cache/cache-impl.service';
import { faTrashCan } from '@fortawesome/free-solid-svg-icons';
import { TranslateService } from '@ngx-translate/core';
import { catchError, of } from 'rxjs';

@Component({
  selector: 'app-edit-logo',
  templateUrl: './edit-logo.component.html',
  styleUrls: ['./edit-logo.component.scss'],
})
export class EditLogoComponent implements OnInit{
  // fontawesome
  faTrashCan = faTrashCan;
  //roles
  FREEMIUM_COMPANY_ROLES = FREEMIUM_COMPANY_ROLES;
  COMPANY_ROLES = COMPANY_ROLES;
  user?: User;
  companyUuid?: string;
  logoMain?: Logo;
  logoRGPD?: Logo;

  fileName = '';
  reader = new FileReader();
  isValidFileSize = false;

  constructor(
    private loginService: LoginService,
    private companyService: CompanyService,
    private alertService: AlertService,
    private translate: TranslateService,
    private cacheImplService: CacheImplService
  ) {}

  ngOnInit(): void {
    this.user = this.loginService.userValue ?? new User();
    this.companyUuid = this.user.companyUuid ?? '';

    this.getCompanyLogo(this.companyUuid, 'MAIN').subscribe({
      next: logo => this.logoMain = logo,
      error: error => console.error('Failed to fetch MAIN logo', error)
    });

    this.getCompanyLogo(this.companyUuid, 'RGPD').subscribe({
      next: logo => this.logoRGPD = logo,
      error: error => console.error('Failed to fetch RGPD logo', error)
    });
  }

  getCompanyLogo(companyUuid: string, logoType: string) {
    // Assuming getCompanyLogoApi returns an Observable<Logo>
    return this.companyService.getCompanyLogoApi(companyUuid, logoType).pipe(
      catchError(error => {
        console.error(`Error fetching logo of type ${logoType}:`, error);
        return of(new Logo());
      })
    );
  }

  uploadFile(event: any, type: string) {
    const file = event.target.files[0];
    this.fileName = file.name;
    this.reader.readAsDataURL(file);

    this.reader.onload = () => {
      this.isValidFileSize = this.validateFile(file);
      if (this.isValidFileSize) {
        const fileObject: LogoOutput = {
          name: type,
          originalFileName: file.name,
          content: this.reader.result?.toString().replace(/^data:(.*,)?/, ''),
        };

        this.companyService
          .editCompanyLogo(this.companyUuid ?? '', fileObject)
          .subscribe({
            next: () => {
              if (type === 'MAIN') {
                this.getCompanyLogo(this.companyUuid ?? '', type).subscribe(
                  logoMain => (this.logoMain = logoMain)
                );
                if(this.user?.logo != undefined){
                  this.user.logo = 'data:image/'+ fileObject.name +';base64,'+ fileObject.content;
                  this.cacheImplService.setItem('user', JSON.stringify(this.user));
                this.loginService.setUserValue(this.user);

                }
                this.alertService.success(this.translate.instant("PRIVATE.PERSONALIZATION.EDIT-LOGO.MESSAGE-MAIN-LOGO-OK"))

              }
              if (type === 'RGPD') {
                this.getCompanyLogo(this.companyUuid ?? '', 'RGPD').subscribe(
                  logoRGPD => (this.logoRGPD = logoRGPD)
                );
                this.alertService.success(this.translate.instant("PRIVATE.PERSONALIZATION.EDIT-LOGO.MESSAGE-RGPD-LOGO-OK"))
              }

            },
          });
      }
    };
  }

  private validateFile(file: File) {
    if (file.type === 'image/jpeg' || file.type === 'image/png' || file.type === 'image/gif') {
      if (file.size < 250000) {
        return true;
      } else {
        this.alertService.warn(this.translate.instant("PRIVATE.PERSONALIZATION.EDIT-LOGO.MESSAGE-FILE-SIZE"));
        return false;
      }
    } else {
      this.alertService.warn(this.translate.instant("PRIVATE.PERSONALIZATION.EDIT-LOGO.MESSAGE-FILE-TYPE"));
      return false;
    }
  }

  deleteLogo(type: string) {
    try {
      this.companyService
        .deleteCompanyLogo(this.companyUuid ?? '', type)
        .subscribe({
          next: () => {
            if (type === 'MAIN') {
              this.logoMain = undefined;
            }
            if (type === 'RGPD') {
              this.logoRGPD = undefined;
            }
          },
        });
    } catch (e) {
      //ignore
    }
  }
}
