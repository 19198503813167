import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import LanguageEnum from '@app/core/enums/language.enum';
import { AlertService } from '@app/core/services/alert/alert.service';
import { DataClientService } from '@app/core/services/data-client/data-client.service';
import {
  faCircleArrowLeft,
  faCircleArrowRight,
} from '@fortawesome/free-solid-svg-icons';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-page2-data-company',
  templateUrl: './page2-data-company.component.html',
  styleUrls: ['./page2-data-company.component.scss'],
})
export class Page2DataCompanyComponent implements OnInit {
  faCircleArrowRight = faCircleArrowRight;
  faCircleArrowLeft = faCircleArrowLeft;


  @Output() continue2 = new EventEmitter();
  @Output() continue3 = new EventEmitter();

  dataCompany!: FormGroup;
  classInput = '';
  isSubmit = false;

  constructor(
    private fb: FormBuilder,
    private dataClientService: DataClientService,
    private alertService: AlertService,
    private translate: TranslateService
   
  ) {}

  // función que valida cada dato del formulario
  shouldShowError(inputControlName: string): boolean {
    const control = this.dataCompany.get(inputControlName);
    return !!(control && control.touched && control.invalid);
  }

  ngOnInit(): void {
    this.dataCompany = new FormGroup({
      businessName: new FormControl({ value: '', disabled: false }, [
        Validators.required,
      ]),
      website: new FormControl({ value: '', disabled: false }),
      name: new FormControl({ value: '', disabled: false }),
      email: new FormControl({ value: '', disabled: false }),
      cif: new FormControl({ value: '', disabled: false }, [
        Validators.required ]),
      dniCif: new FormControl({ value: '', disabled: false }, [       
        Validators.pattern(/^[0-9a-zA-Z]{9,15}$/),
      ]),
      mobile: new FormControl({ value: '', disabled: false }, [
        Validators.pattern(/^[0-9+]{9,15}$/),
      ]),
    });
   
    if (this.dataClientService.dataClient?.get('dataAccess.languageId')?.value.id === LanguageEnum.es_EC){
      this.dataCompany.get('cif')?.setValidators([ Validators.pattern(/^\d{13}$/)]);
      
    }else {
      this.dataCompany.get('cif')?.setValidators([ Validators.pattern(/^\w{9}$/)]);
    }
    this.dataCompany.get('cif')?.updateValueAndValidity();
  }

  togglePagesOneTwo() {
    this.continue2.emit();
  }

  togglePagesTwoThree() {
    this.continue3.emit();
  }

  onSubmit() {
    this.isSubmit = true;

    Object.keys(this.dataCompany.controls).forEach(controlName => {
      this.dataCompany.get(controlName)?.markAsTouched();
    });

    if (this.dataCompany.valid) {
      this.dataClientService.addFormGroup('dataCompany', this.dataCompany)
      this.togglePagesTwoThree();

    } else {
      this.classInput = 'wrong';
      this.alertService.error(
        this.translate.instant(
          'PRIVATE.PATIENT.DATA-PATIENT.MESSAGE-PATIENT-NEW-ERROR'
        )
      );
    }
  }
}
