<div class="back-light-blue-color" [ngClass]="{'wrapper': isApp, 'wrapper_quick': isQuickapi }">
    <div class="maxwidth">
      <app-generate-doc [patient]="patient" (formGroupEvent)="handleChildFormGroup($event)"></app-generate-doc>

      <div class="docpdf rgpd">
        <app-digital-document-template [patient]="patient"></app-digital-document-template>
      </div>

    </div>
  </div>
