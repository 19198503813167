<h5 class="title tipo-second-color bold">{{'PRIVATE.DASHBOARD.CONSENTS.DOCTOR.TITLE' | translate}}</h5>
<div class="inlineflex">
    <div class="item" *ngFor="let doctor of statisticConsentsByDoctor, let i=index">
        <p class="name_profesional tipo-main-color bold">{{doctor.doctor}}</p>
        <div class="consents" *ngFor="let consent of doctor.consents, let i=index">
            <p class="number tipo-main-color">{{consent.amount}}</p>
            <p class="name_consent tipo-second-color">{{consent.procedureName}}</p>
        </div>  
    </div>
</div>
