import { RepresentativePatientDTO } from './representative.model';
import { RgpdStats } from '../stats/rgpdStats.model';
import { DigitalDocumentsStats } from '../stats/digitalDocumentsStats.model';
import { ConsentsStats } from '../stats/consentsStats.model';
import OutputTypeEnum from '@app/core/enums/output-type.enum';

export class Patient {
  uuid?: string;
  id?:string;
  externalId?: string;
  name?: string;
  firstLastName?: string;
  secondLastName?: string;
  documentNumber?: string;
  genderId?: number;
  birthdate?: string;
  email?: string;
  phonePrefix?: string;
  mobile?: string;
  healthHistoryExt?: string;
  representativePatients?: Array<RepresentativePatientDTO>;
  creationDate?: Date;
  cancelDate?: Date;
  isActive?: boolean;
  rgpdStats?: RgpdStats;
  digitalDocumentsStats?: DigitalDocumentsStats;
  consentsStats?: ConsentsStats;
  numOfRepresentativePatient?: number;
  businessName?: string;
}


export class PatientInputDTO {
  uuid?: string;
  name?: string;
  firstLastName?: string;
  secondLastName?: string;
  documentNumber?: string;
  genderId?: number;
  birthdate?: string;
  email?: string;
  phonePrefix?: string;
  mobile?: string;
  healthHistoryExt?: string;
  representativePatients?: Array<RepresentativePatientDTO>;
  creationDate?: Date;
  cancelDate?: Date;
  isActive?: boolean;
  rgpdStats?: RgpdStats;
  digitalDocumentsStats?: DigitalDocumentsStats;
  consentsStats?: ConsentsStats;
  numOfRepresentativePatient?: number;
  outputType?: OutputTypeEnum;
  generateRGPD?: boolean;
  templateUuid?: string;
  templateType?: string;
  device?:string;
  isQuickApi?: boolean;
}