import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ProceduresAndSpecialty, TotalProceduresBySpecialty } from '@app/core/models/front/public';
import { AlertService } from '@app/core/services/alert/alert.service';
import { PublicService } from '@app/core/services/public/public.service';
import { CatalogueSearchService } from '@app/core/services/share-search/catalogue-search.service';
import { faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss']
})
export class SearchComponent implements OnInit {
  faMagnifyingGlass = faMagnifyingGlass;

  specialities?: TotalProceduresBySpecialty[];
  proceduresBySpecialty?: ProceduresAndSpecialty[];
  
  @Output() specialitiesEvent = new EventEmitter<TotalProceduresBySpecialty[]>();
  @Output() proceduresEvent = new EventEmitter<ProceduresAndSpecialty[]>();

 

 
   dataSearch!: FormGroup;
   specialityId = 0;
   procedureName = '';

   constructor(
    private publicService: PublicService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private alertService: AlertService,
    private shareSearch: CatalogueSearchService
  ) { }

  ngOnInit(): void {   

    this.dataSearch = new FormGroup({      
      specialityName: new FormControl({ value: '', disabled: false }),
      procedureName: new FormControl({ value: '', disabled: false })    
    });
    this.getSpecialties();
  }

  getSpecialties() {
    this.publicService.getTotalProceduresBySpecialties().subscribe({
      next: respuesta => {
        this.specialities = respuesta; 
        this.specialitiesEvent.emit(this.specialities)       
      },
    });
  }

  getSearch(){
    if(this.dataSearch.get('procedureName')?.value != '' || this.dataSearch.get('specialityName')?.value != 0 ){
      this.specialityId = this.dataSearch.get('specialityName')?.value;
      this.procedureName = this.dataSearch.get('procedureName')?.value;     
      this.searchProceduresBySpecialty();     
      this.router.navigate(['/catalogo/'+ this.specialityId +'/procedure-name/'+ this.procedureName], {relativeTo: this.activatedRoute});
          
    }else {
      this.alertService.error('Debe elegir al menos una especialidad o filtrar por nombre con al menos 3 caracteres');
    }
  }



  searchProceduresBySpecialty() {
    this.publicService.getProceduresByFilter(this.specialityId,  this.procedureName).subscribe({
      next: data => {
        this.proceduresBySpecialty = data;       
        this.shareSearch.updateProcedures(this.proceduresBySpecialty)   
           
      }
    })
  }
}
