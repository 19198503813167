export class Device {
    deviceDescription?: string;
    deviceName?: string;
}

export class UserDevice {
    id?: string;
    description?: string
}

export class DocumentsDevice {
    fileName?: string;
    docGUI?: string;
    docStatus?: string;
    downloaded?: string;
    additionalData?: string;
}
