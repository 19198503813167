import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { USERS_LOGIN } from '@app/core/constants/Permissions';
import { User } from '@app/core/models/input/user.model';
import { LoginService } from '@app/core/services/login/login.service';
import { faChevronDown, faRightFromBracket } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-header-admin',
  templateUrl: './header-admin.component.html',
  styleUrls: ['./header-admin.component.scss'],
})
export class HeaderAdminComponent implements OnInit {
  // fontawesome
  faRightFromBracket = faRightFromBracket;
  faChevronDown = faChevronDown;

  //roles
  USERS_LOGIN = USERS_LOGIN;
  // user
  user?: User;

  menuvisible = false;
  logoblanco = false;

  constructor(private router: Router, private loginService: LoginService) {}

  ngOnInit(): void {
    this.user = this.loginService.userValue ?? new User();
  }

  mostrarMenu() {
    this.menuvisible = !this.menuvisible;
    this.logoblanco = !this.logoblanco;
  }

  quitarMenu() {
    this.menuvisible = false;
    this.logoblanco = false;
  }

  logOut() {
    this.loginService.logout("admin");
  }
}
