<div class="wrapper">
    <div class="cabecera">
        <img class="movil" src="../../../assets/img/quienessomos/cabecera-movil.jpg" alt="Quiénes somos el Consentimiento.es">
        <img class="desktop" src="../../../assets/img/quienessomos/cabecera.jpg" alt="Quiénes somos el Consentimiento.es">
        <h1 class="titulo_seccion tipo-second-color bold">Quiénes somos</h1>
    </div>
    <div class="maxwidth">
        <div class="contenido">
            <div class="cabecera_contenido">
                <img class="desktop" src="../../../assets/img/quienessomos/doctor-quienessomos.png" alt="Contamos con un equipo multidisciplinar que trabajan para elaborar los mejores consentimientos">
                <div class="intro tipo-main-color regular">
                    <p class="contenido_p">Contamos con un <span class="bold">equipo multidisciplinar</span> que combina <span class="bold">peritos médicos y expertos en derecho sanitario.</span> La <span class="bold">oficina del consentimiento</span> atiende demandas de los clientes y gestiona con los peritos y abogados la conveniencia de su alta, revisión o adaptación.</p>
                    <p class="contenido_p">Mientras, el <span class="bold">equipo tecnológico</span> desarrolla las necesidades técnicas de la aplicación que está en constante evolución, incorporando facilidades como la firma remota o el acceso mediante blockchain que aumenta la seguridad.</p>
                                      
                </div>
            </div>
        </div>
        <div class="equipos">
            <div class="equipo peritos">
                <fa-icon [icon]="faStethoscope" class="icono rojo"></fa-icon> 
                <h3 class="titulo_equipo regular rojo ">Peritos médicos</h3>
            </div>
            <div class="equipo elci">
                <fa-icon [icon]="faBriefcase" class="icono naranja"></fa-icon> 
                <h3 class="titulo_equipo regular naranja">Oficina ELCI</h3>
            </div>          
            <div class="equipo legal">
                <fa-icon [icon]="faScaleBalanced" class="icono amarillo"></fa-icon> 
                <h3 class="titulo_equipo regular amarillo">Expertos legales</h3>
            </div>
            <div class="equipo tecnico">
                <fa-icon [icon]="faLaptop" class="icono verde"></fa-icon> 
                <h3 class="titulo_equipo regular verde center">Equipo tecnológico</h3>
            </div>
            <div class="linea back-blue-color">
                <div class="punto uno"></div>
                <div class="punto dos"></div>
                <div class="punto tres"></div>
                <div class="punto cuatro"></div>
            </div>
        </div>
    </div>
    <div class="pie">
        <img class="movil" src="../../../assets/img/quienessomos/footer-movil.jpg" alt="Quiénes somos el Consentimiento.es">
        <img class="desktop" src="../../../assets/img/quienessomos/footer.jpg" alt="Quiénes somos el Consentimiento.es">
    </div>
</div>   
