<div class="wrapper_home">
    <div class="bienvenida">
        <video id="video" src='../../../assets/img/home/open-video.mp4' autoplay='true' muted="'true" loop="'true"></video>
        <div class="banda center" >
            <div class="titular" *ngFor='let item of claim; let i=index'>
                <h1 class="claim tipo-second-color bold">{{item.claim}}</h1>
            </div>
        </div>
        <fa-icon [icon]="faCircleArrowDown" class="icono tipo-white-color" (click)="scroll()"></fa-icon>
    </div>
    <div class="home" *ngFor = 'let item of home; let i=index'>
        <img class="movil" src="{{item.imagenmovil}}" alt="{{item.claim}}">
        <img class="desktop" src="{{item.imagen}}" alt="{{item.claim}}">
        <div class="home_blur">
           <div class="home_textos">
                <h2 [innerHTML]="[formatText (item.claim)]" class="home_h2 tipo-second-color">{{item.claim}}</h2>
                <a [routerLink]='[item.enlace]'>
                    <button class="home_button tipo-second-color bold">{{item.cta}}</button>
                </a>
          </div>
        </div>
        <fa-icon [icon]="faCircleArrowDown" class="icono tipo-white-color" (click)="scroll()"></fa-icon>
    </div>

</div>


