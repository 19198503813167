<div class="wrapper">
    <div class="clientes">
        <img class="movil" src="../../../assets/img/clientes/clientes-movil.jpg" alt="Accede a la aplicación de elConsentimiento.es">
        <img class="desktop" src="../../../assets/img/clientes/clientes.jpg" alt="Accede a la aplicación de elConsentimiento.es">
        <div class="intro_clientes">
            <h1 class="bienvenida tipo-white-color">{{ 'PUBLIC.RESET-PASSWORD.HELLO' | translate }}</h1>
            <h4 class="tipo-white-color light">{{ 'PUBLIC.RESET-PASSWORD.STABLISH-NEW-PASSWORD' | translate }}</h4>
        </div>
        <form [formGroup]="passwordForm" (ngSubmit)="onSubmit()" class="newpass">
            <input type="password" class="newpassword" [placeholder]="'PUBLIC.RESET-PASSWORD.NEW-PASSWORD' | translate" required formControlName="password">
            <input type="password" class="renewpassword" [placeholder]="'PUBLIC.RESET-PASSWORD.RETYPE-PASSWORD' | translate" required formControlName="rePassword">
            <input type="submit" name="submit" id="submitButton" class="back-blue-color tipo-white-color" value="Enviar" >
            <p class="error" *ngIf="!coincide">{{ 'PUBLIC.RESET-PASSWORD.PASSWORDS-NOT-MATCH' | translate }}</p>
            <p class="error" *ngIf="passwordForm.get('password')?.errors?.['minlength']">{{ 'PUBLIC.RESET-PASSWORD.PASSWORD-LENGTH-REQ' | translate }} {{MIN_PASSWORD_LENGTH}} {{ 'PUBLIC.RESET-PASSWORD.PASSWORD-CHARACTERS' | translate }}</p>
        </form>
    </div>

</div>