import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./public/public-routing.module').then(m => m.PublicRoutingModule)
  },
  {
    path: 'portal',
    loadChildren: () => import('./private/private-routing.module').then(m => m.PrivateRoutingModule)
  },
  {
    path: 'super-admin',
    loadChildren: () => import('./super-admin/super-admin-routing.module').then(m => m.SuperAdminRoutingModule)
  },
  {
    path: 'api',
    loadChildren: () => import('./quick-api/quick-api-routing.module').then(m => m.QuickApiRoutingModule)
  },
  { path: '**', redirectTo: 'login', pathMatch: 'full' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {scrollPositionRestoration: 'enabled'})],
  exports: [RouterModule],
})
export class AppRoutingModule {}
