<div class="wrapper">    
    <div class="list">
        <h4 class="title tipo-second-color bold" id="title_table">{{'SUPER-ADMIN.CONSENT-MOST-USED.TITLE-CONSENTS-MOST-USED' | translate}}</h4>
        <table class="table" aria-describedby="title_table">
            <thead class="tipo-second-color bold">  
                <th scope="col" class="id">
                    <div class="column">   
                        <span>{{'SUPER-ADMIN.CONSENT-BY-SPECIALTY.DATA-ID' | translate}}</span>
                    </div>
                </th>                          
                <th scope="col" class="consent_title">
                    <div class="column">
                        <span>{{'SUPER-ADMIN.LIST-CONSENTS-ADMIN.DATA-CONSENT' | translate}}</span> 
                        <input type="text" name="filterConsent" (debounceInput)="addFilter('procedureName', filterConsent.value)" [appDebounceInput]="300" #filterConsent id="filterConsent" placeholder="{{'PRIVATE.PATIENT.LIST-PATIENTS.FILTER' | translate }}">
                    </div>
                </th>      
                <th scope="col" class="specialty_title">
                    <div class="column">                        
                        <span>{{'SUPER-ADMIN.LIST-CONSENTS-ADMIN.DATA-SPECIALTY' | translate}}</span> 
                        <select name="specialty" id="specialty" [(ngModel)]="selectedSpecialty" (ngModelChange)="addSpecialtyFilter($event)" >
                            <option value="0"> {{ 'PRIVATE.CONSENTS.LIST-CONSENTS.OPTION-1' | translate }}</option>
                            <option *ngFor="let specialty of specialties" [value]="specialty.id">
                                {{specialty.description}}
                                </option>
                        </select> 
                    </div>
                </th>   
                <th scope="col" class="num_cis">
                    <div class="column">                       
                        <span>{{'SUPER-ADMIN.CONSENTS-BY-DOCTORS.DATA-NUM-CIS' | translate}}</span>                        
                    </div>
                </th>            
                <th scope="col" class="date_on">
                    <div class="column">    
                        <span>{{'SUPER-ADMIN.CONSENT-BY-SPECIALTY.DATA-DATE-ON' | translate}}</span>
                        <input type="text" name="filterMindate" (debounceInput)="addFilter('creationDate-start', filterMindate.value)" [appDebounceInput]="300" #filterMindate id="input" placeholder="{{'PRIVATE.PATIENT.LIST-PATIENTS.FILTER' | translate }}">
                    </div>
                </th>
                <th scope="col" class="date_off">
                    <div class="column">  
                        <span>{{'SUPER-ADMIN.CONSENT-BY-SPECIALTY.DATA-DATE-OFF' | translate}}</span>
                        <input type="text" name="filterMaxdate" (debounceInput)="addFilter('creationDate-end', filterMaxdate.value)" [appDebounceInput]="300" #filterMaxdate id="input" placeholder="{{'PRIVATE.PATIENT.LIST-PATIENTS.FILTER' | translate }}">
                    </div>
                </th>
            </thead>
            <tbody>
                
                <tr *ngFor="let consent of consents">
                    <td class="id">{{consent.procedureId}}</td>                       
                    <td class="consent"> {{consent.procedureName}}</td>                                   
                    <td class="specialty"> {{consent.specialtyName}} </td>  
                    <td class="num_cis" >{{consent.count}}</td>                
                    <td class="date_on" >{{consent.minDate | date : 'dd/MM/YYYY' }}</td>   
                    <td class="date_off" >{{consent.maxDate | date : 'dd/MM/YYYY'}}</td>  
                </tr>
               
            </tbody>
        </table>
       
</div>    

