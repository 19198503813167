import { Component, Input, OnInit } from '@angular/core';
import { Device } from '@app/core/models/input/device.model';
import { AlertService } from '@app/core/services/alert/alert.service';
import { faEye, faLinkSlash } from '@fortawesome/free-solid-svg-icons';
import { DeviceService } from '../../../../core/services/device/device.service';
import { TranslateService } from '@ngx-translate/core';
import { User } from '@app/core/models/input/user.model';
import { LoginService } from '@app/core/services/login/login.service';
import { CompanyService } from '@app/core/services/company/company.service';
import { Company } from '@app/core/models/input/company/company.model';

@Component({
  selector: 'app-devices',
  templateUrl: './devices.component.html',
  styleUrls: ['./devices.component.scss'],
})
export class DevicesComponent implements OnInit {
  faLinkSlash = faLinkSlash;
  faEye = faEye;
  open = false;
  @Input() devices?: Device[];
  @Input() enrollmentCode?: string;
  deviceToDelete?: string;
  companyUuid?: string;
  user?: User;
  company?: Company;


  constructor(
    private deviceService: DeviceService,
    private alertService: AlertService,
    private translate: TranslateService,
    private loginService: LoginService) {
     }

  openModal(deviceName?: string) {
    this.open = !this.open;
    this.deviceToDelete = deviceName;
  }

  ngOnInit(): void {
    this.user = this.loginService.userValue ?? new User();
    this.companyUuid = this.user.companyUuid;
}


  deleteDevice() {
    this.deviceService
      .deleteDevice(
        this.deviceToDelete ?? ''
      )
      .subscribe({
        next: () => {
          this.alertService.success(this.translate.instant("PRIVATE.PERSONALIZATION.DEVICE-DOCUMENTS.MESSAGE-DELETE-DEVICE-OK"));
          this.open = !this.open;
        },
        error: () => {
          this.alertService.error(this.translate.instant("PRIVATE.PERSONALIZATION.DEVICE-DOCUMENTS.MESSAGE-DELETE-DEVICE-NO"));
          this.open = !this.open;
        },
      });
  }
}
