import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Clauses, ProcedureContent } from '@app/core/models/front/public';
import { PublicService } from '@app/core/services/public/public.service';
import { Location } from '@angular/common';

@Component({
  selector: 'app-procedure-detail',
  templateUrl: './procedure-detail.component.html',
  styleUrls: ['./procedure-detail.component.scss']
})
export class ProcedureDetailComponent implements OnInit {

  procedureCode?: number;
  procedureContent?: ProcedureContent;
  clause?: Clauses[];

  constructor(
    private publicService: PublicService,
    private activatedRoute: ActivatedRoute,
    private location: Location
  ){
    this.activatedRoute.params.subscribe(params => {      
      this.procedureCode = params['code'];     
    });
  }

  ngOnInit(): void {   
    this.getProcedure();
    
  }

  getProcedure() {   
    if(this.procedureCode){
      this.publicService.getProcedureContent(this.procedureCode).subscribe({
        next: data => {          
          this.procedureContent = data;
          this.clause = data.clauses;                    
        }
      })
    }    
  }
  goBack() {
    this.location.back();
  }
}
