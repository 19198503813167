import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { SharedModule } from '@app/shared/shared.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { Page1DataHiringComponent } from './components/page1-data-hiring/page1-data-hiring.component';
import { CompanyHiringComponent } from './pages/company-hiring/company-hiring.component';
import { Page2DataCompanyComponent } from './components/page2-data-company/page2-data-company.component';
import { Page3DataAccessComponent } from './components/page3-data-access/page3-data-access.component';
import { Page4DataContactComponent } from './components/page4-data-contact/page4-data-contact.component';
import { Page5DataRgpdComponent } from './components/page5-data-rgpd/page5-data-rgpd.component';
import { Page6DataConfirmComponent } from './components/page6-data-confirm/page6-data-confirm.component';
import { ListCompaniesComponent } from './pages/list-companies/list-companies.component';
import { FileCompanyAdminComponent } from './pages/file-company-admin/file-company-admin.component';
import { DataCompanyAdminComponent } from './components/data-company-admin/data-company-admin.component';
import { InfoCompanyComponent } from './components/info-company/info-company.component';
import { HeaderCompanyComponent } from './components/header-company/header-company.component';
import { CompanyModule } from '@app/private/company/company.module';




@NgModule({
  declarations: [
    CompanyHiringComponent,
    Page1DataHiringComponent,
    Page2DataCompanyComponent,
    Page3DataAccessComponent,
    Page4DataContactComponent,
    Page5DataRgpdComponent,
    Page6DataConfirmComponent,
    ListCompaniesComponent,
    FileCompanyAdminComponent,
    DataCompanyAdminComponent,
    InfoCompanyComponent,
    HeaderCompanyComponent,
   
  ],
  imports: [
    CommonModule,
    FontAwesomeModule,
    SharedModule,
    ReactiveFormsModule,
    FormsModule,
    CompanyModule
   
  ],
  exports: [
    InfoCompanyComponent,
    HeaderCompanyComponent
  ],
})
export class CompanyAdminModule { }
