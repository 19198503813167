import { Component, Output, EventEmitter, OnInit } from '@angular/core';
import { faCircleArrowRight, faCircleXmark } from '@fortawesome/free-solid-svg-icons';
import {
  FormGroup,
  FormControl,
  Validators,
  FormBuilder,
} from '@angular/forms';
import { AlertService } from '@app/core/services/alert/alert.service';
import { TranslateService } from '@ngx-translate/core';
import { DataClientService } from '@app/core/services/data-client/data-client.service';
import { CompanyService } from '@app/core/services/company/company.service';
import { BillingType, CompanyParameters, DoctorsAmounts, PaymentMethodTypes, RegistryTypes, SignaturesAmounts, SubscriptionTypes } from '@app/core/models/input/company/company-parameters.model';
import { SpecialtiesService } from '@app/core/services/api/consents/specialties/specialties.service';
import { Specialty } from '@app/core/models/input/specialty.model';
import PaymentMethodTypeEnum from '@app/core/enums/payment-method-type.enum';
import RegistryTypeEnum from '@app/core/enums/registry-type.enum';
@Component({
  selector: 'app-page1-data-hiring',
  templateUrl: './page1-data-hiring.component.html',
  styleUrls: ['./page1-data-hiring.component.scss'],
})
export class Page1DataHiringComponent implements OnInit {
  faCircleArrowRight = faCircleArrowRight;
  faCircleXmark = faCircleXmark;

  @Output() continue = new EventEmitter<boolean>();
  // @Output() continue6 = new EventEmitter<boolean>();
  dataHiring!: FormGroup;
  classInput = '';
  isSubmit = false;
  isSepa = false;
  isHis = false;

  parameters?: CompanyParameters[];
  billingTypes?: BillingType[];
  doctorsAmounts?: DoctorsAmounts[];
  paymentMethodTypes?: PaymentMethodTypes[];
  registryTypes?:RegistryTypes[];
  signaturesAmounts?:SignaturesAmounts[];
  subscriptionTypes?:SubscriptionTypes[];
  specialty?: Specialty[] = [];
  specialties?: Specialty[];

  constructor(
    private fb: FormBuilder,
    private dataClientService: DataClientService,
    private alertService: AlertService,
    private translate: TranslateService,
    private companyService: CompanyService,
    private specialtiesService: SpecialtiesService,
  ) {}

  togglePagesOneTwo() {
    this.continue.emit();
  }

  // función que valida cada dato del formulario
  shouldShowError(inputControlName: string): boolean {
    const control = this.dataHiring.get(inputControlName);
    return !!(control && control.touched && control.invalid);
  }

  ngOnInit(): void {
    this.dataHiring = new FormGroup({
      doctorsAmountId: new FormControl({ value: '', disabled: false }, [
        Validators.required,
      ]),

      billingTypeId: new FormControl({ value: '', disabled: false }, [
        Validators.required,
      ]),
      paymentMethodTypeId: new FormControl({ value: '', disabled: false }, [
        Validators.required,
      ]),
      registryTypeId: new FormControl({ value: '', disabled: false }, [
        Validators.required,
      ]),
      price: new FormControl({ value: '', disabled: false }, [
        Validators.required,
      ]),
      subscriptionsTypeId: new FormControl({ value: '', disabled: false }, [
        Validators.required,
      ]),
      signaturesAmountId: new FormControl({ value: '', disabled: false }, [
        Validators.required,
      ]),
      his: new FormControl({ value: '', disabled: false }),
      specialtiesId: new FormControl({ value: '', disabled: false }),
      specialtiesName: new FormControl({ value: '', disabled: false }),
      bankAccount: new FormControl({ value: '', disabled: false }),
      
    });

   this.companyService.getParametersToCreate().subscribe({
    next: data => {
      this.billingTypes = data['billingTypes'];
      this.doctorsAmounts = data['doctorsAmounts'];
      this.paymentMethodTypes = data['paymentMethodTypes'];
      this.registryTypes = data['registryTypes'];
      this.signaturesAmounts = data['signaturesAmounts'];
      this.subscriptionTypes = data['subscriptionTypes'];

    }
  })

   // consultamos las especialidades
   this.specialtiesService.getSpecialties().subscribe({
    next: specialties => {
      this.specialties = specialties;
    },
  });


  }
  checkSepa(){
    if(this.dataHiring.get('paymentMethodTypeId')?.value == PaymentMethodTypeEnum.SEPA){
      this.isSepa = true;
      this.dataHiring.get('bankAccount')?.setValidators([Validators.required,])
    }else {
      this.isSepa = false;
      this.dataHiring.get('bankAccount')?.clearValidators();
    }
    this.dataHiring.get('bankAccount')?.updateValueAndValidity();
  }

  checkHis(){
    if(this.dataHiring.get('registryTypeId')?.value == RegistryTypeEnum.HIS){
      this.isHis = true;
      this.dataHiring.get('his')?.setValidators([Validators.required,]);
      this.dataHiring.get('signaturesAmountId')?.clearValidators();
    }
    else{
      this.isHis = false;
      this.dataHiring.get('his')?.clearValidators();
      this.dataHiring.get('signaturesAmountId')?.setValidators([Validators.required,]);

    }

    this.dataHiring.get('his')?.updateValueAndValidity();
    this.dataHiring.get('signaturesAmountId')?.updateValueAndValidity();
  }

   // para quitar una especialidad
   deleteItem(i: number, specialty: Specialty) {
    this.specialty?.splice(i, 1);
    this.specialties?.push(specialty);
    this.specialties = this.specialties?.sort((a, b) => {
      const descriptionA = a.description || '';
      const descriptionB = b.description || '';
      return descriptionA.localeCompare(descriptionB);
    });
  }
  // para añadir una especialidad
  addItem(i: number, specialtyAdd: Specialty) {
    if (this.specialty != undefined) {
      this.specialties?.splice(i, 1);
      this.specialty?.push(specialtyAdd);
    }
  }

  editField(){
    const error = document.querySelector('.messageSpecialty');
    const box = document.querySelector('.specialty_select');
    error?.classList.remove('wrongtext');
    box?.classList.remove('wrong');
  }

  onSubmit() {

    this.isSubmit = true;

    Object.keys(this.dataHiring.controls).forEach(controlName => {
      this.dataHiring.get(controlName)?.markAsTouched();
    });


    if (this.dataHiring.valid && ((this.isHis && this.specialty !== undefined && this.specialty.length > 0) || !this.isHis )) {

      if (this.isHis && this.specialty != undefined && this.specialty?.length > 0){
        const specialtyIds: number[] = this.specialty?.map(specialty => specialty?.id ?? 0) ?? [];
        this.dataHiring.patchValue({specialtiesId:specialtyIds});
        const specialtyNames: string[] = this.specialty?.map(specialty => specialty?.description ?? '') ?? [];
        this.dataHiring.patchValue({specialtiesName:specialtyNames});
      } else {
        const specialtyIds: number[] = [];
        this.dataHiring.patchValue({specialtiesId:specialtyIds});
      }

     this.dataClientService.addFormGroup('dataHiring', this.dataHiring)
     this.togglePagesOneTwo();
    }
    else {
      this.classInput = 'wrong';
      this.alertService.error(
        this.translate.instant(
          'PRIVATE.PATIENT.DATA-PATIENT.MESSAGE-PATIENT-NEW-ERROR'
        )
      );

      if(this.isHis && this.specialty && this.specialty?.length === 0){
        const error = document.querySelector('.messageSpecialty');
        const box = document.querySelector('.specialty_select');
        error?.classList.add('wrongtext');
        box?.classList.add('wrong');
      }
    }
  }
}


