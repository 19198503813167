<div class="wrapper">
    <div class="cabecera">
        <img class="movil" src="../../../assets/img/avisolegal/cabecera-movil.jpg" alt="Política de cookies">
        <img class="desktop" src="../../../assets/img/avisolegal/cabecera.jpg" alt="Política de cookies">
        <h1 class="titulo_seccion tipo-second-color bold">Política de cookies</h1>
    </div>
    <div class="maxwidth">
        <div class="contenido tipo-main-color regular">

                    <p class="tipo-main-color regular lineheight">En cumplimiento con el deber de información recogido en el apartado 2º del artículo 22 de la Ley 34/2002, de 11 de Julio, de Servicios de la Sociedad de la Información y del Comercio Electrónico, la
                        presente política de cookies tiene por finalidad informarle de manera clara y precisa sobre las cookies que se utilizan en la página web de DS LEGAL .</p>

                    <p class="tipo-main-color bold lineheight">¿Qué son las cookies?</p>
                    <p class="tipo-main-color regular lineheight">Una cookie es un fichero de pequeño tamaño que los sitios web envían al navegador y se descargan en su ordenador. Las cookies permiten que la página web almacene y recupere la información sobre su
                        visita, como su idioma preferido y otras opciones, con el fin de mejorar los servicios que se ofrecen y contribuir a tener una mejor experiencia de navegación para el usuario.</p>

                    <p class="tipo-main-color bold lineheight">Tipos de cookies</p>

                    <p class="tipo-main-color regular lineheight">En función de quién gestione el dominio desde donde se envían las cookies y se traten los datos, se distinguirá entre cookies propias y cookies de terceros. Las cookies también pueden clasificarse según el plazo de tiempo que permanezcan almacenadas en el navegador del usuario, distinguiéndose entre cookies de sesión o cookies persistentes.
                        Finalmente, en función de la finalidad para la que se trate la información recopilada, las cookies se pueden clasificar en las siguientes categorías:</p>
                    <ul>
                        <li class="lineheight">Cookies técnicas: permiten al usuario navegar por un sitio web y utilizar algunos de los servicios ofrecidos por el mismo (como, por ejemplo, las que sirven para recordar los elementos integrantes de un carrito de compra).</li>
                        <li class="lineheight">Cookies de personalización: permiten que el usuario acceda al sitio web con unas características determinadas, como puede ser el idioma.</li>
                        <li class="lineheight">Cookies de seguridad: sirven para impedir o dificultar los ataques contra el sitio web.</li>
                        <li class="lineheight">Cookies de complemento para intercambiar contenidos sociales: los llamados plug-in, que permiten compartir contenidos en redes sociales.</li>
                        <li class="lineheight">Cookies de análisis: permiten al responsable el seguimiento del comportamiento del usuario.</li>
                        <li class="lineheight">Cookies publicitarias: permiten gestionar los espacios de publicidad del sitio web.</li>
                        <li class="lineheight">Cookies de publicidad comportamental: como las anteriores, sirven para gestionar los espacios de publicidad del sitio web en función de la información recogida sobre el comportamiento del usuario y sus hábitos, adaptando la publicidad al perfil del usuario.</li>
                        <li class="lineheight">Cookies de geolocalización: utilizadas para averiguar el país dónde se encuentra el usuario.</li>
                    </ul>

                    <p class="tipo-main-color bold lineheight">Autorización para el uso de cookies</p>
                    <p class="tipo-main-color regular lineheight">De conformidad con el aviso de cookies que aparece en el sitio web, el usuario puede consentir expresamente el uso de cookies que requieran consentimiento; sin embargo, el uso de cookies técnicas o necesarias no requiere de consentimiento. Sin perjuicio de todo ello, el usuario puede modificar la configuración de su navegador para rechazar el uso de las cookies.</p>

                     <p class="tipo-main-color bold lineheight">Cómo modificar la configuración de las cookies</p>
                     <p class="tipo-main-color regular lineheight">Usted puede restringir, bloquear o borrar las cookies de DS LEGAL o cualquier otra página web utilizando su navegador. En cada navegador la operativa es diferente, puede encontrar cómo hacerlo en el menú de ayuda de su navegador dónde se establece el procedimiento para su eliminación. Para más información:</p>
                     <ul>
                        <li class="lineheight"><a href="https://support.google.com/chrome/answer/95647?co=GENIE.Platform%253DDesktop&hl=es" target="_blank" rel="noopener noreferrer">Google Chrome</a></li>
                        <li class="lineheight"><a href="https://support.mozilla.org/es/kb/habilitar-y-deshabilitar-cookies-sitios-web-rastrear-preferencias" target="_blank" rel="noopener noreferrer">Mozilla Firefox</a></li>
                        <li class="lineheight"><a href="https://support.apple.com/es-es/guide/safari/sfri11471/mac" target="_blank" rel="noopener noreferrer">Safari</a></li>
                        <li class="lineheight"><a href="https://support.microsoft.com/es-es/help/278835/how-to-delete-cookie-files-in-internet-explorer" target="_blank" rel="noopener noreferrer">Internet Explorer</a></li>

                    </ul>

                     <p class="tipo-main-color regular lineheight">Es posible que al deshabilitar las cookies la página web no funcione correctamente o no pueda acceder a determinadas funciones de la misma.</p>

                    <p class="tipo-main-color bold lineheight">Cookies utilizadas en la web</p>
                    <p class="tipo-main-color regular lineheight">En el portal web se utilizan diferentes tipos de cookies con distintas finalidades, a continuación, se
                        enumeran las cookies empleadas:</p>
                        <ul>
                            <li class="lineheight">Cookies técnicas.</li>
                            <li class="lineheight">Cookies de seguridad.</li>
                            <li class="lineheight">Cookies de análisis.</li>
                            <li class="lineheight">Cookies de geolocalización.</li>
                        </ul>
                    <p class="tipo-main-color bold lineheight">Modificación de las condiciones</p>
                    <p class="tipo-main-color regular lineheight">El Consentimiento se reserva expresamente el derecho a modificar unilateralmente, total o parcialmente, sin necesidad de previo aviso, la presente Política de Cookies. El usuario reconoce y acepta que es su responsabilidad revisar la presente Política de Cookies.</p>


        </div>
    </div>
    <div class="pie">
        <img class="movil" src="../../../assets/img/avisolegal/footer-movil.jpg" alt="Política de cookies">
        <img class="desktop" src="../../../assets/img/avisolegal/footer.jpg" alt="Política de cookies">
    </div>
</div>

