<div class="wrapper">
    <div class="cabecera">
        <img class="movil" src="../../../assets/img/avisolegal/cabecera-movil.jpg" alt="Política de privacidad">
        <img class="desktop" src="../../../assets/img/avisolegal/cabecera.jpg" alt="Política de privacidad">
        <h1 class="titulo_seccion tipo-second-color bold">Política de privacidad</h1>
    </div>
    <div class="maxwidth">
        <div class="contenido tipo-main-color regular">
            <section class="avisolegal">
                <article >

                    <h5>Información básica sobre Protección de Datos</h5>
                    <ul>
                        <li><strong>Responsable: </strong> Directum Societas, S.L. (en adelante El Consentimiento).</li>
                        <li><strong>Finalidad:</strong> Gestión de la relación profesional, comercial y/o contractual.</li>
                        <li><strong>Legitimación:</strong> Consentimiento del interesado.</li>
                        <li><strong>Destinatarios:</strong> Otras empresas colaboradoras.</li>
                        <li><strong>Derechos: </strong> Acceder, rectificar o suprimir los datos, así como otros derechos, como se explica en la información adicional. </li>
                        <li><strong>Información adicional: </strong> Puede consultar la información adicional y detallada sobre Protección de Datos a continuación. </li>
                    </ul>
                    <h5>Información ampliada</h5>
                    <ul>
                        <li><strong>Identidad del responsable:</strong> DIRECTUM SOCIETAS, S.L. (en adelante, El Consentimiento)</li>
                        <li><strong>Domicilio social:</strong> C/ Doctor Esquerdo 66, 28007 Madrid</li>
                        <li><strong>Teléfono:</strong> <a href="tel:+34911599997">(+911) 599 997</a></li>
                        <li><strong>Dirección de correo electrónico:</strong> <a href="mailto:info@elconsentimiento.es" target="_blank" rel="noopener noreferrer">info@elconsentimiento.es</a></li>
                        <li><strong>Delegado de Protección de Datos: </strong> <a href="mailto:lopd@elconsentimiento.es" target="_blank" rel="noopener noreferrer">lopd@elconsentimiento.es</a></li>
                    </ul>
                    <h4>Finalidad</h4>

                    <h5>¿Con qué finalidad tratamos sus datos? </h5>
                    <p> En El Consentimiento tratamos la información que nos facilitan las personas interesadas con el fin de mantener la relación profesional, comercial y/o contractual que en su caso se establezca, con independencia de que se llegue a formalizar o no un contrato de prestación de servicios, o posteriormente se mantenga o no el mismo en vigor, a efectos estadísticos y de remisión de propaganda y publicidad de nuevos productos o servicios relacionados con su actividad, pudiendo utilizar para las comunicaciones canales de SMS y apps de mensajería instantánea para lo cual el interesado ha prestado previamente su consentimiento expreso.</p>
                    <p>Con el fin de poder ofrecerle productos y servicios de acuerdo con sus intereses y mejorar su experiencia como cliente, elaboraremos un ‘perfil comercial’, en base a la información facilitada, no tomándose decisiones automatizadas en base a dicho perfil, adoptando en todo momento las medidas de seguridad oportunas para salvaguardar el tratamiento de los datos de carácter personal que nos ha facilitado.</p>

                    <h5>¿Por cuánto tiempo conservaremos sus datos?</h5>
                    <p>Los datos personales se conservarán durante el tiempo que se mantenga la relación contractual y, una vez finalice ésta, no se solicite la supresión pertinente por el interesado. Puede encontrar más información en el aviso legal de este sitio web.</p>

                    <h4>Legitimación</h4>
                    <h5> ¿Cuál es la legitimación para el tratamiento de sus datos?</h5>
                    <p>La base legal para el tratamiento de sus datos es la ejecución del contrato de prestación de servicios suscrito, según los términos y condiciones que figuran en el mismo.  </p><br>
                    <h5>¿A qué destinatarios se comunicarán sus datos? </h5>
                    <p>Los datos se comunicarán a otras empresas del grupo empresarial y a las demás entidades colaboradoras intervinientes en la relación contractual al objeto de dar cumplimiento al servicio contratado, así como para remitir publicidad relacionada con el ejercicio de su actividad, pudiendo en cualquier caso ejercer el derecho de oposición en la dirección postal y electrónica siguientes: </p>
                    <ul>
                        <li>C/ Doctor Esquerdo nº 66, Local C.P.28007, Madrid, España</li>
                        <li><a href="mailto:lopd@elconsentimiento.es" target="_blank" rel="noopener noreferrer">lopd@elconsentimiento.es</a></li>
                    </ul>
                    <h4>Derechos</h4>

                    <h5>¿Cuáles son sus derechos cuando nos facilita sus datos?</h5>
                    <p>Cualquier persona tiene derecho a obtener confirmación sobre si en Directum Societas, S.L. estamos tratando datos personales que les conciernan, o no.</p>
                    <p>Las personas interesadas tienen derecho a acceder a sus datos personales, así como a solicitar la rectificación de los datos inexactos o, en su caso, solicitar su supresión cuando, entre otros motivos, los datos ya no sean necesarios para los fines que fueron recogidos.</p>
                    <p>En determinadas circunstancias, los interesados podrán solicitar la limitación del tratamiento de sus datos, en cuyo caso únicamente los conservaremos para el ejercicio o la defensa de reclamaciones.</p>
                    <p> En determinadas circunstancias y por motivos relacionados con su situación particular, los interesados podrán oponerse al tratamiento de sus datos. El Consentimiento dejará de tratar los datos, salvo por motivos legítimos imperiosos, o el ejercicio o la defensa de posibles reclamaciones.</p>
                    <p> Cualesquiera de los derechos descritos con anterioridad deberán notificarse por escrito debidamente firmado a las siguientes direcciones de correo postal y electrónico, acreditando su identidad:</p>
                    <ul>
                        <li>C/ Doctor Esquerdo nº 66, Local C.P.28007, Madrid, España</li>
                        <li><a href="mailto:lopd@elconsentimiento.es" target="_blank" rel="noopener noreferrer">lopd@elconsentimiento.es</a></li>
                    </ul>

                    <h4>Procedencia</h4>
                    <h5> ¿Cómo hemos obtenido sus datos?</h5>
                    <p>Los datos personales que tratamos en El Consentimiento han sido facilitados por Ud. y están siendo tratados con su consentimiento. Es posible que sus datos procedan de otras empresas colaboradoras a las que previamente Ud. prestó su consentimiento a efectos de tratamiento para las finalidades descritas en cada caso. </p>
                    <p>Si no desea que El Consentimiento o empresas colaboradoras traten sus datos personales puede hacérnoslo saber en cualquier momento notificándonoslo por escrito debidamente firmado a las siguientes direcciones de correo postal y electrónico, acreditando su identidad:</p>
                    <ul>
                        <li>C/ Doctor Esquerdo nº 66, Local C.P.28007, Madrid, España</li>
                        <li><a href="mailto:lopd@elconsentimiento.es" target="_blank" rel="noopener noreferrer">lopd@elconsentimiento.es</a></li>
                    </ul>
                    <p>Le informamos que las categorías de datos que se tratan son:</p>
                    <ul>
                        <li>Datos de identificación</li>
                        <li>Direcciones postales y electrónicas</li>
                        <li>Teléfono de contacto</li>
                    </ul>
                    <p>No se tratan datos especialmente protegidos, salvo que Ud., directamente nos los facilite para la contratación de algún servicio en los que sea imprescindible su tratamiento. En este caso, El Consentimiento adoptará las medidas de seguridad necesarias para salvaguardar su tratamiento en los términos establecidos en el Reglamento Europeo de Protección de Datos. </p>
                </article>
            </section>


        </div>
    </div>
    <div class="pie">
        <img class="movil" src="../../../assets/img/avisolegal/footer-movil.jpg" alt="Política de privacidad">
        <img class="desktop" src="../../../assets/img/avisolegal/footer.jpg" alt="Política de privacidad">
    </div>
</div>
