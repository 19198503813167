import { AfterViewInit, Component } from '@angular/core';
import { faCircleArrowDown } from '@fortawesome/free-solid-svg-icons';
import { Claimshome, Pantallahome } from '../../interfaces/interfaces';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements AfterViewInit {
  faCircleArrowDown = faCircleArrowDown;
  home: Array<Pantallahome> = [
    {
      imagenmovil: '../../../assets/img/home/home1-movil.jpg',
      imagen: '../../../assets/img/home/home1.jpg',
      claim: 'La <strong>solución digital</strong> que clínicas, hospitales y profesionales de la salud necesitan para <strong>mejorar la relación con el paciente</strong>',
      cta: 'Qué es',
      enlace: 'quees'
    },
    {
      imagenmovil: '../../../assets/img/home/home2-movil.jpg',
      imagen: '../../../assets/img/home/home2.jpg',
      claim: 'La <strong>información específica</strong> que el <strong>paciente debe saber antes</strong> de someterse a <strong>una intervención</strong>',
      cta: 'Cómo funciona',
      enlace: 'comofunciona'
    },
    {
      imagenmovil: '../../../assets/img/home/home3-movil.jpg',
      imagen: '../../../assets/img/home/home3.jpg',
      claim: 'El <strong>respaldo</strong> imprescindible para <strong>la tranquilidad del profesional</strong> de la salud en su labor asistencial',
      cta: 'Quiénes somos',
      enlace: 'quienessomos'
    },
    {
      imagenmovil: '../../../assets/img/home/home4-movil.jpg',
      imagen: '../../../assets/img/home/home4.jpg',
      claim: 'Disponemos de un <strong>catálogo amplio</strong> de especialidades y <strong>procedimientos validados</strong> médica y legalmente',
      cta: 'Catálogo',
      enlace: 'catalogo'
    },

  ];

  formatText(text: string): string {
    const formattedText = text.replace('solución digital', '<strong>solución digital</strong>')
                          .replace('mejorar la relación con el paciente', '<strong>mejorar la relación con el paciente</strong>')
                          .replace('información específica', '<strong>información específica</strong>')
                          .replace('paciente debe saber antes', '<strong>paciente debe saber antes</strong>')
                          .replace('una intervención', '<strong>una intervención</strong>')
                          .replace('respaldo', '<strong>respaldo</strong>')
                          .replace('la tranquilidad del profesional', '<strong>la tranquilidad del profesional</strong>')
                          .replace('catálogo amplio', '<strong>catálogo amplio</strong>')
                          .replace('procedimientos validados', '<strong>procedimientos validados</strong>')
    return formattedText;
  }

  claim: Array<Claimshome> = [
    {
      claim: '¿Estás preparado para el cambio?'
    },
    {
      claim: 'Agiliza la gestión de documentos'
    },
    {
      claim: 'Un consentimiento para cada procedimiento'
    },
    {
      claim: '¡Y dedícate a tus pacientes!'
    }
  ];
contador= 0;
  scroll() {
    const altoVentana = document.documentElement.clientHeight;
    const wrapper = document.querySelector('.wrapper_home');
    const bajar = document.querySelector ('.icono');
    const currentScroll = document.documentElement.scrollTop || document.body.scrollTop;
          if (wrapper){
           window.scrollBy (0, altoVentana);
           this.contador = this.contador+1;
            if (bajar && (this.contador == 5) || (currentScroll > altoVentana*4)) {
              window.scroll(0,0);
              this.contador = 0;
            }

        }
      }
      ngAfterViewInit() {
        window.scroll(0,0);
      }
}
