import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ErrorResponse } from '@app/core/models/error-response';
import { AlertService } from '@app/core/services/alert/alert.service';
import { LoginService } from '@app/core/services/login/login.service';
import { Observable, catchError, throwError } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root',
})
export class ErrorInterceptor implements HttpInterceptor {
  constructor(
    private loginService: LoginService,
    private alertService: AlertService,
    private translateService: TranslateService,
  ) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError(err => {
        const errorBody: ErrorResponse = !err.error ? new ErrorResponse(err.status) : err.error;
        // If user is not logged (password or user is not correct)
        if ([401, 403].includes(err.status)) {
          this.alertService.error(this.translateService.instant("CORE.INTERCEPTORS.ERROR.WRONG-USER-OR-PASSWORD"));
        }
        // If user is logged and access an unauthorized area
        if ([401, 403].includes(err.status) && this.loginService.userValue) {
          // auto logout if 401 or 403 response returned from api
          this.loginService.logout("login");
          this.alertService.error(
            this.translateService.instant("CORE.INTERCEPTORS.ERROR.TOKEN-EXPIRED")
          );
        }
        if (errorBody?.messages) {
          errorBody.messages.forEach(errorMessage => {
            this.alertService.error(errorMessage);
          });
        }

        const error = errorBody?.messages ?? err.statusText;
        return throwError(() => error);
      })
    );
  }
}
