<div class="wrapper">
  <!-- logo consentimiento -->
  <header class="header inlineflex">
    <div class="header_logo">
      <a [routerLink]="['']" class="tipo-white-color">
        <img
          alt="El consentimiento"
          src="../../../assets/img/header/logo-ELCI.png" />
      </a>
    </div>

    <!-- menú cabecera desktop -->
    <ul class="menu_desktop">
      <ng-container *ngFor="let menuItem of menuItems; let i = index">
        <li
          class="menu_desktop_li"
          *ngIf="
            menuItem.route === '/portal' &&
            menuItem.subMenuItems.length === 0 &&
            this.roleService.hasRole(menuItem.permissions)
          "
          (mouseover)="removeDesktopSubMenu()">
          <a
            [id]="menuItem.route"
            [routerLink]="[menuItem.route]"
            class="tipo-main-color menu_a"
            (click)="selectMenuOption(menuItem.route)"
            [routerLinkActive]="['selected']"
            [routerLinkActiveOptions]="{ exact: true }">
            {{ menuItem.name | translate }}
          </a>
        </li>
        <li
          class="menu_desktop_li"
          *ngIf="
            menuItem.route !== '/portal' &&
            menuItem.subMenuItems.length === 0 &&
            this.roleService.hasRole(menuItem.permissions)
          "
          (mouseover)="removeDesktopSubMenu()">
          <a
            [id]="menuItem.route"
            [routerLink]="[menuItem.route]"
            class="tipo-main-color menu_a"
            (click)="selectMenuOption(menuItem.route)"
            [routerLinkActive]="['selected']">
            {{ menuItem.name | translate }}
          </a>
        </li>
        <li
          class="tipo-main-color menu_a"
          (mouseover)="showDesktopSubMenu()"
          *ngIf="
            menuItem.subMenuItems.length > 0 &&
            this.roleService.hasRole(menuItem.permissions)
          ">
          {{ menuItem.name | translate }}
          <!-- Render submenus if available -->
          <ul
            class="submenu_desktop"
            *ngIf="menuItem.subMenuItems.length > 0 && showSubMenu">
            <ng-container
              *ngFor="let subMenuItem of menuItem.subMenuItems; let j = index">
              <li class="submenu_desktop_li">
                <a
                  [id]="subMenuItem.route"
                  [routerLink]="[subMenuItem.route]"
                  class="tipo-main-color menu_a"
                  (click)="selectMenuOption(subMenuItem.route)"
                  [routerLinkActive]="['selected']">
                  {{ subMenuItem.name | translate }}
                </a>
              </li>
            </ng-container>
          </ul>
        </li>
      </ng-container>
    </ul>

    <!-- logo empresa, login y salir -->
    <div class="header_logocompany">
      <img *ngIf="user?.logo !== null; else notImage" [src]="user?.logo" class="logocompany" [alt]="user?.company" />
      <ng-template #notImage>
        <p class="company_name small regular">{{user?.company}}</p>
      </ng-template>
     
      <!-- menú hamburguesa para móvil -->
      <button
        class="header_button"
        (click)="mostrarMenu()"
        [ngClass]="{ activo: logoblanco }">
        <p class="tipo-second-color small bold button_p">
          {{ 'PRIVATE.HEADER.BUTTON-MENU' | translate }}
        </p>
        <div class="linea top"></div>
        <div class="linea mid"></div>
        <div class="linea bot"></div>
      </button>
      <div
        class="menu back-blue-color tipo-white-color bold"
        [ngClass]="{ visible: menuvisible }"
        (mouseleave)="quitarMenu()">
        <ul class="menu_ul">
          <a
            [routerLink]="['portal']"
            class="tipo-white-color"
            (click)="mostrarMenu()">
            <li class="menu_li" *appHasRole="HEADER_ROLES">
              {{ 'PRIVATE.HEADER.MENU-LI1' | translate }}
            </li>
          </a>
          <a
            [routerLink]="['patients']"
            class="tipo-white-color"
            (click)="mostrarMenu()">
            <li class="menu_li">{{ 'PRIVATE.HEADER.MENU-LI2' | translate }}</li>
          </a>
          <a
            [routerLink]="['consents']"
            class="tipo-white-color"
            (click)="mostrarMenu()">
            <li class="menu_li">{{ 'PRIVATE.HEADER.MENU-LI3' | translate }}</li>
          </a>
          <li class="menu_li" *appHasRole="COMPANY_ROLES">
            {{ 'PRIVATE.HEADER.MENU-LI4' | translate }}
            <ul class="submenu_ul">
              <a
                [routerLink]="['professionals']"
                class="tipo-white-color"
                (click)="mostrarMenu()">
                <li class="submenu_li">
                  {{ 'PRIVATE.HEADER.SUBMENU-LI1' | translate }}
                </li>
              </a>
              <a
                [routerLink]="['administrators']"
                class="tipo-white-color"
                (click)="mostrarMenu()">
                <li class="submenu_li">
                  {{ 'PRIVATE.HEADER.SUBMENU-LI2' | translate }}
                </li>
              </a>
            </ul>
          </li>
          <a
            [routerLink]="['configuration/preferences']"
            class="tipo-white-color"
            (click)="mostrarMenu()">
            <li class="menu_li">{{ 'PRIVATE.HEADER.MENU-LI5' | translate }}</li>
          </a>
          <li
            [routerLink]="[
              '/portal/configuration/companies/' + user?.companyUuid
            ]"
            *appHasRole="FREEMIUM_COMPANY_ROLES"
            class="submenu_li"
            (click)="mostrarMenu()">
            {{ 'PRIVATE.HEADER.MYACCOUNT' | translate }}
          </li>
          <li
            [routerLink]="['/portal/professionals/' + user?.uuid + '/file']"
            *appHasRole="DOCTOR_ROLES"
            class="submenu_li"
            (click)="mostrarMenu()">
            {{ 'PRIVATE.HEADER.MYACCOUNT' | translate }}
          </li>
          <li (click)="logOut()" class="submenu_li">
            <fa-icon [icon]="faRightFromBracket" class="icono"></fa-icon>
            {{ 'PRIVATE.HEADER.BUTTON-OUT' | translate }}
          </li>
        </ul>
      </div>
      <div class="inlineflex user">
        <p
          class="username tipo-second-color regular small"
          *appHasRole="USERS_LOGIN">
          {{ user?.name }}  {{user?.lastname ?? " " }}
        </p>
        <p
          class="username tipo-second-color regular small"
          *appHasRole="FREEMIUM_COMPANY_ROLES">
          {{ user?.userName }}
        </p>
        <fa-icon
          [icon]="faChevronDown"
          class="icono tipo-second-color myaccountdown"></fa-icon>
        <!-- TODO por lógica <fa-icon
          [icon]="faChevronDown"
          [routerLink]="['fileadministrator/' + user?.uuid]"
          class="icono tipo-second-color mycount"
          *appHasRole="ADMINISTRATORS_ROLES"></fa-icon> -->

        <div class="myaccount">
          <ul>
            <li
              [routerLink]="[
                '/portal/configuration/companies/' + user?.companyUuid
              ]"
              *appHasRole="FREEMIUM_COMPANY_ROLES">
              {{ 'PRIVATE.HEADER.MYACCOUNT' | translate }}
            </li>
            <li
              [routerLink]="['/portal/professionals/' + user?.uuid + '/file']"
              *appHasRole="DOCTOR_ROLES">
              {{ 'PRIVATE.HEADER.MYACCOUNT' | translate }}
            </li>
            <li (click)="logOut()">
              <fa-icon [icon]="faRightFromBracket" class="icono"></fa-icon>
              {{ 'PRIVATE.HEADER.BUTTON-OUT' | translate }}
            </li>
          </ul>
        </div>
      </div>
    </div>
  </header>
</div>
