import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EnvironmentService } from '@app/environment.service';
import { Observable } from 'rxjs';
import { CacheImplService } from '../../../shared/services/cache/cache-impl.service';

@Injectable({
  providedIn: 'root',
})
export class JwtInterceptor implements HttpInterceptor {
  constructor(
    private env: EnvironmentService,
    private cacheImplService: CacheImplService
  ) {}

  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    // add auth header with jwt if user is logged in and request is to the api url
    const token = this.cacheImplService.getItem('token')
    const isApiUrl = request.url.startsWith(this.env.apiUrl);
    const isApiConsents = request.url.startsWith(this.env.apiConsents);
    if ((token && isApiUrl) || (token && isApiConsents)) {
      request = request.clone({
        headers: request.headers.append('Authorization', `Bearer ${token}`),
      });
    }

    return next.handle(request);
  }
}
