import { Location } from '@angular/common';
import { Component, Input } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {
  COMPANY_ROLES,
  DOCTOR_ROLES,
  OPEN_CIS,
  VIEW_CIS,
  GENERATE_CIS
} from '@app/core/constants/Permissions';
import { PatientParamsRouteEnum } from '@app/core/enums/route.enum';
import SubscriptionTypeEnum from '@app/core/enums/subscription-type.enum';
import { SubscriptionTypes } from '@app/core/models/input/company/company-parameters.model';
import { User } from '@app/core/models/input/user.model';
import { AlertService } from '@app/core/services/alert/alert.service';
import { LoginService } from '@app/core/services/login/login.service';
import { PatientService } from '@app/core/services/patient/patient.service';
import {
  faBackward,
  faCheckDouble,
  faDownload,
  faFileCirclePlus,
  faFileContract,
  faHospitalUser,
  faTrashCan,
} from '@fortawesome/free-solid-svg-icons';
import { TranslateService } from '@ngx-translate/core';


@Component({
  selector: 'app-file-patient',
  templateUrl: './file-patient.component.html',
  styleUrls: ['./file-patient.component.scss'],
})
export class FilePatientComponent {
  faFileCirclePlus = faFileCirclePlus;
  faFileContract = faFileContract;
  faTrashCan = faTrashCan;
  faBackward = faBackward;
  faDownload = faDownload;
  faCheckDouble = faCheckDouble;
  faHospitalUser = faHospitalUser;

  DOCTOR_ROLES = DOCTOR_ROLES;
  COMPANY_ROLES = COMPANY_ROLES;
  OPEN_CIS = OPEN_CIS;
  VIEW_CIS = VIEW_CIS;
  GENERATE_CIS = GENERATE_CIS;

  patientUuid?: string;
  selectedTab?: string;
  user?: User;
  subscriptions?: SubscriptionTypes[] = [];  
  isBiometric = false;
  confirmDelete = false;
  
  patientFullName?: string; 
  PatientParamsRouteEnum = PatientParamsRouteEnum;

  @Input() isAdmin = false;
  @Input() showRGPDspermission = true;
  @Input() showDocumentsTypes = true;

  constructor(
    private activatedRoute: ActivatedRoute,
    private alertService: AlertService,
    private patientService: PatientService,
    private router: Router,
    private translate: TranslateService,
    private location: Location,
    private loginService: LoginService
  ) {
    this.activatedRoute.params.subscribe(params => {      
      this.patientUuid = params['uuid'];
      this.selectedTab = params['tab'];
    });
  }

 
  ngOnInit(): void {
    this.user = this.loginService.userValue ?? new User(); 
    // Comprobar el tipo de suscripción y si es solo papel, no mostrar botón Biométrica
    const subscriptionType: SubscriptionTypes[]  = this.user.subscription ?? [];    
    this.isBiometric = !!subscriptionType.find(subscription => subscription.id === SubscriptionTypeEnum.BIOMETRIC);     
  }
 
  handleChildPatientFullName(patientFullName: string) {
    this.patientFullName = patientFullName    
  }

  changeTab(tab: string) {
    // Select the tab
    this.selectedTab = tab;
    // Update the router
    this.router.navigate(['../', tab], {
      relativeTo: this.activatedRoute,
    });
  }

  confirm() {
    this.confirmDelete = !this.confirmDelete;
  }

  deletePatient() {
    if (this.patientUuid != undefined) {
      this.patientService.deletePatientByUuid(this.patientUuid).subscribe({
        next: () => {
          this.alertService.success(
            this.translate.instant(
              'PRIVATE.PATIENT.FILE-PATIENT.MESSAGE-DELETE-OK'
            )
          );
          this.location.back();
        },

        error: error => {
          this.alertService.error(error[0]);
        },
      });
      this.confirm();
    }
  }
}
