<div class="wrapper">
    <div class="maxwidth">
        <div class="search box">
            <h4 class="title tipo-second-color bold">{{ 'PRIVATE.DASHBOARD.OVERVIEW.CI-BY-DATE' | translate }} <span class="small light tipo-main-color">{{ 'PRIVATE.DASHBOARD.OVERVIEW.DEFAULT-RANGE' | translate }}</span></h4>
            <form [formGroup]="dataStatistics" class="dates" >
                <div  class="date_from">
                    <label for="date_from regular tipo-main-color">{{ 'PRIVATE.DASHBOARD.OVERVIEW.FROM' | translate }} </label>
                    <input type="date" formControlName="startDate" id="date_from" class="tipo-main-color">
                </div>
                <div class="date_until">
                    <label for="date_until regular tipo-main-color">{{ 'PRIVATE.DASHBOARD.OVERVIEW.TO' | translate }} </label>
                    <input type="date" formControlName="endDate" id="date_until" class="tipo-main-color">
                </div>
                <input type="submit" value="{{ 'PRIVATE.DASHBOARD.OVERVIEW.ACCEPT' | translate }}" class="submit" (click)="onSubmit()">
            </form>
        </div>

        <div class="box_container">
            <!-- All users data -->
            <div class="box box3 column">
                 <app-consents-state [consentsByStatuses]="statistics.consentsByStatuses"></app-consents-state>
            </div>

            <div class="box box3 column">
                <app-consents-most-used [topConsents]="statistics.topConsents"></app-consents-most-used>
            </div>
            <div class="column">
                <div class="box box3">
                    <app-patients [statisticPatients]="statistics.statisticPatients"></app-patients>
                </div>
                <div class="box box3">
                    <app-mydocuments [statisticDDs]="statistics.statisticDDs"></app-mydocuments>
                </div>
                <div class="box box3">
                    <app-ci-six-months [consentsLastSixMonths]="statistics.consentsLastSixMonths"></app-ci-six-months>
                </div>

            </div>
            <!-- datos solo para usuario Empresa -->
            <div class="box" *appHasRole="COMPANY_ROLES">
                <app-consents-doctor [statisticConsentsByDoctor]="statistics.statisticConsentsByDoctor"></app-consents-doctor>

            </div>
            <div class="box">
                <app-news></app-news>
            </div>

        </div>
    </div>
</div>

