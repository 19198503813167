import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { AlertService } from '@app/core/services/alert/alert.service';
import { ElciValidators } from '@app/core/utils/validators';
import { EnvironmentService } from '@app/environment.service';
import { TranslateService } from '@ngx-translate/core';
import { ContactFormService } from '../../services/contact-form.service';
import { Country } from '@app/core/models/input/country.model';
import { CountryService } from '@app/core/services/country/country.service';
import { State } from '@app/core/models/input/state.model';
import { StateService } from '@app/core/services/state/state.service';

@Component({
  selector: 'app-contacto',
  templateUrl: './contacto.component.html',
  styleUrls: ['./contacto.component.scss'],
})
export class ContactoComponent implements OnInit {
  formularioContacto!: FormGroup;
  siteKey = this.env.turnstileSiteKey;
  isOnSubmit = false;
  captchaValid = false;
  countries: Country[] = [];
  provinces: State[] = [];

  constructor(
    private contactFormService: ContactFormService,
    private env: EnvironmentService,
    private alertService: AlertService,
    private translate: TranslateService,
    private countryService: CountryService,
    private stateService: StateService
  ) {
    this.formularioContacto = new FormGroup({
      centerName: new FormControl('', [Validators.required, Validators.minLength(3)]),
      contactPerson: new FormControl('', Validators.required),
      position: new FormControl('', Validators.required),
      specialty: new FormControl('', Validators.required),
      country: new FormControl('', Validators.required),
      province: new FormControl('', Validators.required),
      phone: new FormControl('', [Validators.required, Validators.pattern(/^\d{9,15}$/)]),
      email: new FormControl('', [Validators.required, Validators.minLength(3), ElciValidators.emailValidator]),
      check1: new FormControl('', Validators.requiredTrue),
      captchaToken: new FormControl('', Validators.required),
    });
  }

  ngOnInit() {
    this.loadCountries();
  }

  loadCountries() {
    this.countryService.getCountries().subscribe({
      next: (countries: Country[]) => {
        this.countries = countries;
      },
    });
  }

  onCountryChange(event: Event) {
    const selectElement = event.target as HTMLSelectElement;
    const countryName = selectElement.value;

    const selectedCountry = this.countries.find(country => country.name === countryName);
    if (selectedCountry) {
      const countryId = selectedCountry.id ?? 1;
      this.stateService.getStateByCountryId(countryId).subscribe({
        next: (provinces: State[]) => {
          this.provinces = provinces;
        },
      });
    }
  }

  sendCaptchaResponse(captchaResponse: any) {
    this.formularioContacto.get('captchaToken')?.setValue(captchaResponse);
    this.captchaValid = true;
  }

  onCaptchaExpired() {
    this.captchaValid = false;
  }

  shouldShowError(inputControlName: string): boolean {
    const control = this.formularioContacto.get(inputControlName);
    return !!(control && control.touched && control.invalid);
  }

  onSubmit() {
    this.isOnSubmit = true;
    if (this.formularioContacto.valid && this.captchaValid) {
      this.contactFormService.newContactInfo(this.formularioContacto.value).subscribe({
        next: () => {
          this.alertService.success(this.translate.instant('PUBLIC.CONTACT.CONTACT-INFORMATION-OK'));
          this.formularioContacto.reset();
          this.isOnSubmit = false;
        },
      });
    }
  }
}
