import { Component, OnInit } from '@angular/core';
import { DESC } from '@app/core/constants/Constants';
import { COMPANY_ROLES, DOCTOR_ROLES, GENERATE_CIS, NOT_AUTH_GENERATE_CIS, VIEW_CIS } from '@app/core/constants/Permissions';
import { Filter } from '@app/core/models/front/filters';
import { Pagination } from '@app/core/models/front/pagination';
import { Sorters } from '@app/core/models/front/sorters';
import { Consent } from '@app/core/models/input/consent/consent.model';
import { ConsentService } from '@app/core/services/consent/consent.service';
import { StatusService } from '@app/core/services/status/status.service';
import {
  faCaretDown,
  faCaretLeft,
  faCaretRight,
  faCaretUp,
  faCircleExclamation,
  faCircleXmark,
  faFileArrowDown,
  faFileCircleCheck,
  faPrint,
  faTabletScreenButton,
  faEnvelopeOpenText,
  faRotate,
  faFolderOpen
} from '@fortawesome/free-solid-svg-icons';
import { Page } from '../../../../core/models/input/page/pages.model';
import { Status } from '@app/core/models/input/common/status.model';
import OutputTypeEnum from '../../../../core/enums/output-type.enum';
import { ActivatedRoute, Router } from '@angular/router';
import { FileService } from '@app/core/services/file/file.service';
import DownloadUtils from '@app/core/utils/download.utils';
import { User } from '@app/core/models/input/user.model';
import { LoginService } from '@app/core/services/login/login.service';
import { RoleService } from '@app/core/services/role/role.service';
import StatusEnum from '@app/core/enums/status.enum';
import ObjectTypeEnum from '@app/core/enums/objectType.enum';
import { FilterUtils } from '@app/core/utils/filter.utils';


@Component({
  selector: 'app-consents',
  templateUrl: './list-consents.component.html',
  styleUrls: ['./list-consents.component.scss'],
})
export class ListConsentsComponent implements OnInit {
  faCaretUp = faCaretUp;
  faCaretDown = faCaretDown;
  faCaretLeft = faCaretLeft;
  faCaretRight = faCaretRight;
  faFileArrowDown = faFileArrowDown;
  faTabletScreenButton = faTabletScreenButton;
  faPrint = faPrint;
  faCircleExclamation = faCircleExclamation;
  faCircleXmark = faCircleXmark;
  faFileCircleCheck = faFileCircleCheck;
  faEnvelopeOpenText = faEnvelopeOpenText;
  faRotate = faRotate;
  faFolderOpen = faFolderOpen;

  DOCTOR_ROLES = DOCTOR_ROLES;
  COMPANY_ROLES = COMPANY_ROLES;
  GENERATE_CIS = GENERATE_CIS;
  NOT_AUTH_GENERATE_CIS = NOT_AUTH_GENERATE_CIS;

  refreshingConsents = false;

  OutputType = OutputTypeEnum;
  ObjectTypeEnum = ObjectTypeEnum;
  Status = StatusEnum;

  consents?: Consent[];
  statuses?: Status[];
  page?: Page;
  pagination = new Pagination();
  filters = new Array<Filter>();
  sorters = new Sorters('creationDate', DESC);

  selectedStatus = 0;

  user = new User;
  canDownloadFile = false;
  isDoctor = false;

  // Utils
  filterUtils: FilterUtils;

  constructor(
    private consentService: ConsentService,
    private statusService: StatusService,
    private router: Router,
    private route: ActivatedRoute,
    private fileService: FileService,
    private loginService: LoginService,
    public roleService: RoleService
  ) {this.filterUtils = new FilterUtils(this.filters);}

  ngOnInit(): void {

    this.getStatuses();
    this.user = this.loginService.userValue ?? new User();

    if (this.roleService.hasRole(VIEW_CIS) ){
      this.canDownloadFile = true;      
    }
    if(this.roleService.hasRole(DOCTOR_ROLES)){
      this.isDoctor = true
    }

    this.route.queryParams.subscribe(params => {
      if (params['filter']){
      const filter = JSON.parse(params['filter']);
      this.filters.push(filter)
      this.getConsents();
      this.selectedStatus = filter.filter;
    }else {
      this.getConsents();
    }
    });
  }

  paginar(amount: number) {
    this.pagination.page += amount;
    this.getConsents();
  }

  addFilter(name: string, filter: string) {
    // If we filter the requested page will be 0
    this.pagination.page = 0;
    this.filterUtils.addFilter(name, filter);
    this.getConsents();
  }

  removeFilter(name: string) {
    // If we filter the requested page will be 0
    this.pagination.page = 0;
    this.filterUtils.removeFilter(name);
    this.getConsents();
  }

  goToConsentDetail(consent: Consent) {
    this.consentService.setConsent(consent);
    this.router.navigate(['./'+consent.uuid], {relativeTo: this.route});
  }


  addStatusFilter(statusId: string) {
    // 0 means all statuses    
    if (statusId != "0") {
      this.addFilter("statusId", statusId)
    } else {
      this.removeFilter("statusId");
    }    
  }

  openWindow(): void {
    const window = document.querySelector('.window');
    window?.classList.remove('nodisplay');
  }

  closeWindow(): void {
    const window = document.querySelector('.window');
    window?.classList.add('nodisplay');
  }

  refreshConsents() {
    this.refreshingConsents = true;
    this.getConsents();
  }

  getStatuses() {
    this.statusService.getStatuses()
    .subscribe({
      next: statuses => {
        this.statuses = statuses;
      }
    })
  }

  getConsents() {
    this.consentService
      .getConsents(this.pagination, this.filters, this.sorters)
      .subscribe({
        next: consentsPage => {
          this.page = consentsPage;
          this.consents = this.page.content;
          this.refreshingConsents = false;
        },
      });
  }

  downloadFile(uuid: string): void {
    this.fileService.getConsentPdf(uuid).subscribe({
      next: dataDocument => {
        if (dataDocument.body?.name){
          const newName = dataDocument.body?.name.replace(/\.pdf$/, '');
          DownloadUtils.download(dataDocument.body?.content ?? '', newName, dataDocument.body?.mimeType ?? '');
         }
      }
    })
  }

}
