import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';
import { Observable } from 'rxjs';
import { AlertService } from '../../services/alert/alert.service';
import { LoginService } from '../../services/login/login.service';

@Injectable({
  providedIn: 'root',
})
export class AuthenticationGuard {
  constructor(
    private router: Router,
    private loginService: LoginService,
    private alertService: AlertService
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    const user = this.loginService.userValue;

    if (user) {
      // check if route is restricted by role
      const { roles } = route.data;
      const actualUserRoleIds = user.roles
        ?.map(role => role.id)
        .filter(id => id !== undefined);
      if (
        roles &&
        actualUserRoleIds &&
        !actualUserRoleIds.some(roleId => roles.includes(roleId))
      ) {
        // role not authorized so redirect to home page
        this.alertService.info('Please Log In!');
        this.router.navigate(['/login']);
        return false;
      }

      // authorized so return true
      return true;
    }

    // not logged in so redirect to login page with the return url
    this.router.navigate(['/login'], { queryParams: { returnUrl: state.url } });
    return false;
  }
}
