export const MIN_PASSWORD_LENGTH = 8;
export const DEFAULT_PAGE_SIZE = 20;
export const DEFAULT_PAGE = 0;
export const DESC = "DESC";
export const ASC = "ASC";
export const RGPD_LOGO = "RGPD";
export const MAIN_LOGO = "MAIN";
export const PATTERN_DATE_MINUTES = "yyyy-MM-dd HH:mm:ss";
export const PATTERN_DATE_MINUTES_ZONE = "yyyy-MM-dd'T'HH:mm:ss.SSSZ";
export const DEFAULT_TEMPLATE_CI = "DEFAULT_CI";
export const DEFAULT_TEMPLATE_RGPD = "DEFAULT_RGPD";
export const DEFAULT_ELCI_LANGUAGE = "es_ES";
export const DEFAULT_ELCI_SPECIALTY_LANG = "es";

// Storage keys
export const APP_LANGUAGES = "languages"
export const STORAGE_KEY_LOCALE = "locale"
export const CI_LANGUAGES = "ciTemplateLanguages"
export const RGPD_LANGUAGES = "rgpdTemplateLanguages"

