import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { PhonePrefix } from '@app/core/models/input/phone-prefix/phone-prefix.model';
import { EnvironmentService } from '@app/environment.service';
import { CacheImplService } from '@app/shared/services/cache/cache-impl.service';
import { Observable, catchError, of, tap, throwError } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PhonePrefixService {

  constructor(
    private http: HttpClient,
    private cacheService: CacheImplService,
    private env: EnvironmentService,
  ) { }

  getPhonePrefixApi(): Observable<PhonePrefix[]> {
    const PhonePrefixFromStorage = this.getPrefixFromStorage();
    if(PhonePrefixFromStorage != null) {
      return of(PhonePrefixFromStorage);
    }else {
      return this.http.get<PhonePrefix[]>(`${this.env.apiUrl}/phone-prefixes`)
      .pipe (
        tap(data => this.setPhonePrefix(data)),
          catchError(error => {
            // Handle or log the error
            console.error('Error fetching phone prefix:', error);
            return throwError(() => new Error('Error fetching phone prefix'));
          })
      )
    }
  }


    // Get the prefix directly from session storage
    private getPrefixFromStorage(): PhonePrefix[] | null {
      // Attempt to get the prefix from session storage
      const storedPrefix = this.cacheService.getItemNotEncrypted(
        `phone-prefix`
      );
      if (storedPrefix) {
        // If found, parse and return it
        return JSON.parse(storedPrefix);
      } else {
        // If not found, return null
        return null;
      }
    }

      // Save the prefix in session storage
  public setPhonePrefix(patientPrefix: PhonePrefix[]): void {
    if (patientPrefix) {
      this.cacheService.setItemNotEncrypted(
        `phone-prefix`,
        JSON.stringify(patientPrefix)
      );
    }
  }
}

