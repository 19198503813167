
<div *ngIf="consent?.patient?.email !== null && consent?.patient?.email !== ''">
    <p class="tipo-second-color bold section">
    {{ 'PRIVATE.CONSENTS.SIGNED-CONSENT.TITLE' | translate }}
    </p>
    <div class="input_row">
    <div class="input">
        <label for="email">
        {{ 'PRIVATE.CONSENTS.SIGNED-CONSENT.INPUT-EMAIL' | translate }}
        </label>
        <input
        type="email"
        name="email"
        id="email"       
        [(ngModel)]="emailPatient"       
        />

    </div>
    <div class="buttons">
        <button class="button tipo-white-color back-blue-color button_resend"
        [disabled]="reSendingFile"         
         (click)="sendMail(consent?.uuid ?? '')">         
         <fa-icon [icon]="faSpinner" class="icono" animation="spin-pulse" *ngIf="reSendingFile"></fa-icon> 
        <fa-icon [icon]="faPaperPlane" class="icono"  *ngIf="!reSendingFile"></fa-icon>
        {{ 'PRIVATE.CONSENTS.SIGNED-CONSENT.BUTTON-RESEND' | translate }}
        </button>
    </div>
    </div>
</div>

<app-legal-representatives-signs *ngIf="StatusEnum.SIGNED === consent?.status?.id"></app-legal-representatives-signs>
<div class="input_row" *ngIf="StatusEnum.SIGNED === consent?.status?.id">
    <div class="input">
      <select name="device" id="device" [(ngModel)]="selectedDevice">
        <option value ="0">
          {{ 'PRIVATE.CONSENTS.EXPIRED-CONSENT.INPUT-SELECT' | translate }}
        </option>
        <option
          *ngFor="let device of devices; let i = index"
          ngValue="{{ device.deviceName }}">
          {{ device.deviceDescription }}
        </option>
      </select>
    </div>
    <div class="buttons">
      <button
        class="button tipo-white-color back-blue-color"
        (click)="revokeConsent(consent?.uuid ?? '')"
        [disabled]="revokingFile" >
        <fa-icon [icon]="faCircleXmark" class="icono" *ngIf="!revokingFile"></fa-icon>
        <fa-icon [icon]="faSpinner" class="icono" animation="spin-pulse" *ngIf="revokingFile"></fa-icon> 
        {{ 'PRIVATE.CONSENTS.SIGNED-CONSENT.BUTTON-REVOKED' | translate }}
      </button>
    </div>

</div>
