<div class="wrapper">
  <div class="contacto">
    <img class="movil" src="../../../assets/img/contacto/contacto-movil.jpg" alt="Contacta con nosotros e infórmate" />
    <img class="desktop" src="../../../assets/img/contacto/contacto.jpg" alt="Contacta con nosotros e infórmate" />
    <h4 class="intro_contacto tipo-white-color light">
      Pídenos información sin compromiso. Estaremos encantados de enseñarte nuestra solución y mostrarte sus ventajas.
    </h4>
    <form [formGroup]="formularioContacto" class="form_contacto" (ngSubmit)="onSubmit()">
      <input
        type="text"
        placeholder="Nombre del centro"
        formControlName="centerName"
        [ngClass]="{ wrong: shouldShowError('centerName') }" />
      <p class="wrong-text" *ngIf="this.formularioContacto.get('centerName')?.errors?.['required'] && this.isOnSubmit">
        {{ 'SHARED.REQUIRED-INPUT' | translate }}
      </p>

      <input
        type="text"
        placeholder="Persona de contacto"
        formControlName="contactPerson"
        [ngClass]="{ wrong: shouldShowError('centerName') }" />
      <p
        class="wrong-text"
        *ngIf="this.formularioContacto.get('contactPerson')?.errors?.['required'] && this.isOnSubmit">
        {{ 'SHARED.REQUIRED-INPUT' | translate }}
      </p>

      <input
        type="text"
        placeholder="Cargo"
        formControlName="position"
        [ngClass]="{ wrong: shouldShowError('centerName') }" />
      <p class="wrong-text" *ngIf="this.formularioContacto.get('position')?.errors?.['required'] && this.isOnSubmit">
        {{ 'SHARED.REQUIRED-INPUT' | translate }}
      </p>

      <input
        type="text"
        placeholder="Especialidad"
        formControlName="specialty"
        [ngClass]="{ wrong: shouldShowError('centerName') }" />
      <p class="wrong-text" *ngIf="this.formularioContacto.get('specialty')?.errors?.['required'] && this.isOnSubmit">
        {{ 'SHARED.REQUIRED-INPUT' | translate }}
      </p>
      <div class="half-size">
          <select formControlName="country" (change)="onCountryChange($event)" class="tipo-main-color">
            <option value="" disabled selected>Selecciona un país</option>
            <option *ngFor="let country of countries" [value]="country.name">{{ country.name }}</option>
          </select>
          <p class="wrong-text" *ngIf="this.formularioContacto.get('country')?.errors?.['required'] && this.isOnSubmit">
            {{ 'SHARED.REQUIRED-INPUT' | translate }}
          </p>
     </div>
     <div class="half-size">
        <select formControlName="province" class="tipo-main-color">
          <option value="" disabled selected >Selecciona una provincia</option>
          <option *ngFor="let province of provinces" [value]="province.name">{{ province.name }}</option>
        </select>
        <p class="wrong-text" *ngIf="this.formularioContacto.get('province')?.errors?.['required'] && this.isOnSubmit">
          {{ 'SHARED.REQUIRED-INPUT' | translate }}
        </p>
    </div>
      <input
        type="text"
        placeholder="Teléfono"
        formControlName="phone"
        [ngClass]="{ wrong: shouldShowError('centerName') }" />
      <p class="wrong-text" *ngIf="this.formularioContacto.get('phone')?.errors?.['required'] && this.isOnSubmit">
        {{ 'SHARED.REQUIRED-INPUT' | translate }}
      </p>
      <p class="wrong-text" *ngIf="this.formularioContacto.get('phone')?.errors?.['pattern'] && this.isOnSubmit">
        {{ 'SHARED.INVALID-PHONE-NUMBER' | translate }}
      </p>

      <input
        type="text"
        placeholder="Email"
        formControlName="email"
        [ngClass]="{ wrong: shouldShowError('centerName') }" />
      <p class="wrong-text" *ngIf="this.formularioContacto.get('email')?.errors?.['required'] && this.isOnSubmit">
        {{ 'SHARED.REQUIRED-INPUT' | translate }}
      </p>
      <p class="wrong-text" *ngIf="this.formularioContacto.get('email')?.errors?.['minlength'] && this.isOnSubmit">
        {{ 'SHARED.INVALID-LENGTH' | translate }}
      </p>
      <p class="wrong-text" *ngIf="this.formularioContacto.get('email')?.errors?.['invalidEmail'] && this.isOnSubmit">
        {{ 'SHARED.INVALID-EMAIL' | translate }}
      </p>

      <div class="rgpd">
        <div class="politics">
          <input type="checkbox" formControlName="check1" id="check1" />
          <label for="check1" id="acepto">
            He leído y acepto la
            <a [routerLink]="['/politicaprivacidad']" target="_blank" rel="noopener noreferrer" class="tipo-main-color">política de privacidad.</a>
          </label>
        </div>
        <div class="turnstile-container">
          <ngx-turnstile
            [siteKey]="siteKey"
            (resolved)="sendCaptchaResponse($event)"
            formControlName="captchaToken"
            ngDefaultControl></ngx-turnstile>
        </div>
      </div>
      <input type="submit" name="submit" id="submitButton" class="back-blue-color tipo-white-color" value="Enviar" />
    </form>
  </div>
</div>
