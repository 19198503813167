export class ErrorResponse {
    timestamp?: string;
    status: number;
    error?: string;
    messages?: string[];

    constructor(
        status: number
    ) {
        this.status = status;
        this.messages = []
    }
}