<p class="tipo-second-color bold section">{{ "PRIVATE.CONSENTS.PENDINGSIGNRL2-CONSENT.TITLE" | translate }}</p>
<div class="input_row">
    <div class="input">
        <select name="device" id="device" [(ngModel)]="selectValueDevice">
            <option [ngValue]="null" hidden>{{ "PRIVATE.CONSENTS.PENDINGBIO-CONSENT.INPUT-DEVICE" | translate }}</option>
            <option *ngFor= "let device of devices" ngValue="{{device.deviceName}}">{{device.deviceDescription}}</option>

        </select>
    </div>
    <button class="button tipo-white-color back-blue-color" [disabled]="isSendChecked" [ngClass]="{'back-blue-color': !isSendChecked, 'inactive': isSendChecked}" (click)="submitSecondRepresentativeDevice()">
        <fa-icon [icon]="faPaperPlane" class="icono"></fa-icon>
        {{ "PRIVATE.CONSENTS.EXPIRED-CONSENT.BUTTON-SEND" | translate }}
    </button>
</div>
