import { Component } from '@angular/core';

@Component({
  selector: 'app-company-hiring',
  templateUrl: './company-hiring.component.html',
  styleUrls: ['./company-hiring.component.scss']
})
export class CompanyHiringComponent {
  showPageOne = true;
  showPageTwo = false;
  showPageThree = false;
  showPageFour = false;
  showPageFive = false;
  showPageSix = false;



  togglePagesOneTwo() {
    this.showPageOne = !this.showPageOne;
    this.showPageTwo = !this.showPageTwo;  
  }
  
  togglePagesTwoThree() {
    this.showPageTwo = !this.showPageTwo;
    this.showPageThree = !this.showPageThree;
  }

  togglePagesThreeFour() {    
    this.showPageThree = !this.showPageThree;
    this.showPageFour = !this.showPageFour;
  }

  togglePagesFourFive() {
    this.showPageFour = !this.showPageFour;
    this.showPageFive = !this.showPageFive;
  }

  togglePagesFiveSix() {
    this.showPageFive = !this.showPageFive;
    this.showPageSix = !this.showPageSix;
  }

  togglePagesOneSix() {
    this.showPageOne = !this.showPageOne;
    this.showPageSix = !this.showPageSix;
  }

  
}
