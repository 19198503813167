import { Component, Input } from '@angular/core';
import { CompanyDataAdmin } from '@app/core/models/input/company/company.model';

@Component({
  selector: 'app-info-company',
  templateUrl: './info-company.component.html',
  styleUrls: ['./info-company.component.scss']
})
export class InfoCompanyComponent {
  @Input() isUsers = false;
  @Input() isListCompanies = false;
  @Input() companyData?: CompanyDataAdmin;

 
}
