<div class="wrapper">
  <div class="maxwidth">
    <div class="tabset">
      <!-- Tab 1 -->
      <input
        type="radio"
        name="tabset"
        id="tab1"
        aria-controls="global"
        [checked]="selectedTab === 'global'"
        (change)="changeTab('global')" />
      <label for="tab1" class="bold tipo-main-color" *appHasRole="HEADER_ROLES">
        {{ 'PRIVATE.PERSONALIZATION.CONFIGURATION.TITLE-TAB1' | translate }}
      </label>
      <!-- Tab 2 -->
      <input
        type="radio"
        name="tabset"
        id="tab2"
        aria-controls="devices"
        [checked]="selectedTab === 'devices'"
        (change)="changeTab('devices')" 
        *ngIf="isBiometric"/>
      <label for="tab2" class="bold tipo-main-color" *ngIf="isBiometric">
        {{ 'PRIVATE.PERSONALIZATION.CONFIGURATION.TITLE-TAB2' | translate }}
      </label>

      <!-- los bloques con class block-company son los que tienen que salir cuando se conecta una empresa. Los etiquetados como block-doctor cuando se conecta un médico -->
      <div class="tab-panels">
        <!-- datos corporativos -->
        <section id="global" class="tab-panel" *appHasRole="HEADER_ROLES">
          <div class="box_container">
            <div class="box box2" *appHasRole="COMPANY_ROLES">
              <app-edit-url  (companyEvent)="handleCompanyEnrollmentcode($event)"></app-edit-url>
            </div>
            <div class="box box2" *appHasRole="FREEMIUM_COMPANY_ROLES">
              <app-edit-logo></app-edit-logo>
            </div>
            <div class="box box2" *appHasRole="FREEMIUM_DOCTOR_ROLES">
              <app-edit-sign></app-edit-sign>
            </div>
          </div>
        </section>
        <!-- dispositivos -->
        <section id="devices" class="tab-panel">
          <div class="box_container" *ngIf="isBiometric">
            <div class="box box2" >
              <app-edit-device [devices]="devices"></app-edit-device>
            </div>
            <div class="box box1" *appHasRole="COMPANY_ROLES" >
              <app-devices [devices]="devices" [enrollmentCode]="enrollmentCode"></app-devices>
            </div>
          </div>
        </section>
      </div>
    </div>

  </div>
</div>
