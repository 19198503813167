<form [formGroup]="dataSignatureForm" class="dataconsent">
  <div class="box box2 box_gendocs">
    <div class="generate_doc">
      <p class="subtitle tipo-second-color bold">
        {{ 'PRIVATE.PATIENT.GENERATE-DOC.TITLE' | translate }}
      </p>
      <div class="sign_type">
        <button
          class="button tipo-white-color back-blue-color"
          [ngClass]="{button_selected: selectedOutputType === outputTypeEnum.DIGITAL_VID}"
          (click)="changeSubscriptionType(outputTypeEnum.DIGITAL_VID, isBiometric)">
          <fa-icon [icon]="faTabletScreenButton" class="icono"></fa-icon>
          {{ 'PRIVATE.PATIENT.GENERATE-DOC.DIGITAL' | translate }}
        </button>
        <button
        class="button tipo-white-color back-blue-color"
        [ngClass]="{ button_selected: selectedOutputType === outputTypeEnum.DIGITAL_REMOTE && isRemote, inactive: !isRemote }"
        (click)="changeSubscriptionType(outputTypeEnum.DIGITAL_REMOTE, isRemote)">
        <fa-icon [icon]="faEnvelopeCircleCheck" class="icono"></fa-icon>
        {{ 'PRIVATE.PATIENT.GENERATE-DOC.REMOTE' | translate }}
      </button>
      </div>
      <div class="digital" *ngIf="selectedOutputType === outputTypeEnum.DIGITAL_VID">
        <select formControlName="device" name="device" id="device" [ngClass]="{'wrong': shouldShowError('device')}">
          <option value="0">
            {{ 'PRIVATE.PATIENT.GENERATE-DOC.SELECT' | translate }}
          </option>
          <option
            *ngFor="let device of devices; let i = index"
            [value]="device.deviceName">
            {{ device.deviceDescription }}
          </option>
        </select>
        <p class="message_error wrong-text" *ngIf="this.dataSignatureForm.get('device')?.errors?.['notEqualToZero'] && isOnSubmit">{{"PRIVATE.CONSENTS.GENERATE-CONSENT.FORM-MESSAGES.DEVICE-REQUIRED" | translate }}</p>
      </div>
      <div class="remote" *ngIf="selectedOutputType === outputTypeEnum.DIGITAL_REMOTE && isRemote">
        <p
          class="message_error wrong-text"
          *ngIf="shouldShowError('patientEmail') || shouldShowError('patientPhone')  || shouldShowError('firstRepresentativeEmail') || shouldShowError('firstRepresentativePhone') || shouldShowError('secondRepresentativeEmail') || shouldShowError('secondRepresentativePhone')">
          {{"PRIVATE.PATIENT.GENERATE-DOC.MESSAGE-ERROR-EMAIL-PHONE" | translate}}
        </p>
        <div class="input_row">
          <input
            type="email"
            name="email"
            id="email"
            [ngClass]="{ wrong: shouldShowError('patientEmail') }"
            formControlName="patientEmail"
            *ngIf="numberOfRepresentatives() === 0"
            placeholder="{{ 'PRIVATE.CONSENTS.GENERATE-CONSENT.EMAIL-PATIENT' | translate }}" />

            <div class="phone_group" *ngIf="numberOfRepresentatives() === 0">
              <select formControlName="patientPrefix" class="prefix" >
                <option *ngFor= "let prefix of phonePrefixes" [value]="prefix.countryCode" >{{prefix.countryCode}}</option>
            </select>
              <input
              type="text"
              name="phone"
              class="phone"
              formControlName="patientPhone"
              placeholder="{{ 'PRIVATE.CONSENTS.GENERATE-CONSENT.PHONE-PATIENT' | translate }}" />
            </div>

        </div>

        <!-- Aparece si hay representantes legales -->
        <div class="input_row">
          <input
            type="email"
            name="email"
            id="email"
            formControlName="firstRepresentativeEmail"
            *ngIf="numberOfRepresentatives() === 1 || numberOfRepresentatives() === 2"
            placeholder="{{ 'PRIVATE.CONSENTS.GENERATE-CONSENT.EMAIL-RL1' | translate }}" />
            <div class="phone_group" *ngIf="numberOfRepresentatives() === 1 || numberOfRepresentatives() === 2">
              <input type="text" class="prefix" formControlName="firstRepresentativePrefix">
              <input
              type="text"
              name="phone"
              class="phone"
              formControlName="firstRepresentativePhone"
              placeholder="{{ 'PRIVATE.CONSENTS.GENERATE-CONSENT.PHONE-RL1' | translate }}" />
            </div>

        </div>
        <div class="input_row">
          <input
            type="email"
            name="email"
            id="email"
            formControlName="secondRepresentativeEmail"
            *ngIf="numberOfRepresentatives() === 2"
            placeholder="{{ 'PRIVATE.CONSENTS.GENERATE-CONSENT.EMAIL-RL2' | translate }}" />
            <div class="phone_group" *ngIf="numberOfRepresentatives() === 2">
              <input type="text" class="prefix" formControlName="secondRepresentativePrefix">
              <input
                type="text"
                name="phone"
                id="phone"
                formControlName="secondRepresentativePhone"
                placeholder="{{ 'PRIVATE.CONSENTS.GENERATE-CONSENT.PHONE-RL2' | translate }}" />
            </div>

        </div>

      </div>
      <div class="buttons" *ngIf="isPatientCreated">
        <button class="button tipo-white-color back-blue-color"
        [disabled]="isOnSubmit" [ngClass]="{'back-blue-color': !isOnSubmit, 'inactive': isOnSubmit}"
        (click)="onSubmit()">
          <fa-icon [icon]="faPaperPlane" class="icono"></fa-icon>
          {{ 'PRIVATE.PATIENT.GENERATE-DOC.BUTTON-SEND' | translate }}
        </button>
      </div>
    </div>
  </div>
</form>
