export class Variable {
    uuid?: string;
    name?: string;
    type?: string;
    anchor?: string;
    value?: string;
    config?: string;
    container?: string;
    requiered?: boolean;
    orderNumber?: number;
    globalIdentifier?: string;
}
